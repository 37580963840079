import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Menubar from "../components/Menubar";
import moment from "moment";
import { AuthContext } from "../components/AuthenticatedComponent";
import Content from "../components/Content";
import BookTable from "../components/BookTable";
import TeacherBookTable from "../components/TeacherBookTable";
import AdminClient from "../utils/Http/AdminClient";
import BranchClient from "../utils/Http/BranchClient";
import BookClient from "../utils/Http/BookClient";
import ScheduleClient from "../utils/Http/ScheduleClient";
import HolidayClient from "../utils/Http/HolidayClient";
import Constants from "../common/Constants";

class BookManagement extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props);

    this.state = {
      _branch: "",
      branches: [],
      weekDays: [],
      searchType: "name",
      realBooks: [],
      books: [],
      realTeachers: [],
      teachers: [],
      searchText: "",
      schedules: {},
      holidays: [],
    };
  }

  setDayOfWeek(dow) {
    let day = "";
    if (dow === 0) day = "일";
    if (dow === 1) day = "월";
    if (dow === 2) day = "화";
    if (dow === 3) day = "수";
    if (dow === 4) day = "목";
    if (dow === 5) day = "금";
    if (dow === 6) day = "토";

    return day;
  }

  setWeekday(firstDay) {
    let days = [];
    for (var i = 0; i < 7; i++) {
      let nextDay = moment(firstDay).add(i, "days");
      let dow = this.setDayOfWeek(i);
      days.push({
        date: nextDay.format("YYYY-MM-DD"),
        dow: dow,
      });
    }
    return days;
  }

  setWeekCalendar = (date) => {
    const momentNow = moment(date);
    const firstDayOfMonth = momentNow.clone().startOf("month");
    const firstDayOfCalendar = firstDayOfMonth.clone().startOf("week");
    const offset = firstDayOfMonth.diff(firstDayOfCalendar, "days");
    const dow = momentNow.day();
    const nWeekOfMonth = Math.ceil((momentNow.date() + offset) / 7);
    const year = momentNow.get("year");
    const month = momentNow.get("month");

    const firstDayOfWeek = momentNow.subtract(dow, "days").format("YYYY-MM-DD");
    const lastDayOfWeek = moment(firstDayOfWeek)
      .add(6, "days")
      .format("YYYY-MM-DD");
    const weekDays = this.setWeekday(firstDayOfWeek);
    return {
      momentNow: momentNow,
      nWeekOfMonth: nWeekOfMonth,
      year: year,
      month: month + 1,
      firstDayOfWeek: firstDayOfWeek,
      lastDayOfWeek: lastDayOfWeek,
      weekDays: weekDays,
      dow: dow,
    };
  };

  setTeachers = async (_branch) => {
    try {
      let teachersRes = {
        data: {
          success: false,
          result: [],
        },
      };
      if (this.context.role === "강사") {
        const resultTeacher = await AdminClient.getAdmin(this.context._id);
        teachersRes.data.success = resultTeacher.data.success;
        teachersRes.data.result.push(resultTeacher.data.result);
      } else {
        teachersRes = await AdminClient.getList({
          _branch: _branch,
          role: "강사",
        });
      }
      return teachersRes;
    } catch (error) {
      return null;
    }
  };

  setBranchAdmins = async (_branch) => {
    try {
      const branchAdminRes = await AdminClient.getAdminList({
        _branch: _branch,
        role: this.context.role,
      });
      return branchAdminRes;
    } catch (error) {
      return null;
    }
  };

  setBooks = async (_branch, firstDayOfWeek) => {
    try {
      var query = "";
      var bookRes = {};
      if (this.context.role === "강사") {
        const start_at = moment(firstDayOfWeek);
        const end_at = start_at.clone().add(7, "days");
        bookRes = await BookClient.getTeacherBookList({
          _teacher: this.context._id,
          start_at: start_at.format("YYYY-MM-DD"),
          end_at: end_at.format("YYYY-MM-DD"),
        });
      } else {
        const lesson_at = moment(firstDayOfWeek)
          .add(this.state.dow, "days")
          .format("YYYY-MM-DD");
        bookRes = await BookClient.getItems({
          _branch: _branch,
          lesson_at: lesson_at,
        });
      }

      if (bookRes.data.success) {
        bookRes.data.result.map((book) => {
          if (book.books.length > 0) {
            book.books = book.books.sort((a, b) => {
              if (a.lesson_at == b.lesson_at) {
                if (a.lesson_time < b.lesson_time) {
                  return 1;
                } else return -1;
              }

              if (a.lesson_at < b.lesson_at) {
                return 1;
              } else return -1;
            });
            // console.log(book.books);
          }
        });
      }

      return bookRes;
    } catch (error) {
      return null;
    }
  };

  onChangeNextWeek = async () => {
    const firstDayOfNextWeek = moment(this.state.firstDayOfWeek).add(7, "days");
    let nextWeek = this.setWeekCalendar(firstDayOfNextWeek);

    try {
      const books = await this.setBooks(
        this.state._branch,
        nextWeek.firstDayOfWeek
      );

      if (books.data.success) {
        this.setState(
          {
            nWeekOfMonth: nextWeek.nWeekOfMonth,
            year: nextWeek.year,
            month: nextWeek.month,
            firstDayOfWeek: nextWeek.firstDayOfWeek,
            lastDayOfWeek: nextWeek.lastDayOfWeek,
            weekDays: nextWeek.weekDays,
            books: books.data.result,
            // newBookObject: newBookObject,
          },
          () => {
            this.loadSchedule();
          }
        );
      } else {
        alert(books.data.message);
      }
    } catch (error) {
      alert(error);
    }

    // let newBookObject = this.setBookData(nextWeek.weekDays, this.state.realBooks, this.state.realTeachers);
  };

  onChangePreWeek = async () => {
    const firstDayOfPreWeek = moment(this.state.firstDayOfWeek).subtract(
      7,
      "days"
    );
    let preWeek = this.setWeekCalendar(firstDayOfPreWeek);

    try {
      const books = await this.setBooks(
        this.state._branch,
        preWeek.firstDayOfWeek
      );

      if (books.data.success) {
        this.setState(
          {
            nWeekOfMonth: preWeek.nWeekOfMonth,
            year: preWeek.year,
            month: preWeek.month,
            firstDayOfWeek: preWeek.firstDayOfWeek,
            lastDayOfWeek: preWeek.lastDayOfWeek,
            weekDays: preWeek.weekDays,
            books: books.data.result,
          },
          () => {
            this.loadSchedule();
          }
        );
      } else {
        alert(books.data.message);
      }
    } catch (error) {
      alert(error);
    }

    // this.setState({
    // });
    // let newBookObject = this.setBookData(preWeek.weekDays, this.state.realBooks, this.state.realTeachers);
  };

  onChangeBranch = async (e) => {
    // const teachers = await this.setTeachers(e.target.value)
    // const books = await this.setBooks(e.target.value)

    const value = e.target.value;
    try {
      const teachers = await AdminClient.getAdminList({
        _branch: e.target.value,
        role: "강사",
      });
      const books = await this.setBooks(value, this.state.firstDayOfWeek);
      const branchAdmins = await this.setBranchAdmins(value);

      if (teachers.data.success && books.data.success) {
        const newBookObject = await this.setBookData(
          this.state.weekDays,
          books.data.result,
          teachers.data.result
        );

        const holidayRes = await HolidayClient.getList({
          _branch: e.target.value,
        });

        if (!holidayRes.data.success) {
          alert(holidayRes.data.message);
        }

        this.setState(
          {
            _branch: value,
            teachers: teachers.data.result,
            books: books.data.result,
            branchAdmins: branchAdmins.data.result,
            newBookObject: newBookObject,
            holidays: holidayRes.data.success ? holidayRes.data.result : [],
          },
          () => {
            this.loadSchedule();
          }
        );
      }
    } catch (error) {
      alert("데이터를 불러올 수 없습니다.");
    }
  };

  onChangeSearchText = (e) => {
    // this.setState({
    //     searchText: e.target.value
    // })
  };

  onBlurSearchText = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  setSearchType = (e) => {
    this.setState({
      searchType: e.target.value,
      searchText: "",
      books: this.state.realBooks,
      teachers: this.state.realTeachers,
    });
  };
  setSearchText = (e) => {
    this.setState({ searchText: e.target.value });
  };

  onSubmitSearch = async (e) => {
    if (this.state.searchType === "name" || this.state.searchType === "phone") {
      if (this.state.searchText === "" || !this.state.searchText) {
        const newBookObject = await this.setBookData(
          this.state.weekDays,
          this.state.realBooks,
          this.state.realTeachers
        );
        this.setState({
          books: this.state.realBooks,
          searchText: null,
          newBookObject: newBookObject,
        });
      } else {
        const result = this.state.realBooks.filter(
          (item) => item._user[this.state.searchType] === this.state.searchText
        );
        const newBookObject = await this.setBookData(
          this.state.weekDays,
          result,
          this.state.realTeachers
        );
        this.setState({
          books: result,
          searchText: this.state.searchText,
          newBookObject: newBookObject,
        });
      }
    } else {
      if (this.state.searchText === "" || !this.state.searchText) {
        const newBookObject = await this.setBookData(
          this.state.weekDays,
          this.state.realBooks,
          this.state.realTeachers
        );
        this.setState({
          books: this.state.realBooks,
          teachers: this.state.realTeachers,
          searchText: null,
          newBookObject: newBookObject,
        });
      } else {
        const result = this.state.realTeachers.filter(
          (item) => item.name === this.state.searchText
        );
        const newBookObject = await this.setBookData(
          this.state.weekDays,
          this.state.realBooks,
          result
        );

        this.setState({
          teachers: result,
          searchText: this.state.searchText,
          newBookObject: newBookObject,
        });
      }
    }
  };

  setBookData = (weekDays, bookRes, teacherRes) => {
    let newBookObject = weekDays.map((item) => {
      let newObj = {};
      newObj.weekDay = item;
      newObj.books = bookRes.filter((bookItem) => {
        let momentLessonAt = moment(bookItem.lesson_at).format("YYYY-MM-DD");
        return item.date === momentLessonAt;
      });

      let filteredTeachers = [];
      if (this.context.role === "강사") {
        filteredTeachers = [this.context._id];
      } else {
        let teachers = newObj.books.map((bookItem) => bookItem._teacher);
        filteredTeachers = [...new Set(teachers)];
      }

      let newTeachers = [];
      filteredTeachers.forEach((teacherItem, index) => {
        let findTeacher = teacherRes.find((item) => item._id === teacherItem);
        if (findTeacher) {
          newTeachers.push(findTeacher);
        } else {
          newTeachers.push({ _id: teacherItem, name: "강사없음" });
        }
      });

      newObj.teachers = newTeachers;
      return newObj;
    });

    return newBookObject;
  };

  isHoliday(date) {
    var year = date.year();
    var month = date.month() + 1;
    var day = date.date();

    var holiday = this.state.holidays.find(function (item) {
      return item.year === year && item.month === month && item.day === day;
    });
    return holiday !== undefined;
  }

  componentDidMount = async () => {
    const calendarDate = await this.setWeekCalendar(Date.now());

    try {
      let branchRes = {
        data: {
          success: false,
          result: [],
        },
      };

      if (
        this.context.role === "지점관리자" ||
        this.context.role === "매니저" ||
        this.context.role === "강사"
      ) {
        branchRes.data.success = true;
        branchRes.data.result = [this.context.branch];
      } else {
        branchRes = await BranchClient.getList();
      }
      // console.log(branchRes);
      if (branchRes.data.success) {
        if (branchRes.data.result.length > 0) {
          let teacherRes = {
            data: {
              success: false,
              result: [],
            },
          };
          // if (this.context.role === '강사') {
          //   teacherRes.data.success = true;
          //   teacherRes.data.result.push({
          //     name: this.context.name,
          //     _id: this.context._id,
          //   });
          // } else {
          teacherRes = await this.setTeachers(branchRes.data.result[0]._id);
          // console.log(teacherRes);
          // }
          const branchAdminRes = await this.setBranchAdmins(
            branchRes.data.result[0]._id
          );
          // console.log(branchAdminRes);
          if (teacherRes.data.success && branchAdminRes.data.success) {
            const bookRes = await this.setBooks(branchRes.data.result[0]._id);
            // console.log(bookRes);
            if (bookRes.data.success) {
              const newBookObject = await this.setBookData(
                calendarDate.weekDays,
                bookRes.data.result,
                teacherRes.data.result
              );

              const holidayRes = await HolidayClient.getList({
                _branch: branchRes.data.result[0]._id,
              });

              if (!holidayRes.data.success) {
                alert(holidayRes.data.message);
              }

              this.setState(
                {
                  newBookObject: newBookObject,
                  branches: branchRes.data.result,
                  _branch: branchRes.data.result[0]._id,
                  nWeekOfMonth: calendarDate.nWeekOfMonth,
                  momentNow: calendarDate.momentNow,
                  year: calendarDate.year,
                  month: calendarDate.month,
                  firstDayOfWeek: calendarDate.firstDayOfWeek,
                  lastDayOfWeek: calendarDate.lastDayOfWeek,
                  weekDays: calendarDate.weekDays,
                  branchAdmins: branchAdminRes.data.result,
                  realBooks: bookRes.data.result,
                  books: bookRes.data.result,
                  realTeachers: teacherRes.data.result,
                  teachers: teacherRes.data.result,
                  dow: calendarDate.dow,
                  holidays: holidayRes.data.success
                    ? holidayRes.data.result
                    : [],
                },
                () => {
                  this.loadSchedule();
                }
              );
            } else {
              alert("예약정보를 가져올 수 없습니다.");
            }
          } else {
            alert("선생님 / 지점장 정보를 가져올 수 없습니다.");
          }
        } else {
          alert("등록된 선생님 정보가 없습니다. 선생님을 등록하세요.");
        }
      } else {
        alert("지점 정보를 가져올 수 없습니다.");
      }
    } catch (error) {
      console.log(error);
      alert("데이터를 불러올 수 없습니다..");
    }
  };

  loadSchedule = async () => {
    if (this.context.role === "강사") {
      await this.loadScheduleTeacher();
    } else {
      await this.loadScheduleAdmin();
    }
  };

  loadScheduleTeacher = async () => {
    const start_date = moment(this.state.firstDayOfWeek).format("YYYY-MM-DD");
    const end_date = moment(this.state.firstDayOfWeek)
      .endOf("week")
      .format("YYYY-MM-DD");

    const scheduleRes = await ScheduleClient.getSchedule(this.context._id, {
      start_at: start_date,
      end_at: end_date,
    });

    if (!scheduleRes.data.success) {
      alert(scheduleRes.data.message);
    }

    // console.log(scheduleRes.data.result);

    var schedules = [];

    for (var dow = 0; dow < 7; ++dow) {
      schedules.push([]);

      Constants.times.forEach((time) => {
        var initValue = {
          allowHalfTime: false,
          openOnTime: Constants.scheduleState.Open,
          openHalf: Constants.scheduleState.Close,
        };

        if (scheduleRes.data.result.allowHalfTimes[dow]) {
          initValue.allowHalfTime = true;
          initValue.openHalf = Constants.scheduleState.Open;
        }
        schedules[dow][time] = initValue;
      });
    }

    // console.log(schedules);

    // console.log(scheduleRes.data.result.unable_schedule);
    scheduleRes.data.result.unable_schedule.forEach((unable) => {
      var splits = unable.time.split(":");
      var schedule = schedules[unable.dow][`${splits[0]}:00`];

      if (splits[1] == "00") {
        schedule.openOnTime = Constants.scheduleState.Close;
      } else {
        schedule.openHalf = Constants.scheduleState.Close;
      }
    });

    scheduleRes.data.result.temporarySchedule.forEach((temporarySchedule) => {
      const splits = temporarySchedule.lessonTime.split(":");
      const hour = splits[0];
      const minutes = splits[1];
      const dow = moment(temporarySchedule.lessonDate).day();
      const schedule = schedules[dow][`${hour}:00`];

      if (minutes == "00") {
        schedule.openOnTime =
          temporarySchedule.status == 1
            ? Constants.scheduleState.Open
            : Constants.scheduleState.Close;
      } else {
        schedule.openHalf =
          temporarySchedule.status == 1
            ? Constants.scheduleState.Open
            : Constants.scheduleState.Close;
      }
    });

    scheduleRes.data.result.temporaryScheduleHalfTime.forEach(
      (temporaryScheduleHalfTime) => {
        const splits = temporaryScheduleHalfTime.lessonTime.split(":");
        const hour = splits[0];
        const dow = moment(temporaryScheduleHalfTime.lessonDate).day();
        const schedule = schedules[dow][`${hour}:00`];
        schedule.allowHalfTime =
          temporaryScheduleHalfTime.type == 1 ? true : false;
      }
    );

    this.state.books.forEach((book) => {
      if (book.status == "cancel") return;
      const dow = moment(book.lesson_at).day();
      const schedule = schedules[dow];
      if (!schedule) return;

      var splits = book.lesson_time.split(":");
      if (splits[1] == "00") {
        schedule[book.lesson_time].openOnTime = Constants.scheduleState.Booked;
        if (book.lesson_type !== Constants.lessonType.Thirty) {
          schedule[book.lesson_time].openHalf = Constants.scheduleState.Booked;
        }
      } else {
        schedule[`${splits[0]}:00`].openHalf = Constants.scheduleState.Booked;
        if (book.lesson_type !== Constants.lessonType.Thirty) {
          schedule[`${this.getNextHourString(splits[0])}:00`].openOnTime =
            Constants.scheduleState.Booked;
        }
      }
    });
    // console.log(schedules);

    this.setState({
      schedules: schedules,
    });
  };

  loadScheduleAdmin = async () => {
    const scheduleRes = await ScheduleClient.getScheduleAll({
      _branch: this.state._branch,
      lessonDate: moment(this.state.firstDayOfWeek)
        .add(this.state.dow, "days")
        .format("YYYY-MM-DD"),
    });
    // console.log(scheduleRes.data.result);

    if (!scheduleRes.data.success) {
      alert(scheduleRes.data.message);
    }

    // console.log(scheduleRes.data.result);

    var schedules = [];

    scheduleRes.data.result.schedules.forEach((schedule) => {
      // console.log(schedule);
      schedules[schedule._teacher] = {};

      Constants.times.forEach((time) => {
        var initValue = {
          allowHalfTime: false,
          openOnTime: Constants.scheduleState.Open,
          openHalf: Constants.scheduleState.Close,
        };

        if (schedule.allowHalfTimes[this.state.dow]) {
          initValue.allowHalfTime = true;
          initValue.openHalf = Constants.scheduleState.Open;
        }
        schedules[schedule._teacher][time] = initValue;
      });

      schedule.unable_schedule.forEach((unable) => {
        var splits = unable.time.split(":");
        // console.log(unable.time);
        if (this.state.dow == unable.dow) {
          const time = `${splits[0]}:00`;
          if (splits[1] == "00") {
            schedules[schedule._teacher][time].openOnTime =
              Constants.scheduleState.Close;
          } else {
            schedules[schedule._teacher][time].openHalf =
              Constants.scheduleState.Close;
          }
        }
      });
    });

    scheduleRes.data.result.temporarySchedules.forEach((temporarySchedule) => {
      const splits = temporarySchedule.lessonTime.split(":");
      const hour = splits[0];
      const minutes = splits[1];
      const schedule = schedules[temporarySchedule._teacher][`${hour}:00`];

      if (minutes == "00") {
        schedule.openOnTime =
          temporarySchedule.status == 1
            ? Constants.scheduleState.Open
            : Constants.scheduleState.Close;
      } else {
        schedule.openHalf =
          temporarySchedule.status == 1
            ? Constants.scheduleState.Open
            : Constants.scheduleState.Close;
      }
    });

    scheduleRes.data.result.temporaryScheduleHalfTimes.forEach(
      (temporaryScheduleHalfTime) => {
        const splits = temporaryScheduleHalfTime.lessonTime.split(":");
        const hour = splits[0];
        const schedule =
          schedules[temporaryScheduleHalfTime._teacher][`${hour}:00`];
        schedule.allowHalfTime =
          temporaryScheduleHalfTime.type == 1 ? true : false;
      }
    );

    // console.log(this.state.books);
    this.state.books.forEach((book) => {
      if (book.status == "cancel") return;
      const schedule = schedules[book._teacher];
      if (!schedule) return;

      var splits = book.lesson_time.split(":");
      if (splits[1] == "00") {
        schedule[book.lesson_time].openOnTime = Constants.scheduleState.Booked;
        if (book.lesson_type !== Constants.lessonType.Thirty) {
          schedule[book.lesson_time].openHalf = Constants.scheduleState.Booked;
        }
      } else {
        schedule[`${splits[0]}:00`].openHalf = Constants.scheduleState.Booked;
        if (book.lesson_type !== Constants.lessonType.Thirty) {
          schedule[`${this.getNextHourString(splits[0])}:00`].openOnTime =
            Constants.scheduleState.Booked;
        }
      }
    });

    // console.log(schedules);

    // for (var dow = 0; dow < 7; ++dow) {
    //   schedules.push([]);

    //   Constants.times.forEach((time) => {
    //     var initValue = {
    //       allowHalfTime: false,
    //       openOnTime: true,
    //       openHalf: false,
    //     };

    //     if (scheduleRes.data.result.allowHalfTimes[dow]) {
    //       initValue.allowHalfTime = true;
    //       initValue.openHalf = true;
    //     }
    //     schedules[dow][time] = initValue;
    //   });
    // }

    // // console.log(schedules);

    // scheduleRes.data.result.unable_schedule.forEach((unable) => {
    //   var splits = unable.time.split(":");
    //   var schedule = schedules[unable.dow][`${splits[0]}:00`];

    //   if (splits[1] == "00") {
    //     schedule.openOnTime = false;
    //   } else {
    //     schedule.openHalf = false;
    //   }
    // });

    // console.log(schedules);

    this.setState({
      schedules: schedules,
    });
  };

  getNextHourString(hour) {
    return ("00" + (Number(hour) + 1).toString()).slice(-2);
  }

  scroll = (target) => {
    let element = document.getElementById("bookmangement");
    let width = document.getElementById("sc-1").offsetWidth;
    element.scrollTo({ left: width * target, behavior: "smooth" });
  };

  onChangeDay = async (dow) => {
    this.state.dow = dow;

    const books = await this.setBooks(
      this.state._branch,
      this.state.firstDayOfWeek
    );

    this.setState(
      {
        books: books.data.result,
      },
      () => {
        this.loadSchedule();
      }
    );
  };

  onContextMenu = async (e) => {
    e.preventDefault();

    try {
      if (this.context.role === "강사") return;

      // useContextMenu({
      //   id: MENU_ID
      // });
      // alert('asdf');
    } catch (error) {
      alert(error);
    }
  };

  getTodayString() {
    var today = moment(this.state.firstDayOfWeek)
      .add(this.state.dow, "days")
      .format("YYYY년 MM월 DD일 ");
    return today + this.setDayOfWeek(this.state.dow) + "요일";
  }

  render_lessonNotePageButton() {
    const start_date = moment(this.state.firstDayOfWeek);
    const end_date = start_date.clone().add(6, "days");
    const start_at = start_date.format("YYYY-MM-DD");
    const end_at = end_date.format("YYYY-MM-DD");
    return (
      <div className="nk-block-head-content">
        <div className="toggle-wrap nk-block-tools-toggle">
          <a
            href="#"
            className="btn btn-icon btn-trigger toggle-expand mr-n1"
            data-target="pageMenu"
          >
            <em className="icon ni ni-menu-alt-r"></em>
          </a>
          <div className="toggle-expand-content" data-content="pageMenu">
            <ul className="nk-block-tools g-3">
              <li className="nk-block-tools-opt">
                <Link
                  to={`/teacher/lessonnote?start_at=${start_at}&end_at=${end_at}`}
                >
                  <a
                    href={`/teacher/lessonnote?start_at=${start_at}&end_at=${end_at}`}
                    className="btn btn-primary"
                  >
                    레슨일지
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  render_appointmentButton() {
    return (
      <div className="nk-block-head-content">
        <div className="toggle-wrap nk-block-tools-toggle">
          <a
            href="#"
            className="btn btn-icon btn-trigger toggle-expand mr-n1"
            data-target="pageMenu"
          >
            <em className="icon ni ni-menu-alt-r"></em>
          </a>
          <div className="toggle-expand-content" data-content="pageMenu">
            <ul className="nk-block-tools g-3">
              <li className="nk-block-tools-opt">
                <Link to="/book/bookregister">
                  <a href="/book/bookregister" className="btn btn-primary">
                    예약추가
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const branchAdminsLabel = () => {
      const label = this.state.branchAdmins
        .filter((item) => !item.name.includes("개발자"))
        .map((item, index) => {
          return (
            <div key={index} className="mt-1">
              {/* <a href="/admin/adminmodify/5ffee3ac89e8664a59373cc2"> */}
              {item.name}
              {/* </a> */}
            </div>
          );
        });
      return label;
    };

    var is_teacher = this.context.role === "강사";

    const date = moment(this.state.firstDayOfWeek).add(this.state.dow, "days");

    return (
      <>
        <Menubar />
        <Content>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">예약관리</h3>
                <div className="nk-block-des text-soft">
                  <p>주차별 예약관리</p>
                </div>
              </div>
              {is_teacher
                ? this.render_lessonNotePageButton()
                : this.render_appointmentButton()}
            </div>
          </div>
          <div className="nk-block">
            <div className="card card-bordered card-stretch">
              <div className="card-inner-group">
                <div className="card-inner position-relative card-tools-toggle">
                  <div className="card-title-group d-md-flex d-block">
                    <div className="card-tools mb-1">
                      <div className="form-inline flex-nowrap gx-3">
                        <button
                          className="btn btn-link"
                          onClick={this.onChangePreWeek}
                        >
                          ⟪&nbsp;지난주
                        </button>
                        <h5 className="mb-0">
                          {moment(this.state.firstDayOfWeek).get("year")}년{" "}
                          {moment(this.state.firstDayOfWeek).get("month") + 1}월{" "}
                          {moment(this.state.firstDayOfWeek).get("date")}일 ~{" "}
                          {moment(this.state.lastDayOfWeek).get("year")}년{" "}
                          {moment(this.state.lastDayOfWeek).get("month") + 1}월{" "}
                          {moment(this.state.lastDayOfWeek).get("date")}일 (
                          {this.state.nWeekOfMonth} 주차)
                        </h5>
                        <button
                          className="btn btn-link"
                          onClick={this.onChangeNextWeek}
                        >
                          다음주&nbsp;⟫
                        </button>
                      </div>
                    </div>
                    <div className="card-tools mr-n1">
                      <ul className="btn-toolbar gx-1">
                        <li>
                          <a
                            href={null}
                            className="btn btn-icon search-toggle toggle-search"
                            data-target="search"
                          >
                            <em className="icon ni ni-search"></em>
                          </a>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <li>
                          <div className="d-flex">
                            <div className="mr-1">
                              <select
                                className="form-control"
                                name="searchType"
                                onChange={this.setSearchType}
                                defaultValue={this.state.searchType}
                              >
                                <option value="" disabled>
                                  검색옵션
                                </option>
                                <option value="name">회원이름</option>
                                <option value="phone">전화번호</option>
                                {this.context.role === "강사" ? null : (
                                  <option value="teacher">강사이름</option>
                                )}
                              </select>
                            </div>
                            <div>
                              <select
                                name="_branch"
                                id="selectdrop"
                                className="form-control"
                                onChange={this.onChangeBranch}
                                value={this.state._branch}
                              >
                                <option value="" disabled>
                                  지점 선택
                                </option>
                                {this.state.branches.map((item, index) => {
                                  return (
                                    <option key={index} value={item._id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="card-search search-wrap" data-search="search">
                    <div className="card-body">
                      <div className="search-content">
                        <div className="row">
                          <a
                            href={null}
                            className="search-back btn btn-icon toggle-search"
                            data-target="search"
                          >
                            <em className="icon ni ni-arrow-left"></em>
                          </a>
                          <input
                            type="text"
                            className="form-control border-transparent form-focus-none"
                            placeholder="검색"
                            onChange={this.setSearchText}
                          />
                        </div>
                        <button className="search-submit btn btn-icon">
                          <em
                            className="icon ni ni-search"
                            onClick={this.onSubmitSearch}
                          ></em>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {is_teacher ? (
                  <div className="card-inner p-0">
                    <TeacherBookTable
                      _branch={this.state._branch}
                      _teacher={this.context._id}
                      weekDays={this.state.weekDays}
                      books={this.state.books}
                      schedules={this.state.schedules}
                    />
                  </div>
                ) : (
                  <div className="card-inner p-0">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6 align-self-center">
                          <h5 className="card-title mb-0 ">
                            {this.getTodayString()}
                          </h5>
                        </div>
                        <div className="col-md-6 text-right">
                          <div className="btn-group" aria-label="Basic example">
                            <button
                              className="btn btn-primary"
                              onClick={() => this.onChangeDay(0)}
                            >
                              일
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => this.onChangeDay(1)}
                            >
                              월
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => this.onChangeDay(2)}
                            >
                              화
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => this.onChangeDay(3)}
                            >
                              수
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => this.onChangeDay(4)}
                            >
                              목
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => this.onChangeDay(5)}
                            >
                              금
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => this.onChangeDay(6)}
                            >
                              토
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <BookTable
                      _branch={this.state._branch}
                      teachers={this.state.teachers}
                      books={this.state.books}
                      schedules={this.state.schedules}
                      isHoliday={this.isHoliday(date)}
                      lessonDate={date.format("YYYY-MM-DD")}
                      dow={this.state.dow}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <ContextMenu /> */}
        </Content>
      </>
    );
  }
}

export default withRouter(BookManagement);
