import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import { AuthContext } from "../components/AuthenticatedComponent";
import Validation from "../utils/Validations";
import Content from "../components/Content";
import AdminClient from "../utils/Http/AdminClient";
import BranchClient from "../utils/Http/BranchClient";
import SubjectClient from "../utils/Http/SubjectClient";
import moment from 'moment';
import BookClient from "../utils/Http/BookClient";

class TeacherProfile extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            id: "",
            name: "",
            phone: "",
            _branch: "",
            branchArr: [],
            _subject: "",
            subjectArr: [],
            password1: "",
            password2: "",
            role: "강사",
            lesson_price: 0,
            cancel_price: 0,
            contract_period_start: moment().format('YYYY-MM-DD'),
            contract_period_end: moment().format('YYYY-MM-DD'),
            teacher_list: [],
            selected_teacher: "",
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount = async () => {
        try {
            const res = await AdminClient.getAdmin(this.props.match.params.id);
            const branchList = await BranchClient.getList();
            const subjectList = await SubjectClient.getList();
            const teacherList = await AdminClient.getAdminList({
                role: '강사',
                _branch: res.data.result._branch._id,
            });

            this.setState({
                id: res.data.result.id,
                name: res.data.result.name,
                phone: res.data.result.phone,
                lesson_price: res.data.result.lesson_price,
                cancel_price: res.data.result.cancel_price,
                contract_period_start: moment(res.data.result.contract_period.start).format('YYYY-MM-DD'),
                contract_period_end: moment(res.data.result.contract_period.end).format('YYYY-MM-DD'),
                _branch: res.data.result._branch
                    ? res.data.result._branch._id
                    : null,
                _subject: res.data.result._subject
                    ? res.data.result._subject._id
                    : null,
                branchArr: branchList.data.success
                    ? branchList.data.result
                    : [],
                subjectArr: subjectList.data.success
                    ? subjectList.data.result
                    : [],
                teacher_list: teacherList.data.result.filter((item) => item._id != this.props.match.params.id),
            });
        } catch (err) {
            console.log(err);
        }
    };

    onChange = (e) => {
        console.log(e.target.name, e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        if(this.state.selected_teacher === "")
        {
            alert("이전 받을 강사를 선택 해 주세요.");
            return;
        }
        try{
            const res = await BookClient.lessonTransfer({ from: this.state.selected_teacher, to: this.props.match.params.id});

            if (res.data.success === true) {
                alert('레슨 이전 성공');
                // this.props.history.push('/teacher/teacherlist');
                // window.location.href = "/teacher/teacherlist";
            } else {
                alert(res.data.message);
            }
        }
        catch(err){
            console.log(err);
            alert(err.message + "실패");
        }
    };
    render() {
        // console.log(this.context);

        //   if(!this.state._branch) {
        //       return null
        //   }

        if (!this.state.id) {
            return null;
        }
        return (
            <>
                <Menubar />
                <Content>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">
                                    강사 프로필
                                </h3>
                                {/* <div className="nk-block-des text-soft">
                                <p>{this.state.users.length}건의 데이터 조회</p>
                            </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="nk-block">
                        <div class="btn-group mb-2" aria-label="Basic example">
                            <Link
                                to={`/teacher/teacherprofile/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/teacher/teacherprofile/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="linkbar">프로필</p>
                                </a>
                            </Link>
                            <Link
                                to={`/teacher/teacherschedule/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/teacher/teacherschedule/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="nolinkbar">
                                        스케쥴 관리
                                    </p>
                                </a>
                            </Link>
                            <Link
                                to={`/teacher/teacherbookstatus/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/teacher/teacherbookstatus/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="nolinkbar">예약 현황</p>
                                </a>
                            </Link>
                            <Link
                                to={`/admin/teacherlessonnote/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/admin/teacherlessonnote/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="nolinkbar">레슨 노트</p>
                                </a>
                            </Link>
                            <Link
                                to={`/teacher/teacherlessontransfer/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/teacher/teacherlessontransfer/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="nolinkbar">레슨 이전</p>
                                </a>
                            </Link>
                        </div>
                        <div className="card card-bordered">
                            <div className="card-inner">
                                {/* <div className="card-head">
                                    <h5 className="card-title">강사계정</h5>
                                </div> */}
                                <form onSubmit={this.onSubmit} className="gy-3">
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    이름
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        defaultValue={
                                                            this.state.name
                                                        }
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    계약 기간
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col duration">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name="contract_period_start"
                                                        value={this.state.contract_period_start}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name="contract_period_end"
                                                        value={this.state.contract_period_end}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    강사 선택
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <select
                                                        className="form-control"
                                                        name="selected_teacher"
                                                        id="selected_teacher"
                                                        onChange={this.onChange}
                                                        required="required"
                                                    >
                                                        {/* <option value='' disabled>선택</option> */}
                                                        <option value="">
                                                            강사를 선택해주세요.
                                                        </option>
                                                        {this.state.teacher_list.map(
                                                            (item, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={
                                                                        item._id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3">
                                        <div className="col-lg-7 offset-lg-5">
                                            <div className="form-group mt-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-primary mr-1"
                                                >
                                                    가져오기
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Content>
            </>
        );
    }
}

export default withRouter(TeacherProfile);