import AxiosClient from "./AxiosClient";

const AdminClient = {
    create: async (data) => {
        return await AxiosClient.post('admin', data);
    },

    getAdmin: async (data) => {
        return await AxiosClient.get(`admin/list/${data}`);
    },

    getList: async(data) =>{
        return await AxiosClient.get('admin/list', data);
    },

    getAdminList: async(data) =>{
        return await AxiosClient.get('admin/adminList', data);
    },

    delete: async (data) => {
        return await AxiosClient.del('admin/delete', data);
    },

    update: async (id, data) => {
        return await AxiosClient.patch(`admin/update/${id}`, data);
    },

    login: async(data) => {
        return await AxiosClient.post('admin/login', data);
    },

    passwordSearch: async(data) => {
        return await AxiosClient.post('admin/passwordsearch', data);
    },

    reset: async(id, data) => {
        return await AxiosClient.patch(`admin/reset/${id}`, data);
    },

    getTeacherLessonRate: async(data) => {
        return await AxiosClient.get('admin/teacherLessonRate', data);
    },

    getKakaoLogin: async () => {
        return await AxiosClient.get(`admin/kakaologin`);
    },

    kakaoConnect: async (id, data) => {
        return await AxiosClient.patch(`admin/kakaoconnect/${id}`, data);
    },

    kakaoDisConnect: async (id, data) => {
        return await AxiosClient.patch(`admin/kakaodisconnect/${id}`, data);
    },

    getkakaoFriends: async (data) => {
        return await AxiosClient.get('admin/kakaofriends', data);
    }
}

export default AdminClient;