import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts"
import DashboardClient from "../utils/Http/DashboardClient"
import ReactModal from 'react-modal';

var constants = require("../common/Constants");
const ModalItem = {
    None: { id: 0, name: "" },
    NewUser: { id: 1, name: "신규 회원 수" },
    User: { id: 2, name: "회원 수" },
    Sales: { id: 3, name: "매출" },
    Ticket: { id: 4, name: "수강권 구매 비율" },
    Funnels: { id: 5, name: "회원 유입 경로" },
};

export default function DashboardMonthlyStatistics ({ branchList, _branch, renderSales }) {

    const [modalItem, setModalItem] = useState({
        item: ModalItem.None,
        config: {},
    });
    const [year, setYear] = useState(moment().year());
    const [data, setData] = useState({
        months: [],
        register_users: {},
        new_users: {},
        old_users: {},
        sales_list: {},
        register_user_count_series: [],
        register_user_count_option: {},
        user_count_series: [],
        user_count_option: {},
        sales_price_series: [],
        sales_price_option: {},
    });

    useEffect(() => {
        load();
    }, [branchList, _branch, year])

    async function load() {
        try
        {
            var statistics = [];
            var sales = [];
            if(_branch) {
                const branchData = await loadBranch(_branch, year)
                statistics = [ ... statistics, ... branchData.statistics ];
                sales = [ ... sales, ... branchData.sales ];
            }
            else {
                for(const branch of branchList) {
                    const branchData = await loadBranch(branch._id, year);
                    statistics = [ ... statistics, ... branchData.statistics ];
                    sales = [ ... sales, ... branchData.sales ];
                }
            }

            const months = [ '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12' ];
            var register_users = {};
            var register_users_daily = {};
            var new_users = {};
            var new_users_daily = {};
            var old_users = {};
            var old_users_daily = {};
            var total_price = [];
            statistics.forEach((item) => {
                const month = moment(item.date).format('MM');
                const date = moment(item.date).format('YYYY-MM-DD');
                switch(item.type)
                {
                    case constants.statistics_type.USER_COUNT:
                        if(Object.keys(new_users).some(x => x == month) == false) {
                            new_users[month] = [];
                        }

                        if(!new_users_daily[month])
                            new_users_daily[month] = {};
                        if(!new_users_daily[month][date])
                            new_users_daily[month][date] = [];

                        item.data.new_user_list.forEach((user) => {
                            new_users[month].push(user);
                            new_users_daily[month][date].push(user);
                        });

                        if(Object.keys(old_users).some(x => x == month) == false) {
                            old_users[month] = [];
                        }

                        if(!old_users_daily[month])
                            old_users_daily[month] = {};
                        if(!old_users_daily[month][date])
                            old_users_daily[month][date] = [];

                        item.data.old_user_list.forEach((user) => {
                            old_users[month].push(user);
                            old_users_daily[month][date].push(user);
                        });

                        if(Object.keys(register_users).some(x => x == month) == false) {
                            register_users[month] = [];
                        }
                        item.data.register_user.forEach((user) => {
                            register_users[month].push(user);
                        });

                        if(!register_users_daily[month])
                            register_users_daily[month] = {};
                        if(!register_users_daily[month][date])
                            register_users_daily[month][date] = [];
                        item.data.register_user.forEach((user) => {
                            register_users_daily[month][date].push(user);
                        });
                        break;
                }
            });

            // console.log(sales);
            var sales_list = {};
            var sales_list_daily = {};
            sales.forEach((item) => {
                // console.log(item);
                const month = moment(item.date).format('MM');
                const date = moment(item.date).format('YYYY-MM-DD');
                // console.log(month);
                if(!sales_list[month])
                    sales_list[month] = [];
                sales_list[month].push(item);

                if(!sales_list_daily[month])
                    sales_list_daily[month] = {};
                if(!sales_list_daily[month][date])
                    sales_list_daily[month][date] = [];
                sales_list_daily[month][date].push(item);
            });


            var new_user_count = [];
            var old_user_count = [];
            var total_user_count = [];
            var register_user_count = [];
            var sales_price = [];
            const formatted_month = [];
            months.forEach((month) => {
                formatted_month.push(`${Number(month)}월`);
                const yesterday = moment().subtract(1, 'days');
                var days = 0;
                if(yesterday.year() === year && yesterday.month() + 1 == Number(month))
                    days = yesterday.date();
                else
                    days = moment(`${year}-${month}`).daysInMonth();

                    const new_user = Number(((new_users[month] ? new_users[month].length : 0) / days).toFixed(0));
                    const old_user = Number(((old_users[month] ? old_users[month].length : 0) / days).toFixed(0));
                    new_user_count.push(new_user);
                    old_user_count.push(old_user);
                    total_user_count.push(Number((new_user + old_user).toFixed(2)));

                    const register_user = register_users[month] ? register_users[month].length: 0;
                    register_user_count.push(register_user);

                    sales_price.push(sales_list[month] ? sales_list[month].reduce((acc, obj) => { return acc + (obj.type != 2 ? obj.price : obj.price * -1); }, 0) : 0);
                    // console.log(new_user, old_user);
                    // console.log(total_user_count, days);
            });

            const register_user_count_series = [
                { name: '신규 회원', data: register_user_count }
            ];
            const register_user_count_option = {
                chart: { type: 'bar', height: 350, events: { dataPointSelection: onClickNewUserChart } },
                plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' }, },
                dataLabels: { enabled: true, formatter: function (val) { return val + " 명"; }, style: { fontSize: '12px', colors: ["#304758"] } },
                stroke: { show: true, width: 2, colors: ['transparent'] },
                // labels: Object.keys(register_users),
                xaxis: { categories: formatted_month },
                yaxis: {
                    labels:{ formatter: function (val) { return val + " 명" } },
                },
                fill: { opacity: 1 },
                tooltip: { y: { formatter: function (val) { return val + " 명" } } },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: { height: 500 },
                        legend: { position: 'bottom' },
                        plotOptions: { bar: { horizontal: true }},
                        xaxis: { labels: {formatter: function (val) { return val + " 명"; } } },
                        yaxis: {
                            labels:{ formatter: function (val) { return val; } },
                        },
                    },
                }],
                grid: { xaxis: { lines: { show: false } }, yaxis: { lines: { show: true } }, }
            };

            const user_count_series = [ { name: '신규', type: 'column', data: new_user_count }, { name: '기존', type: 'column', data: old_user_count } ];
            const user_count_option = {
                chart: { type: 'bar', height: 350, stacked: true, events: { dataPointSelection: onClickUserChart } },
                plotOptions: { bar: { horizontal: false, dataLabels: { total: { enabled: true } } } },
                dataLabels: { enabled: true, formatter: function (val) { return Number(val.toFixed(2)) + " 명"; }, style: { fontSize: '12px', colors: ["#304758"] } },
                stroke: { width: 1, colors: ['transparent'] },
                xaxis: { categories: formatted_month },
                yaxis: {
                    labels:{ formatter: function (val) { return val + " 명" } },
                },
                fill: { opacity: 1 },
                tooltip: { shared: true, intersect:false, y: { formatter: function (val) { return val + " 명" } } },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: { height: 500 },
                        legend: { position: 'bottom' },
                        plotOptions: { bar: { horizontal: true }},
                        xaxis: { labels: {formatter: function (val) { return val + " 명"; } } },
                        yaxis: {
                            labels:{ formatter: function (val) { return val; } },
                        },
                    }
                }],
                grid: { xaxis: { lines: { show: false } }, yaxis: { lines: { show: true } }, }
            };
            // console.log(total_price);
            // console.log(months);
            const sales_price_series = [ { name: 'Price', data: sales_price } ];
            const sales_price_option = {
                chart: { type: 'bar', height: 350, events: { dataPointSelection: onClickSalesChart } },
                plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' }, },
                dataLabels: { enabled: true, formatter: function (val) { return val.toLocaleString("en-US") + "원"; }, style: { fontSize: '12px', colors: ["#304758"] } },
                stroke: { show: true, width: 2, colors: ['transparent'] },
                xaxis: { categories: formatted_month },
                yaxis: {
                    labels:{ formatter: function (val) { return val.toLocaleString("en-US") + " 원" } },
                },
                fill: { opacity: 1 },
                tooltip: { y: { formatter: function (val) { return val.toLocaleString("en-US") + " 원" } } },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: { height: 500 },
                        legend: { position: 'bottom' },
                        plotOptions: { bar: { horizontal: true }},
                        xaxis: { labels: {formatter: function (val) { return val / 10000 + "만원"; } } },
                        yaxis: {
                            labels:{ formatter: function (val) { return val; } },
                        },
                    }
                }],
                grid: { xaxis: { lines: { show: false } }, yaxis: { lines: { show: true } }, }
            };

            setData({
                months: months,
                register_users: register_users_daily,
                new_users: new_users_daily,
                old_users: old_users_daily,
                sales_list: sales_list_daily,
                register_user_count_option: register_user_count_option,
                register_user_count_series: register_user_count_series,
                user_count_series: user_count_series,
                user_count_option: user_count_option,
                sales_price_option: sales_price_option,
                sales_price_series: sales_price_series,
            });
        }
        catch(ex)
        {
            alert(ex);
        }
    }

    async function loadBranch(_branch, year) {
        const result = [];
        const dashboarStatisticsdRes = await DashboardClient.getMonthlyStatistics({
            _branch: _branch,
            year: year,
        });

        const sales = dashboarStatisticsdRes.data.success ? dashboarStatisticsdRes.data.result.sales.sort((a, b) => {
            if(a.date > b.date)
                return 1;
            return -1;
        }) : [];
        // console.log(sales);

        return {
            statistics: dashboarStatisticsdRes.data.success ? dashboarStatisticsdRes.data.result.statistics : [],
            sales: sales,
        };
    }

    function getSalesTypeName(type)
    {
        switch(type)
        {
            case 1:
                return '수강권 구매';
            case 2:
                return '수강권 환불';
            case 3:
                return '레슨 횟수 구매';
        }
    }

    function onClickNewUserChart (event, chartContext, config)
    {
        console.log(`onClickNewUserChart `, event, chartContext, config);
        setModalItem({
            item: ModalItem.NewUser,
            config: config,
        });
    }

    function onClickUserChart (event, chartContext, config)
    {
        console.log(`onClickUserChart `, event, chartContext, config);
        setModalItem({
            item: ModalItem.User,
            config: config
        });
    }

    function onClickSalesChart (event, chartContext, config)
    {
        console.log(`onClickSalesChart `, event, chartContext, config);
        setModalItem({
            item: ModalItem.Sales,
            config: config
        });
    }

    function render_LinkUserDetail(id, name) {
        return (
            <td>
                <a href={"/user/userdetail/" + id}>{name}</a>
            </td>
        );
    }

    function renderModalNewUser() {
        const month = data.months[modalItem.config.dataPointIndex];
        if(!month)
            return null;
        var index = 0;
        return (
            <>
                <div className="table-responsive">
                    <table className="table fixed-height">
                        <thead className="thead-light">
                            <tr>
                                <th></th>
                                <th>날짜</th>
                                <th>이름</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.register_users[month] ?
                            Object.keys(data.register_users[month]).map((date) => {
                                return data.register_users[month][date].map((item) =>
                                {
                                    index++;
                                    return (
                                        <tr key={index}>
                                            <td>{index}</td>
                                            <td>{date}</td>
                                            {render_LinkUserDetail(item._user, item.name)}
                                        </tr>
                                    );
                                })
                            })
                         : null}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    function renderModalUser() {
        const month = data.months[modalItem.config.dataPointIndex];
        if(!month)
            return null;
        var index = 0;
        return (
            <>
                <div className="table-responsive">
                    <table className="table fixed-height">
                        <thead className="thead-light">
                            <tr>
                                <th></th>
                                <th>날짜</th>
                                <th>신규 회원 수 / 기존 회원 수</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.new_users[month] ?
                            Object.keys(data.new_users[month]).map((date) => {
                                index++;
                                return (
                                    <tr key={index}>
                                        <td>{index}</td>
                                        <td>{date}</td>
                                        <td>{data.new_users[month][date].length} / {data.old_users[month] && data.old_users[month][date] ? data.old_users[month][date].length : 0}</td>
                                    </tr>
                                );
                            })
                         : null}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    function renderModalSales() {
        const month = data.months[modalItem.config.dataPointIndex];
        if(!month)
            return null;
        var index = 0;

        const keys = Object.keys(data.sales_list[month]).sort((a, b) => a > b ? 1 : -1 );

        return (
            <>
                <div className="table-responsive">
                    <table className="table fixed-height">
                        <thead className="thead-light">
                            <tr>
                                <th></th>
                                <th>날짜</th>
                                <th>지점</th>
                                <th>회원명</th>
                                <th>구분</th>
                                <th>수강권</th>
                                <th>가격</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.sales_list[month] ?
                            keys.map((date) => {
                                return data.sales_list[month][date].map((item) =>
                                {
                                    // console.log(item);
                                    index++;
                                    return (
                                        <tr key={index}>
                                            <td>{index}</td>
                                            <td>{date}</td>
                                            <td>{item._branch.name}</td>
                                            {render_LinkUserDetail(item._user._id, item._user.name)}
                                            <td>{getSalesTypeName(item.type)}</td>
                                            <td>{item._ticket ? item._ticket.name : ''}</td>
                                            <td>{item.price.toLocaleString("en-US")} 원</td>
                                        </tr>
                                    );
                                })
                            })
                         : null}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    function renderModal () {
        switch(modalItem.item) {
            case ModalItem.NewUser:
                return renderModalNewUser();
            case ModalItem.User:
                return renderModalUser();
            case ModalItem.Sales:
                return renderModalSales();
        }
        return null;
    }

    const start_year = 2022;
    const end_year = moment().year();
    var years = [];
    for(var i = start_year; i <= end_year; ++i)
    {
        years.push(i);
    }
    return (
        <>
            <h4>월간 통계</h4>
            <div>
                <div className="col-lg-7">
                    <div className="row">
                        <div className="col">
                            <select
                                className="form-control "
                                id="selectdrop"
                                value={year}
                                onChange={(e) => { setYear(Number(e.target.value)); }}
                            >
                                {
                                    years.map((element) =>
                                        (
                                            <option value={element}>
                                                {element} 년
                                            </option>
                                        )
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <label className="form-label" for="site-name"> 신규 회원 수 </label>
                <div id="columnchart">
                    <ReactApexChart options={data.register_user_count_option} series={data.register_user_count_series} type="bar" height={350} />
                </div>
            </div>
            <div className="divider"></div>
            <div>
                <label className="form-label" for="site-name"> 회원 수 </label>
                <div id="columnchart">
                    <ReactApexChart options={data.user_count_option} series={data.user_count_series} type="bar" height={350}/>
                </div>
            </div>
            <div className="divider"></div>
            {renderSales ? (
                <div>
                    <label className="form-label" for="site-name"> 매출 </label>
                    <div id="columnchart">
                        <ReactApexChart options={data.sales_price_option} series={data.sales_price_series} type="bar" height={350}/>
                    </div>
                </div>
                ) : null
            }

            <ReactModal className="modal-content" overlayClassName="modal-overlay" isOpen={modalItem.item.id != ModalItem.None.id} onRequestClose={() => { setModalItem({ item: ModalItem.None, config: {} }) }} shouldCloseOnOverlayClick={true}>
                <div className="card-title">
                    <h6 className="title" style={{margin:"10px"}}>
                        { modalItem.item.name }
                    </h6>
                </div>
                { renderModal() }
                <div className="modal-close" onClick={() => { setModalItem({ item: ModalItem.None, config: {} }) }}>닫기</div>
            </ReactModal>
        </>
    );

}
