import AxiosClient from "./AxiosClient";

const SuggestionClient = {
  create: async (id, content) => {
    return await AxiosClient.post(`suggestion/admin`, {
      content: content,
    });
  },
  listAll: async (page, count) => {
    return await AxiosClient.get(`suggestion/list`, {
      page: page,
      countPerPage: count,
    });
  },

  list: async (_branch, page, count) => {
    return await AxiosClient.get(`suggestion/list/${_branch}`, {
      page: page,
      countPerPage: count,
    });
  },
};

export default SuggestionClient;
