import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { AuthContext } from "../components/AuthenticatedComponent";
import Calendar from "../components/Calendar";
import Content from "../components/Content";
import Menubar from "../components/Menubar";

class BookStatus extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        if (
            this.context.role !== "일반관리자" &&
            this.context.role !== "최고관리자" &&
            this.context.role !== "지점관리자" &&
            this.context.role !== "매니저"
        ) {
            return (
                <div>
                    <Menubar />
                    <div className="nk-content">
                        <div className="container-fluid">
                            접근 권한이 없습니다.
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <>
                <Menubar />
                <Content>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">
                                    예약 캘린더
                                </h3>
                                <div className="nk-block-des text-soft">
                                    <p>월별 예약관리</p>
                                </div>
                            </div>
                            <div className="nk-block-head-content">
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                    >
                                        <em className="icon ni ni-menu-alt-r"></em>
                                    </a>
                                    <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                    >
                                        <ul className="nk-block-tools g-3">
                                            <li className="nk-block-tools-opt">
                                                <Link to="/book/bookregister">
                                                    <a
                                                        href="/book/bookregister"
                                                        className="btn btn-primary"
                                                    >
                                                        예약추가
                                                    </a>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <div className="card card-bordered">
                            <div className="card-inner">
                                <Calendar />
                            </div>
                        </div>
                    </div>
                </Content>
            </>
        );
    }
}

export default withRouter(BookStatus);
