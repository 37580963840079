import React, { Component } from "react";
import moment from "moment";
import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../components/AuthenticatedComponent";
import TicketClient from "../utils/Http/TicketClient";
import ReceiptClient from "../utils/Http/ReceiptClient";
import Constants from "../common/Constants";
import constants from "../common/Constants";

class Ticket extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    const discountTypeList = [
      // { id: 0, desc: "없음" },
      // { id: 1, desc: "%" },
      { id: 2, desc: "원" },
    ];

    this.state = {
      tickets: props.tickets,
      ticketDatas: [],
      discountTypeList: discountTypeList,
      showClosedTicket: false,
      coupons: props.coupons,
    };
  }

  componentDidMount = async () => {
    try {
      const lessonPriceRes = await TicketClient.LessonPriceList({
        _branch: this.props.branch,
      });

      this.setState({
        lessonPriceList: lessonPriceRes.data.success
          ? lessonPriceRes.data.result
          : [],
      });
    } catch (err) {
      console.log(err);
    }
  };

  deleteTicket = async (e) => {
    e.preventDefault();

    try {
      const ticket = this.getTicket(e.target.dataset.key);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        return;
      }

      const res = await TicketClient.delete({
        _id: ticket._id,
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  refundTicket = async (e) => {
    e.preventDefault();

    try {
      const ticket = this.getTicket(e.target.dataset.key);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        return;
      }

      const res = await TicketClient.refund({
        _id: ticket._id,
        refund: ticket.refund,
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  updateTicket = async (e) => {
    e.preventDefault();

    try {
      let ticket = this.getTicket(e.target.dataset.key);
      ticket.discount_type = 2;
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        return;
      }
      // console.log(ticket);
      const res = await TicketClient.update({
        ticket,
      });

      alert(res.data.message);
      if (res.data.success) {
        window.location.reload();
      }
    } catch (error) {
      alert(error);
    }
  };

  receiptRefresh = async (e) => {
    e.preventDefault();

    try {
      const ticket = this.getTicket(e.target.dataset.key);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        return;
      }

      const res = await ReceiptClient.refresh(ticket._id);

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  createHolding = async (e) => {
    e.preventDefault();

    try {
      const ticket = this.getTicket(e.target.dataset.key);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        return;
      }
      // console.log(ticket);
      const now = moment(Date.now()).format("YYYY-MM-DD");
      const res = await TicketClient.createHolding({
        _ticket: ticket._id,
        startDate:
          ticket.holdingStartDate != null ? ticket.holdingStartDate : now,
        endDate: ticket.holdingEndDate != null ? ticket.holdingEndDate : now,
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  deleteHolding = async (e) => {
    e.preventDefault();

    try {
      const ticket = this.getTicket(e.target.dataset.ticket);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        return;
      }
      const res = await TicketClient.deleteHolding({
        _ticket: ticket._id,
        _holding: ticket.holdings[e.target.dataset.key],
      });

      alert(res.data.message);
      if (res.data.success) {
        window.location.reload();
      }
    } catch (error) {
      alert(error);
    }
  };

  modifyHolding = async (e) => {
    e.preventDefault();

    try {
      console.log(e);
    } catch (error) {
      alert(error);
    }
  };

  getTicket = (ticket) => {
    return this.state.tickets.find(function (item) {
      if (item._id === ticket) return true;
      return false;
    });
  };

  onChange = (e) => {
    e.preventDefault();

    const ticket = this.getTicket(e.target.dataset.key);
    if (!ticket) {
      alert("수강권을 찾을 수 없습니다");
      console.log(e.target.dataset.key);
      return;
    }

    ticket[e.target.name] = e.target.value;
    this.setState({
      tickets: this.state.tickets,
    });
  };

  onChangeTicketType = (e, type) => {
    const ticket = this.getTicket(e.target.dataset.key);
    if (!ticket) {
      alert("수강권을 찾을 수 없습니다");
      // console.log(e.target.dataset.key);
      return;
    }

    ticket[e.target.name] = type;
    // this.forceUpdate();
    this.setState({
      tickets: this.state.tickets,
    });
  };

  onChangeLessonPlan = (e, _ticket, _subject) => {
    const ticket = this.getTicket(_ticket);
    if (!ticket) {
      alert("수강권을 찾을 수 없습니다");
      // console.log(e.target.dataset.key);
      return;
    }

    const lessonPlan = ticket.lessonPlans.find(item => {
      return item._id === _subject;
    });

    if (!lessonPlan) {
      alert("레슨 시간 정보를 찾을 수 없습니다");
      // console.log(e.target.dataset.key, e.target.dataset.value);
      return;
    }

    lessonPlan.value = parseInt(e.target.value);
    // console.log(ticket);

    // this.forceUpdate();
    this.setState({
      tickets: this.state.tickets,
    });
  }


  onChangeHoldingStartDate = async (e) => {
    e.preventDefault();
    try {
      const ticket = this.getTicket(e.target.dataset.key);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        console.log(e.target.dataset.key);
        return;
      }
      const holdingStartDate = moment(e.target.value).format("YYYY-MM-DD");
      ticket.holdingStartDate = holdingStartDate;

      this.setState({
        tickets: this.state.tickets,
      });
    } catch (error) {
      alert(error);
    }
  };

  onChangeHoldingEndDate = async (e) => {
    e.preventDefault();
    try {
      const ticket = this.getTicket(e.target.dataset.key);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        console.log(e.target.dataset.key);
        return;
      }
      const holdingEndDate = moment(e.target.value).format("YYYY-MM-DD");
      ticket.holdingEndDate = holdingEndDate;

      this.setState({
        tickets: this.state.tickets,
      });
    } catch (error) {
      alert(error);
    }
  };

  onChangeAddLessonCount = async (e) => {
    e.preventDefault();
    try {
      const ticket = this.getTicket(e.target.dataset.key);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        console.log(e.target.dataset.key);
        return;
      }

      const count = Number(ticket.lesson_buy) + Number(e.target.value);

      function func(element) {
        if (element.countStart <= count && count <= element.countEnd) {
          return true;
        }
      }
      const findRet = this.state.lessonPriceList.find(func);
      ticket.addLessonPrice = findRet
        ? e.target.value * findRet.price
        : "정보 없음";
      ticket.addLessonCount = Number(e.target.value);

      this.setState({
        tickets: this.state.tickets,
      });
    } catch (error) {
      alert(error);
    }
  };

  onChangeAddLessonPrice = async (e) => {
    e.preventDefault();
    try {
      const ticket = this.getTicket(e.target.dataset.key);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        console.log(e.target.dataset.key);
        return;
      }

      ticket.addLessonPrice = e.target.value;

      this.setState({
        tickets: this.state.tickets,
      });
    } catch (error) {
      alert(error);
    }
  };

  onClickAddDays = async (e) => {
    e.preventDefault();

    try {
      const ticket = this.getTicket(e.target.dataset.key);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        return;
      }
      if (!ticket.addDaysValue || ticket.addDaysValue <= 0) {
        alert("기간 연장 일수를 확인해주세요.");
        return;
      }

      if (!ticket.addDaysDesc || ticket.addDaysDesc.length === 0) {
        alert("메모를 적어주세요.");
        return;
      }

      const res = await TicketClient.addDays({
        _id: ticket._id,
        days: ticket.addDaysValue,
        desc: ticket.addDaysDesc,
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  onClickAddDaysCancel = async (e, _ticket, id) => {
    e.preventDefault();

    try {
      const ticket = this.getTicket(_ticket);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        return;
      }
      const res = await TicketClient.addDaysCancel(_ticket, { _id: id });

      alert(res.data.message);
      if (res.data.success) {
        window.location.reload();
      }
    } catch (error) {
      alert(error);
    }
  };

  onChangeTransferLessons = (e) => {
    e.preventDefault();
    try {
      const ticket = this.getTicket(e.target.dataset.key);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        console.log(e.target.dataset.key);
        return;
      }

      ticket.transferLessons = e.target.value;
      ticket.transferLessonsDays = Number(e.target.value) * 7;
      // console.log(ticket);
      this.setState({
        tickets: this.state.tickets,
      });
    } catch (error) {
      alert(error);
    }
  };

  onClickTransferLessons = async (e) => {
    e.preventDefault();

    try {
      const ticket = this.getTicket(e.target.dataset.key);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        return;
      }
      if (!ticket.transferLessons || ticket.transferLessons <= 0) {
        alert("변경할 레슨 횟수를 확인해주세요.");
        return;
      }

      const res = await TicketClient.transferLessons({
        _id: ticket._id,
        count: ticket.transferLessons,
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  onLessonBuy = async (e) => {
    e.preventDefault();

    try {
      const ticket = this.getTicket(e.target.dataset.key);
      if (!ticket) {
        alert("수강권을 찾을 수 없습니다");
        return;
      }
      if (!ticket.addLessonCount || ticket.addLessonCount <= 0) {
        alert("횟수를 확인해주세요.");
        return;
      }

      const count = ticket.lesson_buy + ticket.addLessonCount;
      function func(element) {
        if (element.countStart <= count && count <= element.countEnd) {
          return true;
        }
      }
      const findRet = this.state.lessonPriceList.find(func);
      if (!findRet) {
        alert("가격 정보를 찾을 수 없습니다.");
        return;
      }

      const res = await TicketClient.lessonBuy({
        _id: ticket._id,
        count: ticket.addLessonCount,
        price: ticket.addLessonPrice,
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  onClickShowAllTicket = async (e) => {
    e.preventDefault();

    this.setState({
      showClosedTicket: !this.state.showClosedTicket,
    });
  };

  getDiscountPrice(ticket) {
    let price = ticket.price;
    switch (ticket.discount_type) {
      case 1:
        return price * ((100 - ticket.discount_value) / 100);
      case 2:
        return ticket.discount_value;
    }
    return 0;
  }

  getCouponPrice(ticket) {
    return ticket._coupon ? ticket._coupon.price : 0;
  }

  getPoint(ticket) {
    return ticket.point.use ?? 0;
  }

  getPrice(ticket) {
    if (ticket.totalPrice !== undefined) {
      return ticket.totalPrice;
    }
    let price = ticket.price;
    switch (ticket.discount_type) {
      case 1:
        price *= (100 - ticket.discount_value) / 100;
        break;
      case 2:
        price -= ticket.discount_value;
        break;
      default:
        break;
    }
    price += ticket.lesson_buy_price ? ticket.lesson_buy_price : 0;

    return price;
  }
  generateHolding(ticket, i) {
    // console.log(ticket);
    let ret = [];
    ret.push(
      <form>
        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label"></label>
            </div>
          </div>
        </div>

        <form>
          <div className="row g-3 align-center">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" for="site-off">
                  기간 연장
                </label>
                <span className="form-note">일수를 입력하세요</span>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col">
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      className="form-control"
                      name="addDaysValue"
                      data-key={ticket._id}
                      onChange={this.onChange}
                      value={ticket.addDaysValue}
                      defaultValue={0}
                      required="required"
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      name="addDaysDesc"
                      data-key={ticket._id}
                      onChange={this.onChange}
                      value={ticket.addDaysDesc}
                      defaultValue={""}
                    />
                  </div>
                </div>
                <button
                  onClick={this.onClickAddDays}
                  data-key={ticket._id}
                  className="btn btn-lg btn-primary mr-1"
                >
                  입력
                </button>
              </div>
            </div>
          </div>
          <div className="nk-block">
            <div className="card card-bordered card-stretch">
              <div class="table-responsive">
                <table class="table">
                  <thead className="thead-light">
                    <th>일수</th>
                    <th>메모</th>
                    <th></th>
                  </thead>
                  <tbody>
                    {ticket.addDays !== undefined
                      ? ticket.addDays.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="number"
                                data-key={ticket._id}
                                text={item.days}
                                value={item.days}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                data-key={ticket._id}
                                text={item.desc}
                                value={item.desc}
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-dim btn-outline-secondary"
                                onClick={(e) => {
                                  this.onClickAddDaysCancel(
                                    e,
                                    ticket._id,
                                    item._id
                                  );
                                }}
                                disabled={item.delete}
                              >
                                {item.delete ? "취소완료" : "취소"}
                              </button>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" for="site-off">
                {"레슨 -> 기간 전환"}
              </label>
              <span className="form-note">횟수를 입력하세요(1회당 7일)</span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col">
                <div className="form-control-wrap">
                  <input
                    type="number"
                    className="form-control"
                    name="transferLessons"
                    data-key={ticket._id}
                    onChange={this.onChangeTransferLessons}
                    value={ticket.transferLessons}
                    defaultValue={0}
                    required="required"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">일</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    name="transferLessonsDays"
                    data-key={ticket._id}
                    value={ticket.transferLessonsDays}
                    defaultValue={""}
                    disabled={true}
                  />
                </div>
              </div>
              <button
                onClick={this.onClickTransferLessons}
                data-key={ticket._id}
                className="btn btn-lg btn-primary mr-1"
              >
                입력
              </button>
            </div>
          </div>
        </div>

        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" for="site-off">
                레슨 추가
              </label>
              <span className="form-note">
                횟수를 입력하세요 (기존 구매 횟수 : {ticket.lesson_buy})
              </span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col">
                <div className="form-control-wrap">
                  <input
                    type="number"
                    className="form-control"
                    name="addLessonCount"
                    data-key={ticket._id}
                    onChange={this.onChangeAddLessonCount}
                    value={ticket.addLessonCount}
                    defaultValue={0}
                    required="required"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    name="addLessonPrice"
                    data-key={ticket._id}
                    onChange={this.onChangeAddLessonPrice}
                    value={ticket.addLessonPrice}
                    defaultValue={"정보 없음"}
                  />
                </div>
              </div>
              <button
                onClick={this.onLessonBuy}
                data-key={ticket._id}
                className="btn btn-lg btn-primary mr-1"
              >
                입력
              </button>
            </div>
          </div>
        </div>

        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" for="site-off">
                홀딩 추가
              </label>
              <span className="form-note">시작일과 종료일을 입력하세요</span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col duration">
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className="form-control"
                    name="startDate"
                    data-key={ticket._id}
                    onChange={this.onChangeHoldingStartDate}
                    defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                    required="required"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className="form-control"
                    name="endDate"
                    data-key={ticket._id}
                    onChange={this.onChangeHoldingEndDate}
                    defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                    required="required"
                  />
                </div>
              </div>
              <button
                onClick={this.createHolding}
                data-key={ticket._id}
                className="btn btn-lg btn-primary mr-1"
              >
                입력
              </button>
            </div>
          </div>
        </div>

        <div className="nk-block">
          <div className="card card-bordered card-stretch">
            <div class="table-responsive">
              <table class="table">
                <thead className="thead-light">
                  <th>시작일</th>
                  <th>종료일</th>
                  <th>일수</th>
                  {/* <th>수정</th> */}
                  <th>삭제</th>
                </thead>
                <tbody>
                  {ticket.holdings.map((holding, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="date"
                          data-key={index}
                          data-ticket={ticket._id}
                          text={holding.startDate}
                          value={moment(holding.startDate).format("YYYY-MM-DD")}
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          data-key={index}
                          data-ticket={ticket._id}
                          text={holding.endDate}
                          value={moment(holding.endDate).format("YYYY-MM-DD")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={
                            moment(holding.endDate).diff(
                              moment(holding.startDate),
                              "days"
                            ) + 1
                          }
                          disabled="true"
                        />
                      </td>
                      {/* <td>
						<button className="btn btn-dim btn-outline-secondary" data-key={index} data-ticketindex={i} onClick={this.modifyHolding}>
						  수정
						</button>
					  </td> */}
                      <td>
                        <button
                          className="btn btn-dim btn-outline-secondary"
                          data-key={index}
                          data-ticket={ticket._id}
                          onClick={this.deleteHolding}
                          disabled={holding.delete}
                        >
                          {holding.delete ? "취소완료" : "취소"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    );

    return ret;
  }

  render_lessonPlans(ticket) {
    return (
      <table>
        <tbody>
        <tr>
          {ticket.lessonPlans.map(lessonPlan =>
            {
              return (
                <div>
                  <label> {lessonPlan.name} </label>
                  <select
                    // className="form-control"
                    id={`lessonPlan_${ticket._id}_${lessonPlan._id}`}
                    defaultValue={lessonPlan.value}
                    onChange={(e) => this.onChangeLessonPlan(e, ticket._id, lessonPlan._id)}
                  >
                    <option value={constants.lessonType.Fifty}>50분</option>
                    <option value={constants.lessonType.Thirty}>30분</option>
                  </select>
                </div>
              );
            }
          )
          }
        </tr>
        </tbody>
      </table>
    );
  }

  generateTicket(ticket, i) {
    return (
      <form>
        <div className="divider"></div>
        <div>
          <label className="form-label" for="site-off">
            수강권 [{ticket.name}] {ticket.status === 2 ? "[환불]" : ""}
          </label>
          <label>&nbsp;&nbsp;({ticket.assignee ? ticket.assignee : ""})</label>
        </div>

        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" for="site-off">
                수강기간
              </label>
              <span className="form-note">시작일, 종료일을 입력하세요</span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col duration">
                <input
                  type="date"
                  data-key={ticket._id}
                  className="form-control"
                  name="startDate"
                  onChange={this.onChange}
                  defaultValue={moment(ticket.startDate).format("YYYY-MM-DD")}
                  required="required"
                />
              </div>
              <div className="col">
                <input
                  type="date"
                  data-key={ticket._id}
                  className="form-control"
                  name="endDate"
                  onChange={this.onChange}
                  defaultValue={moment(ticket.endDate).format("YYYY-MM-DD")}
                  // disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" for="site-off">
                수강횟수
              </label>
              <span className="form-note">남은 횟수 / 총 횟수</span>
              <span className="form-note">
                이월 레슨 ({ticket.lesson_carry_over} 회): 기존 수강기간 중
                재등록시 기존 수강 종료일 익일 새벽 4시에 자동으로 이월됩니다.
              </span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col per">
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">남은 횟수</span>
                  </div>
                  <input
                    type="number"
                    data-msg="Required"
                    data-key={ticket._id}
                    className="form-control"
                    onChange={this.onChange}
                    name="lesson"
                    defaultValue={ticket.lesson}
                    required="required"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">총 횟수</span>
                  </div>
                  <input
                    type="number"
                    data-msg="Required"
                    className="form-control"
                    name="lesson_all"
                    defaultValue={
                      ticket.lesson_all +
                      ticket.lesson_buy +
                      ticket.lesson_carry_over
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" for="site-off">
                홀딩 일수
              </label>
              <span className="form-note">남은 일수 / 총 일수</span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col per">
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">남은 일수</span>
                  </div>
                  <input
                    type="number"
                    data-msg="Required"
                    className="form-control"
                    data-key={ticket._id}
                    onChange={this.onChange}
                    name="holding"
                    defaultValue={ticket.holding}
                    required="required"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">총 일수</span>
                  </div>
                  <input
                    type="number"
                    data-msg="Required"
                    className="form-control"
                    onChange={this.onChange}
                    name="holding_all"
                    defaultValue={ticket.holding_all}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" for="site-off">
                할인 정보
              </label>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col">
                <div className="form-wrap">
                  <input
                    type="number"
                    className="form-control"
                    name="discount_value"
                    data-key={ticket._id}
                    defaultValue={ticket.discount_value}
                    required="required"
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-wrap">
                  <select
                    className="form-control"
                    name="discount_type"
                    onChange={this.onChange}
                    data-key={ticket._id}
                    // value={this.state._branch ? this.state._branch.name : null}
                    defaultValue={ticket.discount_type}
                    disabled={true}
                  >
                    {this.state.discountTypeList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.desc}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" for="site-off">
                쿠폰 정보
              </label>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col">
                <div className="form-wrap">
                  <input
                    type="text"
                    className="form-control"
                    name="coupon"
                    data-key={ticket._id}
                    required="required"
                    disabled="true"
                    value={`${ticket._coupon ? ticket._coupon.name : ""}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" for="site-off">
                결제금액
              </label>
              <span className="form-note">
                수강권 금액 / 총 결제금액(수강권 금액({ticket.price}) - 할인(
                {this.getDiscountPrice(ticket)}) - 쿠폰할인 (
                {this.getCouponPrice(ticket)}) - 적립금 ({this.getPoint(ticket)}
                ) + 레슨 추가(
                {ticket.lesson_buy_price ? ticket.lesson_buy_price : 0}))
              </span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col per">
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">원</span>
                  </div>
                  <input
                    type="number"
                    data-msg="Required"
                    data-key={ticket._id}
                    className="form-control"
                    onChange={this.onChange}
                    name="price"
                    defaultValue={ticket.price}
                    required="required"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">원</span>
                  </div>
                  <input
                    type="number"
                    data-msg="Required"
                    className="form-control"
                    name=""
                    defaultValue={this.getPrice(ticket)}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" for="site-off">
                레슨 시간
              </label>
            </div>
          </div>
          <div className="col-lg-7">
            {this.render_lessonPlans(ticket)}
          </div>
        </div>

        <div className="row g-3 align-start">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" for="site-off">
                메모
              </label>
            </div>
          </div>
          <div className="col-lg-7">
            <textarea
              type="text"
              className="form-control"
              id="comment"
              name="comment"
              data-key={ticket._id}
              onChange={this.onChange}
              defaultValue={ticket.comment}
              required="required"
            />
          </div>
        </div>

        <button
          onClick={this.updateTicket}
          data-key={ticket._id}
          className="btn btn-lg btn-primary mr-1"
        >
          수정
        </button>
        <button
          onClick={this.deleteTicket}
          data-key={ticket._id}
          className="btn btn-lg btn-primary mr-1"
        >
          삭제
        </button>
        {this.context.role !== "강사" && this.context.role !== "매니저" ? (
          <button
            onClick={this.receiptRefresh}
            data-key={ticket._id}
            className="btn btn-lg btn-primary mr-1"
          >
            매출 갱신
          </button>
        ) : null}

        <div className="row g-3 align-center">
          <div className="col-lg-5">
            <div className="form-group">
              <label className="form-label" for="site-off">
                환불
              </label>
              <span className="form-note">환불 금액</span>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col">
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">원</span>
                  </div>
                  <input
                    type="number"
                    id="refund"
                    name="refund"
                    className="form-control"
                    data-key={ticket._id}
                    onChange={this.onChange}
                    value={ticket.refund}
                    defaultValue={0}
                  />
                </div>
              </div>

              <button
                onClick={this.refundTicket}
                data-key={ticket._id}
                className="btn btn-lg btn-primary mr-1"
              >
                환불
              </button>
            </div>
          </div>
        </div>

        {this.generateHolding(ticket, i)}
      </form>
    );
  }

  generate() {
    let ret = [];

    const ticket_opened = [];
    const ticket_closed = [];
    const now = moment().format("YYYY-MM-DD");
    var i = 0;
    for (i = 0; i < this.state.tickets.length; ++i) {
      if (this.state.tickets[i].status === 3) continue;
      if (
        this.state.tickets[i].status === 1 &&
        now <= moment(this.state.tickets[i].endDate).format("YYYY-MM-DD")
      ) {
        ticket_opened.push(this.state.tickets[i]);
      } else {
        ticket_closed.push(this.state.tickets[i]);
      }
    }

    for (i = 0; i < ticket_opened.length; ++i) {
      ret.push(this.generateTicket(ticket_opened[i], i));
    }

    ret.push(
      <>
        <div className="divider" />
        <div>
          <button
            className="btn btn-primary mr-1"
            onClick={this.onClickShowAllTicket}
          >
            {this.state.showClosedTicket ? "숨기기" : "수강권 모두 보기"}
          </button>
        </div>
      </>
    );

    if (this.state.showClosedTicket) {
      for (i = 0; i < ticket_closed.length; ++i) {
        ret.push(this.generateTicket(ticket_closed[i], i));
      }
    }

    return ret;
  }

  render() {
    return <>{this.generate()}</>;
  }
}

export default Ticket;
