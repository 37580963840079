import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import Content from "../components/Content";
import BranchClient from '../utils/Http/BranchClient';

class BranchList extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            branchs: [],
            errorMsg: "",
            offset: 0,
            orgtableData: [],
            perPage: 10,
            currentPage: 0,
            searchType: "name",
            selectedRow: [],
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState(
            {
                currentPage: selectedPage,
                offset: offset,
            },
            () => {
                this.loadMoreData();
            }
        );
    };

    loadMoreData() {
        const data = this.state.orgtableData;

        const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
        );
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            branchs: slice,
        });
    }

    setSearchType = (e) => {
        this.setState({
            searchType: e.target.value,
        });
    };

    setSearchText = (e) => {
        this.setState({
            searchText: e.target.value,
        });
    };

    onSearch = async (e) => {
        // console.log(e);
        try {
            const searchRes =
            await BranchClient.getList({
                [this.state.searchType]: this.state.searchText,
                searchType: this.state.searchType,
            });

            if (searchRes.data.success) {
                this.setState({
                    branchs: searchRes.data.result,
                });
            } else {
                alert("잘못된 검색이거나 검색결과가 없습니다.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    onDeleteRow = async () => {
        if (!window.confirm("정말로 삭제하시겠습니까?")) return;
        try {
            const deleteRes = await BranchClient.delete({
                _id: this.state.selectedRow,
            });

            if (deleteRes.data.success) {
                window.location.reload();
            } else {
                alert("삭제에 실패했습니다.");
            }
        } catch (error) {
            alert("삭제에 실패했습니다.");
        }
    };

    onChangeSelectRow = (e) => {
        if (e.target.checked) {
            this.setState((state) => ({
                selectedRow: [...state.selectedRow, e.target.name],
            }));
        } else {
            this.setState((state) => ({
                selectedRow: state.selectedRow.filter(
                    (item) => item !== e.target.name
                ),
            }));
        }
    };

    onCheckAll = (e) => {
        const checkboxes = document.getElementsByClassName("myCheckbox");
        checkboxes.forEach((item) => {
            item.checked = e.target.checked;
        });

        if (e.target.checked) {
            this.setState((state) => ({
                selectedRow: state.branchs.map((item) => item._id),
            }));
        } else {
            this.setState({
                selectedRow: [],
            });
        }
    };

    componentDidMount = async () => {
        try
        {
            const branchList = await BranchClient.getList();

            if(branchList.data.success === false)
            {
                alert(branchList.data.message);
            }
            var data = branchList.data.result;
            var slice = data.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
            );

            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                orgtableData: branchList.data.result,
                branchs: slice,
            });
        }
        catch(ex)
        {
            console.log(ex);
            this.setState({ errorMsg: "Error retreiving data" });
        }
    }

    render() {
        if (
            this.context.role !== "일반관리자" &&
            this.context.role !== "최고관리자"
        ) {
            return (
                <div>
                    <Menubar />
                    <div className="nk-content">
                        <div className="container-fluid">
                            접근 권한이 없습니다.
                        </div>
                    </div>
                </div>
            );
        }

        const { branchs } = this.state;

        return (
            <>
                <Menubar />
                <Content>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">
                                    지점 리스트
                                </h3>
                            </div>
                            <div className="nk-block-head-content">
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                    >
                                        <em className="icon ni ni-menu-alt-r"></em>
                                    </a>
                                    <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                    >
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <CSVLink
                                                    data={this.state.branchs}
                                                    className="btn btn-white btn-outline-light"
                                                >
                                                    <em className="icon ni ni-download-cloud"></em>
                                                    Export
                                                </CSVLink>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                                <Link to="/branch/branchregister">
                                                    <a
                                                        href="/branch/branchregister"
                                                        className="btn btn-primary"
                                                    >
                                                        데이터 추가
                                                    </a>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="nk-block">
                        <div className="card card-bordered card-stretch">
                            <div className="card-inner-group">
                                <div className="card-inner position-relative card-tools-toggle">
                                    <div className="card-title-group">
                                        <div className="card-tools">
                                            <div className="form-inline flex-nowrap gx-3">
                                                <div className="form-wrap w-150px">
                                                    {this.context.role ===
                                                    "최고관리자" ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary "
                                                            onClick={
                                                                this.onDeleteRow
                                                            }
                                                        >
                                                            선택삭제
                                                        </button>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-tools mr-n1">
                                            <ul className="btn-toolbar gx-1">
                                                <li>
                                                    <a
                                                        href={null}
                                                        className="btn btn-icon search-toggle toggle-search"
                                                        data-target="search"
                                                    >
                                                        <em className="icon ni ni-search"></em>
                                                    </a>
                                                </li>
                                                <li className="btn-toolbar-sep"></li>
                                                <li>
                                                    <div className="d-flex">
                                                        <select
                                                            className="form-control "
                                                            id="selectdrop"
                                                            onChange={
                                                                this
                                                                    .setSearchType
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled
                                                            >
                                                                선택
                                                            </option>
                                                            <option value="name">
                                                                지점명
                                                            </option>
                                                            <option value="address">
                                                                주소
                                                            </option>
                                                            <option value="phone">
                                                                전화번호
                                                            </option>
                                                        </select>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div
                                        className="card-search search-wrap"
                                        data-search="search"
                                    >
                                        <div className="card-body">
                                            <div className="search-content">
                                                <div className="row">
                                                    <a
                                                        href={null}
                                                        className="search-back btn btn-icon toggle-search"
                                                        data-target="search"
                                                    >
                                                        <em className="icon ni ni-arrow-left"></em>
                                                    </a>
                                                    <input
                                                        type="text"
                                                        className="form-control border-transparent form-focus-none"
                                                        placeholder="검색"
                                                        onChange={
                                                            this.setSearchText
                                                        }
                                                    />
                                                </div>
                                                <button className="search-submit btn btn-icon">
                                                    <em
                                                        className="icon ni ni-search"
                                                        onClick={this.onSearch}
                                                    ></em>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-inner p-0">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            onChange={
                                                                this.onCheckAll
                                                            }
                                                        />
                                                    </th>
                                                    <th></th>
                                                    <th>지점명</th>
                                                    <th>주소</th>
                                                    <th>휴대전화</th>
                                                    <th>운영시간</th>
                                                    <th>카카오톡 ID</th>
                                                    <th>비고</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {branchs.length
                                                    ? branchs.map(
                                                          (branch, index) => (
                                                              <tr key={index}>
                                                                  <th scope="row">
                                                                      <input
                                                                          className="myCheckbox"
                                                                          type="checkbox"
                                                                          name={
                                                                              branch._id
                                                                          }
                                                                          onChange={
                                                                              this
                                                                                  .onChangeSelectRow
                                                                          }
                                                                      />
                                                                  </th>
                                                                  <td>
                                                                      {index +
                                                                          1}
                                                                  </td>
                                                                  <td>
                                                                      <Link
                                                                          to={
                                                                              "/branch/branchmodify/" +
                                                                              branch._id
                                                                          }
                                                                      >
                                                                          <a
                                                                              href={
                                                                                  "/branch/branchmodify/" +
                                                                                  branch._id
                                                                              }
                                                                          >
                                                                              {
                                                                                  branch.name
                                                                              }
                                                                          </a>
                                                                      </Link>
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          branch.address
                                                                      }
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          branch.phone
                                                                      }
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          branch.operate_hour
                                                                      }
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          branch.kakaoid
                                                                      }
                                                                  </td>
                                                                  <td></td>
                                                              </tr>
                                                          )
                                                      )
                                                    : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-inner">
                                    <ReactPaginate
                                        previousLabel={"Prev"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        previousClassName={"page-item"}
                                        nextClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextLinkClassName={"page-link"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </>
        );
    }
}

export default withRouter(BranchList);
