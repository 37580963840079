import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import DashboardClient from "../utils/Http/DashboardClient";
import ReactModal from "react-modal";

var constants = require("../common/Constants");
const ModalItem = {
  None: { id: 0, name: "" },
  NewUser: { id: 1, name: "신규 회원 수" },
  User: { id: 2, name: "회원 수" },
  Sales: { id: 3, name: "매출" },
  Ticket: { id: 4, name: "수강권 구매 비율" },
  Funnels: { id: 5, name: "회원 유입 경로" },
  Accessibility: { id: 6, name: "접근성" },
};

export default function DashboardDailyStatistics({
  branchList,
  _branch,
  renderSales,
}) {
  const [modalItem, setModalItem] = useState({
    item: ModalItem.None,
    config: {},
  });
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [data, setData] = useState({
    dates: [],
    new_user_list: {},
    old_user_list: {},
    register_user_list: {},
    sales_list: {},
    sales_ticket_list: {},
    funnel_list: {},
    accessibility_list: {},
    register_user_count_series: [],
    register_user_count_option: {},
    user_count_series: [],
    user_count_option: {},
    sales_tickets_series: [],
    sales_tickets_option: {},
    sales_price_series: [],
    sales_price_option: {},
    user_funnel_series: [],
    user_funnel_option: {},
    user_accessibility_series: [],
    user_accessibility_option: {},
  });

  useEffect(() => {
    load();
  }, [branchList, _branch]);

  async function load() {
    try {
      // console.log(startDate, endDate);
      var statistics = [];
      var sales = [];
      if (_branch) {
        const branchData = await loadBranch(_branch, startDate, endDate);
        statistics = [...statistics, ...branchData.statistics];
        sales = [...sales, ...branchData.sales];
      } else {
        for (const branch of branchList) {
          const branchData = await loadBranch(branch._id, startDate, endDate);
          statistics = [...statistics, ...branchData.statistics];
          sales = [...sales, ...branchData.sales];
        }
      }
      console.log(sales);

      const dates = [];
      var curr_date = moment(startDate);
      var end_date = moment(endDate);
      while (curr_date.format("YYYY-MM-DD") <= end_date.format("YYYY-MM-DD")) {
        dates.push(curr_date.format("YYYY-MM-DD"));
        curr_date.add(1, "days");
      }

      var register_users = {};
      var new_users = {};
      var old_users = {};
      var total_price = [];
      var sales_ticket = {};
      var funnels = {};
      var accessibilities = {};
      statistics.forEach((item) => {
        const date = item.date;
        switch (item.type) {
          case constants.statistics_type.USER_COUNT:
            if (Object.keys(new_users).some((x) => x == date) == false) {
              new_users[date] = [];
            }
            item.data.new_user_list.forEach((user) => {
              new_users[date].push(user);
            });
            if (Object.keys(old_users).some((x) => x == date) == false) {
              old_users[date] = [];
            }
            item.data.old_user_list.forEach((user) => {
              old_users[date].push(user);
            });

            if (Object.keys(register_users).some((x) => x == date) == false) {
              register_users[date] = [];
            }
            item.data.register_user.forEach((user) => {
              register_users[date].push(user);
            });
            break;
          case constants.statistics_type.USER_FUNNEL:
            if (item.data) {
              console.log(item);
              const keys = Object.keys(item.data);
              keys.forEach((key) => {
                if (!funnels[key]) funnels[key] = [];
                item.data[key].forEach((data) => {
                  funnels[key].push({
                    _user: data._user,
                    user_name: data.user_name,
                    date: date,
                  });
                });
              });
            }
            break;
          case constants.statistics_type.SALES:
            break;
          case constants.statistics_type.USER_ACCESSIBILITY:
            if (item.data) {
              const keys = Object.keys(item.data);
              keys.forEach((key) => {
                if (!accessibilities[key]) accessibilities[key] = [];
                item.data[key].forEach((data) => {
                  accessibilities[key].push({
                    _user: data._user,
                    user_name: data.user_name,
                    date: date,
                  });
                });
              });
            }
            break;
        }
      });

      var sales_list = {};
      var sales_ticket_list = {};
      sales.forEach((item) => {
        const date = moment(item.date).format("YYYY-MM-DD");
        // console.log(date);
        if (!sales_list[date]) sales_list[date] = [];
        sales_list[date].push(item);

        const ticket_name = item._ticket ? item._ticket.name : "";
        // console.log(item);
        if (!sales_ticket_list[ticket_name])
          sales_ticket_list[ticket_name] = [];
        sales_ticket_list[ticket_name].push(item);
      });

      // console.log(sales_list);

      var new_user_count = [];
      var old_user_count = [];
      var total_user_count = [];
      var register_user_count = [];
      var sales_price = [];
      dates.forEach((date) => {
        const new_user = new_users[date] ? new_users[date].length : 0;
        const old_user = old_users[date] ? old_users[date].length : 0;
        new_user_count.push(new_user);
        old_user_count.push(old_user);
        total_user_count.push(new_user + old_user);

        const register_user = register_users[date]
          ? register_users[date].length
          : 0;
        register_user_count.push(register_user);

        sales_price.push(
          sales_list[date]
            ? sales_list[date].reduce((acc, obj) => {
                return acc + (obj.type != 2 ? obj.price : obj.price * -1);
              }, 0)
            : 0
        );
      });

      const user_count_series = [
        { name: "신규", data: new_user_count },
        { name: "기존", data: old_user_count },
        { name: "전체", data: total_user_count },
      ];
      const user_count_option = {
        chart: {
          type: "bar",
          height: 350,
          events: { dataPointSelection: onClickUserChart },
        },
        plotOptions: { bar: { horizontal: false, endingShape: "rounded" } },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: { fontSize: "12px", colors: ["#304758"] },
        },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        xaxis: { categories: dates },
        yaxis: { title: { text: "명" } },
        fill: { opacity: 1 },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " 명";
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: { height: 500 },
              legend: { position: "bottom" },
              plotOptions: { bar: { horizontal: true } },
              xaxis: {
                labels: {
                  formatter: function (val) {
                    return val + " 명";
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: function (val) {
                    return val;
                  },
                },
              },
            },
          },
        ],
        grid: {
          xaxis: { lines: { show: false } },
          yaxis: { lines: { show: true } },
        },
      };

      const sales_price_series = [{ name: "Price", data: sales_price }];
      const sales_price_option = {
        chart: {
          type: "bar",
          height: 350,
          events: { dataPointSelection: onClickSalesChart },
        },
        plotOptions: { bar: { horizontal: false, endingShape: "rounded" } },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toLocaleString("en-US") + "원";
          },
          style: { fontSize: "12px", colors: ["#304758"] },
        },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        xaxis: { categories: dates },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toLocaleString("en-US") + " 원";
            },
          },
          title: { text: "원" },
        },
        fill: { opacity: 1 },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString("en-US") + " 원";
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: { height: 500 },
              legend: { position: "bottom" },
              plotOptions: { bar: { horizontal: true } },
              xaxis: {
                labels: {
                  formatter: function (val) {
                    return Number.isInteger(val) ? val / 10000 + "만원" : val;
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: function (val) {
                    return val;
                  },
                },
              },
            },
          },
        ],
        grid: {
          xaxis: { lines: { show: false } },
          yaxis: { lines: { show: true } },
        },
      };

      const sales_ticket_count = [];
      const sales_ticket_keys = Object.keys(sales_ticket_list);
      sales_ticket_keys.forEach((key) => {
        sales_ticket_count.push(sales_ticket_list[key].length);
      });

      const sales_tickets_series = sales_ticket_count;
      const sales_tickets_option = {
        chart: {
          width: 380,
          type: "pie",
          events: { dataPointSelection: onClickTicketChart },
        },
        labels: sales_ticket_keys,
        fill: {
          colors: [
            "#008FFB",
            "#00E396",
            "#FEB019",
            "#FF4560",
            "#775DD0",
            "#4CAF50",
            "#546E7A",
            "#D4526E",
            "#13D8AA",
            "#A5978B",
            "#4ECDC4",
            "#C7F464",
            "#81D4FA",
            "#FD6A6A",
            "#2B908F",
            "#F9A3A4",
            "#90EE7E",
            "#69D2E7",
            "#F86624",
            "#2E294E",
          ],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: { width: 200 },
              legend: { position: "bottom" },
            },
          },
        ],
      };
      // console.log(funnels);
      const funnel_keys_desc = [];
      const funnel_count = [];
      const funnel_keys = Object.keys(funnels);
      funnel_keys.forEach((key) => {
        funnel_count.push(funnels[key].length);
        funnel_keys_desc.push(constants.funnel[key]);
      });

      const user_funnel_series = funnel_count;
      const user_funnel_option = {
        chart: {
          width: 380,
          height: 350,
          type: "pie",
          events: { dataPointSelection: onClickFunnelsChart },
        },
        labels: funnel_keys_desc,
        fill: {
          colors: [
            "#008FFB",
            "#00E396",
            "#FEB019",
            "#FF4560",
            "#775DD0",
            "#4CAF50",
            "#546E7A",
            "#D4526E",
            "#13D8AA",
            "#A5978B",
            "#4ECDC4",
            "#C7F464",
            "#81D4FA",
            "#FD6A6A",
            "#2B908F",
            "#F9A3A4",
            "#90EE7E",
            "#69D2E7",
            "#F86624",
            "#2E294E",
          ],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: { width: 200 },
              legend: { position: "bottom" },
            },
          },
        ],
      };

      const register_user_count_series = [
        { name: "전체 회원", type: "column", data: total_user_count },
        { name: "신규 회원", type: "line", data: register_user_count },
      ];
      const register_user_count_option = {
        chart: {
          height: 350,
          type: "line",
          events: { dataPointSelection: onClickNewUserChart },
        },
        stroke: { width: [0, 4] },
        dataLabels: { enabled: true, enabledOnSeries: [0, 1] },
        // labels: Object.keys(register_user_count),
        xaxis: { categories: dates },
        yaxis: [
          { title: { text: "전체 회원" } },
          { opposite: true, title: { text: "신규 회원" } },
        ],
        grid: {
          xaxis: { lines: { show: false } },
          yaxis: { lines: { show: true } },
        },
      };

      // console.log(register_users);
      const accessibility_keys_desc = [];
      const accessibility_count = [];
      const accessibility_keys = Object.keys(accessibilities);
      accessibility_keys.forEach((key) => {
        accessibility_count.push(accessibilities[key].length);
        accessibility_keys_desc.push(key);
      });

      const user_accessibility_series = accessibility_count;
      const user_accessibility_option = {
        chart: {
          width: 380,
          height: 350,
          type: "pie",
          events: { dataPointSelection: onClickAccessibilitiesChart },
        },
        labels: accessibility_keys_desc,
        fill: {
          colors: [
            "#008FFB",
            "#00E396",
            "#FEB019",
            "#FF4560",
            "#775DD0",
            "#4CAF50",
            "#546E7A",
            "#D4526E",
            "#13D8AA",
            "#A5978B",
            "#4ECDC4",
            "#C7F464",
            "#81D4FA",
            "#FD6A6A",
            "#2B908F",
            "#F9A3A4",
            "#90EE7E",
            "#69D2E7",
            "#F86624",
            "#2E294E",
          ],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: { width: 200 },
              legend: { position: "bottom" },
            },
          },
        ],
      };
      console.log(accessibilities);

      setData({
        dates: dates,
        new_user_list: new_users,
        old_user_list: old_users,
        register_user_list: register_users,
        sales_list: sales_list,
        sales_ticket_list: sales_ticket_list,
        funnel_list: funnels,
        accessibility_list: accessibilities,
        register_user_count_option: register_user_count_option,
        register_user_count_series: register_user_count_series,
        user_count_option: user_count_option,
        user_count_series: user_count_series,
        sales_price_option: sales_price_option,
        sales_price_series: sales_price_series,
        sales_tickets_option: sales_tickets_option,
        sales_tickets_series: sales_tickets_series,
        user_funnel_option: user_funnel_option,
        user_funnel_series: user_funnel_series,
        user_accessibility_option: user_accessibility_option,
        user_accessibility_series: user_accessibility_series,
      });
    } catch (ex) {
      alert(ex);
    }
  }

  function onClickNewUserChart(event, chartContext, config) {
    // console.log(`onClickNewUserChart `, event, chartContext, config);
    setModalItem({
      item: ModalItem.NewUser,
      config: config,
    });
  }

  function onClickUserChart(event, chartContext, config) {
    // console.log(`onClickUserChart `, event, chartContext, config);
    setModalItem({
      item: ModalItem.User,
      config: config,
    });
  }

  function onClickSalesChart(event, chartContext, config) {
    // console.log(`onClickSalesChart `, event, chartContext, config);
    setModalItem({
      item: ModalItem.Sales,
      config: config,
    });
  }

  function onClickTicketChart(event, chartContext, config) {
    // console.log(`onClickTicketChart `, event, chartContext, config);
    setModalItem({
      item: ModalItem.Ticket,
      config: config,
    });
  }

  function onClickFunnelsChart(event, chartContext, config) {
    // console.log(`onClickFunnelsChart `, event, chartContext, config);
    setModalItem({
      item: ModalItem.Funnels,
      config: config,
    });
  }

  function onClickAccessibilitiesChart(event, chartContext, config) {
    // console.log(`onClickAccessibilitiesChart `, event, chartContext, config);
    setModalItem({
      item: ModalItem.Accessibility,
      config: config,
    });
  }

  async function loadBranch(_branch, startDate, endDate) {
    const result = [];
    const dashboarStatisticsdRes = await DashboardClient.getDailyStatistics({
      _branch: _branch,
      startDate: startDate,
      endDate: endDate,
    });

    return {
      statistics: dashboarStatisticsdRes.data.success
        ? dashboarStatisticsdRes.data.result.statistics
        : [],
      sales: dashboarStatisticsdRes.data.success
        ? dashboarStatisticsdRes.data.result.sales
        : [],
    };
  }

  function getSalesTypeName(type) {
    switch (type) {
      case 1:
        return "수강권 구매";
      case 2:
        return "수강권 환불";
      case 3:
        return "레슨 횟수 구매";
    }
  }

  function render_LinkUserDetail(id, name) {
    return (
      <td>
        <a href={"/user/userdetail/" + id}>{name}</a>
      </td>
    );
  }

  function renderModalNewUser() {
    const date = data.dates[modalItem.config.dataPointIndex];
    // console.log(data);
    if (!date) return null;

    return (
      <>
        <div className="table-responsive">
          <table className="table fixed-height">
            <thead className="thead-light">
              <tr>
                <th></th>
                <th>회원명</th>
              </tr>
            </thead>
            <tbody>
              {data.register_user_list[date]
                ? data.register_user_list[date].map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {render_LinkUserDetail(item._user, item.name)}
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  function renderModalUser() {
    const date = data.dates[modalItem.config.dataPointIndex];
    // console.log(data);
    if (!date) return null;

    const new_user_count = data.new_user_list[date]
      ? data.new_user_list[date].length
      : 0;
    return (
      <>
        <div className="table-responsive">
          <table className="table fixed-height">
            <thead className="thead-light">
              <tr>
                <th></th>
                <th>구분</th>
                <th>회원명</th>
              </tr>
            </thead>
            <tbody>
              {data.new_user_list[date]
                ? data.new_user_list[date].map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>신규</td>
                      {render_LinkUserDetail(item._id, item.name)}
                      {/* <td>{moment(item.endDate).format("YYYY-MM-DD")}</td>
                                <td>
                                    {item.lesson} / {item.lesson_all + item.lesson_buy + item.lesson_carry_over}
                                </td>
                                <td>{moment(item.book.lesson_at).format("YYYY-MM-DD")}</td> */}
                    </tr>
                  ))
                : null}
              {data.old_user_list[date]
                ? data.old_user_list[date].map((item, index) => (
                    <tr key={index}>
                      <td>{new_user_count + index + 1}</td>
                      <td>기존</td>
                      {render_LinkUserDetail(item._id, item.name)}
                      {/* <td>{moment(item.endDate).format("YYYY-MM-DD")}</td>
                                <td>
                                    {item.lesson} / {item.lesson_all + item.lesson_buy + item.lesson_carry_over}
                                </td>
                                <td>{moment(item.book.lesson_at).format("YYYY-MM-DD")}</td> */}
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  function renderModalSales() {
    // console.log(modalItem);
    const date = data.dates[modalItem.config.dataPointIndex];
    // console.log(date);
    if (!date) return null;

    return (
      <>
        <div className="table-responsive">
          <table className="table fixed-height">
            <thead className="thead-light">
              <tr>
                <th></th>
                <th>회원명</th>
                <th>구분</th>
                <th>수강권</th>
                <th>가격</th>
              </tr>
            </thead>
            <tbody>
              {data.sales_list[date]
                ? data.sales_list[date].map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      {render_LinkUserDetail(item._user._id, item._user.name)}
                      <td>{getSalesTypeName(item.type)}</td>
                      <td>{item._ticket ? item._ticket.name : ""}</td>
                      <td>{item.price.toLocaleString("en-US")} 원</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  function renderModalSalesTicket() {
    const keys = Object.keys(data.sales_ticket_list);
    const key = keys[modalItem.config.dataPointIndex];
    if (!key) return null;
    console.log(data.sales_ticket_list[key]);
    return (
      <>
        <div className="table-responsive">
          <table className="table fixed-height">
            <thead className="thead-light">
              <tr>
                <th></th>
                <th>회원명</th>
                <th>수강권</th>
                <th>가격</th>
                <th>결제일</th>
              </tr>
            </thead>
            <tbody>
              {data.sales_ticket_list[key]
                ? data.sales_ticket_list[key].map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {render_LinkUserDetail(item._user._id, item._user.name)}
                        <td>{item._ticket.name}</td>
                        <td>{item.price.toLocaleString("en-US")} 원</td>
                        <td>{moment(item.date).format("YYYY-MM-DD")}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  function renderModalFunnel() {
    const keys = Object.keys(data.funnel_list);
    const key = keys[modalItem.config.dataPointIndex];
    if (!key) return null;
    // console.log(data.funnel_list, key);
    // console.log(data.funnel_list[key]);
    return (
      <>
        <div className="table-responsive">
          <table className="table fixed-height">
            <thead className="thead-light">
              <tr>
                <th></th>
                <th>회원명</th>
                <th>등록일</th>
                {/* <th>수강권</th>
                                <th>가격</th>
                                <th>결제일</th> */}
              </tr>
            </thead>
            <tbody>
              {data.funnel_list[key]
                ? data.funnel_list[key].map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {render_LinkUserDetail(item._user, item.user_name)}
                        <td>{item.date}</td>
                        {/* <td>{item._ticket.name}</td>
                                    <td>{item.price.toLocaleString("en-US")} 원</td>
                                    <td>{moment(item.date).format('YYYY-MM-DD')}</td> */}
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  function renderModalAccessibility() {
    const keys = Object.keys(data.accessibility_list);
    const key = keys[modalItem.config.dataPointIndex];
    if (!key) return null;
    // console.log(data.funnel_list, key);
    // console.log(data.funnel_list[key]);
    return (
      <>
        <div className="table-responsive">
          <table className="table fixed-height">
            <thead className="thead-light">
              <tr>
                <th></th>
                <th>회원명</th>
                <th>등록일</th>
                {/* <th>수강권</th>
                                <th>가격</th>
                                <th>결제일</th> */}
              </tr>
            </thead>
            <tbody>
              {data.accessibility_list[key]
                ? data.accessibility_list[key].map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {render_LinkUserDetail(item._user, item.user_name)}
                        <td>{item.date}</td>
                        {/* <td>{item._ticket.name}</td>
                                    <td>{item.price.toLocaleString("en-US")} 원</td>
                                    <td>{moment(item.date).format('YYYY-MM-DD')}</td> */}
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  function renderModal() {
    // console.log(modalItem);
    switch (modalItem.item) {
      case ModalItem.NewUser:
        return renderModalNewUser();
      case ModalItem.User:
        return renderModalUser();
      case ModalItem.Sales:
        return renderModalSales();
      case ModalItem.Ticket:
        return renderModalSalesTicket();
      case ModalItem.Funnels:
        return renderModalFunnel();
      case ModalItem.Accessibility:
        return renderModalAccessibility();
    }
    return null;
  }

  console.log(data);
  return (
    <>
      <h4>일간 통계</h4>
      <div className="col">
        <div className="row">
          <div className="col duration">
            <input
              type="date"
              className="form-control"
              name="startDate"
              onChange={(e) => {
                setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
              }}
              defaultValue={startDate}
              required="required"
            />
          </div>
          <div className="col">
            <input
              type="date"
              className="form-control"
              name="endDate"
              onChange={(e) => {
                setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
              }}
              defaultValue={endDate}
              // disabled={true}
            />
          </div>
          <div>
            <button // className="btn btn-lg btn-primary mr-1"
              className="btn btn-sm btn-primary"
              onClick={load}
            >
              검색
            </button>
          </div>
        </div>
        <div>
          <label className="form-label" for="site-name">
            {" "}
            {ModalItem.NewUser.name}{" "}
          </label>
          <div id="chart">
            <ReactApexChart
              options={data.register_user_count_option}
              series={data.register_user_count_series}
              type="line"
              height={350}
            />
          </div>
        </div>
        <div className="divider"></div>
        <div>
          <label className="form-label" for="site-name">
            {" "}
            {ModalItem.User.name}{" "}
          </label>
          <div id="columnchart">
            <ReactApexChart
              options={data.user_count_option}
              series={data.user_count_series}
              type="bar"
              height={350}
            />
          </div>
        </div>
        <div className="divider"></div>
        {renderSales ? (
          <div>
            <div>
              <label className="form-label" for="site-name">
                {" "}
                {ModalItem.Sales.name}{" "}
              </label>
              <div id="columnchart">
                <ReactApexChart
                  options={data.sales_price_option}
                  series={data.sales_price_series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
            <div className="divider"></div>
          </div>
        ) : null}
        <div>
          <label className="form-label" for="site-name">
            {" "}
            {ModalItem.Ticket.name}{" "}
          </label>
          <div id="chart">
            <ReactApexChart
              options={data.sales_tickets_option}
              series={data.sales_tickets_series}
              type="pie"
              height={350}
            />
          </div>
        </div>
        <div className="divider"></div>
        <div>
          <label className="form-label" for="site-name">
            {" "}
            {ModalItem.Funnels.name}{" "}
          </label>
          <div id="chart">
            <ReactApexChart
              options={data.user_funnel_option}
              series={data.user_funnel_series}
              type="pie"
              height={350}
            />
          </div>
        </div>
        <div className="divider"></div>
        <div>
          <label className="form-label" for="site-name">
            {" "}
            {ModalItem.Accessibility.name}{" "}
          </label>
          <div id="chart">
            <ReactApexChart
              options={data.user_accessibility_option}
              series={data.user_accessibility_series}
              type="pie"
              height={350}
            />
          </div>
        </div>
      </div>

      <ReactModal
        className="modal-content"
        overlayClassName="modal-overlay"
        isOpen={modalItem.item.id != ModalItem.None.id}
        onRequestClose={() => {
          setModalItem({ item: ModalItem.None, config: {} });
        }}
        shouldCloseOnOverlayClick={true}
      >
        <div className="card-title">
          <h6 className="title" style={{ margin: "10px" }}>
            {modalItem.item.name}
          </h6>
        </div>
        {renderModal()}
        <div
          className="modal-close"
          onClick={() => {
            setModalItem({ item: ModalItem.None, config: {} });
          }}
        >
          닫기
        </div>
      </ReactModal>
    </>
  );
}
