import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "./AuthenticatedComponent";
import AdminClient from "../utils/Http/AdminClient";

class Menubar extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      toggleOn: false,
      kakao_connect: false,
    };
  }

  componentDidMount = async () => {
    const script0 = document.createElement("script");
    script0.src =
      "https://cdn.jsdelivr.net/clipboard.js/1.5.3/clipboard.min.js";
    script0.async = false;

    const script1 = document.createElement("script");
    script1.src = "../js/vendors/nioapp/nioapp.min.js";
    script1.async = false;

    const script2 = document.createElement("script");
    script2.src = "../assets/js/bundle.js?ver=2.3.0";
    script2.async = false;

    const script3 = document.createElement("script");
    script3.src = "../assets/js/scripts.js?ver=2.3.0";
    script3.async = false;

    document.body.appendChild(script0);
    document.body.appendChild(script1);
    document.body.appendChild(script2);
    document.body.appendChild(script3);

    try {
      const response = await AdminClient.getAdmin(this.context._id);

      // console.log(response.data);

      // console.log(response.data.result.kakao_info);
      const kakao_info = response.data.result.kakao_info;
      // console.log(kakao_info !== undefined && Object.keys(kakao_info).length !== 0 && kakao_info.constructor === Object);
      this.setState({
        menuOpen: false,
        kakao_connect:
          response.data.result.kakao_connect !== undefined
            ? response.data.result.kakao_connect
            : false,
      });

      // console.log(this.context.branch._id);
      // const friends = await AdminClient.getkakaoFriends({_branch: this.context.branch._id});

      // console.log(friends.data);
    } catch (err) {
      console.log(err);
      alert("계정 정보를 불러올 수 없습니다.");
    }
  };

  KakaoConnect = async () => {
    if (
      !window.confirm(
        "이미 연동된 카카오 계정으로 연동시 이전 등록 정보는 사라집니다."
      )
    ) {
      return;
    }

    const res = await AdminClient.getKakaoLogin();
    if (res.data.success) {
      window.location.href = res.data.result.url;
    }
  };

  KakaoDisconnect = async () => {
    if (window.confirm("카카오 연동 해제 하시겠습니까?") == false) {
      return;
    }

    const res = await AdminClient.kakaoDisConnect(this.context._id);
    if (res.data.success) {
      alert("카카오 연동 해제 완료되었습니다.");
      window.location.href = "/";
    } else {
      alert(res.data.message);
    }
  };

  render() {
    let visibleNav = false;
    let branchView = false;
    let generalNav = false;
    if (this.context.role === "최고관리자") {
      visibleNav = true;
    }
    if (this.context.role === "일반관리자") {
      generalNav = true;
    }
    if (this.context.role === "지점관리자" || this.context.role === "매니저") {
      branchView = true;
    }

    const status = this.status;

    return (
      <div className="nk-header nk-header-fluid is-theme">
        <div className="container-xl wide-xl">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger mr-sm-2 d-lg-none">
              <span
                className="nk-nav-toggle nk-quick-nav-icon"
                data-target="headerNav"
              >
                <em className="icon ni ni-menu"></em>
              </span>
            </div>
            <div className="nk-header-brand">
              <Link to="/dashboard">
                <a href="/dashboard" className="logo-link">
                  <span>뮤라벨 관리자 시스템</span>
                </a>
              </Link>
            </div>
            <div className="nk-header-menu" data-content="headerNav">
              <div className="nk-header-mobile">
                <div className="nk-header-brand">
                  <Link to="/dashboard">
                    <a href="/dashboard" className="logo-link">
                      <span>뮤라벨 관리자 시스템</span>
                    </a>
                  </Link>
                </div>
                <div className="nk-menu-trigger mr-n2">
                  <Link to="/dashboard">
                    <a
                      href="/dashboard"
                      className="nk-nav-toggle nk-quick-nav-icon"
                      data-target="headerNav"
                    >
                      <em className="icon ni ni-arrow-left"></em>
                    </a>
                  </Link>
                </div>
              </div>
              <ul className="nk-menu nk-menu-main ui-s2">
                <li className="nk-menu-item">
                  <Link to="/dashboard">
                    <a href="/dashboard" className="nk-menu-link">
                      <span className="nk-menu-text">
                        {visibleNav ? "대쉬보드" : null}
                      </span>
                    </a>
                  </Link>
                </li>
                {visibleNav || branchView || generalNav ? (
                  <li className="nk-menu-item has-sub">
                    <Link
                      onClick={(e) =>
                        (window.location.href = "/book/bookmanagement")
                      }
                      to="/book/bookmanagement"
                    >
                      <a
                        href="/book/bookmanagement"
                        className="nk-menu-link nk-menu-toggle"
                      >
                        <span className="nk-menu-text">예약관리</span>
                      </a>
                    </Link>
                    <ul className="nk-menu-sub">
                      <li className="nk-menu-item">
                        <Link to="/book/bookmanagement">
                          <a
                            className="nk-menu-link"
                            href="/book/bookmanagement"
                          >
                            <span className="nk-menu-text">예약자관리</span>
                          </a>
                        </Link>
                      </li>
                      {visibleNav || branchView ? (
                        <li className="nk-menu-item">
                          <Link to="/book/bookstatus">
                            <a className="nk-menu-link" href="/book/bookstatus">
                              <span className="nk-menu-text">예약현황</span>
                            </a>
                          </Link>
                        </li>
                      ) : null}
                      {visibleNav || branchView ? (
                        <li className="nk-menu-item">
                          <Link to="/book/bookregister">
                            <a
                              className="nk-menu-link"
                              href="/book/bookregister"
                            >
                              <span className="nk-menu-text">예약등록</span>
                            </a>
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                ) : null}
                {visibleNav || branchView || generalNav ? (
                  <li className="nk-menu-item has-sub">
                    <Link
                      onClick={(e) => (window.location.href = "/user/userlist")}
                      to="/user/userlist"
                    >
                      <a
                        href="/user/userlist"
                        className="nk-menu-link nk-menu-toggle"
                      >
                        <span className="nk-menu-text">회원관리</span>
                      </a>
                    </Link>
                    <ul className="nk-menu-sub">
                      <li className="nk-menu-item">
                        <Link to="/user/userlist">
                          <a href="/user/userlist" className="nk-menu-link">
                            <span className="nk-menu-text">회원리스트</span>
                          </a>
                        </Link>
                      </li>
                      <li className="nk-menu-item">
                        <Link to="/user/userregister">
                          <a href="/user/userregister" className="nk-menu-link">
                            <span className="nk-menu-text">신규회원등록</span>
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : null}
                {visibleNav || branchView ? (
                  <li className="nk-menu-item has-sub">
                    <Link
                      onClick={(e) =>
                        (window.location.href = "/branch/branchlist")
                      }
                      to="/branch/branchlist"
                    >
                      <a
                        href="/branch/branchlist"
                        className="nk-menu-link nk-menu-toggle"
                      >
                        <span className="nk-menu-text">지점관리</span>
                      </a>
                    </Link>
                    {visibleNav ? (
                      <ul className="nk-menu-sub">
                        <li className="nk-menu-item">
                          <Link to="/branch/branchlist">
                            <a
                              className="nk-menu-link"
                              href="/branch/branchlist"
                            >
                              <span className="nk-menu-text">지점리스트</span>
                            </a>
                          </Link>
                        </li>
                        <li className="nk-menu-item">
                          <Link to="/branch/branchregister">
                            <a
                              className="nk-menu-link"
                              href="/branch/branchregister"
                            >
                              <span className="nk-menu-text">지점등록</span>
                            </a>
                          </Link>
                        </li>
                        <li className="nk-menu-item">
                          <Link to="/branch/suggestionlist">
                            <a
                              className="nk-menu-link"
                              href="/branch/suggestionlist"
                            >
                              <span className="nk-menu-text">건의함</span>
                            </a>
                          </Link>
                        </li>
                        <li className="nk-menu-item">
                          <Link to="/branch/suggestion">
                            <a
                              className="nk-menu-link"
                              href="/branch/suggestion"
                            >
                              <span className="nk-menu-text">
                                건의사항 작성
                              </span>
                            </a>
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      <ul className="nk-menu-sub">
                        <li className="nk-menu-item">
                          <Link
                            to={
                              "/branch/branchmodify/" + this.context.branch._id
                            }
                          >
                            <a
                              className="nk-menu-link"
                              href={
                                "/branch/branchmodify/" +
                                this.context.branch._id
                              }
                            >
                              <span className="nk-menu-text">지점정보</span>
                            </a>
                          </Link>
                        </li>
                        <li className="nk-menu-item">
                          <Link to="/branch/suggestionlist">
                            <a
                              className="nk-menu-link"
                              href="/branch/suggestionlist"
                            >
                              <span className="nk-menu-text">건의함</span>
                            </a>
                          </Link>
                        </li>
                        <li className="nk-menu-item">
                          <Link to="/branch/suggestion">
                            <a
                              className="nk-menu-link"
                              href="/branch/suggestion"
                            >
                              <span className="nk-menu-text">
                                건의사항 작성
                              </span>
                            </a>
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                ) : null}
                {visibleNav || branchView || generalNav ? (
                  <li className="nk-menu-item has-sub">
                    <Link
                      onClick={(e) =>
                        (window.location.href = "/teacher/teacherlist")
                      }
                      to="/teacher/teacherlist"
                    >
                      <a
                        href="/teacher/teacherlist"
                        className="nk-menu-link nk-menu-toggle"
                      >
                        <span className="nk-menu-text">강사관리</span>
                      </a>
                    </Link>
                    <ul className="nk-menu-sub">
                      <li className="nk-menu-item">
                        <Link to="/teacher/teacherlist">
                          <a
                            className="nk-menu-link"
                            href="/teacher/teacherlist"
                          >
                            <span className="nk-menu-text">강사리스트</span>
                          </a>
                        </Link>
                      </li>
                      <li className="nk-menu-item">
                        <Link to="/teacher/teacherregister">
                          <a
                            className="nk-menu-link"
                            href="/teacher/teacherregister"
                          >
                            <span className="nk-menu-text">신규강사등록</span>
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : null}
                {visibleNav || branchView || generalNav ? (
                  <li className="nk-menu-item has-sub">
                    <Link
                      onClick={(e) =>
                        (window.location.href = "/subject/subjectlist")
                      }
                      to="/subject/subjectlist"
                    >
                      <a
                        href="/subject/subjectlist"
                        className="nk-menu-link nk-menu-toggle"
                      >
                        <span className="nk-menu-text">과목관리</span>
                      </a>
                    </Link>
                    <ul className="nk-menu-sub">
                      <li className="nk-menu-item">
                        <Link to="/subject/subjectlist">
                          <a
                            className="nk-menu-link"
                            href="/subject/subjectlist"
                          >
                            <span className="nk-menu-text">과목리스트</span>
                          </a>
                        </Link>
                      </li>
                      <li className="nk-menu-item">
                        <Link to="/subject/subjectregister">
                          <a
                            className="nk-menu-link"
                            href="/subject/subjectregister"
                          >
                            <span className="nk-menu-text">신규과목등록</span>
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : null}
                {visibleNav || branchView || generalNav ? (
                  <li className="nk-menu-item has-sub">
                    <a
                      onClick={(e) =>
                        (window.location.href = "/admin/adminlist")
                      }
                      href="/admin/adminlist"
                      className="nk-menu-link nk-menu-toggle"
                    >
                      <span className="nk-menu-text">관리자 관리</span>
                    </a>
                    <ul className="nk-menu-sub">
                      <li className="nk-menu-item">
                        <a className="nk-menu-link" href="/admin/adminlist">
                          <span className="nk-menu-text">관리자리스트</span>
                        </a>
                      </li>
                      <li className="nk-menu-item">
                        <a className="nk-menu-link" href="/admin/adminregister">
                          <span className="nk-menu-text">관리자 등록</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                ) : null}
              </ul>
            </div>

            {!visibleNav && !branchView && !generalNav ? (
              <li className="nk-menu-item has-sub">
                <Link
                  onClick={(e) => (window.location.href = "/branch/suggestion")}
                  to="/branch/suggestion"
                >
                  <a
                    href="/branch/suggestion"
                    className="nk-menu-link nk-menu-toggle"
                  >
                    <span className="nk-menu-text">건의사항 작성하기</span>
                  </a>
                </Link>
              </li>
            ) : null}

            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                <li
                  onClick={(e) => {
                    this.setState({
                      toggleOn: !this.state.toggleOn,
                    });
                  }}
                  className="dropdown user-dropdown order-sm-first"
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    href="#"
                    className="dropdown-toggle"
                  >
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt"></em>
                      </div>
                      <div className="user-info d-none d-xl-block">
                        <div className="user-status">{this.context.role}</div>
                        <div className="user-name dropdown-indicator">
                          {this.context.name}
                        </div>
                      </div>
                    </div>
                  </a>

                  {this.state.toggleOn && (
                    <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light show">
                      <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                        <div className="user-card">
                          <div className="user-avatar">
                            <span>{this.context.name.charAt(0)}</span>
                          </div>
                          <div className="user-info">
                            <span className="lead-text">
                              {this.context.name}
                            </span>
                            <span className="sub-text">
                              {this.context.email}
                            </span>
                          </div>
                          {/* <div className="user-action">
                                                <a className="btn btn-icon mr-n2" href="html/invest/profile-setting.html"><em className="icon ni ni-setting"></em></a>
                                            </div> */}
                        </div>
                      </div>
                      <div className="dropdown-inner">
                        <ul className="link-list">
                          <li>
                            <Link to={`/admin/adminmodify/${this.context._id}`}>
                              <a
                                href={`/admin/adminmodify/${this.context._id}`}
                              >
                                <em className="icon ni ni-user-alt"></em>
                                <span>계정</span>
                              </a>
                            </Link>
                          </li>
                          <li>
                            <a href="http://www.mlb-piano.com" target="_blank">
                              <em className="icon ni ni-heart"></em>
                              <span>홈페이지</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      {this.context.role === "지점관리자" ||
                      this.context.role === "강사" ? (
                        <div className="dropdown-inner">
                          <ul className="link-list">
                            <p
                              className="username"
                              style={{ cursor: "pointer" }}
                              onClick={
                                this.state.kakao_connect === false
                                  ? this.KakaoConnect
                                  : this.KakaoDisconnect
                              }
                            >
                              {this.state.kakao_connect === false
                                ? "카카오 연결하기"
                                : "카카오 연결 해제"}
                            </p>
                          </ul>
                        </div>
                      ) : null}
                      <div className="dropdown-inner">
                        <ul className="link-list">
                          <li>
                            <a href="/logout">
                              <em className="icon ni ni-signout"></em>
                              <span>로그아웃</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menubar;
