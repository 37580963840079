import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "./AuthenticatedComponent";
import moment from "moment";
import LessonNotificationClient from '../utils/Http/LessonNotificationClient';

class BranchLessonNotification extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        var now = moment();
        var date = now.format("YYYY-MM-DD");

        this.state = {
            lesson_notification_date: date,
            lesson_notifications: [],
        };
    }

    componentDidMount () {
        this.LoadLessonNotification(this.state.lesson_notification_date);
    };

    onChangeDate = (e) => {
        console.log(e.target.value);
        this.LoadLessonNotification(e.target.value);
    }

    LoadLessonNotification = async (date) => {
        try {

            const LessonNotificationRes = await LessonNotificationClient.get({
                _branch: this.props.branch,
                date: moment(date).format('YYYY-MM-DD'),
            });

            if(LessonNotificationRes.data.success === false)
            {
                alert(LessonNotificationRes.data.message);
                return;
            }
            this.setState({
                lesson_notification_date: date,
                lesson_notifications: LessonNotificationRes.data.result,
            });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return (
            <>
                <div>
                    <input
                        type="date"
                        className="form-control"
                        name="lesson_notification_date"
                        onChange={this.onChangeDate}
                        value={moment(this.state.lesson_notification_date).format("YYYY-MM-DD")}
                    />
                </div>
                <div className="divider" />
                <div className="card card-bordered card-stretch">
                    <div class="table-responsive">
                        <table class="table">
                            <thead className="thead-light">
                                <th></th>
                                <th>이름</th>
                                <th>시간</th>
                                <th>전송성공</th>
                                <th>메시지</th>
                                <th>날짜</th>
                            </thead>
                            <tbody>
                                {this.state.lesson_notifications.map((data, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{data.name}{data.is_teacher === true ? `(T)` : ''}</td>
                                        <td>{data.time}</td>
                                        <td>{data.success ? 'Y' : 'N'}</td>
                                        <td>{data.message}</td>
                                        <td>{moment(data.create_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(BranchLessonNotification);