import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import { AuthContext } from "../components/AuthenticatedComponent";
import Validation from "../utils/Validations";
import Content from "../components/Content";
import AdminClient from "../utils/Http/AdminClient";
import BranchClient from "../utils/Http/BranchClient";
import SubjectClient from "../utils/Http/SubjectClient";
import moment from 'moment';

class TeacherProfile extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            id: "",
            name: "",
            phone: "",
            _branch: "",
            branchArr: [],
            _subject: "",
            subjectArr: [],
            password1: "",
            password2: "",
            role: "강사",
            lesson_price: 0,
            cancel_price: 0,
            contract_period_start: moment().format('YYYY-MM-DD'),
            contract_period_end: moment().format('YYYY-MM-DD'),
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount = async () => {
        try {
            const res = await AdminClient.getAdmin(this.props.match.params.id);
            const branchList = await BranchClient.getList();
            const subjectList = await SubjectClient.getList();
            this.setState({
                id: res.data.result.id,
                name: res.data.result.name,
                phone: res.data.result.phone,
                lesson_price: res.data.result.lesson_price,
                cancel_price: res.data.result.cancel_price,
                contract_period_start: moment(res.data.result.contract_period.start).format('YYYY-MM-DD'),
                contract_period_end: moment(res.data.result.contract_period.end).format('YYYY-MM-DD'),
                _branch: res.data.result._branch
                    ? res.data.result._branch._id
                    : null,
                _subject: res.data.result._subject
                    ? res.data.result._subject._id
                    : null,
                branchArr: branchList.data.success
                    ? branchList.data.result
                    : [],
                subjectArr: subjectList.data.success
                    ? subjectList.data.result
                    : [],
            });
        } catch (err) {
            console.log(err);
        }
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        const idValidation = await Validation.id(this.state.id);

        const contract_period_start = moment(this.state.contract_period_start).format('YYYY-MM-DD');
        const contract_period_end = moment(this.state.contract_period_end).format('YYYY-MM-DD');
        if(contract_period_end < contract_period_start)
        {
            alert("계약 종료일은 시작일 이후로 설정되어야합니다.");
            return;
        }

        if (!idValidation.success) {
            alert(idValidation.message);
        } else {
            const teachermodify = {
                id: this.state.id,
                name: this.state.name,
                phone: this.state.phone,
                _branch: this.state._branch,
                _subject: this.state._subject,
                password1: this.state.password1,
                password2: this.state.password2,
                lesson_price: this.state.lesson_price,
                cancel_price: this.state.cancel_price,
                contract_period_start: contract_period_start,
                contract_period_end: contract_period_end,
                role: "강사",
            };

            try{
                const res = await AdminClient.update(this.props.match.params.id, teachermodify);

                if (res.data.success === true) {
                    alert(res.data.message);
                    // this.props.history.push('/teacher/teacherlist');
                    window.location.href = "/teacher/teacherlist";
                } else {
                    if(res.data.books)
                    {
                        let message = res.data.message + "\n";
                        const count_max = 10;
                        var count = Math.min(res.data.books.length, count_max);
                        for(var i = 0; i < count; ++i)
                        {
                            const book = res.data.books[i];
                            message += `${moment(book.lesson_at).format('YYYY-MM-DD')} ${book.lesson_time} ${book._user.name}\n`
                        }
                        if(res.data.books.length > 10)
                            message += "...";
                        alert(message);
                    }
                    else
                    {
                        alert(res.data.message);
                    }
                }
            }
            catch(err){
                console.log(err);
                alert(err.message + "실패");
            }
        }
    };
    render() {
        // console.log(this.context);

        //   if(!this.state._branch) {
        //       return null
        //   }

        if (!this.state.id) {
            return null;
        }
        return (
            <>
                <Menubar />
                <Content>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">
                                    강사 프로필
                                </h3>
                                {/* <div className="nk-block-des text-soft">
                                <p>{this.state.users.length}건의 데이터 조회</p>
                            </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="nk-block">
                        <div class="btn-group mb-2" aria-label="Basic example">
                            <Link
                                to={`/teacher/teacherprofile/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/teacher/teacherprofile/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="linkbar">프로필</p>
                                </a>
                            </Link>
                            <Link
                                to={`/teacher/teacherschedule/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/teacher/teacherschedule/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="nolinkbar">
                                        스케쥴 관리
                                    </p>
                                </a>
                            </Link>
                            <Link
                                to={`/teacher/teacherbookstatus/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/teacher/teacherbookstatus/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="nolinkbar">예약 현황</p>
                                </a>
                            </Link>
                            <Link
                                to={`/admin/teacherlessonnote/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/admin/teacherlessonnote/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="nolinkbar">레슨 노트</p>
                                </a>
                            </Link>
                            <Link
                                to={`/teacher/teacherlessontransfer/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/teacher/teacherlessontransfer/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="nolinkbar">레슨 이전</p>
                                </a>
                            </Link>
                        </div>
                        <div className="card card-bordered">
                            <div className="card-inner">
                                <div className="card-head">
                                    <h5 className="card-title">강사계정</h5>
                                </div>
                                <form onSubmit={this.onSubmit} className="gy-3">
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    이름
                                                </label>
                                                <span className="form-note">
                                                    강사이름
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        onChange={this.onChange}
                                                        defaultValue={
                                                            this.state.name
                                                        }
                                                        required="required"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    아이디
                                                </label>
                                                <span className="form-note">
                                                    로그인 아이디
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="id"
                                                        name="id"
                                                        readOnly
                                                        onChange={this.onChange}
                                                        defaultValue={
                                                            this.state.id
                                                        }
                                                        required="required"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    비밀번호
                                                </label>
                                                <span className="form-note">
                                                    로그인 비밀번호
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="password1"
                                                        name="password1"
                                                        onChange={this.onChange}
                                                        defaultValue={
                                                            this.state.password1
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    비밀번호 확인
                                                </label>
                                                <span className="form-note">
                                                    로그인 비밀번호 확인
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="password2"
                                                        name="password2"
                                                        onChange={this.onChange}
                                                        defaultValue={
                                                            this.state.password2
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    휴대전화
                                                </label>
                                                <span className="form-note">
                                                    - 없이 입력
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="phone"
                                                        name="phone"
                                                        onChange={this.onChange}
                                                        defaultValue={
                                                            this.state.phone
                                                        }
                                                        required="required"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    소속지점
                                                </label>
                                                <span className="form-note">
                                                    지점 선택
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <select
                                                        className="form-control"
                                                        name="_branch"
                                                        id="form_message"
                                                        onChange={this.onChange}
                                                        required="required"
                                                        defaultValue={
                                                            this.state._branch
                                                                ? this.state
                                                                      ._branch
                                                                : "없음"
                                                        }
                                                    >
                                                        {/* <option value='' disabled>선택</option> */}
                                                        <option disabled>
                                                            {this.state._branch
                                                                ? "선택"
                                                                : "없음"}
                                                        </option>
                                                        {this.state.branchArr.map(
                                                            (item, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={
                                                                        item._id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    과목
                                                </label>
                                                <span className="form-note">
                                                    과목 선택
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <select
                                                        className="form-control"
                                                        name="_subject"
                                                        id="form_message"
                                                        onChange={this.onChange}
                                                        required="required"
                                                        // value={this.state._subject ? this.state._subject.name : null}
                                                        defaultValue={
                                                            this.state._subject
                                                                ? this.state
                                                                      ._subject
                                                                : "없음"
                                                        }
                                                    >
                                                        {/* <option value='' disabled>선택</option> */}
                                                        <option disabled>
                                                            {this.state._subject
                                                                ? "선택"
                                                                : "없음"}
                                                        </option>
                                                        {/* <option value={this.state._subject ? this.state._subject.name : null} disabled>{this.state._subject ? this.state._subject.name : null}</option> */}
                                                        {this.state.subjectArr.map(
                                                            (item, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={
                                                                        item._id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.context.role === "최고관리자" || this.context.role === "일반관리자" || this.context.role === "지점관리자" ?
                                    (
                                    <div>
                                        <div className="row g-3 align-center">
                                            <div className="col-lg-5">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        for="site-name"
                                                    >
                                                        레슨비
                                                    </label>
                                                    <span className="form-note">
                                                        레슨 1회당 비용을
                                                        입력하세요.
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="form-group">
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="lesson_price"
                                                            name="lesson_price"
                                                            value={
                                                                this.state
                                                                    .lesson_price
                                                            }
                                                            onChange={this.onChange}
                                                            required="required"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-3 align-center">
                                            <div className="col-lg-5">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        for="site-name"
                                                    >
                                                        당일 취소
                                                    </label>
                                                    <span className="form-note">
                                                        당일 취소 1회당 비용을
                                                        입력하세요.
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="form-group">
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="cancel_price"
                                                            name="cancel_price"
                                                            value={
                                                                this.state
                                                                    .cancel_price
                                                            }
                                                            onChange={this.onChange}
                                                            required="required"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ) : null}
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    계약 기간
                                                </label>
                                                <span className="form-note">
                                                    출강 시작 / 종료일을 입력하세요.
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col duration">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name="contract_period_start"
                                                        onChange={this.onChange}
                                                        value={this.state.contract_period_start}
                                                        required="required"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name="contract_period_end"
                                                        onChange={this.onChange}
                                                        value={this.state.contract_period_end}
                                                        // disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3">
                                        <div className="col-lg-7 offset-lg-5">
                                            <div className="form-group mt-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-primary mr-1"
                                                >
                                                    수정내용 저장
                                                </button>
                                                <Link to="/teacher/teacherlist">
                                                    <a
                                                        href="/teacher/teacherlist"
                                                        className="btn btn-lg btn-secondary"
                                                    >
                                                        취소
                                                    </a>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Content>
            </>
        );
    }
}

export default withRouter(TeacherProfile);