import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment-timezone";
import AdminClient from "../utils/Http/AdminClient";
import Constants, {lessonType} from "../common/Constants";

import "../App.css";

import "react-contexify/dist/ReactContexify.css";

moment.tz.setDefault("Asia/Seoul");

export default function TeacherBookTable(props) {
  const [teacher, setTeacher] = useState(null);

  useEffect(() => {
    load();
  }, []);

  async function load() {
    try {
      const response = await AdminClient.getAdmin(props._teacher);
      if (!response.data.success) {
        alert(`강사 정보를 가져 올 수 없습니다. ${response.data.message}`);
        return;
      }
      // console.log(response.data.result);

      // console.log(props);
      setTeacher(response.data.result);
    } catch (error) {
      alert(error.message);
    }
  }

  function isOpenSchedule(date, time, dow) {
    // console.log(props.schedules);
    if (!teacher) return false;
    if (
      date < moment(teacher.contract_period.start).format("YYYY-MM-DD") ||
      moment(teacher.contract_period.end).format("YYYY-MM-DD") < date
    )
      return false;

    const schedule = props.schedules[dow];
    // console.log(schedule, dow);
    if (!schedule) return false;

    var splits = time.split(":");

    // console.log(schedule, dow);
    if (splits[1] == "00") {
      return (
        schedule[`${splits[0]}:00`].openOnTime == Constants.scheduleState.Open
      );
    } else {
      return (
        schedule[`${splits[0]}:00`].openHalf == Constants.scheduleState.Open
      );
    }
  }

  function isAllowedHalftime(date, time, dow) {
    // console.log(props.schedules);
    if (!teacher) return false;
    if (
      date < moment(teacher.contract_period.start).format("YYYY-MM-DD") ||
      moment(teacher.contract_period.end).format("YYYY-MM-DD") < date
    )
      return false;

    const schedule = props.schedules[dow];

    if (!schedule) return false;

    return schedule[time].allowHalfTime;
  }

  function getClass(books, date, time, dow) {
    const book = books.find((item) => item.status === "apply");
    // console.log(book);
    if (book && !book.comment && !book.teacherComment) return "bg-yellow";

    if (books.length > 0) return "bg-white";

    if (isOpenSchedule(date, time, dow)) return "bg-white";

    return "bg-gray text-white";
  }

  function getBooks(lessonDate, lessonTime) {
    return props.books.filter(
      (book) =>
        (book.status === "apply" || book.status === "cancelOnTheDay") &&
        moment(book.lesson_at).format("YYYY-MM-DD") === lessonDate &&
        book.lesson_time === lessonTime
    );
  }

  function renderOnTime(date, time, dow) {
    let books = getBooks(date, time);
    return (
      <td
        key={props._teacher + "-" + time}
        className={`${getClass(books, date, time, dow)}`}
      >
        {renderBooks(books, date)}
      </td>
    );
  }

  function renderHalftime(date, time, dow) {
    const splits = time.split(":");
    const hour = splits[0];

    const onTime = `${hour}:00`;
    const halfTime = `${hour}:30`;
    const booksOnTime = getBooks(date, onTime);
    const booksHalftime = getBooks(date, halfTime);
    return (
        <td className="p-0">
          {/* <div
          className="bg-gray text-white"
          //   style={{ width: "132px", height: "31px" }}
        >
          a
        </div>
        <div
          className="divider m-0"
          //   style={{ width: "120px", height: "2px" }}
        ></div>

        <div
          className="bg-gray text-white"
          //   style={{ width: "132px", height: "31px" }}
        >
          a
        </div> */}
          <table className="p-0 w-100">
            <tr className={getClass(booksOnTime, date, onTime, dow)}>
              <td>{renderBooks(booksOnTime, date)}</td>
            </tr>
            <tr className={getClass(booksHalftime, date, halfTime, dow)}>
              <td>{renderBooks(booksHalftime, date)}</td>
            </tr>
          </table>
        </td>
    );
  }

  function renderLessonTypeBadge(type)
  {
    switch(type)
    {
      case lessonType.Fifty:
        return (<span className="badge badge-primary">50분</span>);
      case lessonType.Thirty:
        return (<span className="badge badge-success">30분</span>);
      case lessonType.Sixty:
        return (<span className="badge badge-danger">60분</span>);
      default:
        return (<></>);
    }

  }

  function renderBooks(books, date) {
    if (books.length == 0) return null;

    return books.map((book) => {
      // console.log(book);
      return (
        <div>
          <div>
            <strong>
              {book.status === "apply" ? (
                <a
                  className={book.user.new_bedge_until > date ? "new-user" : ""}
                  href={`/user/userdetail/${book.user._id}`}
                >
                  {book.user.name}
                </a>
              ) : (
                <a
                  className="line-through"
                  href={`/user/userdetail/${book.user._id}`}
                >
                  {book.user.name}
                </a>
              )}
            </strong>
          </div>
          <div>
            (
            {book.ticket.lesson +
              book.books.findIndex((item) => item._id === book._id)}
            /{" "}
            {book.ticket.lesson_all +
              book.ticket.lesson_buy +
              book.ticket.lesson_carry_over}
            ) {renderLessonTypeBadge(book.lesson_type)}
          </div>
        </div>
      );
    });
  }

  function bookCell(row) {
    const lessonTime = Constants.times[row];
    const label = props.weekDays.map((item, index) => {
      const allowedHalftime = isAllowedHalftime(item.date, lessonTime, index);
      // console.log(row, allowedHalftime);
      return (
        <>
          {allowedHalftime
            ? renderHalftime(item.date, lessonTime, index)
            : renderOnTime(item.date, lessonTime, index)}
        </>
      );
    });
    return label;
  }

  return (
    <div className="nk-block">
      <div className="card card-bordered card-stretch">
        <div className="table-responsive bookstyle" id="bookmangement">
          <table className="table table-hover table-bordered">
            <thead>
              <tr className="text-left">
                <th scope="row" rowSpan="2"></th>
                {props.weekDays.map((item, index) => {
                  const momentItem = moment(item.date);
                  return (
                    <th key={index} scope="cols" id={`sc-${index}`}>
                      {momentItem.get("month") + 1}월 {momentItem.get("date")}일{" "}
                      {item.dow}
                      요일
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {Constants.times.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{Constants.times[index]}</td>
                    {bookCell(index)}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
