import AxiosClient from "./AxiosClient";

const UserClient = {
    create: async (data) => {
        return await AxiosClient.post('user', data);
    },

    delete: async (data) => {
        return await AxiosClient.del('user/delete', data);
    },

    get: async (id, data) => {
        return await AxiosClient.get(`user/adminlist/${id}`, data);
    },
    getList: async(data) => {
        return await AxiosClient.get('user/list', data);
    },

    getById: async (id) => {
        return await AxiosClient.get(`user`, {
            id: id
        });
    },

    getUnreservedList: async(data) => {
        return await AxiosClient.get('user/unreservedlist', data);
    },

    updateState: async(id, data) => {
        return await AxiosClient.patch(`user/update/state/${id}`, data);
    },

    updateAdmin: async(id, data) => {
        return await AxiosClient.patch(`user/adminupdate/${id}`, data);
    },

    DuplicationCheck: async(data) => {
        return await AxiosClient.get('user/duplicate', data);
    },

    GetHistory: async(id, data) => {
        return await AxiosClient.get(`user/history/${id}`, data);
    },

    specialList: async(id) => {
        return await AxiosClient.get(`user/speciallist/${id}`);
    },

    blackList: async(id) => {
        return await AxiosClient.get(`user/blacklist`);
    },
}

export default UserClient;