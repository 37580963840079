import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Menubar from '../components/Menubar';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { AuthContext } from '../components/AuthenticatedComponent';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Content from '../components/Content';
import BookClient from '../utils/Http/BookClient';

export class BookList extends Component {
  static propTypes = {};

  static contextType = AuthContext;

  constructor(props) {
	super(props);

	this.state = {
	  books: [],
	  book: '',

	  selectedRow: [],

	  // offset: 0,
	  // orgtableData: [],
	  // perPage: 10,
	  // currentPage: 0,
	};
	// this.handlePageClick = this.handlePageClick.bind(this);
  }
  // handlePageClick = (e) => {
  //     const selectedPage = e.selected;
  //     const offset = selectedPage * this.state.perPage;

  //     this.setState({
  //         currentPage: selectedPage,
  //         offset: offset
  //     }, () => {
  //         this.loadMoreData()
  //     });
  // };
  // loadMoreData() {
  // 	const data = this.state.orgtableData;

  // 	const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
  // 	this.setState({
  // 		pageCount: Math.ceil(data.length / this.state.perPage),
  // 		books:slice
  // 	})

  // }

  componentDidMount = async () => {
	const books = await BookClient.getMonth(this.props.location.search);
	const date = queryString.parse(this.props.location.search);
	if (books.data.success) {
	  this.setState({
		books: books.data.result,
		date: date.gte,
	  });
	} else {
	  alert('데이터 조회 실패');
	}
  };

  onChangeSelectRow = (e) => {
	if (e.target.checked) {
	  this.setState((state) => ({
		selectedRow: [...state.selectedRow, e.target.name],
	  }));
	} else {
	  this.setState((state) => ({
		selectedRow: state.selectedRow.filter((item) => item !== e.target.name),
	  }));
	}
  };

  onCheckAll = (e) => {
	const checkboxes = document.getElementsByClassName('myCheckbox');
	checkboxes.forEach((item) => {
	  item.checked = e.target.checked;
	});

	if (e.target.checked) {
	  this.setState((state) => ({ selectedRow: state.books.map((item) => item._id) }));
	} else {
	  this.setState({
		selectedRow: [],
	  });
	}
  };

  onDeleteRow = async () => {
	if (!window.confirm('정말로 삭제하시겠습니까?')) return;
	try {
	  const deleteUser = await BookClient.delete({
		_id: this.state.selectedRow,
	  })

	  if (deleteUser.data.success) {
		window.location.reload();
	  } else {
		alert('삭제에 실패했습니다.');
	  }
	} catch (error) {
	  alert('삭제에 실패했습니다.');
	}
  };

  handleRemove = async (e) => {
	try {
	  if (window.confirm('취소하시겠습니까?') === true) {
		const id = e.target.dataset.book;
		// console.log(this.state.id+'sdfsdfsdf')
		const book = this.state.books.filter((x) => x._id == id)[0];
		alert('handleRemove ' + moment(Date.now()).format('YYYY-MM-DD') + ' ' + moment(book.lesson_at).format('YYYY-MM-DD'));
		let status = moment().format('YYYY-MM-DD') == moment(book.lesson_at).format('YYYY-MM-DD') ? 'cancelOnTheDay' : 'cancel';
		const res = await BookClient.update(id, {
			admin: true,
			status: status,
		});

		window.location.reload();
		// console.log(res.data)
	  } else {
		return;
	  }
	} catch (err) {
	  console.log(err);
	}
  };

  renderBookStatus(book) {
	if (book.status === 'apply') {
	  return <td>
		<button className="btn btn-dim btn-outline-secondary" data-book={book._id} onClick={this.handleRemove}>
		  예약취소
		</button>
	  </td>;
	} else if (book.status === 'cancelOnTheDay') {
	  return <td>
		<button className="btn btn-dim btn-outline-secondary" data-book={book._id} disabled>
		  당일취소
		</button>
	  </td>
	} else {
	  return <td>
		<button className="btn btn-dim btn-outline-secondary" data-book={book._id} disabled>
		  취소완료
		</button>
	  </td>
	}
  };

  render() {
	console.log(queryString.parse(this.props.location.search));
	const { books } = this.state;

	return (
	  <>
		<Menubar />
		<Content>
		  <div className="nk-block-head nk-block-head-sm">
			<div className="nk-block-between">
			  <div className="nk-block-head-content">
				<h3 className="nk-block-title page-title">{this.state.date} 예약 리스트</h3>
			  </div>
			</div>
		  </div>
		  <div className="nk-block">
			<div className="card card-bordered card-stretch">
			  <div class="table-responsive">
				<table class="table">
				  <thead className="thead-light">
					<th>아이디</th>
					<th>회원명</th>
					<th>예약일</th>
					<th>예약시간</th>
					<th>지점</th>
					<th>강사</th>
					<th>과목</th>
					<th>취소여부</th>
				  </thead>
				  <tbody>
					{books.map((book, index) => (
					  <tr key={index}>
						{this.context.role === '최고관리자' || this.context.role === '일반관리자' ? (
						  <td>
							<Link to={'/user/userdetail/' + book._user._id}>
							  <a href={'/user/userdetail/' + book._user._id}>{book._user ? book._user.id : null}</a>
							</Link>
						  </td>
						) : (
						  <td>{book._user ? book._user.id : null}</td>
						)}
						<td>{book._user ? book._user.name : null}</td>
						<td>{book.lesson_at ? moment(book.lesson_at).format('YYYY년 MM월 DD일') : null}</td>
						<td>{book.lesson_time ? book.lesson_time : null}</td>
						<td>{book._branch ? book._branch.name : null}</td>
						<td>{book._teacher ? book._teacher.name : book.teacher_name}</td>
						<td>{book._subject ? book._subject.name : null}</td>
						{this.renderBookStatus(book)}
					  </tr>
					))}
				  </tbody>
				</table>
			  </div>
			</div>
		  </div>
		</Content>
	  </>
	);
  }
}

export default BookList;
