import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { AuthContext } from "./AuthenticatedComponent";
import moment from "moment";
import AdminClient from '../utils/Http/AdminClient';
import BranchClient from '../utils/Http/BranchClient';

class BranchInfo extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        // var now = moment();
        // var date = now.add(1, 'day').format("YYYY-MM-DD");

        // const query = queryString.parse(this.props.location.search);

        this.state = {
            name: "",
            address: "",
            phone: "",
            operate_hour: "",
            kakaoid: "",
        };
    }

    componentDidMount () {
        this.load();
    };

    load = async () => {
        try {

            const response = await BranchClient.get(this.props.branch);

            if(response.data.success === false)
            {
                alert(response.data.message);
                return;
            }

            this.setState({
                name: response.data.result.name,
                address: response.data.result.address,
                phone: response.data.result.phone,
                operate_hour: response.data.result.operate_hour,
                kakaoid: response.data.result.kakaoid,
            });
        } catch (err) {
            console.log(err);
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();

        const branchmodify = {
            name: this.state.name,
            address: this.state.address,
            phone: this.state.phone,
            operate_hour: this.state.operate_hour,
            kakaoid: this.state.kakaoid,
        };

        try
        {
            const res = await BranchClient.update(this.props.match.params.id, branchmodify);

            if (res.data.success === true) {
                alert(res.data.message);
                // this.props.history.push('/branch/branchlist');
                // window.location.href = "/branch/branchlist";
                window.location.reload();
            } else {
                alert(res.data.message);
            }
        }
        catch (ex)
        {
            console.log(ex);
            alert(ex.message + "실패");
        }
    };

    render() {
        return (
            <div className="nk-block">
                <div class="card card-bordered">
                    <div class="card-inner">
                        <div class="card-head">
                            <h5 class="card-title">지점정보</h5>
                        </div>
                        <form
                            onSubmit={this.onSubmit}
                            className="text-left mt-4"
                        >
                            <div className="row g-3 align-center">
                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            for="site-name"
                                        >
                                            지점명
                                        </label>
                                        <span className="form-note">
                                            등록할 지점 이름
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="form-group">
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                name="name"
                                                onChange={this.onChange}
                                                defaultValue={this.state.name}
                                                required="required"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 align-center">
                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            for="site-name"
                                        >
                                            주소
                                        </label>
                                        <span className="form-note">
                                            등록할 지점 주소
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="form-group">
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="address"
                                                name="address"
                                                onChange={this.onChange}
                                                defaultValue={
                                                    this.state.address
                                                }
                                                required="required"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 align-center">
                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            for="site-name"
                                        >
                                            전화번호
                                        </label>
                                        <span className="form-note">
                                            - 없이 입력
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="form-group">
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="phone"
                                                name="phone"
                                                onChange={this.onChange}
                                                defaultValue={this.state.phone}
                                                required="required"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 align-center">
                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            for="site-name"
                                        >
                                            운영시간
                                        </label>
                                        <span className="form-note">
                                            ex) 00:00시 부터 ~ 00:00시 까지
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="form-group">
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="operate_hour"
                                                name="operate_hour"
                                                onChange={this.onChange}
                                                defaultValue={
                                                    this.state.operate_hour
                                                }
                                                required="required"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 align-center">
                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            for="site-name"
                                        >
                                            카카오톡 아이디
                                        </label>
                                        <span className="form-note">
                                            카카오톡 상담 아이디
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="form-group">
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="kakaoid"
                                                name="kakaoid"
                                                onChange={this.onChange}
                                                defaultValue={
                                                    this.state.kakaoid
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="col-lg-7 offset-lg-5">
                                    <div className="form-group mt-2">
                                        {this.context.role === "최고관리자" || this.context.role === "일반관리자" || this.context.role === "지점관리자" || this.context.role === "매니저" ? (
                                            <button
                                                type="submit"
                                                className="btn btn-lg btn-primary mr-1"
                                            >
                                                확인
                                            </button>
                                        ) : null}
                                        <Link to="/branch/branchlist">
                                            <a
                                                href="/branch/branchlist"
                                                className="btn btn-lg btn-secondary"
                                            >
                                                취소
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(BranchInfo);