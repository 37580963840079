import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import { AuthContext } from "../components/AuthenticatedComponent";
import Validation from "../utils/Validations";
import Content from "../components/Content";
import AdminClient from "../utils/Http/AdminClient";
import BranchClient from "../utils/Http/BranchClient";
import SubjectClient from "../utils/Http/SubjectClient";

import moment from "moment";
// moment.tz.setDefault("Asia/Seoul");

class SubjectRegister extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      name: "",
    };
  }

  componentDidMount = async () => {};

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await SubjectClient.create({ name: this.state.name });

      if (res.data.success === true) {
        alert(res.data.message);
        // this.props.history.push('/teacher/teacherlist');
        window.location.href = "/subject/subjectlist";
      } else {
        alert(res.data.message);
      }
    } catch (err) {
      console.log(err);
      alert(err.message + "실패");
    }
  };

  render() {
    if (
      this.context.role !== "최고관리자" &&
      this.context.role !== "일반관리자" &&
      this.context.role !== "지점관리자" &&
      this.context.role !== "매니저"
    ) {
      return (
        <div>
          <Menubar />
          <div className="nk-content">
            <div className="container-fluid">접근 권한이 없습니다.</div>
          </div>
        </div>
      );
    }
    return (
      <>
        <Menubar />
        <Content>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">과목 등록</h3>
                {/* <div className="nk-block-des text-soft">
                                <p>{this.state.users.length}건의 데이터 조회</p>
                            </div> */}
              </div>
            </div>
          </div>
          <div className="nk-block">
            <div className="card card-bordered">
              <div className="card-inner">
                <div className="card-head">
                  <h5 className="card-title">정보</h5>
                </div>
                <form onSubmit={this.onSubmit} className="text-left mt-4">
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          이름
                        </label>
                        <span className="form-note">과목이름</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-lg-7 offset-lg-5">
                      <div className="form-group mt-2">
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary mr-1"
                        >
                          저장
                        </button>
                        <Link to="/teacher/teacherlist">
                          <a
                            href="/teacher/teacherlist"
                            className="btn btn-lg btn-secondary"
                          >
                            취소
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Content>
      </>
    );
  }
}

export default withRouter(SubjectRegister);
