import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import ContentHeader from "../components/ContentHeader";
import Content from "../components/Content";
import queryString from "query-string";
import { AuthContext } from "../components/AuthenticatedComponent";
import ComponentBranchInfo from "../components/BranchInfo";
import ComponentBranchBannerInfo from "../components/BranchBannerInfo";
import ComponentLessonNotification from "../components/BranchLessonNotification";
import ComponentKakaoMessage from "../components/BranchKakaoMessage";
import ComponentNoticePopup from "../components/BranchNoticePopup";
import moment from "moment";
import Holidays from "date-holidays";
import AdminClient from "../utils/Http/AdminClient";
import BranchClient from "../utils/Http/BranchClient";
import TicketClient from "../utils/Http/TicketClient";
import BookClient from "../utils/Http/BookClient";
import EventClient from "../utils/Http/EventClient";
import HolidayClient from "../utils/Http/HolidayClient";
import ScheduleClient from "../utils/Http/ScheduleClient";
import SubjectClient from "../utils/Http/SubjectClient";
import { EmojiNeutralFill } from "react-bootstrap-icons";
import {lessonType} from "../common/Constants";

var constants = require("../common/Constants");
var hd = new Holidays("KR");

const RenderState = {
  Info: 1,
  Event: 2,
  Holiday: 3,
  Ticket: 4,
  LessonPrice: 5,
  TeacherLesson: 6,
  LessonNotification: 7,
  KakaoMessage: 8,
  NoticePopup: 9,
  Banner: 10,
};

class BranchModify extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    var now = moment();
    var date = now.format("YYYY-MM-DD");

    const query = queryString.parse(this.props.location.search);

    this.state = {
      loading: false,
      render_state: query.render_state
        ? Number(query.render_state)
        : RenderState.Info,
      name: "",
      address: "",
      phone: "",
      operate_hour: "",
      kakaoid: "",
      event_name: "",
      event_date: date,
      events: [],
      holiday_name: "",
      holiday_date: date,
      holiday_year: now.year() + 1,
      holidays: [],
      subjects: [],
      ticket_name: "수강권",
      ticket_lesson: 4,
      ticket_holding: 0,
      ticket_days: 30,
      ticket_price: 0,
      ticket_point: 0,
      ticketInfos: [],
      lesson_start: 1,
      lesson_end: 10,
      lesson_price: 50000,
      lessonPriceList: [],
      teacherLessonCount: [],
      teachers: [],
      teacherSchedules: [],
      teacherLessonRate: {},
      teacherBookList: {},
      viewMonth: moment().format("YYYY-MM"),
    };
  }

  componentDidMount = async () => {
    try {
      const BranchRes = await BranchClient.get(this.props.match.params.id);

      const dataListRes = await TicketClient.getDataList({
        _branch: this.props.match.params.id,
      });

      const lessonPriceRes = await TicketClient.LessonPriceList({
        _branch: this.props.match.params.id,
      });

      const teacherLessonCount = await BookClient.getTeacherLessonCount({
        _branch: this.props.match.params.id,
        month: moment(this.state.viewMonth).format("YYYY-MM"),
      });

      const teachers = await AdminClient.getAdminList({
        role: "강사",
        _branch: this.props.match.params.id,
      });

      // console.log(moment(this.state.viewMonth).format("YYYY-MM-DD"));
      const teacherLessonRate = await AdminClient.getTeacherLessonRate({
        _branch: this.props.match.params.id,
        date: moment(this.state.viewMonth).format("YYYY-MM-DD"),
      });
      // console.log(teacherLessonRate.data.result);

      var holidays = BranchRes.data.success
        ? BranchRes.data.result.holidays
        : [];
      holidays.sort(function (a, b) {
        if (a.year > b.year) {
          return -1;
        } else if (a.year < b.year) {
          return 1;
        } else {
          if (a.month > b.month) {
            return -1;
          } else if (a.month < b.month) {
            return 1;
          } else {
            if (a.day > b.day) {
              return -1;
            } else if (a.day < b.day) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      });

      var subjectRes = await SubjectClient.getList();

        this.setState(
        {
          name: BranchRes.data.result.name,
          address: BranchRes.data.result.address,
          phone: BranchRes.data.result.phone,
          operate_hour: BranchRes.data.result.operate_hour,
          kakaoid: BranchRes.data.result.kakaoid,
          events: BranchRes.data.success ? BranchRes.data.result.events : [],
          holidays: holidays,
          ticketInfos: dataListRes.data.success ? dataListRes.data.result : [],
          lessonPriceList: lessonPriceRes.data.success
            ? lessonPriceRes.data.result
            : [],
          teacherLessonCount: teacherLessonCount.data.success
            ? teacherLessonCount.data.result
            : [],
          teachers: teachers.data.success ? teachers.data.result : [],
          teacherLessonRate: teacherLessonRate.data.success
            ? teacherLessonRate.data.result
            : {},
          loading: true,
          subjects: subjectRes.data.success ? subjectRes.data.result : [],
        },
        this.loadBookList
      );
    } catch (err) {
      console.log(err);
    }
  };

  loadBookList = async () => {
    const date = moment(this.state.viewMonth);
    const day_count = date.daysInMonth();
    const teacherSchedules = {};

    for (var i = 0; i < day_count; ++i) {
      const formatted_date = date.format("YYYY-MM-DD");
      const schedule = await ScheduleClient.getList({
        _branch: this.props.match.params.id,
        lessonDate: formatted_date,
      });

      if (!schedule.data.success) continue;

      teacherSchedules[formatted_date] = schedule.data.result;
      date.add(1, "days");
    }
    // console.log(teacherSchedules);

    // console.log('loadBookList');
    var teacherBookList = {};
    const start_date = moment(this.state.viewMonth);
    const end_date = start_date.clone().endOf("month");
    for (const teacher of this.state.teachers) {
      const bookRes = await BookClient.getTeacherBookList({
        _teacher: teacher._id,
        start_at: start_date.format("YYYY-MM-DD"),
        end_at: end_date.format("YYYY-MM-DD"),
      });

      // console.log(bookRes);

      if (bookRes.data.success) {
        teacherBookList[teacher._id] = bookRes.data.result;
      }
    }

    this.setState({
      teacherSchedules: teacherSchedules,
      teacherBookList: teacherBookList,
      loading: false,
    });
  };

  setRenderState(render_state) {
    window.history.pushState(
      null,
      null,
      `/branch/branchmodify/${this.props.match.params.id}?render_state=${render_state}`
    );
    this.setState({
      render_state: render_state,
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getMonthDuration = (month) => {
    var start = moment(month)
      .startOf("month")
      .startOf("week")
      .format("YYYY-MM-DD");
    var end = moment(month).endOf("month").endOf("week").format("YYYY-MM-DD");
    return {
      start,
      end,
    };
  };

  onChangePreMonth = async (date) => {
    const preMonth = moment(date).subtract(1, "month");
    const firstDate = preMonth.startOf("Month").format("YYYY-MM-DD");
    const duration = this.getMonthDuration(firstDate);
    // const getItems = await this.getBooks(duration.start, duration.end);

    this.setState({
      viewMonth: firstDate,
    });

    const teacherLessonCount = await BookClient.getTeacherLessonCount({
      _branch: this.props.match.params.id,
      month: moment(firstDate).format("YYYY-MM"),
    });

    const teacherLessonRate = await AdminClient.getTeacherLessonRate({
      _branch: this.props.match.params.id,
      date: firstDate,
    });

    // console.log(teacherLessonRate.data.result);
    if (this.state.viewMonth == firstDate) {
      this.setState(
        {
          // startDate: duration.start,
          // endDate: duration.end,
          // books: getItems,
          teacherLessonCount: teacherLessonCount.data.success
            ? teacherLessonCount.data.result
            : [],
          teacherLessonRate: teacherLessonRate.data.success
            ? teacherLessonRate.data.result
            : {},
          loading: true,
        },
        this.loadBookList
      );
    }
  };

  onChangeNextMonth = async (date) => {
    const nextMonth = moment(date).add(1, "month");
    const firstDate = nextMonth.startOf("Month").format("YYYY-MM-DD");
    const duration = this.getMonthDuration(firstDate);
    // const getItems = await this.getBooks(duration.start, duration.end);

    this.setState({
      viewMonth: firstDate,
    });

    const teacherLessonCount = await BookClient.getTeacherLessonCount({
      _branch: this.props.match.params.id,
      month: moment(firstDate).format("YYYY-MM"),
    });

    const teacherLessonRate = await AdminClient.getTeacherLessonRate({
      _branch: this.props.match.params.id,
      date: firstDate,
    });

    // console.log(teacherLessonRate.data.result);

    if (this.state.viewMonth == firstDate) {
      this.setState(
        {
          // startDate: duration.start,
          // endDate: duration.end,
          // books: getItems,
          teacherLessonCount: teacherLessonCount.data.success
            ? teacherLessonCount.data.result
            : [],
          teacherLessonRate: teacherLessonRate.data.success
            ? teacherLessonRate.data.result
            : {},
          loading: true,
        },
        this.loadBookList
      );
    }
  };

  onCreateTicketInfo = async (e) => {
    e.preventDefault();
    try {

      const lessonPlans = [];
      this.state.subjects.forEach(subject => {
        lessonPlans.push({
          _id: subject._id,
          name: subject.name,
          value: parseInt(document.getElementById(`lessonPlan_${subject._id}`).value),
        })
        }
      );

      const ticketData = await TicketClient.createTicketData({
        _branch: this.props.match.params.id,
        name: this.state.ticket_name,
        days: this.state.ticket_days,
        lesson: this.state.ticket_lesson,
        holding: this.state.ticket_holding,
        price: this.state.ticket_price,
        point: this.state.ticket_point,
        lessonPlans: lessonPlans,
      });

      if (ticketData.data.success) {
        window.location.reload();
      } else {
        alert(ticketData.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  onClickUpdateTicketData = async (_id) => {
    await this.modifyTicketData(_id);
    window.location.reload();
  };

  onClickUpdateTicketDataAll = async () => {
    await this.modifyTicketDataAll();
    window.location.reload();
  };

  modifyTicketData = async (_id) => {
    try {
      const name = document.getElementById(`name_${_id}`).value;
      const lesson = parseInt(document.getElementById(`lesson_${_id}`).value);
      const days = parseInt(document.getElementById(`days_${_id}`).value);
      const holding = parseInt(document.getElementById(`holding_${_id}`).value);
      const price = parseInt(document.getElementById(`price_${_id}`).value);
      // const point = parseInt(document.getElementById(`point_${_id}`).value);
      const lessonPlans = [];

      this.state.subjects.forEach(subject => {
        // console.log(document.getElementById(`lessonPlan_${_id}_${subject._id}`));
        lessonPlans.push({
          _id: subject._id,
          name: subject.name,
          value: parseInt(document.getElementById(`lessonPlan_${_id}_${subject._id}`).value),
        })
      });

      const res = await TicketClient.updateData(_id, {
        name: name,
        lesson: lesson,
        days: days,
        holding: holding,
        price: price,
        // point: point,
        lessonPlans: lessonPlans,
      });

      if (!res.data.success) {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  modifyTicketDataAll = async (e) => {
    for (const ticketInfo of this.state.ticketInfos) {
      await this.modifyTicketData(ticketInfo._id);
    }
  };

  onDeleteTicketData = async (e) => {
    e.preventDefault();

    try {
      const ticketData = this.state.ticketInfos[e.target.dataset.key];

      const res = await TicketClient.deleteData({
        _id: ticketData._id,
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  onCreateEvent = async (e) => {
    e.preventDefault();
    try {
      var date = moment(this.state.event_date);
      const res = await EventClient.create({
        _branch: this.props.match.params.id,
        name: this.state.event_name,
        year: date.year(),
        month: date.month() + 1,
        day: date.date(),
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  onDeleteEvent = async (e) => {
    e.preventDefault();

    try {
      const event = this.state.events[e.target.dataset.key];
      const res = await EventClient.delete({
        _branch: this.props.match.params.id,
        _id: event._id,
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  onCreateLessonPrice = async (e) => {
    e.preventDefault();
    try {
      const ticketData = await TicketClient.createLessonPrice({
        _branch: this.props.match.params.id,
        countStart: this.state.lesson_start,
        countEnd: this.state.lesson_end,
        price: this.state.lesson_price,
      });

      if (ticketData.data.success) {
        window.location.reload();
      } else {
        alert(ticketData.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  onDeleteLessonPrice = async (e) => {
    e.preventDefault();

    try {
      const lessonPriceData = this.state.lessonPriceList[e.target.dataset.key];
      const res = await TicketClient.deleteLessonPrice({
        _id: lessonPriceData._id,
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  onCreateHoliday = async (e) => {
    e.preventDefault();
    try {
      var date = moment(this.state.holiday_date);
      const res = await HolidayClient.create({
        _branch: this.props.match.params.id,
        name: this.state.holiday_name,
        year: date.year(),
        month: date.month() + 1,
        day: date.date(),
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  onDeleteHoliday = async (e) => {
    e.preventDefault();

    try {
      const holiday = this.state.holidays[e.target.dataset.key];
      const res = await HolidayClient.delete({
        _branch: this.props.match.params.id,
        _id: holiday._id,
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  onCreateYearHolidays = async (e) => {
    e.preventDefault();
    try {
      const res = await HolidayClient.createByCalendar({
        _branch: this.props.match.params.id,
        year: this.state.holiday_year,
      });

      if (res.data.success) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  isOpenedSchedule = (data, dow, date, time) => {
    const lessonDate = date.format("YYYY-MM-DD");
    for (var i = 0; i < data.temporary_schedule.length; ++i) {
      const schedule = data.temporary_schedule[i];
      if (
        moment(schedule.lessonDate).format("YYYY-MM-DD") === lessonDate &&
        schedule.lessonTime == time
      ) {
        if (schedule.status === 1) return true;
        else return false;
      }
    }

    for (var i = 0; i < data.unable_schedule.length; ++i) {
      if (
        data.unable_schedule[i].dow === dow &&
        data.unable_schedule[i].time == time
      ) {
        return false;
      }
    }

    return true;
  };

  getTeacherScheduleCount(_teacher) {
    const bookList = this.state.teacherBookList[_teacher];

    var count = 0;
    Object.keys(this.state.teacherSchedules).forEach((item) => {
      const schedule = this.state.teacherSchedules[item];
      if (!schedule) return;

      const teacher_schedule = schedule[_teacher];
      if (!teacher_schedule) return;

      constants.times.forEach((time) => {
        if (
          (bookList &&
            bookList.some(
              (x) =>
                moment(x.lesson_at).format("YYYY-MM-DD") == item &&
                x.lesson_time == time
            )) ||
          teacher_schedule[time]
        )
          count++;
      });
    });
    // console.log(count);

    return count;
  }

  render_eventInfo() {
    return (
      <div className="nk-block">
        <div class="card card-bordered">
          <div class="card-inner">
            <div class="card-head">
              <h5 class="card-title">이벤트 정보</h5>
            </div>
            <form className="text-left mt-4">
              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label" for="site-name">
                      이름
                    </label>
                    {/* <span className="form-note">이벤트 이름</span> */}
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="event_name"
                        name="event_name"
                        onChange={this.onChange}
                        defaultValue={this.state.event_name}
                        required="required"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label" for="site-name">
                      날짜
                    </label>
                    {/* <span className="form-note">날짜</span> */}
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control"
                        id="event_date"
                        name="event_date"
                        onChange={this.onChange}
                        defaultValue={this.state.event_date}
                        required="required"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-lg-7 offset-lg-5">
                  <div className="form-group mt-2">
                    {this.context.role === "최고관리자" ||
                    this.context.role === "일반관리자" ||
                    this.context.role === "지점관리자" ||
                    this.context.role === "매니저" ? (
                      <button
                        onClick={this.onCreateEvent}
                        className="btn btn-lg btn-primary mr-1"
                      >
                        추가
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card card-bordered card-stretch">
          <div class="table-responsive">
            <table class="table">
              <thead className="thead-light">
                <th>이름</th>
                <th>날짜</th>
                <th>삭제</th>
              </thead>
              <tbody>
                {this.state.events.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        disabled={true}
                        data-key={index}
                        text={data.name}
                        value={data.name}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        disabled={true}
                        data-key={index}
                        text={data.date}
                        value={`${data.year}-${data.month}-${data.day}`}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-dim btn-outline-secondary"
                        data-key={index}
                        onClick={this.onDeleteEvent}
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  render_holidayInfo() {
    return (
      <div className="nk-block">
        <div class="card card-bordered">
          <div class="card-inner">
            <div class="card-head">
              <h5 class="card-title">휴일 정보</h5>
            </div>
            <form className="text-left mt-4">
              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label" for="site-name">
                      이름
                    </label>
                    {/* <span className="form-note">이벤트 이름</span> */}
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="holiday_name"
                        name="holiday_name"
                        onChange={this.onChange}
                        defaultValue={this.state.holiday_name}
                        required="required"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label" for="site-name">
                      날짜
                    </label>
                    {/* <span className="form-note">날짜</span> */}
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control"
                        id="holiday_date"
                        name="holiday_date"
                        onChange={this.onChange}
                        defaultValue={this.state.holiday_date}
                        required="required"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-lg-7 offset-lg-5">
                  <div className="form-group mt-2">
                    {this.context.role === "최고관리자" ||
                    this.context.role === "일반관리자" ||
                    this.context.role === "지점관리자" ||
                    this.context.role === "매니저" ? (
                      <button
                        onClick={this.onCreateHoliday}
                        className="btn btn-lg btn-primary mr-1"
                      >
                        추가
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
            <div className="row g-3 align-center">
              <div className="col-lg-5">
                <div className="form-group">
                  <label className="form-label" for="site-name">
                    공휴일 추가
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <input
                      type="Number"
                      className="form-control"
                      id="holiday_year"
                      name="holiday_year"
                      onChange={this.onChange}
                      defaultValue={this.state.holiday_year}
                      required="required"
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <div className="form-control-wrap">
                    {/* <button
                        type="date"
                        className="form-control"
                        id="event_date"
                        name="event_date"
                        onChange={this.onChange}
                        defaultValue={this.state.event_date}
                        required="required"
                      /> */}
                    <button
                      className="btn btn-dim btn-outline-secondary"
                      onClick={this.onCreateYearHolidays}
                    >
                      추가
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-bordered card-stretch">
          <div class="table-responsive">
            <table class="table">
              <thead className="thead-light">
                <th>이름</th>
                <th>날짜</th>
                <th>삭제</th>
              </thead>
              <tbody>
                {this.state.holidays.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        disabled={true}
                        data-key={index}
                        text={data.name}
                        value={data.name}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        disabled={true}
                        data-key={index}
                        text={data.date}
                        value={`${data.year}-${data.month}-${data.day}`}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-dim btn-outline-secondary"
                        data-key={index}
                        onClick={this.onDeleteHoliday}
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  render_lessonPlans(ticket) {
    return (
        <table>
          <tbody>
          <tr>
            {this.state.subjects.map(subject =>
                {
                  const lessonPlan = ticket.lessonPlans.find(lessonPlan => lessonPlan._id === subject._id);
                  const lessonTime = lessonPlan ? lessonPlan.value : constants.lessonType.Fifty;
                  return (
                      <div>
                        <label> {subject.name} </label>
                        <select
                            // className="form-control"
                            id={`lessonPlan_${ticket._id}_${subject._id}`}
                            defaultValue={lessonTime}
                        >
                          <option value={constants.lessonType.Fifty}>50분</option>
                          <option value={constants.lessonType.Thirty}>30분</option>
                        </select>
                      </div>
                  );
                }
            )
            }
          </tr>
          </tbody>
        </table>
    );
  }

  render_ticketInfo() {
    return (
      <>
        <div className="nk-block">
          <div class="card card-bordered">
            <div class="card-inner">
              <div class="card-head">
                <h5 class="card-title">수강권정보</h5>
              </div>
              <form className="text-left mt-4">
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" htmlFor="site-name">
                        수강권 이름
                      </label>
                      <span className="form-note">등록할 수강권 이름</span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                            type="text"
                            className="form-control"
                            id="ticket_name"
                            name="ticket_name"
                            onChange={this.onChange}
                            defaultValue={this.state.ticket_name}
                            required="required"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" htmlFor="site-name">
                        레슨 횟수
                      </label>
                      <span className="form-note">등록할 수강권 레슨 횟수</span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                            type="text"
                            className="form-control"
                            id="ticket_lesson"
                            name="ticket_lesson"
                            onChange={this.onChange}
                            defaultValue={this.state.ticket_lesson}
                            required="required"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" htmlFor="site-name">
                        홀딩 일수
                      </label>
                      <span className="form-note">등록할 수강권 홀딩 일수</span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                            type="text"
                            className="form-control"
                            id="ticket_holding"
                            name="ticket_holding"
                            onChange={this.onChange}
                            defaultValue={this.state.ticket_holding}
                            required="required"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" htmlFor="site-name">
                        이용 일수
                      </label>
                      <span className="form-note">학원 이용 기간(일수)</span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                            type="text"
                            className="form-control"
                            id="ticket_days"
                            name="ticket_days"
                            onChange={this.onChange}
                            defaultValue={this.state.ticket_days}
                            required="required"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" htmlFor="site-name">
                        가격
                      </label>
                      <span className="form-note">가격</span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                            type="text"
                            className="form-control"
                            id="ticket_price"
                            name="ticket_price"
                            onChange={this.onChange}
                            defaultValue={this.state.ticket_price}
                            required="required"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" htmlFor="site-name">
                        레슨 시간
                      </label>
                      <span className="form-note">과목 별 레슨 시간</span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <table>
                          <tbody>
                          <tr>
                            {this.state.subjects.map(subject => {
                                  return (
                                      <div>
                                        <label> {subject.name} </label>
                                        <select
                                            // className="form-control"
                                            id={`lessonPlan_${subject._id}`}
                                            defaultValue={lessonType.Fifty}
                                        >
                                          <option value={constants.lessonType.Fifty}>50분</option>
                                          <option value={constants.lessonType.Thirty}>30분</option>
                                        </select>
                                      </div>
                                  );
                                }
                            )
                            }
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-3">
                  <div className="col-lg-7 offset-lg-5">
                    <div className="form-group mt-2">
                      {this.context.role === "최고관리자" ||
                      this.context.role === "일반관리자" ||
                      this.context.role === "지점관리자" ||
                      this.context.role === "매니저" ? (
                          <button
                              onClick={this.onCreateTicketInfo}
                              className="btn btn-lg btn-primary mr-1"
                          >
                            추가
                          </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="card card-bordered card-stretch">
            <div class="table-responsive">
              <table class="table">
                <thead className="thead-light">
                <tr>
                  <th>이름</th>
                  <th>레슨 횟수</th>
                  <th>이용 일수</th>
                  <th>홀딩 일수</th>
                  <th>레슨 시간</th>
                  <th>가격</th>
                  <th>수정</th>
                  <th>삭제</th>
                </tr>
                </thead>
                <tbody>
                {this.state.ticketInfos.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <input
                            id={`name_${data._id}`}
                            type="text"
                            data-key={index}
                            text={data.name}
                            defaultValue={data.name}
                        />
                      </td>
                    <td>
                      <input
                        id={`lesson_${data._id}`}
                        type="text"
                        data-key={index}
                        text={data.lesson}
                        defaultValue={data.lesson}
                      />
                    </td>
                    <td>
                      <input
                        id={`days_${data._id}`}
                        type="text"
                        data-key={index}
                        text={data.days}
                        defaultValue={data.days}
                      />
                    </td>
                    <td>
                      <input
                        id={`holding_${data._id}`}
                        type="text"
                        data-key={index}
                        text={data.holding}
                        defaultValue={data.holding}
                      />
                    </td>
                    <td>
                      {this.render_lessonPlans(data)}
                    </td>
                    <td>
                      <input
                        id={`price_${data._id}`}
                        type="text"
                        data-key={index}
                        text={data.price}
                        defaultValue={data.price}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-dim btn-outline-secondary"
                        onClick={(e) => {
                          this.onClickUpdateTicketData(data._id);
                        }}
                      >
                        수정
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-dim btn-outline-secondary"
                        data-key={index}
                        onClick={this.onDeleteTicketData}
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="row g-3">
              <div className="col-lg-7 offset-lg-5">
                <div className="form-group mt-2">
                  {this.context.role !== "강사" ? (
                    <button
                      className="btn btn-lg btn-primary mr-1"
                      onClick={this.onClickUpdateTicketDataAll}
                    >
                      전체 수정
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  render_lessonPriceInfo() {
    return (
      <div className="nk-block">
        <div class="card card-bordered">
          <div class="card-inner">
            <div class="card-head">
              <h5 class="card-title">추가 레슨 정보</h5>
            </div>
            <form className="text-left mt-4">
              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label" for="site-name">
                      횟수
                    </label>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="row">
                    <div className="col duration">
                      <input
                        type="text"
                        className="form-control"
                        id="lesson_start"
                        name="lesson_start"
                        onChange={this.onChange}
                        defaultValue={this.state.lesson_start}
                        required="required"
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        id="lesson_end"
                        name="lesson_end"
                        onChange={this.onChange}
                        defaultValue={this.state.lesson_end}
                        required="required"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label" for="site-name">
                      가격
                    </label>
                    <span className="form-note">횟수 당 가격</span>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="lesson_price"
                        name="lesson_price"
                        onChange={this.onChange}
                        defaultValue={this.state.lesson_price}
                        required="required"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-lg-7 offset-lg-5">
                  <div className="form-group mt-2">
                    {this.context.role === "최고관리자" ||
                    this.context.role === "일반관리자" ||
                    this.context.role === "지점관리자" ||
                    this.context.role === "매니저" ? (
                      <button
                        onClick={this.onCreateLessonPrice}
                        className="btn btn-lg btn-primary mr-1"
                      >
                        추가
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="card card-bordered card-stretch">
          <div class="table-responsive">
            <table class="table">
              <thead className="thead-light">
                <th>횟수</th>
                <th>가격</th>
                {/* <th>수정</th> */}
                <th>삭제</th>
              </thead>
              <tbody>
                {this.state.lessonPriceList.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        data-key={index}
                        text={item.countStart}
                        value={item.countStart}
                      />
                      ~
                      <input
                        type="text"
                        data-key={index}
                        text={item.countEnd}
                        value={item.countEnd}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        data-key={index}
                        text={item.price}
                        value={item.price}
                      />
                    </td>
                    {/* <td>
                          <button className="btn btn-dim btn-outline-secondary" data-key={index} onClick={this.modifyTicketData}>
                            수정
                          </button>
                        </td> */}
                    <td>
                      <button
                        className="btn btn-dim btn-outline-secondary"
                        data-key={index}
                        onClick={this.onDeleteLessonPrice}
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  render_teacherLessonCounts() {
    var render_pay = false;
    if (
      this.context.role === "최고관리자" ||
      this.context.role === "일반관리자" ||
      this.context.role === "지점관리자"
    )
      render_pay = true;
    return (
      <div className="nk-block">
        <div class="card card-bordered">
          <div class="card-inner">
            <div class="card-head">
              <h5 class="card-title">강사 레슨 횟수</h5>
            </div>
            <div className="row g-3 align-center">
              <div className="col-lg-7">
                <div className="row">
                  <div className="btn-group">
                    <button
                      className="fc-prev-button btn"
                      onClick={() =>
                        this.onChangePreMonth(this.state.viewMonth)
                      }
                    >
                      <span className="fa fa-chevron-left"></span>
                    </button>
                    <h2 className="caletitle">
                      {moment(this.state.viewMonth).format("YYYY-MM")}
                    </h2>
                    {/* <h2 className="fc-toolbar-title">{moment(this.state.viewMonth).format('YYYY-MM')}</h2> */}
                    <button
                      className="fc-next-button btn"
                      onClick={() =>
                        this.onChangeNextMonth(this.state.viewMonth)
                      }
                    >
                      <span className="fa fa-chevron-right"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead className="thead-light">
                <th>이름</th>
                <th>레슨 횟수</th>
                <th>취소 횟수</th>
                <th>예약률</th>
                <th>재수강률</th>
                {render_pay ? <th>레슨비</th> : null}
              </thead>
              <tbody>
                {this.state.loading
                  ? null
                  : this.state.teacherLessonCount.map((item, index) => {
                    // console.log(item);
                      const count =
                        this.state.teacherLessonRate[item.teacher._id]?.count;
                      const totalCount =
                        this.state.teacherLessonRate[item.teacher._id]
                          ?.totalCount;
                      const scheduleCount = this.getTeacherScheduleCount(
                        item.teacher._id
                      );
                      return (
                        <tr key={index}>
                          <td>
                            <a
                              href={`/teacher/teacherprofile/${item.teacher._id}`}
                            >
                              {item.teacher.name}
                            </a>
                          </td>
                          <td>
                            <div>
                              <a
                                href={
                                  "/teacher/lessonlist?status=apply&month=" +
                                  this.state.viewMonth +
                                  "&teacher=" +
                                  item.teacher._id +
                                  "&lessonType=" +
                                  constants.lessonType.Fifty
                                }
                              >
                                {`50분 : ${item.lesson_count_fifty}`}
                              </a>
                            </div>
                            <div>
                              <a
                                href={
                                  "/teacher/lessonlist?status=apply&month=" +
                                  this.state.viewMonth +
                                  "&teacher=" +
                                  item.teacher._id +
                                  "&lessonType=" +
                                  constants.lessonType.Thirty
                                }
                              >
                                {`30분 : ${item.lesson_count_thirty}`}
                              </a>
                            </div>
                            <div>
                              <a
                                href={
                                  "/teacher/lessonlist?status=apply&month=" +
                                  this.state.viewMonth +
                                  "&teacher=" +
                                  item.teacher._id +
                                  "&lessonType=" +
                                  constants.lessonType.Sixty
                                }
                              >
                                {`60분 : ${item.lesson_count_sixty}`}
                              </a>
                            </div>
                          </td>
                          <td>
                            <div>
                              <a
                                href={
                                  "/teacher/lessonlist?status=cancelOnTheDay&month=" +
                                  this.state.viewMonth +
                                  "&teacher=" +
                                  item.teacher._id +
                                  "&lessonType=" +
                                  constants.lessonType.Sixty
                                }
                              >
                                {`50분 : ${item.cancel_count_fifty}`}
                              </a>
                            </div>
                            <div>
                              <a
                                href={
                                  "/teacher/lessonlist?status=cancelOnTheDay&month=" +
                                  this.state.viewMonth +
                                  "&teacher=" +
                                  item.teacher._id +
                                  "&lessonType=" +
                                  constants.lessonType.Sixty
                                }
                              >
                                {`30분 : ${item.cancel_count_thirty}`}
                              </a>
                            </div>
                            <div>
                              <a
                                href={
                                  "/teacher/lessonlist?status=cancelOnTheDay&month=" +
                                  this.state.viewMonth +
                                  "&teacher=" +
                                  item.teacher._id +
                                  "&lessonType=" +
                                  constants.lessonType.Sixty
                                }
                              >
                                {`60분 : ${item.cancel_count_sixty}`}
                              </a>
                            </div>
                          </td>
                          <td>
                            {scheduleCount == 0
                              ? 0
                              : (
                                ((item.lesson_count_fifty + item.lesson_count_thirty + item.lesson_count_sixty + item.cancel_count_fifty + item.cancel_count_thirty + item.cancel_count_sixty) /
                                  scheduleCount) *
                                100
                              ).toFixed(2)}{" "}
                            %{" "}
                            {"(" +
                              (item.lesson_count_fifty + item.lesson_count_thirty + item.lesson_count_sixty + item.cancel_count_fifty + item.cancel_count_thirty + item.cancel_count_sixty) +
                              "/" +
                              scheduleCount +
                              ")"}
                          </td>
                          <td>
                            {totalCount == undefined || totalCount == 0
                              ? 0
                              : ((count / totalCount) * 100).toFixed(2)}{" "}
                            %{" "}
                            {"(" +
                              (count ? count : 0) +
                              "/" +
                              (totalCount ? totalCount : 0) +
                              ")"}
                          </td>
                          {render_pay ? (
                            <td>
                              {" "}
                              {(item.lesson_count_fifty + item.lesson_count_thirty + item.lesson_count_sixty) * item.teacher.lesson_price +
                                (item.cancel_count_fifty + item.cancel_count_thirty + item.cancel_count_sixty) *
                                  item.teacher.cancel_price}{" "}
                            </td>
                          ) : null}
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  render_info() {
    switch (this.state.render_state) {
      case RenderState.Info:
        return <ComponentBranchInfo branch={this.props.match.params.id} />;
      case RenderState.Event:
        return <>{this.render_eventInfo()}</>;
      case RenderState.Holiday:
        return <>{this.render_holidayInfo()}</>;
      case RenderState.Ticket:
        return <>{this.render_ticketInfo()}</>;
      case RenderState.LessonPrice:
        return <>{this.render_lessonPriceInfo()}</>;
      case RenderState.TeacherLesson:
        return <>{this.render_teacherLessonCounts()}</>;
      case RenderState.LessonNotification:
        return (
          <ComponentLessonNotification branch={this.props.match.params.id} />
        );
      case RenderState.KakaoMessage:
        return <ComponentKakaoMessage branch={this.props.match.params.id} />;
      case RenderState.NoticePopup:
        return <ComponentNoticePopup branch={this.props.match.params.id} />;
      case RenderState.Banner:
        return (
          <ComponentBranchBannerInfo branch={this.props.match.params.id} />
        );
    }
    return null;
  }
  render() {
    return (
      <>
        <Menubar />
        <Content>
          <ContentHeader
            prevlink="/branch/branchlist"
            prevTitle="목록보기"
            title="지점정보"
            subTitle={`${this.state.name} 지점의 정보`}
          />
          <div class="btn-group mb-2" aria-label="Basic example">
            <button
              onClick={() => this.setRenderState(RenderState.Info)}
              className="btn btn-outline-primary"
            >
              지점정보
            </button>
            <button
              onClick={() => this.setRenderState(RenderState.Banner)}
              className="btn btn-outline-primary"
            >
              배너
            </button>
            <button
              onClick={() => this.setRenderState(RenderState.Event)}
              className="btn btn-outline-primary"
            >
              이벤트
            </button>
            <button
              onClick={() => this.setRenderState(RenderState.Holiday)}
              className="btn btn-outline-primary"
            >
              휴일
            </button>
            <button
              onClick={() => this.setRenderState(RenderState.Ticket)}
              className="btn btn-outline-primary"
            >
              수강권
            </button>
            <button
              onClick={() => this.setRenderState(RenderState.LessonPrice)}
              className="btn btn-outline-primary"
            >
              추가 레슨
            </button>
            <button
              onClick={() => this.setRenderState(RenderState.TeacherLesson)}
              className="btn btn-outline-primary"
            >
              강사 레슨
            </button>
            <button
              onClick={() =>
                this.setRenderState(RenderState.LessonNotification)
              }
              className="btn btn-outline-primary"
            >
              레슨 알림
            </button>
            <button
              onClick={() => this.setRenderState(RenderState.KakaoMessage)}
              className="btn btn-outline-primary"
            >
              카카오 메세지
            </button>
            <button
              onClick={() => this.setRenderState(RenderState.NoticePopup)}
              className="btn btn-outline-primary"
            >
              공지 팝업
            </button>
          </div>
          {this.render_info()}
        </Content>
      </>
    );
  }
}

export default withRouter(BranchModify);
