import AxiosClient from "./AxiosClient";

const CouponClient = {
  create: async (_user, name, price, started_at, ended_at) => {
    return await AxiosClient.post("coupon", {
      _user: _user,
      name: name,
      price: price,
      started_at: started_at,
      ended_at: ended_at,
    });
  },

  listAll: async (_user) => {
    return await AxiosClient.get(`coupon/listall/${_user}`);
  },

  update: async (_id, name, price, started_at, ended_at) => {
    return await AxiosClient.patch(`coupon/update/${_id}`, {
      name: name,
      price: price,
      started_at: started_at,
      ended_at: ended_at,
    });
  },

  delete: async (_id) => {
    return await AxiosClient.del(`coupon/delete/${_id}`);
  },
};

export default CouponClient;
