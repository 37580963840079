import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import FormLogin from '../components/FormLogin';

class Logout extends Component {
    constructor(props) {
        super(props);
        localStorage.removeItem("token_mlb");
        this.props.history.push("/");
    }
    render() {
        return <div></div>;
    }
}

export default withRouter(Logout);
