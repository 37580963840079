import AxiosClient from "./AxiosClient";

const KakaoClient = {
  friends: async (id) => {
    return await AxiosClient.get(`kakao/friends/${id}`);
  },
  messages: async (data) => {
    return await AxiosClient.get("kakao/messages", data);
  },
  message: async (_branch, _id, message) => {
    return await AxiosClient.post(`kakao/message/${_branch}/${_id}`, {
      message: message,
    });
  },
};

export default KakaoClient;
