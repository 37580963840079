import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import PropTypes from "prop-types";
import queryString from "query-string";
import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Content from "../components/Content";
import LessonNote from "../components/LessonNote";

export class TeacherLessonNote extends Component {
    static propTypes = {};

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            start_at: "",
            end_at: "",
        };
    }

    componentDidMount = () => {
        const query = queryString.parse(this.props.location.search);

        this.setState({
            start_at: query.start_at,
            end_at: query.end_at,
        });
    };

    onChangeNextWeek = async () => {
        const start_date = moment(this.state.start_at).add(7, 'days');
        const end_date = start_date.clone().add(6, 'days');

        this.setState({
            start_at: start_date.format('YYYY-MM-DD'),
            end_at: end_date.format('YYYY-MM-DD'),
        });
    };

    onChangePreWeek = async () => {
        const start_date = moment(this.state.start_at).subtract(7, 'days');
        const end_date = start_date.clone().add(6, 'days');

        this.setState({
            start_at: start_date.format('YYYY-MM-DD'),
            end_at: end_date.format('YYYY-MM-DD'),
        });
    };

    render() {
        return (
            <>
                <Menubar />
                <Content>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">
                                    레슨일지
                                </h3>
                                {/* <div className="nk-block-des text-soft">
                                <p>{this.state.users.length}건의 데이터 조회</p>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <button className="btn btn-link" onClick={this.onChangePreWeek}> ⟪&nbsp;지난주 </button>
                                <h7 className="inline">
                                    {this.state.start_at}{" ~ "}{this.state.end_at}
                                </h7>
                                <button className="btn btn-link" onClick={this.onChangeNextWeek}> 다음주&nbsp;⟫ </button>
                            </div>
                        </div>
                    </div>
                    <LessonNote _teacher={this.context._id} start_at={this.state.start_at} end_at={this.state.end_at} />
                </Content>
            </>
        );
    }
}

export default TeacherLessonNote;
