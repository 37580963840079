import AxiosClient from "./AxiosClient";

const BranchClient = {
    create: async (data) => {
        return await AxiosClient.post('branch', data);
    },

    get: async (data) => {
        return await AxiosClient.get(`branch/list/${data}`);
    },

    getList: async (data) => {
        return await AxiosClient.get('branch/list', data);
    },

    delete: async(data) => {
        return await AxiosClient.del('branch/delete', data);
    },

    update: async(id, data) => {
        return await AxiosClient.patch(`branch/update/${id}`, data);
    },

    updateBanner: async(id, data) => {
        return await AxiosClient.patch(`branch/banner/${id}`, data);
    }
}

export default BranchClient;