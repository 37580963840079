import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "./AuthenticatedComponent";
import moment from "moment";
import ReactPaginate from "react-paginate";
import NoticePopupClient from "../utils/Http/NoticePopupClient";
import { serverUrl } from "../config/api";

class BranchNoticePopup extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      pageCount: 0,
      countPerPage: 10,
      rows: [],
      selectedRow: [],
      totalCount: 0,
    };
  }

  componentDidMount() {
    this.Load(this.state.page, this.state.countPerPage);
  }

  onDeleteRow = async () => {
    if (!window.confirm("정말로 삭제하시겠습니까?")) return;
    try {
      console.log("onDeleteRow");
      var deleted = false;
      for (var i = 0; i < this.state.selectedRow.length; ++i) {
        console.log(this.state.selectedRow[i]);
        if (!this.canDeleteRow(i)) {
          console.log("delete impossible");
          continue;
        }
        const response = await NoticePopupClient.Delete(
          this.state.selectedRow[i]
        );

        if (response.data.success == false) {
          alert(response.data.message);
        } else {
          deleted = true;
        }
      }

      if (deleted) window.location.reload();
    } catch (error) {
      alert("삭제에 실패했습니다.");
    }
  };

  canDeleteRow(index) {
    return this.canRenderCheckBox(this.state.rows[index]);
  }

  onCheckAll = (e) => {
    const checkboxes = document.getElementsByClassName("myCheckbox");
    checkboxes.forEach((item) => {
      item.checked = e.target.checked;
    });

    if (e.target.checked) {
      this.setState((state) => ({
        selectedRow: state.rows.map((item) => item._id),
      }));
    } else {
      this.setState({
        selectedRow: [],
      });
    }
  };

  onChangeSelectRow = (e, index) => {
    // console.log(`onChangeSelectRow ${index}`);
    if (!this.canDeleteRow(index)) {
      return;
    }
    if (e.target.checked) {
      this.setState((state) => ({
        selectedRow: [...state.selectedRow, e.target.name],
      }));
    } else {
      this.setState((state) => ({
        selectedRow: state.selectedRow.filter((item) => item !== e.target.name),
      }));
    }
  };

  OnClickPage = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.countPerPage;

    this.setState({
      page: selectedPage,
    });

    this.Load(selectedPage, this.state.countPerPage);
  };

  Load = async (page, countPerPage) => {
    try {
      const response = await NoticePopupClient.List(this.props.branch, {
        page: page,
        countPerPage: countPerPage,
      });

      if (response.data.success === false) {
        alert(response.data.message);
        return;
      }
      // console.log(response.data.result);
      const totalCount = response.data.result.totalCount;
      this.setState({
        rows: response.data.result.items,
        pageCount:
          Number.parseInt((totalCount - 1) / this.state.countPerPage) + 1,
        totalCount: response.data.result.totalCount,
      });
    } catch (err) {
      console.log(err);
    }
  };

  canRenderCheckBox(item) {
    if (this.context.role === "강사") return false;

    if (
      this.context.role === "최고관리자" ||
      this.context.role === "일반관리자"
    )
      return true;

    if (item._branch && item._branch === this.context.branch._id) return true;

    return false;
  }

  render() {
    console.log("BranchNoticePopupList render");
    return (
      <>
        <div className="nk-block">
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group d-md-flex d-block">
                  <div className="card-tools mb-1">
                    <div className="form-inline flex-nowrap gx-3">
                      <div className="form-wrap">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) => this.props.OnClickCreate()}
                        >
                          데이터 추가
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary "
                          onClick={this.onDeleteRow}
                        >
                          선택삭제
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-search search-wrap" data-search="search">
                  <div className="card-body">
                    <div className="search-content">
                      <div className="row">
                        <a
                          href={null}
                          className="search-back btn btn-icon toggle-search"
                          data-target="search"
                        >
                          <em className="icon ni ni-arrow-left"></em>
                        </a>
                        <input
                          type="text"
                          className="form-control border-transparent form-focus-none"
                          placeholder="검색"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") this.onSearch();
                          }}
                          onChange={this.setSearchText}
                        />
                      </div>
                      <button
                        className="search-submit btn btn-icon"
                        onClick={this.onSearch}
                      >
                        <em className="icon ni ni-search"></em>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* 테이블시작 */}
              <div class="card-inner p-0">
                <div class="table-responsive">
                  <table class="table">
                    <thead className="thead-light">
                      <tr>
                        <th>
                          <input type="checkbox" onChange={this.onCheckAll} />
                        </th>
                        <th></th>
                        <th>등록자</th>
                        <th>제목</th>
                        <th>이미지</th>
                        <th>기간</th>
                        <th>생성일</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.rows.map((item, index) => {
                        // console.log(`${serverUrl}${item.image_url}`);
                        const canManageNotice = this.canRenderCheckBox(item);
                        return (
                          <tr key={index}>
                            <th scope="row">
                              {canManageNotice ? (
                                <input
                                  className="myCheckbox"
                                  type="checkbox"
                                  name={item._id}
                                  onChange={(e) =>
                                    this.onChangeSelectRow(e, index)
                                  }
                                />
                              ) : null}
                            </th>
                            <td>{index + 1}</td>
                            <td
                              style={{
                                "white-space": "pre-wrap",
                                "word-wrap": "break-word",
                              }}
                            >
                              {item.assignee}{" "}
                              {item._branch ? "" : "\n(전 지점)"}
                            </td>
                            <td>{item.title}</td>
                            <td>
                              {item.image_url ? (
                                <img
                                  src={`${serverUrl}${item.image_url}`}
                                  width="150"
                                  height="150"
                                ></img>
                              ) : null}
                            </td>
                            <td>
                              {moment(item.start_at).format("YYYY-MM-DD")} ~{" "}
                              {moment(item.end_at).format("YYYY-MM-DD")}
                            </td>
                            <td>
                              {moment(item.create_at).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </td>
                            <td>
                              {canManageNotice ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={(e) =>
                                    this.props.OnClickUpdate(item._id)
                                  }
                                >
                                  {" "}
                                  수정{" "}
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-inner">
                <ReactPaginate
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.OnClickPage}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  className="text-center"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(BranchNoticePopup);
