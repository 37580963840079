import AxiosClient from "./AxiosClient";

const PointClient = {
  add: async (_user, _branch, amount, expired_at) => {
    return await AxiosClient.post("point/add", {
      _user: _user,
      _branch: _branch,
      amount: amount,
      expired_at: expired_at,
    });
  },
  get: async (_user) => {
    return await AxiosClient.get(`point/get/${_user}`);
  },
  history: async (_user, page, pagePerCount) => {
    return await AxiosClient.get(`point/history/${_user}`, {
      page: page,
      pagePerCount: pagePerCount,
    });
  },
  update: async(_id, amount, expired_at) => {
    return await AxiosClient.patch(`point/update/${_id}`, {amount: amount, expired_at: expired_at});
  },
  delete: async(_id) => {
    return await AxiosClient.del(`point/delete/${_id}`);
  }
};

export default PointClient;
