import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import { withRouter } from "react-router-dom";
import AdminClient from "../utils/Http/AdminClient";

const DecodedToken = (token) => {
    try {
        const myDecodedToken = jwt_decode(token);
        return myDecodedToken;
    } catch (error) {
        return false;
    }
};

export const AuthContext = React.createContext({
    _id: "",
    name: "",
    role: "",
    // permission: ''
    branch: "",
    email: "",
});

export class AuthenticatedComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: "",
            accessor: undefined,
        };
    }

    componentDidMount = async () => {
        let decodeT;
        // const { history } = this.props;
        decodeT = DecodedToken(localStorage.getItem("token_mlb"));
        if (!decodeT) {
            this.props.history.push("/login");
        } else {
            let userObj = {};
            // console.log('decodeT', decodeT._id);
            // "http://127.0.0.1:3000/api/admin/list/60053ce149d21cc4369a243f"
            let userRes = await AdminClient.getAdmin([ decodeT._id ])
            if (!userRes.data.success) {
                alert(
                    "사용자 정보를 호출할 수 없습니다. 관리자에게 문의해 주세요."
                );
                this.props.history.push("/login");
            } else {
                if (!userRes.data.result) {
                    localStorage.removeItem("token_mlb");
                    this.props.history.push("/login");
                } else {
                    // console.log(userRes.data.result);
                    userObj = {
                        _id: userRes.data.result._id,
                        name: userRes.data.result.name,
                        role: userRes.data.result.role,
                        branch: userRes.data.result._branch,
                        email: userRes.data.result.phone,
                    };
                }
            }

            this.setState({
                accessor: userObj,
            });
        }
    };

    render() {
        if (!this.state.accessor) {
            return (
                <div>
                    <h1>Loading...</h1>
                </div>
            );
        }
        return (
            <AuthContext.Provider value={this.state.accessor}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default withRouter(AuthenticatedComponent);
