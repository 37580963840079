import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import PropTypes from "prop-types";
import queryString from "query-string";
import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Content from "../components/Content";
import LessonNote from "../components/LessonNote";

export class TeacherLessonNote extends Component {
    static propTypes = {};

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        const now = moment();
        const start_date = now.clone().subtract(now.day(), 'days');
        const end_date = start_date.clone().add(6, 'days');
        this.state = {
            start_at: start_date.format('YYYY-MM-DD'),
            end_at: end_date.format('YYYY-MM-DD'),
        };
    }

    onChangeNextWeek = async () => {
        const start_date = moment(this.state.start_at).add(7, 'days');
        const end_date = start_date.clone().add(6, 'days');

        this.setState({
            start_at: start_date.format('YYYY-MM-DD'),
            end_at: end_date.format('YYYY-MM-DD'),
        });
    };

    onChangePreWeek = async () => {
        const start_date = moment(this.state.start_at).subtract(7, 'days');
        const end_date = start_date.clone().add(6, 'days');

        this.setState({
            start_at: start_date.format('YYYY-MM-DD'),
            end_at: end_date.format('YYYY-MM-DD'),
        });
    };

    render() {
        return (
            <>
                <Menubar />
                <Content>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">
                                    강사 레슨일지
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="nk-block">
                        <div class="btn-group mb-2" aria-label="Basic example">
                            <Link
                                to={`/teacher/teacherprofile/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/teacher/teacherprofile/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="linkbar">프로필</p>
                                </a>
                            </Link>
                            <Link
                                to={`/teacher/teacherschedule/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/teacher/teacherschedule/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="nolinkbar">
                                        스케쥴 관리
                                    </p>
                                </a>
                            </Link>
                            <Link
                                to={`/teacher/teacherbookstatus/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/teacher/teacherbookstatus/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="nolinkbar">예약 현황</p>
                                </a>
                            </Link>
                            <Link
                                to={`/admin/teacherlessonnote/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/admin/teacherlessonnote/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="nolinkbar">레슨 노트</p>
                                </a>
                            </Link>

                            <Link
                                to={`/teacher/teacherlessontransfer/${this.props.match.params.id}`}
                            >
                                <a
                                    href={`/teacher/teacherlessontransfer/${this.props.match.params.id}`}
                                    className="btn btn-outline-primary"
                                >
                                    <p className="nolinkbar">레슨 이전</p>
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <button className="btn btn-link" onClick={this.onChangePreWeek}> ⟪&nbsp;지난주 </button>
                                <h7 className="inline">
                                    {this.state.start_at}{" ~ "}{this.state.end_at}
                                </h7>
                                <button className="btn btn-link" onClick={this.onChangeNextWeek}> 다음주&nbsp;⟫ </button>
                            </div>
                        </div>
                    </div>
                    <LessonNote _teacher={this.props.match.params.id} start_at={this.state.start_at} end_at={this.state.end_at} />
                </Content>
            </>
        );
    }
}

export default TeacherLessonNote;
