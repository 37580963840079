import React, { useEffect, useState } from "react";
import moment from "moment";
import AdminClient from "../utils/Http/AdminClient";
import BranchClient from "../utils/Http/BranchClient";
import BookClient from "../utils/Http/BookClient";
import DashboardClient from "../utils/Http/DashboardClient";
import ReactModal from "react-modal";
import UserClient from "../utils/Http/UserClient";

var constants = require("../common/Constants");
const ModalItem = {
  None: { id: 0, name: "" },
  ExpiredSoon: { id: 1, name: "수강권 만료 10일 이내 회원" },
  Unreserved: { id: 2, name: "미예약회원" },
  NewUser: { id: 3, name: "신규 회원" },
  LessonTomorrow: { id: 4, name: "내일 레슨 예약" },
  Sales: { id: 5, name: "매출" },
  User: { id: 6, name: "회원 수" },
  // SpecialUser: { id: 7, name: "특별관리대상" },
  // BlackUserList: { id: 8, name: "블랙리스트" },
};
const SortingType = {
  None: 0,
  Name: 1,
  LastLesson: 2,
  EndDate: 3,
  RemainLesson: 4,
};
export default function DashboardBranchInfo({
  branchList,
  _branch,
  renderSales,
}) {
  const [teacherList, setTeacherList] = useState([]);
  const [booksTomorrow, setBooksTomorrow] = useState([]);
  const [data, setData] = useState({
    expiredSoon: [],
    unreservedUsers: [],
    new_user: [],
    sales: [],
    available_users: {
      new_user: [],
      old_user: [],
    },
  });
  const [specialUserList, setSpecialUserList] = useState([]);
  const [blackUserList, setBlackUserList] = useState([]);
  const [sortingType, setSortingType] = useState(SortingType.None);
  const [modalItem, setModalItem] = useState(ModalItem.None);

  useEffect(() => {
    load();
  }, [branchList, _branch]);

  async function load() {
    try {
      if (!_branch) {
        var expiredSoon = [];
        var unreservedUsers = [];
        var new_user = [];
        var sales = [];
        var books = [];
        var teachers = [];
        var available_users = {
          new_user: [],
          old_user: [],
        };
        var special_user_list = [];
        for (const branch of branchList) {
          // console.log(branch);
          const response = await loadBranchData(branch._id);

          // console.log(response.dashboard);
          expiredSoon = [...expiredSoon, ...response.dashboard.expiredSoon];
          unreservedUsers = [
            ...unreservedUsers,
            ...response.dashboard.unreservedUsers,
          ];
          new_user = [...new_user, ...response.dashboard.new_user];
          sales = [...sales, ...response.dashboard.sales];
          teachers = [...teachers, ...response.teachers];
          books = [...books, ...response.books];
          available_users.new_user = [
            ...available_users.new_user,
            ...response.dashboard.available_users.new_user,
          ];
          available_users.old_user = [
            ...available_users.old_user,
            ...response.dashboard.available_users.old_user,
          ];
          // sales += response.dashboard.sales;
          // console.log(sales);

          special_user_list = [
            ...special_user_list,
            ...(await loadSpecialUserList(branch._id)),
          ];
        }

        new_user.sort((a, b) => {
          if (a.create_at < b.create_at) {
            return -1;
          } else {
            return 1;
          }
        });

        sales.sort((a, b) => (a.date > b.date ? 1 : -1));

        setData({
          expiredSoon: expiredSoon,
          unreservedUsers: unreservedUsers,
          new_user: new_user,
          sales: sales,
          available_users: available_users,
        });
        setTeacherList(teachers);
        setBooksTomorrow(books);
        setSpecialUserList(special_user_list);
      } else {
        // console.log(_branch);
        const response = await loadBranchData(_branch);
        setTeacherList(response.teachers);
        setData(response.dashboard);
        setBooksTomorrow(response.books);

        setSpecialUserList(await loadSpecialUserList(_branch));
      }

      loadBlackUserList();
    } catch (error) {
      alert("데이터 가져오기 실패 ! " + error.message);
    }
  }

  async function loadBranchData(_branch) {
    const teacherList = await AdminClient.getAdminList({
      role: "강사",
      _branch: _branch,
    });
    if (!teacherList.data.success) {
      alert(teacherList.data.message);
    }
    // console.log(teacherList);
    // console.log(teacherList.data.result);

    const dashboardRes = await DashboardClient.get({ _branch: _branch });
    if (dashboardRes.data.success) {
      dashboardRes.data.result.expiredSoon =
        dashboardRes.data.result.expiredSoon.sort(function (a, b) {
          if (a.ticket.endDate < b.ticket.endDate) {
            return -1;
          }
        });

      const formatted_today = moment().format("YYYY-MM-DD");
      dashboardRes.data.result.unreservedUsers =
        dashboardRes.data.result.unreservedUsers.filter((item) => {
          for (const holding of item.holdings) {
            if (holding.delete) continue;
            if (
              moment(holding.startDate).format("YYYY-MM-DD") <=
                formatted_today &&
              formatted_today <= moment(holding.endDate).format("YYYY-MM-DD")
            )
              return false;
          }

          return true;
        });
      dashboardRes.data.result.unreservedUsers =
        dashboardRes.data.result.unreservedUsers.sort(function (a, b) {
          if (a.endDate < b.endDate) {
            return -1;
          }
        });

      // console.log(dashboardRes.data.result);
    } else {
      alert("유저 데이터 조회 실패 " + dashboardRes.data.message);
    }
    // console.log(dashboardRes);

    var tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
    const books_tomorrow = await BookClient.getItems({
      lesson_at: tomorrow,
      _branch: _branch,
    });

    let books = [];
    if (books_tomorrow.data.success) {
      // console.log(books_tomorrow.data.result)
      books = books_tomorrow.data.result
        .filter((item) => item.status === "apply")
        .sort((a, b) => {
          if (a.lesson_time < b.lesson_time) return -1;
          else return 1;
        });
    } else {
      alert(books_tomorrow.data.message);
    }
    // console.log(books_tomorrow);

    return {
      teachers: teacherList.data.success ? teacherList.data.result : [],
      dashboard: dashboardRes.data.success ? dashboardRes.data.result : [],
      books: books,
    };
  }

  async function loadSpecialUserList(_branch) {
    try {
      const response = await UserClient.specialList(_branch);

      if (!response.data.success) {
        alert(response.data.message);
        return [];
      }
      // console.log(response.data.result);
      return response.data.result;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  }

  async function loadBlackUserList() {
    try {
      const response = await UserClient.blackList();

      if (!response.data.success) {
        alert(response.data.message);
        return;
      }
      // console.log(response.data.result);
      setBlackUserList(response.data.result);
    } catch (error) {
      console.log(error.message);
    }
  }

  function getTeacherName(teacher_id) {
    const teacher = teacherList.find((teacher) => teacher._id === teacher_id);
    return teacher ? teacher.name : "";
  }

  function getSalesTypeName(type) {
    switch (type) {
      case 1:
        return "수강권 구매";
      case 2:
        return "수강권 환불";
      case 3:
        return "레슨 횟수 구매";
    }
  }

  function render_LinkUserDetail(id, name) {
    return (
      <td>
        <a href={"/user/userdetail/" + id}>{name}</a>
      </td>
    );
  }

  function renderModal() {
    switch (modalItem) {
      case ModalItem.ExpiredSoon:
        var list = [];
        switch (sortingType) {
          case SortingType.Name:
            list = data.expiredSoon.sort((a, b) => (a.name > b.name ? 1 : -1));
            break;
          case SortingType.LastLesson:
            list = data.expiredSoon.sort((a, b) =>
              a.book.lesson_at > b.book.lesson_at ? 1 : -1
            );
            break;
          case SortingType.EndDate:
            list = data.expiredSoon.sort((a, b) =>
              a.ticket.endDate > b.ticket.endDate ? 1 : -1
            );
            break;
          case SortingType.RemainLesson:
            list = data.expiredSoon.sort((a, b) =>
              a.ticket.lesson < b.ticket.lesson ? 1 : -1
            );
            break;
          default:
            list = data.expiredSoon;
            break;
        }
        return (
          <>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th
                      onClick={() => {
                        setSortingType(SortingType.Name);
                      }}
                    >
                      회원명
                    </th>
                    <th
                      onClick={() => {
                        setSortingType(SortingType.EndDate);
                      }}
                    >
                      종료일
                    </th>
                    <th
                      onClick={() => {
                        setSortingType(SortingType.RemainLesson);
                      }}
                    >
                      레슨 횟수
                    </th>
                    <th
                      onClick={() => {
                        setSortingType(SortingType.LastLesson);
                      }}
                    >
                      마지막 레슨일
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.expiredSoon.map((item, index) => {
                    return (
                      <tr key={index}>
                        {render_LinkUserDetail(item._id, item.name)}
                        <td>
                          {moment(item.ticket.endDate).format("YYYY-MM-DD")}
                        </td>
                        <td>
                          {item.ticket.lesson} /{" "}
                          {item.ticket.lesson_all +
                            item.ticket.lesson_buy +
                            item.ticket.lesson_carry_over}
                        </td>
                        <td>
                          {moment(item.book.lesson_at).format("YYYY-MM-DD")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        );
      case ModalItem.Unreserved:
        var list = [];
        switch (sortingType) {
          case SortingType.Name:
            list = data.unreservedUsers.sort((a, b) =>
              a.user.name > b.user.name ? 1 : -1
            );
            break;
          case SortingType.LastLesson:
            list = data.unreservedUsers.sort((a, b) =>
              a.book.lesson_at > b.book.lesson_at ? 1 : -1
            );
            break;
          case SortingType.EndDate:
            list = data.unreservedUsers.sort((a, b) =>
              a.endDate > b.endDate ? 1 : -1
            );
            break;
          case SortingType.RemainLesson:
            list = data.unreservedUsers.sort((a, b) =>
              a.lesson < b.lesson ? 1 : -1
            );
            break;
          default:
            list = data.unreservedUsers;
            break;
        }
        return (
          <>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th
                      onClick={() => {
                        setSortingType(SortingType.Name);
                      }}
                    >
                      회원명
                    </th>
                    <th
                      onClick={() => {
                        setSortingType(SortingType.EndDate);
                      }}
                    >
                      종료일
                    </th>
                    <th
                      onClick={() => {
                        setSortingType(SortingType.RemainLesson);
                      }}
                    >
                      레슨 횟수
                    </th>
                    <th
                      onClick={() => {
                        setSortingType(SortingType.LastLesson);
                      }}
                    >
                      마지막 레슨일
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, index) => (
                    <tr key={index}>
                      {/* {console.item} */}
                      {render_LinkUserDetail(item.user._id, item.user.name)}
                      <td>{moment(item.endDate).format("YYYY-MM-DD")}</td>
                      <td>
                        {item.lesson} /{" "}
                        {item.lesson_all +
                          item.lesson_buy +
                          item.lesson_carry_over}
                      </td>
                      <td>
                        {moment(item.book.lesson_at).format("YYYY-MM-DD")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );
      case ModalItem.NewUser:
        return (
          <>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th
                      onClick={() => {
                        setSortingType(SortingType.Name);
                      }}
                    >
                      회원명
                    </th>
                    <th
                      onClick={() => {
                        setSortingType(SortingType.EndDate);
                      }}
                    >
                      수강권
                    </th>
                    <th>등록일</th>
                    {/* <th onClick={() => { setSortingType(SortingType.RemainLesson); }}>레슨 횟수</th> */}
                    {/* <th onClick={() => { setSortingType(SortingType.LastLesson); }}>마지막 레슨일</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data.new_user.map((item, index) => (
                    <tr key={index}>
                      {/* {console.item} */}
                      {render_LinkUserDetail(item._id, item.name)}
                      <td>{item.ticket.name}</td>
                      <td>{moment(item.create_at).format("YYYY-MM-DD")}</td>
                      {/* <td>
                                            {item.lesson} / {item.lesson_all + item.lesson_buy + item.lesson_carry_over}
                                        </td>
                                        <td>{moment(item.book.lesson_at).format("YYYY-MM-DD")}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );
      case ModalItem.LessonTomorrow:
        return (
          <>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    {renderSales ? <th>지점</th> : null}
                    <th>강사</th>
                    <th>회원명</th>
                    <th>시간</th>
                    <th>카톡 알림</th>
                  </tr>
                </thead>
                <tbody>
                  {booksTomorrow.map((item, index) => (
                    <tr key={index}>
                      {renderSales ? (
                        <td>
                          {
                            branchList.find(
                              (branch) => branch._id === item._branch
                            ).name
                          }
                        </td>
                      ) : null}
                      <td>{getTeacherName(item._teacher)}</td>
                      {render_LinkUserDetail(item.user._id, item.user.name)}
                      <td>{item.lesson_time}</td>
                      <td>
                        {item.notification_send === true
                          ? "전송 완료"
                          : "미전송"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );
      case ModalItem.Sales:
        return (
          <>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th></th>
                    <th>날짜</th>
                    <th>회원명</th>
                    <th>구분</th>
                    <th>금액</th>
                  </tr>
                </thead>
                <tbody>
                  {data.sales.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{moment(item.date).format("YYYY-MM-DD")}</td>
                      {render_LinkUserDetail(item._user._id, item._user.name)}
                      <td>{getSalesTypeName(item.type)}</td>
                      <td>{item.price.toLocaleString("en-US")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );
      case ModalItem.User:
        const new_user_count = data.available_users
          ? data.available_users.new_user.length
          : 0;
        return (
          <>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th></th>
                    <th>구분</th>
                    <th
                      onClick={() => {
                        setSortingType(SortingType.Name);
                      }}
                    >
                      회원명
                    </th>
                    <th
                      onClick={() => {
                        setSortingType(SortingType.EndDate);
                      }}
                    >
                      수강권
                    </th>
                    {/* <th onClick={() => { setSortingType(SortingType.RemainLesson); }}>레슨 횟수</th> */}
                    {/* <th onClick={() => { setSortingType(SortingType.LastLesson); }}>마지막 레슨일</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data.available_users.new_user.map((item, index) => (
                    <tr key={index}>
                      {/* {console.item} */}
                      <td>{index + 1}</td>
                      <td>신규</td>
                      {render_LinkUserDetail(item._id, item.name)}
                      <td>{item.ticket_name}</td>
                      {/* <td>
                                            {item.lesson} / {item.lesson_all + item.lesson_buy + item.lesson_carry_over}
                                        </td>
                                        <td>{moment(item.book.lesson_at).format("YYYY-MM-DD")}</td> */}
                    </tr>
                  ))}
                  {data.available_users.old_user.map((item, index) => (
                    <tr key={index}>
                      {/* {console.item} */}
                      <td>{new_user_count + index + 1}</td>
                      <td>기존</td>
                      {render_LinkUserDetail(item._id, item.name)}
                      <td>{item.ticket_name}</td>
                      {/* <td>
                                            {item.lesson} / {item.lesson_all + item.lesson_buy + item.lesson_carry_over}
                                        </td>
                                        <td>{moment(item.book.lesson_at).format("YYYY-MM-DD")}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );
    }
    return null;
  }

  // console.log(data.available_users);
  return (
    <>
      <h4>현황판</h4>
      <div className="nk-block">
        <div className="row g-gs">
          <div className="col">
            <div className="row g-gs">
              <div
                className="col-6 col-md-3 word-break-all"
                onClick={() => {
                  setModalItem(ModalItem.ExpiredSoon);
                }}
              >
                <div className="card card-bordered">
                  <div className="card-inner">
                    <div className="card-title-group align-start mb-2">
                      <div className="card-title">
                        <h6 className="title">수강권 만료 10일 이내 회원</h6>
                      </div>
                    </div>
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount">
                          {" "}
                          {data.expiredSoon.length} 명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-6 col-md-3 word-break-all"
                onClick={() => {
                  setModalItem(ModalItem.Unreserved);
                }}
              >
                <div className="card card-bordered">
                  <div className="card-inner">
                    <div className="card-title-group align-start mb-2">
                      <div className="card-title">
                        <h6 className="title">미예약 회원</h6>
                      </div>
                    </div>
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount">
                          {" "}
                          {data.unreservedUsers.length} 명{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-6 col-md-3  word-break-all"
                onClick={() => {
                  setModalItem(ModalItem.User);
                }}
              >
                <div className="card card-bordered">
                  <div className="card-inner">
                    <div className="card-title-group align-start mb-2">
                      <div className="card-title">
                        <h6 className="title">회원수</h6>
                      </div>
                    </div>
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount">
                          {" "}
                          신규 : {data.available_users.new_user.length} 명{" "}
                        </span>
                        <span className="amount">
                          {" "}
                          기존 : {data.available_users.old_user.length} 명{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-6 col-md-3  word-break-all"
                onClick={() => {
                  setModalItem(ModalItem.NewUser);
                }}
              >
                <div className="card card-bordered">
                  <div className="card-inner">
                    <div className="card-title-group align-start mb-2">
                      <div className="card-title">
                        <h6 className="title">신규 회원</h6>
                      </div>
                    </div>
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount">
                          {" "}
                          {data.new_user.length} 명{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-6 col-md-3 word-break-all"
                onClick={() => {
                  setModalItem(ModalItem.LessonTomorrow);
                }}
              >
                <div className="card card-bordered">
                  <div className="card-inner">
                    <div className="card-title-group align-start mb-2">
                      <div className="card-title">
                        <h6 className="title">내일 레슨 예약</h6>
                      </div>
                    </div>
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <div className="nk-sale-data">
                        <span className="amount">
                          {" "}
                          {booksTomorrow.length} 명{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {renderSales ? (
                <div
                  className="col-6 col-md-3 word-break-all"
                  onClick={() => {
                    setModalItem(ModalItem.Sales);
                  }}
                >
                  <div className="card card-bordered">
                    <div className="card-inner">
                      <div className="card-title-group align-start mb-2">
                        <div className="card-title">
                          <h6 className="title">매출</h6>
                        </div>
                      </div>
                      <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                        <div className="nk-sale-data">
                          <span className="amount">
                            {/* ₩ { data.sales.toLocaleString("en-US") } */}₩{" "}
                            {data.sales.length > 0
                              ? data.sales
                                  .reduce((acc, obj) => {
                                    return (
                                      acc +
                                      (obj.type != 2
                                        ? obj.price
                                        : obj.price * -1)
                                    );
                                  }, 0)
                                  .toLocaleString("en-US")
                              : 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="nk-block">
        <div className="row g-gs">
          <div className="col">
            <div className="row g-gs">
              <div className="col-12 col-md-6 word-break-all">
                <div className="card card-bordered">
                  <div className="card-inner">
                    <div className="card-title-group align-start mb-2">
                      <div className="card-title">
                        <h6 className="title">특별관리대상</h6>
                      </div>
                    </div>
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      {/* <div className="text-nowrap"> */}
                      <table className="table">
                        <colgroup>
                          <col width="10%" />
                          <col width="10%" />
                          <col width="80%" />
                        </colgroup>
                        <thead className="thead-light">
                          <tr>
                            <th>지점</th>
                            <th>회원명</th>
                            <th>메모</th>
                            {/* <th onClick={() => { setSortingType(SortingType.RemainLesson); }}>레슨 횟수</th> */}
                            {/* <th onClick={() => { setSortingType(SortingType.LastLesson); }}>마지막 레슨일</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {specialUserList.map((item, index) => (
                            <tr key={index}>
                              {/* {console.item} */}
                              <td>{item._branch.name}</td>
                              {render_LinkUserDetail(
                                item._user._id,
                                item._user.name
                              )}
                              <td className="display-enter word-break-all">
                                {item._user.special_management_comment}
                              </td>
                              {/* <td>
                                                                {item.lesson} / {item.lesson_all + item.lesson_buy + item.lesson_carry_over}
                                                            </td>
                                                            <td>{moment(item.book.lesson_at).format("YYYY-MM-DD")}</td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 word-break-all">
                <div className="card card-bordered">
                  <div className="card-inner">
                    <div className="card-title-group align-start mb-2">
                      <div className="card-title">
                        <h6 className="title">블랙리스트</h6>
                      </div>
                    </div>
                    <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                      <table className="table">
                        <colgroup>
                          <col width="10%" />
                          <col width="10%" />
                          <col width="80%" />
                        </colgroup>
                        <thead className="thead-light">
                          <tr>
                            <th>지점</th>
                            <th>회원명</th>
                            <th>메모</th>
                            {/* <th onClick={() => { setSortingType(SortingType.RemainLesson); }}>레슨 횟수</th> */}
                            {/* <th onClick={() => { setSortingType(SortingType.LastLesson); }}>마지막 레슨일</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {blackUserList.map((item, index) => (
                            <tr key={index}>
                              {/* {console.item} */}
                              <td>{item._branch.name}</td>
                              {render_LinkUserDetail(
                                item._user._id,
                                item._user.name
                              )}
                              <td className="display-enter word-break-all">
                                {item._user.black_user_comment}
                              </td>
                              {/* <td>
                                                            {item.lesson} / {item.lesson_all + item.lesson_buy + item.lesson_carry_over}
                                                        </td>
                                                        <td>{moment(item.book.lesson_at).format("YYYY-MM-DD")}</td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        className="modal-content"
        overlayClassName="modal-overlay"
        isOpen={modalItem.id != ModalItem.None.id}
        onRequestClose={() => {
          setModalItem(ModalItem.None);
        }}
        shouldCloseOnOverlayClick={true}
      >
        <div className="card-title">
          <h6 className="title" style={{ margin: "10px" }}>
            {modalItem.name}
          </h6>
        </div>
        {renderModal()}
        <div
          className="modal-close"
          onClick={() => {
            setModalItem(ModalItem.None);
          }}
        >
          닫기
        </div>
      </ReactModal>
    </>
  );
}
