import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "./AuthenticatedComponent";
import moment from "moment";
import LessonNotificationClient from '../utils/Http/LessonNotificationClient';
import BranchNoticePopupList from './BranchNoticePopupList';
import BranchNoticePopupCreate from './BranchNoticePopupCreate';
import BranchNoticePopupUpdate from './BranchNoticePopupUpdate';

const RenderState = {
    List: 1,
    Create: 2,
    Update: 3,
}

class BranchNoticePopup extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            render_state: RenderState.List,
            selected_id: '',
        };
    }

    componentDidMount () {
    };

    SetRenderState(render_state) {
        this.setState({
            render_state: render_state
        })
    }

    OnClickCreate() {
        this.SetRenderState(RenderState.Create);
    }

    OnClickList() {
        this.SetRenderState(RenderState.List);
    }

    OnClickUpdate(id) {
        this.setState({
            selected_id: id
        });
        this.SetRenderState(RenderState.Update);
    }

    OnUpdated() {
        this.SetRenderState(RenderState.List);
    }

    renderByState() {
        switch(this.state.render_state)
        {
            case RenderState.List:
                return (<BranchNoticePopupList branch={this.props.branch} OnClickCreate={() => this.OnClickCreate()} OnClickUpdate={(id) => this.OnClickUpdate(id)} />);
            case RenderState.Create:
                return (<BranchNoticePopupCreate branch={this.props.branch} OnClickCancel={() => this.OnClickList()} />);
            case RenderState.Update:
                return (<BranchNoticePopupUpdate branch={this.props.branch} notice_id={this.state.selected_id} OnUpdated={() => this.OnUpdated()} OnClickCancel={() => this.OnClickList()} />);
        }

        return null;
    }

    render() {
        return (
            <>
                <div className="card card-bordered card-stretch">
                    {this.renderByState()}
                </div>
            </>
        );
    }
}

export default withRouter(BranchNoticePopup);