import React, { useState } from "react";
import { Menu, Item, Submenu, useContextMenu } from "react-contexify";
import moment from "moment";
import "moment-timezone";
import UserClient from "../utils/Http/UserClient";
import BookClient from "../utils/Http/BookClient";
import ScheduleClient from "../utils/Http/ScheduleClient";
import { StarFill } from "react-bootstrap-icons";
import Constants, {lessonType} from "../common/Constants";
import { GetNextHourString } from "../common/Common";

import "../App.css";

import "react-contexify/dist/ReactContexify.css";

var constants = require("../common/Constants");

const MENU_ID = "menu-id";
const MENU_ID2 = "menu-id2";

moment.tz.setDefault("Asia/Seoul");

export default function BookTable(props) {
  const [bookId, setBookId] = useState("");
  const [canceledBookId, setCanceledBookId] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [selectedTime, setLessonTime] = useState("");
  const [trigger, setTrigger] = useState(0);
  const [isBooking, setBooking] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userId, setUserId] = useState("");
  const [showAll, setShowAll] = useState(false);
  // 🔥 you can use this hook from everywhere. All you need is the menu id
  const { show } = useContextMenu({
    id: 1,
  });

  function reload() {
    var t = trigger + 1;
    setTrigger(t);
  }

  function onClickCell(teacher, time, e) {
    if (teacher) {
      setTeacherId(teacher._id);
      setTeacherName(teacher.name);
    }
    const books = props.books.filter(
      (book) =>
        book._teacher === teacher._id &&
        moment(book.lesson_at).format("YYYY-MM-DD") === props.lessonDate &&
        book.lesson_time === time
    );

    const applied = books.find((book) => book.status === "apply");
    const canceled = books.find((book) => book.status === "cancelOnTheDay");

    setBookId(applied != null ? applied._id : "");
    setCanceledBookId(canceled != null ? canceled._id : "");
    setLessonTime(time);
  }

  function onClickTeacher(teacher, e) {
    if (teacher) {
      setTeacherId(teacher._id);
      setTeacherName(teacher.name);
    }

    displayMenu2(e);
  }

  async function onClickBook(e) {
    try {
      e.preventDefault();

      var user = userList.find(function (item) {
        return item._id === userId;
      });
      if (!user) {
        alert("유저 조회 실패. " + userId);
        return;
      }
      var teacher = getTeacher(teacherId);
      if (!teacher) {
        alert("강사 조회 실패. " + teacherId);
        return;
      }

      if (user.ticket.length == 0) {
        alert("보유 중인 수강권이 존재하지 않습니다.");
        return;
      }

      const ticket = user.ticket[0];

      let lessonType = Constants.lessonType.Fifty;
      const lessonPlan = ticket.lessonPlans.find(plan => plan._id === String(teacher._subject._id));
      if (lessonPlan) {
        lessonType = lessonPlan.value;
      }

      const teacher_schedule = props.schedules[teacherId];
      console.log(teacher_schedule);

      const splits = selectedTime.split(":");
      const hour = splits[0];
      const minutes = splits[1];

      const schedule = teacher_schedule[`${hour}:00`];

      if (lessonType == Constants.lessonType.Fifty) {
        if (minutes == "00") {
          if (
            schedule.allowHalfTime &&
            schedule.openHalf != constants.scheduleState.Open
          ) {
            if (
              window.confirm(
                "해당 시간에 예약시 30분 수업으로 진행됩니다. 예약하시겠습니까?"
              ) === false
            ) {
              return;
            }
          }
        } else {
          const nextSchedule =
            teacher_schedule[`${GetNextHourString(hour)}:00`];
          if (
            !nextSchedule.allowHalfTime ||
            nextSchedule.openOnTime != constants.scheduleState.Open
          ) {
            if (
              window.confirm(
                "해당 시간에 예약시 30분 수업으로 진행됩니다. 예약하시겠습니까?"
              ) === false
            ) {
              return;
            }
          }
        }
      } else {
        if(!schedule.allowHalfTime) {
          const confirm = window.confirm("해당 시간에 예약시 60분 수업으로 진행됩니며 레슨 횟수가 2회 소모됩니다. 예약하시겠습니까?");
          if (confirm === false) {
            return;
          }
        }
      }

      var book = {
        _branch: props._branch,
        _subject: teacher._subject,
        _teacher: teacher._id,
        lesson_time: selectedTime,
        lesson_at: props.lessonDate,
        _id: userId,
        repeat: false,
      };

      const res = await BookClient.createBookAdmin(book);

      if (res.data.success === true) {
        let message = "실패";

        var i = 0;
        for (i = 0; i < res.data.result.failed.length; ++i) {
          message += "\n";
          message += res.data.result.failed[i];
        }
        for (i = 0; i < res.data.result.books.length; ++i) {
          res.data.result.books[i].user = user;
          res.data.result.books[i].ticket = user.ticket[0];
          user.ticket[0].lesson -= 1;
          props.books.push(res.data.result.books[i]);
        }
        if (res.data.result.failed.length) {
          alert(message);
        }
        reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  }

  function handleLessonCreate(e) {
    if (props.isHoliday) {
      alert("휴일에는 레슨 예약이 불가능합니다.");
    }
    setBooking(true);
  }

  function closeModal() {
    setBooking(false);
  }

  async function handleLessonCancel(e) {
    await lessonCancel(bookId);
    reload();
  }

  async function lessonCancel(bookId) {
    try {
      const book = props.books.find(function (item) {
        return item._id === bookId;
      });
      let status = "cancel";

      const res = await BookClient.update(bookId, {
        status: status,
        admin: true,
      });

      if (!res.data.success) {
        alert(res.data.message);
        return false;
      }

      book.status = status;
      return true;
    } catch (err) {
      alert("레슨 취소 실패, " + err);
      return false;
    }
  }

  async function LessonCancelOnDay(e, id) {
    try {
      const book = props.books.find(function (item) {
        return item._id === id;
      });
      if (
        moment().format("YYYY-MM-DD") !==
        moment(book.lesson_at).format("YYYY-MM-DD")
      ) {
        alert("날짜가 달라 당일 취소 할 수 없습니다.");
        return;
      }
      let status = "cancelOnTheDay";

      const res = await BookClient.update(id, {
        admin: true,
        status: status,
      });

      if (res.data.success) {
        book.status = status;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleLessonCancelOnDay(e) {
    if (window.confirm("취소하시겠습니까?") === true) {
      await LessonCancelOnDay(e, bookId);
      reload();
    }
  }

  async function LessonCancelCancelOnDay(e) {
    try {
      if (props.isHoliday) {
        alert("휴일에는 사용 불가능한 기능입니다.");
        return;
      }

      const book = props.books.find(function (item) {
        return item._id === canceledBookId;
      });
      if (
        moment().format("YYYY-MM-DD") !==
        moment(book.lesson_at).format("YYYY-MM-DD")
      ) {
        alert("날짜가 달라 당일 취소 할 수 없습니다.");
        return;
      }
      let status = "apply";

      const res = await BookClient.update(canceledBookId, {
        admin: true,
        status: status,
      });

      if (res.data.success) {
        book.status = status;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleLessonCancelCancelOnDay(e) {
    if (window.confirm("취소하시겠습니까?") === true) {
      await LessonCancelCancelOnDay(e);
      reload();
    }
  }

  async function handleLessonCancelAll(e) {
    const books = getBooksAllTime(teacherId);
    for (const book of books) {
      await lessonCancel(book._id);
    }
    reload();
  }

  async function handleLessonCancelOnDayAll(e) {
    if (window.confirm("취소하시겠습니까?") === true) {
      const books = getBooksAllTime(teacherId);
      for (const book of books) {
        await LessonCancelOnDay(e, book._id);
      }
      reload();
    }
  }

  async function HandleOpenScheduleAll(e) {
    const teacher_schedule = props.schedules[teacherId];
    if (!teacher_schedule) {
      alert("스케쥴 정보를 찾을 수 없습니다.");
      return;
    }

    for (const time of constants.times) {
      const schedule = teacher_schedule[time];
      console.log(schedule);
      if (!schedule) continue;

      const splits = time.split(":");
      if (schedule.openOnTime == Constants.scheduleState.Close) {
        if (await OpenSchedule(e, `${splits[0]}:00`)) {
          schedule.openOnTime = Constants.scheduleState.Open;
        }
      }

      if (schedule.openHalf == Constants.scheduleState.Close) {
        if (await OpenSchedule(e, `${splits[0]}:30`)) {
          schedule.openHalf = Constants.scheduleState.Open;
        }
      }
    }
    reload();
  }

  async function HandleCloseScheduleAll(e) {
    const teacher_schedule = props.schedules[teacherId];
    if (!teacher_schedule) {
      alert("스케쥴 정보를 찾을 수 없습니다.");
      return;
    }

    for (const time of constants.times) {
      const schedule = teacher_schedule[time];
      // console.log(schedule);
      if (!schedule) continue;

      const splits = time.split(":");
      if (schedule.openOnTime == Constants.scheduleState.Open) {
        if (await CloseSchedule(e, `${splits[0]}:00`)) {
          schedule.openOnTime = Constants.scheduleState.Close;
        }
      }

      if (schedule.openHalf == Constants.scheduleState.Open) {
        if (await CloseSchedule(e, `${splits[0]}:30`)) {
          schedule.openHalf = Constants.scheduleState.Close;
        }
      }
    }

    reload();
  }

  function getTeacher(id) {
    return props.teachers.find(function (item) {
      return item._id === id;
    });
  }
  function getBook(id) {
    return props.books.find(function (item) {
      return item._id === id && item.status === "apply";
    });
  }

  function isClosedTime(time) {
    if (!bookId) return true;
    const book = getBook(bookId);
    if (!book) return true;
    const teacher = getTeacher(book._teacher);
    if (!isOpenSchedule(teacher, time)) {
      return true;
    }
    if (
      props.books.find(function (item) {
        return (
          item._teacher === book._teacher &&
          item.lesson_time === time &&
          item.status === "apply"
        );
      })
    ) {
      return true;
    }
    // const book = props.books.find(function (item) {
    //     return item._id == bookId && item.lessonTime == time;
    // });

    return false;
  }
  async function handleLessonUpdate(e, time) {
    try {
      const book = getBook(bookId);
      if (book.lesson_time === time) return;

      const res = await BookClient.updateTime({
        _id: bookId,
        lesson_time: time,
      });

      if (res.data.success) {
        book.lesson_time = time;
        reload();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function OpenSchedule(e, time) {
    try {
      const res = await ScheduleClient.open(teacherId, {
        lessonDate: props.lessonDate,
        lessonTime: time,
      });

      if (!res.data.success) {
        alert(res.data.message);
        return false;
      }

      return true;
    } catch (error) {
      alert(error);
      return false;
    }
  }

  async function handleOpenSchedule(e, time) {
    const teacher_schedule = props.schedules[teacherId];
    if (!teacher_schedule) {
      alert("스케쥴 정보를 찾을 수 없습니다.");
      return;
    }

    var splits = time.split(":");

    // const schedule = teacher_schedule[time];
    // if (schedule) {
    //   alert("이미 열려 있는 시간입니다.");
    //   return;
    // }

    const result = await OpenSchedule(e, time);
    if (!result) return;

    console.log(teacher_schedule[`${splits[0]}:00`].openOnTime);

    if (splits[1] == "00") {
      teacher_schedule[`${splits[0]}:00`].openOnTime =
        Constants.scheduleState.Open;
    } else {
      teacher_schedule[`${splits[0]}:00`].openHalf =
        Constants.scheduleState.Open;
    }
    reload();
  }

  async function CloseSchedule(e, time) {
    try {
      let books = getBooks(teacherId, time);
      const book = books.find((book) => book.status === "apply");
      if (book) {
        const cancel = await lessonCancel(book._id);
        if (!cancel) {
          alert("레슨 취소 중 에러 발생. 다시 시도해 주세요.");
          return false;
        }
      }

      const res = await ScheduleClient.close(teacherId, {
        lessonDate: props.lessonDate,
        lessonTime: time,
      });

      if (!res.data.success) {
        alert(res.data.message);
        return false;
      }

      return true;
    } catch (error) {
      alert(error);
      return false;
    }
  }

  async function handleCloseSchedule(e, time) {
    const teacher_schedule = props.schedules[teacherId];
    if (!teacher_schedule) {
      alert("스케쥴 정보를 찾을 수 없습니다.");
      return;
    }

    var splits = time.split(":");

    // const schedule = teacher_schedule[`${splits[0]}:00`];
    // if (!schedule) {
    //   alert("이미 닫혀 있는 시간입니다.");
    //   return;
    // }

    const result = await CloseSchedule(e, time);
    if (!result) return;

    if (splits[1] == "00") {
      teacher_schedule[`${splits[0]}:00`].openOnTime =
        Constants.scheduleState.Close;
    } else {
      teacher_schedule[`${splits[0]}:00`].openHalf =
        Constants.scheduleState.Close;
    }

    reload();
  }

  async function onClickMakeHalftime(e, time) {
    const teacher_schedule = props.schedules[teacherId];
    if (!teacher_schedule) {
      alert("스케쥴 정보를 찾을 수 없습니다.");
      return;
    }

    // const schedule = teacher_schedule[time];
    // if (schedule) {
    //   alert("이미 열려 있는 시간입니다.");
    //   return;
    // }

    const result = await makeHalfTime(e, time);
    if (!result) return;

    var splits = time.split(":");
    teacher_schedule[`${splits[0]}:00`].allowHalfTime = true;
    // console.log(teacher_schedule);
    reload();
  }

  async function makeHalfTime(e, time) {
    try {
      const res = await ScheduleClient.makehalf(teacherId, {
        lessonDate: props.lessonDate,
        lessonTime: time,
      });

      if (!res.data.success) {
        alert(res.data.message);
        return false;
      }

      return true;
    } catch (error) {
      alert(error);
      return false;
    }
  }

  async function onClickMakeHalftimeAll(e) {
    const teacher_schedule = props.schedules[teacherId];
    if (!teacher_schedule) {
      alert("스케쥴 정보를 찾을 수 없습니다.");
      return;
    }

    // console.log("onClickDestroyHalftimeAll");

    for (const time of constants.times) {
      const result = await makeHalfTime(e, time);
      if (!result) continue;

      // console.log(result);
      // var splits = time.split(":");
      teacher_schedule[time].allowHalfTime = true;
    }
    reload();
  }

  async function onClickDestroyHalftime(e, time) {
    const teacher_schedule = props.schedules[teacherId];
    if (!teacher_schedule) {
      alert("스케쥴 정보를 찾을 수 없습니다.");
      return;
    }

    // const schedule = teacher_schedule[time];
    // if (schedule) {
    //   alert("이미 열려 있는 시간입니다.");
    //   return;
    // }

    const result = await destoryHalfTime(e, time);
    if (!result) return;

    var splits = time.split(":");
    // console.log(teacher_schedule);
    teacher_schedule[`${splits[0]}:00`].allowHalfTime = false;
    // console.log(teacher_schedule);
    reload();
  }

  async function onClickDestroyHalftimeAll(e) {
    const teacher_schedule = props.schedules[teacherId];
    if (!teacher_schedule) {
      alert("스케쥴 정보를 찾을 수 없습니다.");
      return;
    }

    // console.log("onClickDestroyHalftimeAll");

    for (const time of constants.times) {
      const result = await destoryHalfTime(e, time);
      if (!result) continue;

      // console.log(result);
      // var splits = time.split(":");
      teacher_schedule[time].allowHalfTime = false;
    }
    reload();
  }

  async function destoryHalfTime(e, time) {
    try {
      const res = await ScheduleClient.destoryhalf(teacherId, {
        lessonDate: props.lessonDate,
        lessonTime: time,
      });

      if (!res.data.success) {
        alert(res.data.message);
        return false;
      }

      return true;
    } catch (error) {
      alert(error);
      return false;
    }
  }

  function displayMenu(e) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    // alert(MENU_ID);
    show(e, {
      id: MENU_ID,
    });
  }

  function displayMenu2(e) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    // alert(MENU_ID);
    show(e, {
      id: MENU_ID2,
    });
  }

  function getMenuTitle() {
    return teacherName + " " + selectedTime;
  }

  function findSchedule(teacher, time) {
    const schedule = props.schedules.find(function (element) {
      return element._teacher === teacher;
    });

    if (schedule) {
      const temp = schedule.temporary_schedule
        ? schedule.temporary_schedule.find(function (element) {
            return (
              moment(element.lessonDate).format("YYYY-MM-DD") ===
                props.lessonDate && element.lessonTime === time
            );
          })
        : null;

      if (temp) {
        return { type: "temp", data: temp };
      }

      const unable = schedule.unable_schedule.find(function (element) {
        return element.dow === props.dow && element.time === time;
      });
      if (unable) {
        return { type: "unable", data: unable };
      }
    }
    return null;
  }

  function isOpenSchedule(teacher, time) {
    if (!teacher) return false;
    // console.log(teacher);
    if (
      props.lessonDate <
        moment(teacher.contract_period.start).format("YYYY-MM-DD") ||
      moment(teacher.contract_period.end).format("YYYY-MM-DD") <
        props.lessonDate
    )
      return false;

    if (props.isHoliday) return false;

    const schedule = props.schedules[teacher._id];

    if (!schedule) return false;

    // console.log(time);

    var splits = time.split(":");

    if (splits[1] == "00") {
      return (
        schedule[`${splits[0]}:00`].openOnTime == Constants.scheduleState.Open
      );
    } else {
      return (
        schedule[`${splits[0]}:00`].openHalf == Constants.scheduleState.Open
      );
    }
  }

  function isAllowedHalftime(teacher, time) {
    if (!teacher) return false;
    // console.log(teacher);
    if (
      props.lessonDate <
        moment(teacher.contract_period.start).format("YYYY-MM-DD") ||
      moment(teacher.contract_period.end).format("YYYY-MM-DD") <
        props.lessonDate
    )
      return false;

    const schedule = props.schedules[teacher._id];

    if (!schedule) return false;

    // console.log(schedule[time]);

    var splits = time.split(":");
    // console.log(`${splits[0]}:00`, schedule[`${splits[0]}:00`]);
    return schedule[`${splits[0]}:00`].allowHalfTime;
  }

  function getClass(teacher, books, time) {
    const book = books.find((item) => item.status === "apply");
    // console.log(book);
    if (book && !book.comment && !book.teacherComment) return "bg-yellow";

    if (books.length > 0) return "bg-white";

    // console.log(teacher.name, time, isOpenSchedule(teacher, time));

    if (isOpenSchedule(teacher, time)) return "bg-white";

    return "bg-gray text-white";
  }

  function isOpenedDate(teacher) {
    if (props.isHoliday) return false;
    var book = props.books.some(
      (book) =>
        (book.status === "apply" || book.status === "cancelOnTheDay") &&
        book._teacher === teacher._id &&
        moment(book.lesson_at).format("YYYY-MM-DD") === props.lessonDate
    );
    if (book) {
      return true;
    }

    if (
      props.lessonDate <
        moment(teacher.contract_period.start).format("YYYY-MM-DD") ||
      moment(teacher.contract_period.end).format("YYYY-MM-DD") <
        props.lessonDate
    )
      return false;

    // console.log(props.schedules);
    var time_arr = [];
    const schedule = props.schedules[teacher._id];
    // console.log(schedule);
    if (!schedule) {
      return false;
    }

    return Object.values(schedule).some(
      (item) =>
        item.openOnTime == Constants.scheduleState.Open ||
        item.openOnTime == Constants.scheduleState.Booked ||
        item.openHalf == Constants.scheduleState.Open ||
        item.openHalf == Constants.scheduleState.Booked
    );
  }

  function getAvailableTeacherList() {
    const date = moment().format("YYYY-MM-DD");
    const teachers = props.teachers.filter((teacher) => {
      if (!teacher.period) return true;
      return teacher.period.start <= date && date <= teacher.period.end;
    });

    if (showAll) {
      return teachers;
    }

    var list = teachers.filter((teacher) => {
      const result = isOpenedDate(teacher);
      // console.log(teacher.name, teacher._id, result);
      return result === true;
    });

    // console.log(list);
    return list;
  }

  async function onSearch(e) {
    try {
      const name = document.getElementById("user_name").value;
      const searchRes = await UserClient.getList({
        user_state: ["승인", "미승인"],
        ticket_state: 0,
        name: name,
        searchType: "name",
        _branch: props._branch,
        page: 0,
        countPerPage: -1,
        sortByPeriod: 0,
      });
      // console.log(searchRes.data.result);

      if (searchRes.data.success) {
        setUserList(searchRes.data.result.list);
      } else {
        alert("잘못된 검색이거나 검색결과가 없습니다.");
      }
    } catch (err) {
      console.log(err);
    }
  }

  function getBooks(_teacher, lessonTime) {
    var filtered = props.books.filter(
      (book) =>
        (book.status === "apply" || book.status === "cancelOnTheDay") &&
        book._teacher === _teacher &&
        moment(book.lesson_at).format("YYYY-MM-DD") === props.lessonDate &&
        book.lesson_time === lessonTime
    );

    return filtered;
  }

  function getBooksAllTime(teacher) {
    var filtered = props.books.filter(
      (book) =>
        book.status === "apply" &&
        book._teacher === teacher &&
        moment(book.lesson_at).format("YYYY-MM-DD") === props.lessonDate
    );
    return filtered;
  }

  function IsBooked(teacher) {
    var filtered = props.books.filter(
      (book) =>
        book.status === "apply" &&
        book._teacher === teacher &&
        moment(book.lesson_at).format("YYYY-MM-DD") === props.lessonDate
    );
    return filtered.length > 0;
  }

  function CanOpenScheduleAll(teacher) {
    return constants.times.some((time) => !isOpenSchedule(teacher, time));
    console.log(constants.times);
    const ret = constants.times.some((time) => {
      const open = isOpenSchedule(teacher, time);
      console.log(`time : ${time} open : ${open}`);
      return open == false;
    });
    console.log(ret);
    return ret;
  }

  function CanCloseScheduleAll(teacher) {
    return constants.times.some((time) => isOpenSchedule(teacher, time));
  }

  function GetNameClass(book, lesson) {
    if (!book.user) return "";

    if (book.ticket_next && book.ticket_next.length == 0) {
      if (!book.ticket) return "";

      const diff = moment(book.ticket.endDate).diff(moment(), "days");

      if (diff < 20 || lesson <= 3) return "user-need-to-re-registration";
    }

    // if(!book.user.new_bedge_until)
    //     return "";

    // if(book.user.new_bedge_until > props.lessonDate)
    //     return "color-red";
    return "";
  }

  function IsNewUser(user) {
    if (!user.new_bedge_until) return false;

    if (user.new_bedge_until > props.lessonDate) return true;

    return false;
  }

  function renderOnTime(teacher, time) {
    let books = getBooks(teacher._id, time);
    // console.log(time, teacher.name, books);
    const className = getClass(teacher, books, time);
    return (
      <td
        key={teacher._id + "-" + time}
        class={className}
        onContextMenu={(e) => {
          onClickCell(teacher, time, e);
          displayMenu(e);
        }}
        onClick={(e) => {
          if (className === "bg-yellow") {
            window.location.href = `/admin/teacherlessonnote/${teacher._id}`;
          } else {
            onClickCell(teacher, time, e);
          }
        }}
      >
        {renderBooks(books)}
      </td>
    );
  }

  function renderHalftime(teacher, time) {
    // return null;
    const splits = time.split(":");
    const hour = splits[0];

    const onTime = `${hour}:00`;
    const halfTime = `${hour}:30`;
    const booksOnTime = getBooks(teacher._id, onTime);
    const booksHalftime = getBooks(teacher._id, halfTime);
    const onTimeClassName = getClass(teacher, booksOnTime, onTime);
    const halfTimeClassName = getClass(teacher, booksHalftime, halfTime);
    return (
      <td className="p-0">
        {/* <div
          className="bg-gray text-white"
          //   style={{ width: "132px", height: "31px" }}
        >
          a
        </div>
        <div
          className="divider m-0"
          //   style={{ width: "120px", height: "2px" }}
        ></div>

        <div
          className="bg-gray text-white"
          //   style={{ width: "132px", height: "31px" }}
        >
          a
        </div> */}
        <table id="test" className="w-100">
          <tr>
            <td
              className={onTimeClassName}
              onContextMenu={(e) => {
                onClickCell(teacher, onTime, e);
                displayMenu(e);
              }}
              onClick={(e) => {
                if (onTimeClassName === "bg-yellow") {
                  window.location.href = `/admin/teacherlessonnote/${teacher._id}`;
                } else {
                  onClickCell(teacher, time, e);
                }
              }}
            >
              {renderBooks(booksOnTime)}
            </td>
          </tr>
          <tr>
            <td
              className={halfTimeClassName}
              onContextMenu={(e) => {
                onClickCell(teacher, halfTime, e);
                displayMenu(e);
              }}
              onClick={(e) => {
                if (halfTimeClassName === "bg-yellow") {
                  window.location.href = `/admin/teacherlessonnote/${teacher._id}`;
                } else {
                  onClickCell(teacher, time, e);
                }
              }}
            >
              {renderBooks(booksHalftime)}
            </td>
          </tr>
        </table>
      </td>
    );
  }

  function renderLessonTypeBadge(min)
  {
    switch(min)
    {
      case lessonType.Fifty:
        return (<span className="badge badge-primary">50분</span>);
      case lessonType.Thirty:
        return (<span className="badge badge-success">30분</span>);
      case lessonType.Sixty:
        return (<span className="badge badge-danger">60분</span>);
      default:
        return (<></>);
    }
  }

  function renderBooks(books) {
    // console.log(books);
    if (books.length == 0) return null;

    return books.map((book) => {
      // if(book.user._id == '609f80df04e518e57cd1b0c9')
      // console.log(book)
      const book_index = book.books
        ? book.books.findIndex((item) => item._id === book._id)
        : 0;
      const lesson = book.ticket.lesson + book_index;
      const lesson_all =
        book.ticket.lesson_all +
        book.ticket.lesson_buy +
        book.ticket.lesson_carry_over;
      return (
        <div>
          <div>
            <strong>
              {book.status === "apply" ? (
                <>
                  {IsNewUser(book.user) ? (
                    <StarFill className="extend-icon color-red" />
                  ) : null}
                  <a
                    className={GetNameClass(book, lesson)}
                    href={`/user/userdetail/${book.user._id}`}
                  >
                    {book.user.name}
                  </a>
                </>
              ) : (
                <a
                  className="line-through"
                  href={`/user/userdetail/${book.user._id}`}
                >
                  {book.user.name}
                </a>
              )}
            </strong>
          </div>
          <div>
            {lesson} / {lesson_all} {renderLessonTypeBadge(book.lesson_type)}
          </div>
        </div>
      );
    });
  }

  function bookCell(row) {
    const lessonTime = constants.times[row];
    // console.log(lessonTime);
    const splits = lessonTime.split(":");
    const halftime = `${splits[0]}:30`;

    const label = getAvailableTeacherList().map((teacher, index) => {
      const allowedHalfTime = isAllowedHalftime(teacher, lessonTime);
      // const halftimeBook = getBooks(teacher, halftime);
      // console.log(lessonTime, allowedHalfTime, teacher);
      return (
        <>
          {allowedHalfTime
            ? renderHalftime(teacher, lessonTime, index)
            : renderOnTime(teacher, lessonTime, index)}
        </>
      );
    });
    return label;
  }

  function render() {
    const teacher = getTeacher(teacherId);
    // console.log(selectedTime);
    const allowedHalfTime = selectedTime
      ? isAllowedHalftime(teacher, selectedTime)
      : false;
    // const allowedHalfTime = false;
    const isOpen = selectedTime ? isOpenSchedule(teacher, selectedTime) : false;
    const books = getBooks(teacherId, selectedTime);
    // console.log(props.books, books, teacherId, selectedTime);
    return (
      <div>
        {isBooking ? (
          <div className="nk-block">
            <div className="card card-bordered">
              <div className="card-inner">
                {/* <form onSubmit={onSearch}> */}
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label">이름</label>
                      <span className="form-note">이름으로 검색</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                          id="user_name"
                          type="text"
                          data-msg="Required"
                          className="form-control required"
                          name="name"
                          // onChange={(e) => setSearchText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") onSearch();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <button onClick={onSearch}>검색</button>
                  </div>
                </div>
                <div class="card-inner p-0">
                  <div class="table-responsive">
                    <table class="table">
                      <thead className="thead-light">
                        <tr>
                          <th>선택</th>
                          <th>회원명</th>
                          <th>휴대전화</th>
                          <th>생년월일</th>
                          <th>성별</th>
                          <th>수강내역</th>
                          <th>수강기간</th>
                          <th>레슨시간</th>
                          <th>남음/총 레슨횟수</th>
                          <th>회원상태</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList.map((user, index) => (
                          <tr key={index}>
                            <th scope="row">
                              <input
                                type="radio"
                                name="lesson_time"
                                value={user}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setUserId(user._id);
                                }}
                              />
                            </th>
                            <td>{user.name ? user.name : null}</td>
                            <td>{user.phone ? user.phone : null}</td>
                            <td>
                              {user.birth_year ? user.birth_year : null}.
                              {user.birth_month ? user.birth_month : null}.
                              {user.birth_day ? user.birth_day : null}
                            </td>
                            <td>{user.gender ? user.gender : null}</td>
                            <td>
                              {user.ticket.length > 0
                                ? user.ticket[0].name
                                : null}
                            </td>
                            <td>
                              {user.ticket.length > 0
                                ? moment(user.ticket[0].startDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : null}
                              ~
                              {user.ticket.length > 0
                                ? moment(user.ticket[0].endDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : null}
                            </td>
                            <td>
                              {user.ticket.length > 0
                                ? user.ticket[0].ticketType ==
                                  constants.ticketType[50]
                                  ? "50분"
                                  : "30분"
                                : null}
                            </td>
                            <td>
                              {user.ticket.length > 0
                                ? user.ticket[0].lesson
                                : null}{" "}
                              /{" "}
                              {user.ticket.length > 0
                                ? user.ticket[0].lesson_all +
                                  user.ticket[0].lesson_buy +
                                  user.ticket[0].lesson_carry_over
                                : null}
                            </td>
                            <td>{user.user_state}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label">강사</label>
                      <span className="form-note">강사 선택</span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">{teacherName}</div>
                    </div>
                  </div>
                </div>
                <div className="row g-3 align-start">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label">시간</label>
                      <span className="form-note">시간 선택</span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">{selectedTime}</div>
                    </div>
                  </div>
                </div>
                <div className="row g-3">
                  <div className="col-lg-7 offset-lg-5">
                    <div className="form-group mt-2">
                      <button
                        onClick={onClickBook}
                        className="btn btn-lg btn-primary mr-1"
                      >
                        예약하기
                      </button>
                      <button
                        onClick={closeModal}
                        className="btn btn-lg btn-secondary"
                      >
                        취소
                      </button>
                    </div>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        ) : null}
        {!props._branch ? (
          <div className="card-body">
            <p>선택된 지점이 없습니다.</p>
          </div>
        ) : (
          <div>
            <div>
              <label onClick={() => setShowAll(!showAll)}>
                {" "}
                {showAll ? "숨기기" : "모두 보기"}
              </label>
            </div>
            <div className="table-responsive bookstyle" id="bookmangement">
              <div>
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th
                        scope="row"
                        className="fixed-column"
                        style={{
                          left: 0,
                          position: "sticky",
                        }}
                      >
                        강사
                      </th>
                      {getAvailableTeacherList().map((item, index) => {
                        return (
                          <th
                            key={index}
                            onContextMenu={(e) => {
                              onClickTeacher(item, e);
                            }}
                          >
                            <div>{item.name}</div>
                          </th>
                        );
                      })}
                      {showAll === true ? null : <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {constants.times.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            className="fixed-column"
                            style={{
                              left: 0,
                              position: "sticky",
                            }}
                          >
                            {constants.times[index]}
                          </td>
                          {bookCell(index)}
                          {showAll === true ? null : <td></td>}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <Menu id={MENU_ID} animation={false}>
          <Item disabled={true}>{getMenuTitle()}</Item>
          {books.length === 0 ||
          books.filter((book) => book.status === "apply").length === 0 ? (
            <Item onClick={handleLessonCreate} disabled={props.isHoliday}>
              레슨 추가
            </Item>
          ) : null}
          {(books.length !== 0 &&
            books.filter((book) => book.status === "apply").length) !== 0 ? (
            <>
              <Item onClick={handleLessonCancel}>레슨 취소</Item>

              {moment().format("YYYY-MM-DD") === props.lessonDate ? (
                <Item onClick={handleLessonCancelOnDay}>당일 취소</Item>
              ) : null}
            </>
          ) : null}
          {books.length !== 0 &&
          books.filter((book) => book.status === "cancelOnTheDay").length !==
            0 ? (
            <Item
              onClick={handleLessonCancelCancelOnDay}
              disabled={props.isHoliday}
            >
              당일 취소 취소
            </Item>
          ) : null}
          {/* <Separator /> */}
          <Submenu label="레슨 시간 변경" disabled={!bookId}>
            {constants.times.map((item, index) => {
              return (
                <Item
                  disabled={isClosedTime(item)}
                  onClick={(e) => handleLessonUpdate(e, item)}
                >
                  {" "}
                  {item}{" "}
                </Item>
              );
            })}
          </Submenu>
          <Item
            onClick={(e) => handleOpenSchedule(e, selectedTime)}
            disabled={isOpen}
          >
            일정 열기
          </Item>
          <Item
            onClick={(e) => handleCloseSchedule(e, selectedTime)}
            disabled={!isOpen}
          >
            일정 닫기
          </Item>
          <Item
            onClick={(e) => onClickMakeHalftime(e, selectedTime)}
            disabled={allowedHalfTime}
          >
            일정 나누기
          </Item>
          <Item
            onClick={(e) => onClickDestroyHalftime(e, selectedTime)}
            disabled={!allowedHalfTime}
          >
            일정 합치기
          </Item>
        </Menu>

        <Menu id={MENU_ID2} animation={false}>
          <Item disabled={true}>{teacherName}</Item>
          <Item onClick={handleLessonCancelAll} disabled={!IsBooked(teacherId)}>
            전체 레슨 취소
          </Item>
          {moment().format("YYYY-MM-DD") === props.lessonDate ? (
            <Item
              onClick={handleLessonCancelOnDayAll}
              disabled={!IsBooked(teacherId)}
            >
              전체 당일 취소
            </Item>
          ) : null}
          <Item
            onClick={HandleOpenScheduleAll}
            disabled={!CanOpenScheduleAll(teacher)}
          >
            전체 일정 열기
          </Item>
          <Item
            onClick={HandleCloseScheduleAll}
            disabled={!CanCloseScheduleAll(teacher)}
          >
            전체 일정 닫기
          </Item>
          <Item onClick={onClickMakeHalftimeAll}>전체 일정 나누기</Item>
          <Item onClick={onClickDestroyHalftimeAll}>전체 일정 합치기</Item>
        </Menu>
      </div>
    );
  }

  return render();
}
