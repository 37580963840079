import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import PropTypes from "prop-types";
import queryString from "query-string";
import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Content from "../components/Content";
import BookClient from "../utils/Http/BookClient";

export class TeacherLessonList extends Component {
    static propTypes = {};

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            books: [],
            viewMonth: "",
            status: "",
            teacher: {},
        };
    }

    componentDidMount = async () => {
        const books = await BookClient.getTeacherLessonList(this.props.location.search);
        const query = queryString.parse(this.props.location.search);
        // console.log(books.data.result);
        if (books.data.success) {
            this.setState({
                books: books.data.result.books,
                viewMonth: query.month,
                status: query.status,
                lessonType: query.lessonType,
                teacher: books.data.result.teacher,
            });
        } else {
            alert(books.data.message);
        }
    };

    render() {
        const { books } = this.state;
        // console.log(this.state.teacher);
        return (
            <>
                <Menubar />
                <Content>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">
                                    {this.state.viewMonth}{" "}
                                    {this.state.teacher.name}{" "}
                                    {this.state.status === "apply"
                                        ? "레슨"
                                        : "취소"}{" "}
                                    리스트
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <div className="card card-bordered card-stretch">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead className="thead-light">
                                        <th>아이디</th>
                                        <th>회원명</th>
                                        <th>예약일</th>
                                        <th>예약시간</th>
                                    </thead>
                                    <tbody>
                                        {books.map((book, index) => (
                                            <tr key={index}>
                                                {this.context.role ===
                                                    "최고관리자" ||
                                                this.context.role ===
                                                    "일반관리자" ? (
                                                    <td>
                                                        <Link
                                                            to={
                                                                "/user/userdetail/" +
                                                                book._user._id
                                                            }
                                                        >
                                                            <a
                                                                href={
                                                                    "/user/userdetail/" +
                                                                    book._user
                                                                        ._id
                                                                }
                                                            >
                                                                {book._user
                                                                    ? book._user
                                                                          .id
                                                                    : null}
                                                            </a>
                                                        </Link>
                                                    </td>
                                                ) : (
                                                    <td>
                                                        {book._user
                                                            ? book._user.id
                                                            : null}
                                                    </td>
                                                )}
                                                <td>
                                                    {book._user
                                                        ? book._user.name
                                                        : null}
                                                </td>
                                                <td>
                                                    {book.lesson_at
                                                        ? moment(
                                                              book.lesson_at
                                                          ).format(
                                                              "YYYY년 MM월 DD일"
                                                          )
                                                        : null}
                                                </td>
                                                <td>
                                                    {book.lesson_time
                                                        ? book.lesson_time
                                                        : null}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Content>
            </>
        );
    }
}

export default TeacherLessonList;
