import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";

import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import moment from "moment";

import { CSVLink } from "react-csv";
import Content from "../components/Content";
import BranchClient from "../utils/Http/BranchClient";
import SubjectClient from "../utils/Http/SubjectClient";

class SubjectList extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props);

    this.state = {
      errorMsg: "",
      offset: 0,
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      searchType: "name",
      selectedRow: [],
      branches: [],
      subjects: [],
      selectedSubject: "",
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      subjects: slice,
    });
  }

  setSearchType = (e) => {
    this.setState({
      searchType: e.target.value,
    });
  };

  onDeleteRow = async () => {
    if (!window.confirm("정말로 삭제하시겠습니까?")) return;
    try {
      var failed = [];
      for (const subject of this.state.selectedRow) {
        const deleteUser = await SubjectClient.delete(subject.id);
        if (deleteUser.data.success) {
        } else {
          failed.push(subject);
        }
      }

      window.location.reload();
    } catch (error) {
      alert("삭제에 실패했습니다.");
    }
  };

  onChangeSelectRow = (e) => {
    if (e.target.checked) {
      this.setState((state) => ({
        selectedRow: [
          ...state.selectedRow,
          { id: e.target.id, name: e.target.name },
        ],
      }));
    } else {
      this.setState((state) => ({
        selectedRow: state.selectedRow.filter((item) => item !== e.target.name),
      }));
    }
  };

  onClickUpdate = async (id) => {
    try {
      const value = document.getElementById("subject_name_" + id).value;

      const response = await SubjectClient.update(id, { name: value });
      if (!response.data.success) {
        alert("수정 실패 " + response.data.message);
        return;
      }

      alert("수정 성공");
      window.location.reload();
    } catch (error) {
      alert("수정 실패 " + error);
    }
  };

  onCheckAll = (e) => {
    const checkboxes = document.getElementsByClassName("myCheckbox");
    checkboxes.forEach((item) => {
      item.checked = e.target.checked;
    });

    if (e.target.checked) {
      this.setState((state) => ({
        selectedRow: state.subjects.map((item) => {
          return { id: item._id, name: item.name };
        }),
      }));
    } else {
      this.setState({
        selectedRow: [],
      });
    }
  };

  componentDidMount = async () => {
    const subjects = await SubjectClient.getList();

    if (subjects.data.success) {
      this.setState({
        pageCount: Math.ceil(subjects.data.result.length / this.state.perPage),
        orgtableData: subjects.data.result,
        subjects: subjects.data.result,
      });
    } else {
      alert("데이터 조회 실패");
    }
  };
  render() {
    if (
      this.context.role !== "일반관리자" &&
      this.context.role !== "최고관리자" &&
      this.context.role !== "지점관리자" &&
      this.context.role !== "매니저"
    ) {
      return (
        <div>
          <Menubar />
          <div className="nk-content">
            <div className="container-fluid">접근 권한이 없습니다.</div>
          </div>
        </div>
      );
    }

    const { subjects } = this.state;
    if (!subjects) {
      return null;
    }

    return (
      <>
        <Menubar />
        <Content>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">과목 리스트</h3>
              </div>
              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    <em className="icon ni ni-menu-alt-r"></em>
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      <li>
                        <CSVLink
                          data={this.state.subjects}
                          className="btn btn-white btn-outline-light"
                        >
                          <em className="icon ni ni-download-cloud"></em>
                          Export
                        </CSVLink>
                      </li>
                      <li className="nk-block-tools-opt">
                        <Link to="/subject/subjectregister">
                          <a
                            href="/subject/subjectregister"
                            className="btn btn-primary"
                          >
                            데이터 추가
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            <div className="card card-bordered card-stretch">
              <div className="card-inner-group">
                <div className="card-inner position-relative card-tools-toggle">
                  <div className="card-title-group d-md-flex d-block">
                    <div className="card-tools mb-1">
                      <div className="form-inline flex-nowrap gx-3">
                        <div className="form-wrap w-150px">
                          <button
                            type="button"
                            className="btn btn-outline-secondary "
                            onClick={this.onDeleteRow}
                          >
                            선택삭제
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-search search-wrap" data-search="search">
                    <div className="card-body">
                      <div className="search-content">
                        <div className="row">
                          <a
                            href={null}
                            className="search-back btn btn-icon toggle-search"
                            data-target="search"
                          >
                            <em className="icon ni ni-arrow-left"></em>
                          </a>
                          <input
                            type="text"
                            className="form-control border-transparent form-focus-none"
                            placeholder="검색"
                            onChange={this.setSearchText}
                          />
                        </div>
                        <button
                          className="search-submit btn btn-icon"
                          onClick={this.onSearch}
                        >
                          <em className="icon ni ni-search"></em>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-inner p-0">
                  <div class="table-responsive">
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th>
                            <input type="checkbox" onChange={this.onCheckAll} />
                          </th>
                          <th></th>
                          <th>이름</th>
                          <th>생성일</th>
                          <th>수정일</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {subjects.map((subject, index) => (
                          <tr key={index}>
                            <td scope="row">
                              <input
                                className="myCheckbox"
                                type="checkbox"
                                id={subject._id}
                                name={subject.name}
                                onChange={this.onChangeSelectRow}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td>
                              <input
                                id={`subject_name_${subject._id}`}
                                defaultValue={subject.name}
                              />
                            </td>
                            <td>
                              {moment(subject.create_at).format(
                                "YYYY년 MM월 DD일"
                              )}
                            </td>
                            <td>
                              {moment(subject.update_at).format(
                                "YYYY년 MM월 DD일"
                              )}
                            </td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  this.onClickUpdate(subject._id);
                                }}
                              >
                                수정
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-inner">
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                  />
                </div>
              </div>
            </div>
          </div>
        </Content>
      </>
    );
  }
}

export default withRouter(SubjectList);
