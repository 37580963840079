import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Menubar from "../components/Menubar";

class LessonDetail extends Component {
    render() {
        return (
            <div>
                <Menubar />
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="nk-block-head nk-block-head-sm">
                                    <div className="nk-block-between">
                                        <div className="nk-block-head-content">
                                            <h3 className="nk-block-title page-title">
                                                레슨내역 상세보기
                                            </h3>
                                        </div>
                                        <div className="nk-block-head-content">
                                            <div className="toggle-wrap nk-block-tools-toggle">
                                                <span
                                                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                                    data-target="pageMenu"
                                                >
                                                    <em className="icon ni ni-more-v"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-1"></div>
                                <div className="row mb-1">
                                    <div className="col-sm-4">
                                        <div className="label-left d-flex align-items-center">
                                            <div>
                                                <label
                                                    className="form-label mb-0"
                                                    htmlFor="default-01"
                                                >
                                                    회원명*
                                                </label>
                                            </div>
                                            <div className="form-control-wrap left-label w-100">
                                                <input
                                                    type="text"
                                                    data-msg="Required"
                                                    className="form-control required"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-1"></div>
                                <div className="row mb-1">
                                    <div className="col-sm-4">
                                        <div className="label-left d-flex align-items-center">
                                            <div>
                                                <label
                                                    className="form-label mb-0"
                                                    htmlFor="default-01"
                                                >
                                                    강사명*
                                                </label>
                                            </div>
                                            <div className="form-control-wrap left-label w-100">
                                                <input
                                                    type="text"
                                                    data-msg="Required"
                                                    className="form-control required"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-1"></div>
                                <div className="row mb-1">
                                    <div className="col-sm-4">
                                        <div className="label-left d-flex align-items-center">
                                            <div>
                                                <label
                                                    className="form-label mb-0"
                                                    htmlFor="default-01"
                                                >
                                                    지점선택*
                                                </label>
                                            </div>
                                            <select className="form-select">
                                                <option>강남본점</option>
                                                <option>종로점</option>
                                                <option>마포점</option>
                                                <option>대구점</option>
                                                <option>제주점</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-1"></div>
                            <div className="row mb-1">
                                <div className="col-sm-4">
                                    <div className="label-left d-flex align-items-center">
                                        <div>
                                            <label
                                                className="form-label mb-0"
                                                htmlFor="default-01"
                                            >
                                                시간*
                                            </label>
                                        </div>
                                        <div className="form-control-wrap left-label w-100">
                                            <input
                                                type="text"
                                                data-msg="Required"
                                                className="form-control required"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-1"></div>
                            <div className="row mb-1">
                                <div className="col-sm-4">
                                    <div className="label-left d-flex align-items-center">
                                        <div>
                                            <label
                                                className="form-label mb-0"
                                                htmlFor="default-01"
                                            >
                                                수강내역*
                                            </label>
                                        </div>
                                        <div className="form-control-wrap left-label w-100">
                                            <input
                                                type="text"
                                                data-msg="Required"
                                                className="form-control required"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-1"></div>
                            <div className="row mb-1">
                                <div className="col-sm-4">
                                    <div className="label-left d-flex align-items-center">
                                        <div>
                                            <label
                                                className="form-label mb-0"
                                                htmlFor="default-01"
                                            >
                                                홀딩일
                                            </label>
                                        </div>
                                        <div className="form-control-wrap left-label w-50">
                                            <input
                                                type="text"
                                                data-msg="Required"
                                                className="form-control required"
                                            />
                                        </div>
                                        <div>일</div>
                                        <Link to="html/lesson-detail.html">
                                            <a
                                                href="html/lesson-detail.html"
                                                className="btn btn-primary ml-3"
                                            >
                                                입력
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-sm-4">
                                    <div className="label-left d-flex align-items-center">
                                        <div>
                                            <label
                                                className="form-label mb-0"
                                                htmlFor="default-01"
                                            >
                                                수강생 참고사항
                                            </label>
                                        </div>
                                        <div className="form-control-wrap left-label w-100">
                                            <input
                                                type="text"
                                                data-msg="Required"
                                                className="form-control required"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-sm-4">
                                    <div className="label-left d-flex align-items-center">
                                        <div>
                                            <label
                                                className="form-label mb-0"
                                                htmlFor="default-01"
                                            >
                                                레슨시 참고사항
                                            </label>
                                        </div>
                                        <div className="form-control-wrap left-label w-100">
                                            <input
                                                type="text"
                                                data-msg="Required"
                                                className="form-control required"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-sm-4">
                                    <div className="label-left d-flex align-items-center">
                                        <div>
                                            <label
                                                className="form-label mb-0"
                                                htmlFor="default-01"
                                            >
                                                남은 레슨 횟수
                                            </label>
                                        </div>
                                        <div className="form-control-wrap left-label w-100">
                                            <input
                                                type="text"
                                                data-msg="Required"
                                                className="form-control required"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-wrap">
                                <a href="html/reservation-management.html">
                                    <button className="btn btn-dim btn-outline-light btn-save">
                                        취소
                                    </button>
                                </a>
                                <a href="html/reservation-management.html">
                                    <button className="btn btn-dim btn-outline-light">
                                        수정완료
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(LessonDetail);
