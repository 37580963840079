import React, { Component } from "react";

export class Content extends Component {
    render() {
        return (
            <>
                <div className="nk-content nk-content-fluid">
                    <div className="container-xl wide-xl">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-footer nk-footer-fluid bg-lighter">
                    <div className="container-xl">
                        <div className="nk-footer-wrap">
                            <div className="nk-footer-copyright">
                                {" "}
                                &copy; 2021 MLB.{" "}
                                <a href="/">musice life balance</a>
                            </div>
                            {/* <div className="nk-footer-links">
                                <ul className="nav nav-sm">
                                    <li className="nav-item"><a className="nav-link" href="#">Terms</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#">Privacy</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#">Help</a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Content;
