import AxiosClient from "./AxiosClient";

const EventClient = {
    create: async (data) => {
        return await AxiosClient.post('event', data);
    },

    delete: async (data) => {
        return await AxiosClient.del('event/delete', data);
    },

}

export default EventClient;