import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import AdminClient from '../utils/Http/AdminClient';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            password: "",
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        try{
            const res = await AdminClient.login({
                id: this.state.id,
                password: this.state.password,
            })

            if (res.data.success === true) {
                localStorage.setItem("token_mlb", res.data.result);
                this.props.history.push("/dashboard");
                // window.location.reload();
            } else {
                alert(res.data.message);
            }
        }
        catch(err){
            console.log(err);
            alert(err.message + "실패");
        }
    };

    componentDidMount = async () => {
        const token = localStorage.getItem("token_mlb");
        if (token) {
            const decode = await jwt_decode(token);
            const tokenfind = await AdminClient.getAdmin(decode._id);
            // console.log(tokenfind);
            if (tokenfind.data.success) {
                this.props.history.push("/dashboard");
            } else {
                this.props.history.push("/login");
                localStorage.removeItem("token_mlb");
            }
        }
    };

    render() {
        return (
            <div className="nk-content ">
                <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                    <div className="card card-bordered">
                        <div className="card-inner card-inner-lg">
                            <div className="nk-block-head">
                                <div className="nk-block-head-content">
                                    <h4 className="nk-block-title">Login</h4>
                                    <div className="nk-block-des"></div>
                                </div>
                            </div>
                            <form onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <div className="form-label-group">
                                        <label
                                            className="form-label"
                                            htmlFor="default-01"
                                        >
                                            ID
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        name="id"
                                        required="required"
                                        onChange={(e) =>
                                            this.setState({
                                                [e.target.name]: String(
                                                    e.target.value
                                                ).toLowerCase(),
                                            })
                                        }
                                        id="default-01"
                                        placeholder="ID를 입력하세요."
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="form-label-group">
                                        <label
                                            className="form-label"
                                            htmlFor="password"
                                        >
                                            비밀번호
                                        </label>
                                        <Link to="/password">
                                            <a
                                                className="alink link-sm"
                                                href="/password"
                                            >
                                                비밀번호 찾기
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="form-control-wrap">
                                        {/* <a href="#" className="form-icon form-icon-right passcode-switch" data-target="password">
                                                <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                                <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                            </a> */}
                                        <input
                                            type="password"
                                            className="form-control form-control-lg"
                                            required="required"
                                            name="password"
                                            onChange={this.onChange}
                                            id="password"
                                            placeholder="비밀번호를 입력하세요."
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-lg bigbtn btn-block"
                                    >
                                        로그인
                                    </button>
                                </div>
                            </form>
                            {/* <div className="form-note-s2 text-center pt-4"> 계정이 있습니까? <a href="/signup">계정 새로 만들기</a></div>
                                <div className="text-center pt-4 pb-3">
                                    <h6 className="overline-title overline-title-sap"><span>OR</span></h6>
                                </div>
                                <ul className="nav justify-center gx-4">
                                    <li className="nav-item"><a className="nav-link" href="#">Facebook</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#">Google</a></li>
                                </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
