import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { AuthContext } from "../components/AuthenticatedComponent";
import Menubar from "../components/Menubar";
import moment from "moment";
import Content from "../components/Content";
import TeacherBookTable from "../components/TeacherBookTable";
import BookClient from "../utils/Http/BookClient";
import ScheduleClient from "../utils/Http/ScheduleClient";
import Constants from "../common/Constants";

class TeacherBookStatus extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props);

    this.state = {
      _branch: "",
      branches: [],
      weekDays: [],
      searchType: "name",
      realBooks: [],
      books: [],
      schedules: {},
    };
  }

  setDayOfWeek(dow) {
    let day = "";
    if (dow === 0) day = "일";
    if (dow === 1) day = "월";
    if (dow === 2) day = "화";
    if (dow === 3) day = "수";
    if (dow === 4) day = "목";
    if (dow === 5) day = "금";
    if (dow === 6) day = "토";

    return day;
  }

  setWeekday(firstDay) {
    let days = [];
    for (var i = 0; i < 7; i++) {
      let nextDay = moment(firstDay).add(i, "days");
      let dow = this.setDayOfWeek(i);
      days.push({
        date: nextDay.format("YYYY-MM-DD"),
        dow: dow,
      });
    }
    return days;
  }

  setWeekday(firstDay) {
    let days = [];
    for (var i = 0; i < 7; i++) {
      let nextDay = moment(firstDay).add(i, "days");
      let dow = this.setDayOfWeek(i);
      days.push({
        date: nextDay.format("YYYY-MM-DD"),
        dow: dow,
      });
    }
    return days;
  }

  setWeekCalendar = (date) => {
    const momentNow = moment(date);
    const firstDayOfMonth = momentNow.clone().startOf("month");
    const firstDayOfCalendar = firstDayOfMonth.clone().startOf("week");
    const offset = firstDayOfMonth.diff(firstDayOfCalendar, "days");
    const dow = momentNow.day();

    const nWeekOfMonth = Math.ceil((momentNow.date() + offset) / 7);
    const year = momentNow.get("year");
    const month = momentNow.get("month");

    const firstDayOfWeek = momentNow.subtract(dow, "days").format("YYYY-MM-DD");
    const lastDayOfWeek = moment(firstDayOfWeek)
      .add(6, "days")
      .format("YYYY-MM-DD");
    const weekDays = this.setWeekday(firstDayOfWeek);
    return {
      momentNow: momentNow,
      nWeekOfMonth: nWeekOfMonth,
      year: year,
      month: month + 1,
      firstDayOfWeek: firstDayOfWeek,
      lastDayOfWeek: lastDayOfWeek,
      weekDays: weekDays,
    };
  };

  setBooks = async (_teacher, firstDayOfWeek) => {
    try {
      const start_at = moment(firstDayOfWeek);
      const end_at = start_at.clone().add(6, "days");
      const bookRes = await BookClient.getTeacherBookList({
        _teacher: _teacher,
        start_at: start_at.format("YYYY-MM-DD"),
        end_at: end_at.format("YYYY-MM-DD"),
      });

      if (bookRes.data.success) {
        bookRes.data.result.map((book) => {
          if (book.books.length > 0) {
            book.books = book.books.sort((a, b) => {
              if (a.lesson_at == b.lesson_at) {
                if (a.lesson_time < b.lesson_time) {
                  return 1;
                } else return -1;
              }

              if (a.lesson_at < b.lesson_at) {
                return 1;
              } else return -1;
            });
            // console.log(book.books);
          }
        });
      }

      return bookRes;
    } catch (error) {
      return null;
    }
  };

  onChangeNextWeek = async () => {
    const firstDayOfNextWeek = moment(this.state.firstDayOfWeek).add(7, "days");
    let nextWeek = this.setWeekCalendar(firstDayOfNextWeek);

    try {
      const bookRes = await this.setBooks(
        this.props.match.params.id,
        nextWeek.firstDayOfWeek
      );

      if (bookRes.data.success) {
        this.setState(
          {
            nWeekOfMonth: nextWeek.nWeekOfMonth,
            year: nextWeek.year,
            month: nextWeek.month,
            firstDayOfWeek: nextWeek.firstDayOfWeek,
            lastDayOfWeek: nextWeek.lastDayOfWeek,
            weekDays: nextWeek.weekDays,
            books: bookRes.data.result,
          },
          () => {
            this.loadSchedule();
          }
        );
      } else {
        alert(bookRes.data.message);
      }
    } catch (error) {
      alert("데이터를 불러올 수 없습니다.");
    }
  };

  onChangePreWeek = async () => {
    const firstDayOfPreWeek = moment(this.state.firstDayOfWeek).subtract(
      7,
      "days"
    );
    let preWeek = this.setWeekCalendar(firstDayOfPreWeek);

    try {
      const bookRes = await this.setBooks(
        this.props.match.params.id,
        preWeek.firstDayOfWeek
      );

      if (bookRes.data.success) {
        this.setState(
          {
            nWeekOfMonth: preWeek.nWeekOfMonth,
            year: preWeek.year,
            month: preWeek.month,
            firstDayOfWeek: preWeek.firstDayOfWeek,
            lastDayOfWeek: preWeek.lastDayOfWeek,
            weekDays: preWeek.weekDays,
            books: bookRes.data.result,
          },
          () => {
            this.loadSchedule();
          }
        );
      } else {
        alert(bookRes.data.message);
      }
    } catch (error) {
      alert("데이터를 불러올 수 없습니다.");
    }
  };

  componentDidMount = async () => {
    const calendarDate = await this.setWeekCalendar(Date.now());

    try {
      const bookRes = await this.setBooks(
        this.props.match.params.id,
        calendarDate.firstDayOfWeek
      );

      if (bookRes.data.success) {
        this.setState(
          {
            nWeekOfMonth: calendarDate.nWeekOfMonth,
            momentNow: calendarDate.momentNow,
            year: calendarDate.year,
            month: calendarDate.month,
            firstDayOfWeek: calendarDate.firstDayOfWeek,
            lastDayOfWeek: calendarDate.lastDayOfWeek,
            weekDays: calendarDate.weekDays,
            realBooks: bookRes.data.result,
            books: bookRes.data.result,
          },
          () => {
            this.loadSchedule();
          }
        );
      } else {
        alert("예약정보 조회 실패");
      }
    } catch (error) {
      alert("데이터를 불러올 수 없습니다.");
    }
  };

  loadSchedule = async () => {
    const start_date = moment(this.state.firstDayOfWeek).format("YYYY-MM-DD");
    const end_date = moment(this.state.firstDayOfWeek)
      .endOf("week")
      .format("YYYY-MM-DD");

    // console.log(start_date, end_date);
    const scheduleRes = await ScheduleClient.getSchedule(
      this.props.match.params.id,
      {
        start_at: start_date,
        end_at: end_date,
      }
    );

    // console.log(moment(calendarDate.firstDayOfWeek).format("YYYY-MM-DD"), moment(calendarDate.firstDayOfWeek).endOf('week').format("YYYY-MM-DD"));

    if (!scheduleRes.data.success) {
      alert(scheduleRes.data.message);
    }

    // console.log(scheduleRes.data.result);

    const schedules = {};

    for (var dow = 0; dow < 7; ++dow) {
      schedules[dow] = {};
      Constants.times.forEach((time) => {
        var initValue = {
          allowHalfTime: false,
          openOnTime: Constants.scheduleState.Open,
          openHalf: Constants.scheduleState.Close,
        };

        if (scheduleRes.data.result.allowHalfTimes[dow]) {
          initValue.allowHalfTime = true;
          initValue.openHalf = Constants.scheduleState.Open;
        }
        schedules[dow][time] = initValue;
      });
    }

    scheduleRes.data.result.unable_schedule.forEach((unable) => {
      var splits = unable.time.split(":");
      var schedule = schedules[unable.dow][`${splits[0]}:00`];

      if (splits[1] == "00") {
        schedule.openOnTime = Constants.scheduleState.Close;
      } else {
        schedule.openHalf = Constants.scheduleState.Close;
      }
    });
    //
    // console.log(scheduleRes.data.result);
    scheduleRes.data.result.temporarySchedule.forEach((temporarySchedule) => {
      // console.log(temporarySchedule);
      const splits = temporarySchedule.lessonTime.split(":");
      const hour = splits[0];
      const minutes = splits[1];
      const dow = moment(temporarySchedule.lessonDate).day();
      const schedule = schedules[dow][`${hour}:00`];

      if (minutes == "00") {
        schedule.openOnTime =
          temporarySchedule.status == 1
            ? Constants.scheduleState.Open
            : Constants.scheduleState.Close;
      } else {
        schedule.openHalf =
          temporarySchedule.status == 1
            ? Constants.scheduleState.Open
            : Constants.scheduleState.Close;
      }
    });

    scheduleRes.data.result.temporaryScheduleHalfTime.forEach(
      (temporaryScheduleHalfTime) => {
        const splits = temporaryScheduleHalfTime.lessonTime.split(":");
        const hour = splits[0];
        const dow = moment(temporaryScheduleHalfTime.lessonDate).day();
        const schedule = schedules[dow][`${hour}:00`];
        schedule.allowHalfTime =
          temporaryScheduleHalfTime.type == 1 ? true : false;
      }
    );

    this.state.books.forEach((book) => {
      // console.log(book);
      if (book.status == "cancel") return;
      const dow = moment(book.lesson_at).day();
      const schedule = schedules[dow];
      if (!schedule) return;

      var splits = book.lesson_time.split(":");
      if (splits[1] == "00") {
        schedule[book.lesson_time].openOnTime = Constants.scheduleState.Booked;
        if (book.lesson_type !== Constants.lessonType.Thirty) {
          schedule[book.lesson_time].openHalf = Constants.scheduleState.Booked;
        }
      } else {
        schedule[`${splits[0]}:00`].openHalf = Constants.scheduleState.Booked;
        if (book.lesson_type !== Constants.lessonType.Thirty) {
          schedule[`${this.getNextHourString(splits[0])}:00`].openOnTime =
            Constants.scheduleState.Booked;
        }
      }
    });
    //
    // console.log(schedules);

    this.setState({
      schedules: schedules,
    });
  };

  getNextHourString(hour) {
    return ("00" + (Number(hour) + 1).toString()).slice(-2);
  }

  scroll = (target) => {
    let element = document.getElementById("bookmangement");
    let width = document.getElementById("sc-1").offsetWidth;
    element.scrollTo({ left: width * target, behavior: "smooth" });
  };

  render() {
    const bookCell = (row) => {
      const lessonTime = Constants.times[row];
      const label = this.state.weekDays.map((item, index) => {
        let bookItem;
        this.state.books.forEach((item3) => {
          const lessonDate = moment(item3.lesson_at).format("YYYY-MM-DD");
          if (lessonDate === item.date) {
            if (item3.lesson_time === lessonTime) {
              bookItem = item3;
            }
          }
        });

        return (
          <td key={row + index}>
            {bookItem ? (
              <div>
                {bookItem.status === "apply" ? (
                  <div>
                    <div>
                      <strong>
                        <Link to={`/user/userdetail/${bookItem.user._id}`}>
                          <a href={`/user/userdetail/${bookItem.user._id}`}>
                            {bookItem.user.name}
                          </a>
                        </Link>
                      </strong>
                    </div>
                    <div>
                      ({bookItem.ticket.lesson} /{" "}
                      {bookItem.ticket.lesson_all +
                        bookItem.ticket.lesson_buy +
                        bookItem.ticket.lesson_carry_over}
                      )
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </td>
        );
      });
      return label;
    };
    return (
      <>
        <Menubar />
        <Content>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">강사 예약관리</h3>
                {/* <div className="nk-block-des text-soft">
                                    <p>{this.state.users.length}건의 데이터 조회</p>
                                </div> */}
              </div>
              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    <em className="icon ni ni-menu-alt-r"></em>
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt">
                        <Link to="/book/bookregister">
                          <a
                            href="/book/bookregister"
                            className="btn btn-primary"
                          >
                            예약추가
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nk-block">
            <div class="btn-group mb-2" aria-label="Basic example">
              <Link
                to={`/teacher/teacherprofile/${this.props.match.params.id}`}
              >
                <a
                  href={`/teacher/teacherprofile/${this.props.match.params.id}`}
                  className="btn btn-outline-primary"
                >
                  <p className="linkbar">프로필</p>
                </a>
              </Link>
              <Link
                to={`/teacher/teacherschedule/${this.props.match.params.id}`}
              >
                <a
                  href={`/teacher/teacherschedule/${this.props.match.params.id}`}
                  className="btn btn-outline-primary"
                >
                  <p className="nolinkbar">스케쥴 관리</p>
                </a>
              </Link>
              <Link
                to={`/teacher/teacherbookstatus/${this.props.match.params.id}`}
              >
                <a
                  href={`/teacher/teacherbookstatus/${this.props.match.params.id}`}
                  className="btn btn-outline-primary"
                >
                  <p className="nolinkbar">예약 현황</p>
                </a>
              </Link>
              <Link
                to={`/admin/teacherlessonnote/${this.props.match.params.id}`}
              >
                <a
                  href={`/admin/teacherlessonnote/${this.props.match.params.id}`}
                  className="btn btn-outline-primary"
                >
                  <p className="nolinkbar">레슨 노트</p>
                </a>
              </Link>
              <Link
                to={`/teacher/teacherlessontransfer/${this.props.match.params.id}`}
              >
                <a
                  href={`/teacher/teacherlessontransfer/${this.props.match.params.id}`}
                  className="btn btn-outline-primary"
                >
                  <p className="nolinkbar">레슨 이전</p>
                </a>
              </Link>
            </div>
            <div className="card card-bordered card-stretch">
              <div className="card-inner-group">
                <div className="card-inner position-relative card-tools-toggle">
                  <div className="card-title-group d-md-flex d-block">
                    <div className="card-tools">
                      <div className="form-inline flex-nowrap gx-3">
                        <button
                          className="btn btn-link"
                          onClick={this.onChangePreWeek}
                        >
                          ⟪&nbsp;지난주
                        </button>
                        <h5 className="mb-0">
                          {moment(this.state.firstDayOfWeek).get("year")}년{" "}
                          {moment(this.state.firstDayOfWeek).get("month") + 1}월{" "}
                          {moment(this.state.firstDayOfWeek).get("date")}일 ~{" "}
                          {moment(this.state.lastDayOfWeek).get("year")}년{" "}
                          {moment(this.state.lastDayOfWeek).get("month") + 1}월{" "}
                          {moment(this.state.lastDayOfWeek).get("date")}일
                        </h5>
                        <button
                          className="btn btn-link"
                          onClick={this.onChangeNextWeek}
                        >
                          다음주&nbsp;⟫
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-inner p-0">
                <TeacherBookTable
                  _branch={this.state._branch}
                  _teacher={this.props.match.params.id}
                  weekDays={this.state.weekDays}
                  books={this.state.books}
                  schedules={this.state.schedules}
                />
              </div>
            </div>
          </div>
        </Content>
      </>
    );
  }
}

export default withRouter(TeacherBookStatus);
