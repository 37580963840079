import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Normal from "./layout/Normal";
import Login from "./pages/Login";
import Password from "./pages/Password";
import Signup from "./pages/Signup";
import Logout from "./pages/Logout";
import PasswordReset from "./pages/PasswordReset";
import KakaoConnect from "./pages/KakaoConnect";

if (process.env.REACT_APP_WEB_ENV === "production") {
  console.log = function no_console() {};
  console.warn = function no_console() {};
}

export default class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact={true} path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route path="/login" component={Login} />
          <Route path="/password" component={Password} />
          <Route path="/passwordreset/:id" component={PasswordReset} />
          <Route path="/signup" component={Signup} />
          <Route path="/logout" component={Logout} />

          <Route path="/dashboard" component={Normal} />

          <Route path="/admin" component={Normal} />

          <Route path="/book" component={Normal} />

          <Route path="/branch" component={Normal} />

          <Route path="/teacher" component={Normal} />

          <Route path="/user" component={Normal} />
          <Route path="/kakaoconnect" component={KakaoConnect} />
          <Route path="/subject" component={Normal} />
        </Switch>
      </Router>
    );
  }
}
