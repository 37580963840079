import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "./AuthenticatedComponent";
import moment from "moment";
import KakaoClient from "../utils/Http/KakaoClient";
import { Textarea } from "react-bootstrap-icons";

const RenderState = {
  Send: 1,
  Sent: 2,
};
class BranchModify extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    var now = moment();
    var date = now.subtract(1, "day").format("YYYY-MM-DD");

    this.state = {
      render_state: RenderState.Send,
      friends: [],
      message: "",
      kakao_message_date: date,
      kakao_messages: [],
      selectedRow: [],
    };
  }

  componentDidMount() {
    this.LoadKakaoFriend();
    this.LoadKakaoMessage(this.state.kakao_message_date);
  }

  onSubmit = async (e) => {
    if (this.state.message.length == 0) {
      alert("메시지를 입력해주세요.");
      return;
    }

    if (this.state.selectedRow.length == 0) {
      alert("메시지를 전송 할 회원을 선택 해 주세요.");
      return;
    }

    var message = "아래 회원들에게 메시지를 전송하시겠습니까?\n\n";
    this.state.selectedRow.forEach((item) => {
      message += `${item.name}\n`;
    });

    if (window.confirm(message) === false) {
      return;
    }

    alert("전송 완료 팝업이 노출 될 때까지 대기 해 주세요.");

    var failed_message = "";
    for (const user of this.state.selectedRow) {
      try {
        // for (var i = 0; i < 100; ++i) {
        const response = await KakaoClient.message(
          this.props.branch,
          user._id,
          this.state.message
        );

        if (response.data.success === false) {
          failed_message += `${user.name} : ${response.data.message}`;
        }
        // }
      } catch (err) {
        failed_message += `${user.name} : ${err.message}`;
      }
    }

    if (failed_message == "") {
      alert("메시지 전송에 성공하였습니다.");
    } else {
      alert(
        "아래 회원들에게 메시지 전송에 실패하였습니다.\n\n" + failed_message
      );
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeDate = (e) => {
    this.LoadKakaoMessage(e.target.value);
  };

  onChangeSelectRow = (e) => {
    if (e.target.checked) {
      this.setState((state) => ({
        selectedRow: [
          ...state.selectedRow,
          { _id: e.target.id, name: e.target.name },
        ],
      }));
    } else {
      this.setState((state) => ({
        selectedRow: state.selectedRow.filter(
          (item) => item._id !== e.target.name
        ),
      }));
    }
  };

  onCheckAll = (e) => {
    const checkboxes = document.getElementsByClassName("myCheckbox");
    checkboxes.forEach((item) => {
      item.checked = e.target.checked;
    });

    if (e.target.checked) {
      this.setState((state) => ({
        selectedRow: state.friends.map((item) => {
          return { _id: item._id, name: item.name };
        }),
      }));
    } else {
      this.setState({
        selectedRow: [],
      });
    }
  };

  LoadKakaoFriend = async (date) => {
    try {
      const KakaoFriendRes = await KakaoClient.friends(this.props.branch);

      if (KakaoFriendRes.data.success === false) {
        alert(KakaoFriendRes.data.message);
        return;
      }
      console.log(KakaoFriendRes.data.result);
      this.setState({
        friends: KakaoFriendRes.data.result,
      });
    } catch (err) {
      console.log(err);
    }
  };

  LoadKakaoMessage = async (date) => {
    try {
      const KakaoMessageRes = await KakaoClient.messages({
        _branch: this.props.branch,
        date: moment(date).format("YYYY-MM-DD"),
      });

      if (KakaoMessageRes.data.success === false) {
        alert(KakaoMessageRes.data.message);
        return;
      }
      // console.log(KakaoMessageRes.data);
      this.setState({
        kakao_message_date: date,
        kakao_messages: KakaoMessageRes.data.result
          ? KakaoMessageRes.data.result.messages
          : [],
      });
    } catch (err) {
      console.log(err);
    }
  };

  render_send() {
    return (
      <>
        <div>
          <div className="nk-block">
            <div class="card card-bordered">
              <div class="card-inner">
                <div class="card-head">
                  <h5 class="card-title">지점정보</h5>
                </div>
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" for="site-name">
                        메시지
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <textarea
                          type="text"
                          className="form-control"
                          id="message"
                          name="message"
                          onChange={this.onChange}
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-3">
                  <div className="col-lg-7 offset-lg-5">
                    <div className="form-group mt-2">
                      <button
                        className="btn btn-lg btn-primary mr-1"
                        onClick={this.onSubmit}
                      >
                        전송
                      </button>
                    </div>
                  </div>
                </div>
                <div className="divider" />
                <div class="card-inner p-0">
                  <div class="table-responsive">
                    <table class="table">
                      <thead className="thead-light">
                        <tr>
                          <th>
                            <input type="checkbox" onChange={this.onCheckAll} />
                          </th>
                          <th></th>
                          <th>이름</th>
                          <th>아이디</th>
                          <th>휴대전화</th>
                          <th>생년월일</th>
                          <th>성별</th>
                          <th>수강권</th>
                          <th>수강기간</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.friends.map((user, index) => (
                          <tr key={index}>
                            <th scope="row">
                              <input
                                className="myCheckbox"
                                type="checkbox"
                                id={user._id}
                                name={user.name}
                                onChange={this.onChangeSelectRow}
                              />
                            </th>
                            <td>{index + 1}</td>
                            <td>{user.name}</td>
                            <td>{user.id ? user.id : null}</td>
                            <td>{user.phone ? user.phone : null}</td>
                            <td>
                              {user.birth_year ? user.birth_year : null}.
                              {user.birth_month ? user.birth_month : null}.
                              {user.birth_day ? user.birth_day : null}
                            </td>
                            <td>{user.gender ? user.gender : null}</td>
                            <td>
                              {user.ticket.length > 0
                                ? user.ticket[0].name
                                : ""}
                            </td>
                            <td>
                              {user.ticket.length > 0
                                ? `${moment(user.ticket[0].startDate).format(
                                    "YYYY-MM-DD"
                                  )} ~ ${moment(user.ticket[0].endDate).format(
                                    "YYYY-MM-DD"
                                  )}`
                                : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  render_sent() {
    return (
      <>
        <div>
          <input
            type="date"
            className="form-control"
            name="kakao_message_date"
            onChange={this.onChangeDate}
            value={moment(this.state.kakao_message_date).format("YYYY-MM-DD")}
          />
        </div>
        <div className="divider" />
        <div className="card card-bordered card-stretch">
          <div class="table-responsive">
            <table class="table">
              <thead className="thead-light">
                <th></th>
                <th>이름</th>
                <th>메시지</th>
                <th>날짜</th>
              </thead>
              <tbody>
                {this.state.kakao_messages.map((data, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {data.name}
                      {data.is_teacher === true ? `(T)` : ""}
                    </td>
                    <td>{data.message}</td>
                    <td>
                      {moment(data.create_date).format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  renderByState() {
    switch (this.state.render_state) {
      case RenderState.Send:
        return this.render_send();
      case RenderState.Sent:
        return this.render_sent();
    }
    return <></>;
  }

  render() {
    return (
      <>
        <div />
        <div class="btn-group mb-2" aria-label="Basic example">
          <button
            onClick={() => this.setState({ render_state: RenderState.Send })}
            className="btn btn-outline-primary"
          >
            메시지 보내기
          </button>
          <button
            onClick={() =>
              this.setState({
                render_state: RenderState.Sent,
                selectedRow: [],
                message: "",
              })
            }
            className="btn btn-outline-primary"
          >
            전송 내역
          </button>
        </div>
        <div>{this.renderByState()}</div>
      </>
    );
  }
}

export default withRouter(BranchModify);
