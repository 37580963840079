import AxiosClient from "./AxiosClient";

const NoticePopupClient = {
    Create: async (data) => {
        return await AxiosClient.post('noticepopup', data, { "Content-type": "multipart/form-data;"});
    },
    Get: async(id) => {
        return await AxiosClient.get(`noticepopup/item/${id}`);
    },
    List: async(id, data) => {
        return await AxiosClient.get(`noticepopup/adminlist/${id}`, data);
    },
    Update: async(id, data) => {
        return await AxiosClient.patch(`noticepopup/update/${id}`, data)
    },
    Delete: async(id) => {
        return await AxiosClient.del(`noticepopup/delete/${id}`);
    },
}

export default NoticePopupClient;