import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { AuthContext } from "./AuthenticatedComponent";
import moment from "moment";
import AdminClient from "../utils/Http/AdminClient";
import BranchClient from "../utils/Http/BranchClient";

class BranchBannerInfo extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    // var now = moment();
    // var date = now.add(1, 'day').format("YYYY-MM-DD");

    // const query = queryString.parse(this.props.location.search);

    this.state = {
      message: "",
    };
  }

  componentDidMount() {
    this.load();
  }

  load = async () => {
    try {
      const response = await BranchClient.get(this.props.branch);

      if (response.data.success === false) {
        alert(response.data.message);
        return;
      }

      this.setState({
        message: response.data.result.banner,
      });
    } catch (err) {
      console.log(err);
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onClickSave = async (e) => {
    try {
      const response = await BranchClient.updateBanner(this.props.branch, {
        message: this.state.message,
      });

      if (response.data.success === false) {
        alert(response.data.message);
        return;
      }

      alert("배너 업데이트 완료.");
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <div className="nk-block">
        <div class="card card-bordered">
          <div class="card-inner">
            <div class="card-head">
              <h5 class="card-title">배너</h5>
            </div>
            <div className="row g-3 align-center">
              <div className="col-lg-5">
                <div className="form-group">
                  <label className="form-label" for="site-name">
                    메시지
                  </label>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <textarea
                      type="text"
                      className="form-control"
                      id="message"
                      name="message"
                      value={this.state.message}
                      onChange={this.onChange}
                      required="required"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-lg-7 offset-lg-5">
                <div className="form-group mt-2">
                  <button
                    onClick={this.onClickSave}
                    className="btn btn-lg btn-primary mr-1"
                  >
                    확인
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BranchBannerInfo);
