import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import Content from "../components/Content";
import BranchClient from "../utils/Http/BranchClient";
import SuggestionClient from "../utils/Http/SuggestionClient";
import moment from "moment";
import "moment-timezone";
moment.tz.setDefault("Asia/Seoul");

class SuggestionList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      branchs: [],
      selectedBranch: "",
      page: 0,
      pageCount: 0,
      countPerPage: 10,
      rows: [],
      totalCount: 0,
    };
  }
  componentDidMount = async () => {
    try {
      if (
        this.context.role === "최고관리자" ||
        this.context.role === "일반관리자"
      ) {
        const branchList = await BranchClient.getList();

        if (branchList.data.success === false) {
          alert(branchList.data.message);
        }
        this.setState(
          {
            branchs: branchList.data.success ? branchList.data.result : [],
          },
          () => this.load()
        );
      } else {
        this.setState(
          {
            selectedBranch: this.context.branch._id,
          },
          () => this.load(this.context.branch._id)
        );
      }
    } catch (ex) {
      alert(ex);
      this.setState({ errorMsg: "Error retreiving data" });
    }
  };

  load = async (_branch) => {
    try {
      var suggestions = [];
      var totalCount = 0;
      if (_branch) {
        const response = await SuggestionClient.list(
          _branch,
          this.state.page,
          this.state.countPerPage
        );

        if (!response.data.success) {
          alert(response.data.message);
          return;
        }

        suggestions = response.data.list;
        totalCount = response.data.totalCount;
      } else {
        const response = await SuggestionClient.listAll(
          this.state.page,
          this.state.countPerPage
        );

        if (!response.data.success) {
          alert(response.data.message);
          return;
        }

        suggestions = response.data.list;
        totalCount = response.data.totalCount;
      }

      this.setState({
        rows: suggestions,
        pageCount:
          Number.parseInt((totalCount - 1) / this.state.countPerPage) + 1,
        totalCount: totalCount,
      });
    } catch (ex) {
      alert(ex);
      this.setState({ errorMsg: "Error retreiving data" });
    }
  };

  onChangeBranch = async (e) => {
    e.preventDefault();

    this.setState(
      {
        selectedBranch: e.target.value,
      },
      () => this.load(e.target.value)
    );
  };

  onClickPage = (e) => {
    const selectedPage = e.selected;
    // alert(e.selected);
    this.setState(
      {
        page: selectedPage,
      },
      () => this.load(this.state.selectedBranch)
    );
  };

  onClickCreate = (e) => {
    e.preventDefault();
  };

  render() {
    if (this.context.role === "강사") {
      return (
        <div>
          <Menubar />
          <div className="nk-content">
            <div className="container-fluid">접근 권한이 없습니다.</div>
          </div>
        </div>
      );
    }

    const { branchs } = this.state;

    return (
      <>
        <Menubar />
        <Content>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">건의함</h3>
              </div>
              {/* <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    <em className="icon ni ni-menu-alt-r"></em>
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      <li>
                        <CSVLink
                          data={this.state.branchs}
                          className="btn btn-white btn-outline-light"
                        >
                          <em className="icon ni ni-download-cloud"></em>
                          Export
                        </CSVLink>
                      </li>
                      <li className="nk-block-tools-opt">
                        <Link to="/branch/branchregister">
                          <a
                            href="/branch/branchregister"
                            className="btn btn-primary"
                          >
                            데이터 추가
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="nk-block">
            <div className="card card-bordered card-stretch">
              <div className="card-inner-group">
                <div className="card-inner position-relative card-tools-toggle">
                  <div className="card-title-group">
                    {this.context.role === "최고관리자" ||
                    this.context.role === "일반관리자" ? (
                      <div>
                        <div className="row align-center">
                          <div className="col-lg-5">
                            <div className="form-group">
                              <label className="form-label" for="site-name">
                                지점
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <select
                                  className="form-control"
                                  name="dashboard_branch"
                                  onChange={this.onChangeBranch}
                                  required="required"
                                  //  value={this.state._branch ? this.state._branch.name : null}
                                  defaultValue={
                                    this.state.selectedBranch
                                      ? this.state.selectedBranch
                                      : "없음"
                                  }
                                >
                                  <option value="">전체</option>
                                  {this.state.branchs.map((item, index) => (
                                    <option key={index} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div>
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={(e) =>
                          (window.location.href = "/branch/suggestion")
                        }
                      >
                        작성하기
                      </button>
                    </div>
                  </div>
                </div>

                <div class="card-inner p-0">
                  <div class="table-responsive">
                    <table class="table">
                      <thead className="thead-light">
                        <tr>
                          <th></th>
                          {this.context.role === "최고관리자" ||
                          this.context.role === "일반관리자" ? (
                            <th>지점명</th>
                          ) : null}
                          <th>구분</th>
                          <th>내용</th>
                          <th>작성일시</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rows.map((suggestion, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            {this.context.role === "최고관리자" ||
                            this.context.role === "일반관리자" ? (
                              <td>{suggestion._branch.name}</td>
                            ) : null}
                            <td>{suggestion.is_admin ? '직원' : '회원'}</td>
                            <td>{suggestion.content}</td>
                            <td>
                              {moment(suggestion.create_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-inner">
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.onClickPage}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                  />
                </div>
              </div>
            </div>
          </div>
        </Content>
      </>
    );
  }
}

export default withRouter(SuggestionList);
