import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import { AuthContext } from "../components/AuthenticatedComponent";
import Validation from "../utils/Validations";
import ContentHeader from "../components/ContentHeader";
import Content from "../components/Content";
import AdminClient from "../utils/Http/AdminClient";
import BranchClient from "../utils/Http/BranchClient";

// import moment from 'moment';
// moment.tz.setDefault("Asia/Seoul");

class AdminRegister extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      id: "",
      password: "",
      password2: "",
      role: "",
      phone: "",
      _branch: "",
      branchArr: [],
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount = async () => {
    try {
      const branchList = await BranchClient.getList();

      this.setState({
        branchArr: branchList.data.success ? branchList.data.result : [],
      });
    } catch (error) {
      console.log(error);
      this.setState({ errorMsg: "Error retreiving data" });
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const idValidation = await Validation.id(this.state.id);

    if (!idValidation.success) {
      alert(idValidation.message);
    } else {
      const sign = {
        name: this.state.name,
        id: this.state.id,
        password: this.state.password,
        password2: this.state.password2,
        role: this.state.role,
        phone: this.state.phone,
      };
      if (this.state._branch) {
        sign._branch = this.state._branch;
      }

      try {
        const res = await AdminClient.create(sign);

        if (res.data.success === true) {
          alert(res.data.message);
          // this.props.history.push('/admin/adminlist');
          window.location.href = "/admin/adminlist";
        } else {
          alert(res.data.message);
        }
      } catch (ex) {
        console.log(ex);
        alert(ex.message + "실패");
      }
    }
  };

  render() {
    if (
      this.context.role !== "일반관리자" &&
      this.context.role !== "최고관리자" &&
      this.context.role !== "지점관리자" &&
      this.context.role !== "매니저"
    ) {
      return (
        <div>
          <Menubar />
          <div className="nk-content">
            <div className="container-fluid">접근 권한이 없습니다.</div>
          </div>
        </div>
      );
    }

    return (
      <>
        <Menubar />
        <Content>
          <ContentHeader
            prevlink="/admin/adminlist"
            prevTitle="목록보기"
            title="관리자 등록하기"
          />
          <div className="nk-block">
            <div class="card card-bordered">
              <div class="card-inner">
                <div class="card-head">
                  <h5 class="card-title">계정</h5>
                </div>
                <form onSubmit={this.onSubmit} className="text-left mt-4">
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          관리자 이름
                        </label>
                        <span className="form-note">관리자 이름</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          아이디
                        </label>
                        <span className="form-note">로그인 계정</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            id="id"
                            name="id"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          비밀번호
                        </label>
                        <span className="form-note">로그인 비밀번호</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          비밀번호 확인
                        </label>
                        <span className="form-note">로그인 비밀번호 확인</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            className="form-control"
                            id="password2"
                            name="password2"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          휴대전화
                        </label>
                        <span className="form-note">- 없이 입력</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          권한
                        </label>
                        <span className="form-note">
                          {this.context.role !== "지점관리자"
                            ? "관리자 / 지점관리자 / 매니저 선택"
                            : "지점관리자 / 매니저 선택"}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <select
                            className="form-control"
                            name="role"
                            onChange={this.onChange}
                            required="required"
                            value={this.state.role}
                          >
                            <option value="" disabled>
                              선택
                            </option>
                            {this.context.role === "최고관리자" ||
                            this.context.role === "일반관리자" ? (
                              <option value="일반관리자" name="role">
                                일반관리자
                              </option>
                            ) : null}
                            {this.context.role !== "매니저" ? (
                              <option value="지점관리자" name="role">
                                지점관리자
                              </option>
                            ) : null}
                            <option value="매니저" name="role">
                              매니저
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.role === "지점관리자" ||
                  this.state.role === "매니저" ? (
                    <div className="row g-3 align-center">
                      <div className="col-lg-5">
                        <div className="form-group">
                          <label className="form-label" for="site-name">
                            지점
                          </label>
                          <span className="form-note">지점 선택</span>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <select
                              className="form-control"
                              name="_branch"
                              onChange={this.onChange}
                              required="required"
                            >
                              <option value="">선택</option>
                              {this.state.branchArr.map((item, index) => {
                                if (
                                  (this.context.role == "지점관리자" ||
                                    this.context.role == "매니저") &&
                                  this.context.branch._id != item._id
                                ) {
                                  return null;
                                }
                                return (
                                  <option key={index} value={item._id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row g-3">
                    <div className="col-lg-7 offset-lg-5">
                      <div className="form-group mt-2">
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary mr-1"
                        >
                          저장
                        </button>
                        <Link to="/admin/adminlist">
                          <a
                            href="/admin/adminlist"
                            className="btn btn-lg btn-secondary"
                          >
                            취소
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Content>
      </>
    );
  }
}

export default withRouter(AdminRegister);
