import { useState, useEffect } from "react";
import UserClient from "../utils/Http/UserClient";
import ReactModal from 'react-modal';
import moment from 'moment';

var constants = require("../common/Constants");

const UserHistoryType = {
    Create: 1,
    Update: 2,
    AdminUpdate: 3,
    Delete: 4,
    TicketCreate: 5,
    TicketUpdate: 6,
    TicketDelete: 7,
    TicketRefund: 8,
    Holding: 9,
    HoldingCancel: 10,
    LessonBuy: 11,
    LessonBuyCancel: 12,
    AddDays: 13,
    AddDaysCancel: 14,
    TransferLessons: 15,
    TransferLessonsCancel: 16,
    // BookCancel_Holding: 8,
}

export default function UserHistory ({_user, isOpen, onClose}) {

    const [lastPage, setLastPage] = useState(0);
    const [items, setItems] = useState([]);

    useEffect(() => {
        load();
    }, [lastPage])

    async function load () {
        try
        {
            const response = await UserClient.GetHistory(_user, { page: lastPage, pagePerCount: 50});

            const data = response.data;
            if(!data.success)
            {
                alert(data.message);
                return;
            }

            // const filtered = data.result.filter((item) => !isNoDisplayToday(item._id) && !isNoDisplayAllDay(item._id));
            // console.log(data.result);
            setItems([... items, ... data.result]);
        }
        catch(ex)
        {
            alert(ex.message + "실패");
        }
    }

    function loadNextPage() {
        setLastPage(lastPage + 1);
    }

    function getTypeString(type)
    {
        switch(type)
        {
            case UserHistoryType.Create:
                return '회원등록';

            case UserHistoryType.Update:
            case UserHistoryType.AdminUpdate:
                return '회원정보수정';
            case UserHistoryType.Delete:
                return '회원삭제';
            case UserHistoryType.TicketCreate:
                return '수강권등록';
            case UserHistoryType.TicketUpdate:
                return '수강권수정';
            case UserHistoryType.TicketDelete:
                return '수강권삭제';
            case UserHistoryType.TicketRefund:
                return '수강권환불';
            case UserHistoryType.Holding:
                return '홀딩추가';
            case UserHistoryType.HoldingCancel:
                return '홀딩취소';
            case UserHistoryType.LessonBuy:
                return '레슨추가';
            case UserHistoryType.LessonBuyCancel:
                return '레슨추가취소';
            case UserHistoryType.AddDays:
                return '기간연장';
            case UserHistoryType.AddDaysCancel:
                return '기간연장취소';
            case UserHistoryType.TransferLessons:
                return '레슨->기간전환';
            case UserHistoryType.TransferLessonsCancel:
                return '레슨->기간전환취소';
        }
    }

    function getDateString(date)
    {
        return moment(date).format('YY/MM/DD');
    }

    function getText(item)
    {
        switch(item.type)
        {
            case UserHistoryType.Create:
                return '';
                case UserHistoryType.Update:
                    return '';
                case UserHistoryType.AdminUpdate:
                    // console.log(item);
                    var ret = ``;
                    if(item.data.data.user_state != item.data.query.user_state)
                        ret += `회원상태 : ${item.data.data.user_state} > ${item.data.query.user_state},`;
                    if(item.data.data.name != item.data.query.name)
                        ret += ` 이름 : ${item.data.data.name} > ${item.data.query.name},`;
                    if(item.data.data.phone != item.data.query.phone)
                        ret += ` 휴대전화 : ${item.data.data.phone} > ${item.data.query.phone},`;
                    if(item.data.data.gender != item.data.query.gender)
                        ret += ` 성별 : ${item.data.data.gender} > ${item.data.query.gender},`;
                    if( item.data.data.birth_year != item.data.query.birth_year || 
                        item.data.data.birth_month != item.data.query.birth_month || 
                        item.data.data.birth_day != item.data.query.birth_day)
                        ret += ` 생년월일 : ${item.data.data.birth_year}-${item.data.data.birth_month}-${item.data.query.birth_day} > ${item.data.query.birth_year}-${item.data.query.birth_month}-${item.data.data.birth_day},`;

                    const funnel_before = item.data.data.funnel.map((item) => constants.funnel[item]).join(',');
                    const funnel_after = item.data.query.funnel.map((item) => constants.funnel[item]).join(',');
                    if( funnel_before != funnel_after )
                    {
                        ret += ` 등록경로 : ${funnel_before} > ${funnel_after},`;
                    }

                    if( item.data.data.special_management != item.data.query.special_management)
                    {
                        if(item.data.query.special_management)
                            ret += ` 특별관리대상 설정,`;
                        else
                            ret += ` 특별관리대상 해제,`;
                    }

                    if( item.data.data.black_user != item.data.query.black_user)
                    {
                        if(item.data.query.black_user)
                            ret += ` 블랙리스트 설정,`;
                        else
                            ret += ` 블랙리스트 해제,`;
                    }

                    if( item.data.data.about_user != item.data.query.about_user)
                    {
                        ret += `\n수강생 참고사항 : \n${item.data.data.about_user}\n ↓ \n${item.data.query.about_user}\n`;
                        ret += `---------------------------------------------------------`;
                    }
                    if( item.data.data.about_lesson != item.data.query.about_lesson)
                    {
                        ret += `\n레슨시 참고사항 : \n${item.data.data.about_lesson}\n ↓ \n${item.data.query.about_lesson}\n`;
                        ret += `---------------------------------------------------------`;
                    }


                    if( item.data.data.special_management_comment != item.data.query.special_management_comment)
                    {
                        ret += `\n특별관리대상 메모 : \n${item.data.data.special_management_comment}\n ↓ \n${item.data.query.special_management_comment}\n`;
                        ret += `---------------------------------------------------------`;
                    }

                    if( item.data.data.black_user_comment != item.data.query.black_user_comment)
                    {
                        ret += `\n블랙리스트 메모 : \n${item.data.data.black_user_comment}\n ↓ \n${item.data.query.black_user_comment}\n`;
                        ret += `---------------------------------------------------------`;
                    }

                    return ret.slice(0, -1);
                case UserHistoryType.Delete:
                    return '';
                case UserHistoryType.TicketCreate:
                    return `${item.data.name}, 수강기간 : ${getDateString(item.data.startDate)}~${getDateString(item.data.endDate)}, 레슨 횟수 : ${item.data.lesson_all} 회`;
                case UserHistoryType.TicketUpdate:
                    var ret = `${item.data.data.name}`;
                    if(item.data.query.startDate)
                        ret += ` 기간 : ${getDateString(item.data.data.startDate)}~${getDateString(item.data.data.endDate)} > ${getDateString(item.data.query.startDate)}~${getDateString(item.data.query.endDate)},`
                    if(Number(item.data.data.lesson) != Number(item.data.query.lesson))
                        ret += ` 레슨 : ${item.data.data.lesson} > ${item.data.query.lesson},`
                    if(Number(item.data.data.holding) != Number(item.data.query.holding))
                        ret += ` 홀딩 : ${item.data.data.holding} > ${item.data.query.holding},`
                    if(Number(item.data.data.discount_value) != Number(item.data.query.discount_value))
                        ret += ` 할인 : ${item.data.data.discount_value} > ${item.data.query.discount_value},`
                    if(Number(item.data.data.price) != Number(item.data.query.price))
                        ret += ` 결제 : ${item.data.data.price} > ${item.data.query.price},`
                    if(item.data.data.comment != item.data.query.comment)
                        ret += ` 메모 : ${item.data.data.comment} > ${item.data.query.comment},`
                    return ret.slice(0, -1);
                case UserHistoryType.TicketDelete:
                    return `${item.data.data.name}`;
                case UserHistoryType.TicketRefund:
                    return `${item.data.data.name}, 환불 금액 : ${item.data.query.refund} 원`;
                case UserHistoryType.Holding:
                    return `${item.data.data.name}, 기간 : ${getDateString(item.data.query.startDate)}~${getDateString(item.data.query.endDate)}, ${item.data.query.days} 일`;
                case UserHistoryType.HoldingCancel:
                    return `${item.data.data.name}, 기간 : ${getDateString(item.data.query.startDate)}~${getDateString(item.data.query.endDate)}, ${item.data.query.days} 일`;
                case UserHistoryType.LessonBuy:
                    return `${item.data.data.name}, ${item.data.query.lesson_buy} 회(${item.data.data.lesson} > ${item.data.query.lesson}), ${item.data.query.lesson_buy_price} 원`;
                case UserHistoryType.LessonBuyCancel:
                    return '';
                case UserHistoryType.AddDays:
                    return `${item.data.data.name}, ${item.data.query.days} 일, ${item.data.query.desc}`;
                case UserHistoryType.AddDaysCancel:
                    return `${item.data.data.name}, ${item.data.query.desc}, ${item.data.query.days} 일, 종료일 : ${getDateString(item.data.data.endDate)} > ${getDateString(moment(item.data.data.endDate).subtract(item.data.query.days, 'days'))}`;
                case UserHistoryType.TransferLessons:
                    return `${item.data.data.name}, ${item.data.query.count} 회(${item.data.data.lesson}>${item.data.query.lesson}), ${item.data.query.days}일 추가, 종료일: ${getDateString(item.data.query.endDate)}`;
                case UserHistoryType.TransferLessonsCancel:
                    return '';
        }
    }

    return (
        <>
            <div>
                <ReactModal className="modal-content" overlayClassName="modal-overlay" isOpen={isOpen} onRequestClose={onClose}>
                    <div class="container">
                        {/* <div className="notice-popup-button" style={{cursor:"pointer"}} onClick={onClose}> 닫기 </div> */}
                        <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title" style={{margin:"10px"}}>
                                        회원 히스토리
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="divider"/>
                        <div className="nk-block">
                            <div>
                                {
                                    items.map((item, index) => {
                                        return (
                                            <div style={{"marginBottom":"10px"}}>
                                                {/* {index + 1}.  */}
                                                <label>{moment(item.create_at).format('YY/MM/DD HH:mm')}</label>
                                                <label style={{paddingLeft:"5px"}}>{getTypeString(item.type)}</label>
                                                <span className="display-enter" style={{paddingLeft:"5px"}}>{getText(item)}</span>
                                                <label style={{paddingLeft:"5px"}}>({item.assignee})</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="divider"/>
                        <div>
                            <label onClick={loadNextPage}>더보기</label>
                        </div>
                    </div>
                    <div className="modal-close" onClick={() => {onClose();}}>닫기</div>
                </ReactModal>
            </div>
        </>
    );
}