import React, { useEffect, useRef, useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import "moment-timezone";
import UserClient from "../utils/Http/UserClient";

import "../App.css";

moment.tz.setDefault("Asia/Seoul");

export default function ExportUserList({branch, user_state, ticket_state, page, countPerPage, sortByPeriod}) {
    const [userList, setUserList] = useState([]);
    const csvLink = useRef();


    async function loadExportData (e) {
        var query = {
            user_state: user_state,
            ticket_state: ticket_state,
            page: page,
            countPerPage: countPerPage,
            sortByPeriod: sortByPeriod,
        };

        if (branch) {
            query._branch = branch._id;
        }

        try{
            const response = await UserClient.getList(query);

            if(response.data.success == false) {
                alert(response.data.message);
                return;
            }
            console.log(response.data);

            setUserList(response.data.result.list);
            csvLink.current.link.click();
        }
        catch (err) {
            alert(err.message);
        }
    }

    function render() {
        return (
            <>
                <button className="btn btn-white btn-outline-light" onClick={loadExportData}>
                    Export <em className="icon ni ni-download-cloud"></em>
                </button>
                <CSVLink
                    data={userList}
                    ref={csvLink}
                >
                </CSVLink>
            </>
        );
    }

    return render();
}
