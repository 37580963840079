import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import AdminClient from "../utils/Http/AdminClient";

class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            id: "",
            password: "",
            password2: "",
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        const sign = {
            name: this.state.name,
            id: this.state.id,
            password: this.state.password,
            password2: this.state.password2,
        };

        try{
            const res = await AdminClient.create(sign);

            if (res.data.success === true) {
                alert(res.data.message);
                this.props.history.push("/");
            } else {
                alert(res.data.message);
            }
        }
        catch(err){
            console.log(err);
            alert(err.message + "실패");
        }
    };

    render() {
        console.log(this.state);
        return (
            <div className="nk-content ">
                <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                    <div className="card card-bordered">
                        <div className="card-inner card-inner-lg">
                            <div className="nk-block-head">
                                <div className="nk-block-head-content">
                                    <h4 className="nk-block-title">회원가입</h4>
                                    <div className="nk-block-des"></div>
                                </div>
                            </div>
                            <form onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <label
                                        className="form-label"
                                        htmlFor="name"
                                    >
                                        이름
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        name="name"
                                        placeholder="이름을 입력하세요"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="id">
                                        ID
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        name="id"
                                        placeholder="아이디를 입력하세요"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label
                                        className="form-label"
                                        htmlFor="password"
                                    >
                                        비밀번호
                                    </label>
                                    <div className="form-control-wrap">
                                        {/* <a href="#" className="form-icon form-icon-right passcode-switch" data-target="password">
                                                <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                                <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                            </a> */}
                                        <input
                                            type="password"
                                            className="form-control form-control-lg"
                                            name="password"
                                            placeholder="비밀번호를 입력하세요"
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label
                                        className="form-label"
                                        htmlFor="password"
                                    >
                                        비밀번호 확인
                                    </label>
                                    <div className="form-control-wrap">
                                        {/* <a href="#" className="form-icon form-icon-right passcode-switch" data-target="password">
                                                <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                                <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                            </a> */}
                                        <input
                                            type="password"
                                            className="form-control form-control-lg"
                                            name="password2"
                                            placeholder="비밀번호를 입력하세요"
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-control-xs custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="checkbox"
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="checkbox"
                                        >
                                            <Link to="/terms">
                                                <a href="/terms">이용약관</a>
                                            </Link>
                                            <span>에 동의합니다.</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-lg btn-primary btn-block"
                                    >
                                        가입하기
                                    </button>
                                    {/* <a href="/login" className="btn btn-lg btn-primary btn-block">가입하기</a> */}
                                </div>
                            </form>
                            <div className="form-note-s2 text-center pt-4">
                                {" "}
                                계정이 이미 존재합니까?&nbsp;&nbsp;
                                <Link to="/login">
                                    <a href="/login">
                                        <strong>로그인 하기</strong>
                                    </a>
                                </Link>
                            </div>
                            <div className="text-center pt-4 pb-3">
                                <h6 className="overline-title overline-title-sap">
                                    <span>OR</span>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Signup);
