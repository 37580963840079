module.exports = Object.freeze({
  dayOfWeek: [
    "일요일",
    "월요일",
    "화요일",
    "수요일",
    "목요일",
    "금요일",
    "토요일",
  ],
  times: [
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
  ],
  halfTimes: [
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
  ],
  funnel: {
    SignBoard: "간판",
    SNS: "SNS",
    Youtube: "유튜브",
    NaverSearch: "네이버 검색",
    MapSearch: "지도 검색",
    Friends: "지인 소개",
  },
  accessibility_type: {
    none: { id: 0, desc: "" },
    home: { id: 1, desc: "집 근처" },
    work: { id: 2, desc: "회사 근처" },
    etc: { id: 3, desc: "" },
  },
  statistics_type: {
    USER_COUNT: 1,
    USER_FUNNEL: 2,
    SALES: 3,
    USER_ACCESSIBILITY: 4,
  },
  ticketType: {
    50: 1,
    30: 2,
  },
  lessonType: {
    Fifty: 1,
    Thirty: 2,
    Sixty: 3,
  },
  scheduleState: {
    Open: 1,
    Close: 2,
    Booked: 3,
  },
});
