import AxiosClient from "./AxiosClient";

const HolidayClient = {
    create: async (data) => {
        return await AxiosClient.post('holiday', data);
    },

    delete: async (data) => {
        return await AxiosClient.del('holiday/delete', data);
    },

    createByCalendar: async (data) => {
        return await AxiosClient.post('holiday/createByCalendar', data);
    },

    getList: async (data) =>{
        return await AxiosClient.get('holiday/listAll', data);
    },
    
}

export default HolidayClient;