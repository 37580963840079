import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import AdminClient from "../utils/Http/AdminClient";

class Password extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            id: "",
            email: "",
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        const passwordsearch = {
            name: this.state.name,
            id: this.state.id,
            email: this.state.email,
        };

        try{
            const res = await AdminClient.passwordSearch(passwordsearch)

            if (res.data.success === true) {
                alert(res.data.message);
                this.props.history.push(`/`);
            } else {
                alert(res.data.message);
            }
        }
        catch(err){
            console.log(err);
            alert(err.message + "실패");
        }
    };

    render() {
        return (
            <div className="nk-content ">
                <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                    <div className="card card-bordered">
                        <div className="card-inner card-inner-lg">
                            <div className="nk-block-head">
                                <div className="nk-block-head-content">
                                    <h5 className="nk-block-title">
                                        비밀번호 재설정
                                    </h5>
                                    <div className="nk-block-des">
                                        <p>
                                            암호를 잊어버린 경우 암호를 재설정
                                            메일을 보내드립니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={this.onSubmit}>
                                <div class="form-group">
                                    <div class="form-label-group">
                                        <label
                                            class="form-label"
                                            for="default-01"
                                        >
                                            Name
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        name="name"
                                        placeholder="이름을 입력하세요."
                                        required="required"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div class="form-group">
                                    <div class="form-label-group">
                                        <label
                                            class="form-label"
                                            for="default-01"
                                        >
                                            ID
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        name="id"
                                        placeholder="아이디를 입력하세요."
                                        required="required"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div class="form-group">
                                    <div class="form-label-group">
                                        <label
                                            class="form-label"
                                            for="default-01"
                                        >
                                            Email
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        name="email"
                                        placeholder="이메일을 입력하세요."
                                        required="required"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div class="form-group">
                                    <button
                                        type="submit"
                                        class="btn btn-lg bigbtn btn-block"
                                    >
                                        재설정 링크 전송
                                    </button>
                                </div>
                            </form>
                            <div className="form-note-s2 text-center pt-4">
                                <Link to="/login">
                                    <a href="/login" className="alink">
                                        로그인으로 돌아가기
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Password);
