import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import moment from "moment";
import UserClient from "../utils/Http/UserClient";
import BranchClient from "../utils/Http/BranchClient";
import TicketClient from "../utils/Http/TicketClient";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../components/AuthenticatedComponent";
import Content from "../components/Content";
import ContentHeader from "../components/ContentHeader";
import Swal from "sweetalert2";

var constants = require("../common/Constants");
class UserRegister extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    const discountTypeList = [
      // { id: 0, desc: "없음" },
      // { id: 1, desc: "%" },
      { id: 2, desc: "원" },
    ];

    this.state = {
      id: "",
      password: "",
      password2: "",
      name: "",
      email: "",
      phone: "",
      gender: "",
      recommender: "",
      recommender_id: "",
      recommender_search_list: [],
      birth_year: "",
      birth_month: "",
      birth_day: "",
      startDate: "",
      endDate: "",
      holdings: [],
      lesson_number: 0,
      lesson_number_all: 0,
      payment: 0,
      holding_date: "",
      about_user: "",
      about_lesson: "",
      _branch: "",
      branchArr: [],
      lesson_course: "",
      update_at: Date.now(),
      create_at: Date.now(),
      selectedTicketData: {
        id: "",
        startDate: "",
      },
      discountTypeList: discountTypeList,
      discount_type: 2,
      discount_value: 0,
      ticket_comment: "",
      ticketDatas: [],
      duplicate_check: false,
      duplicate: false,
      special_management: false,
      special_management_comment: "",
      accessibility_type: constants.accessibility_type.none.id,
      accessibility: "",
      skipPoint: true,
    };
  }

  componentDidMount = async () => {
    try {
      if (
        this.context.role === "최고관리자" ||
        this.context.role === "일반관리자"
      ) {
        const branchList = await BranchClient.getList();
        if (branchList.data.success == false) {
          alert(branchList.data.message);
          return;
        }

        this.setState(
          {
            _branch: branchList.data.result[0]._id,
            branchArr: branchList.data.success ? branchList.data.result : [],
          },
          () => {
            this.loadTicketData();
          }
        );
      } else {
        this.setState(
          {
            _branch: this.context.branch._id,
          },
          () => {
            this.loadTicketData();
          }
        );
      }

      // console.log(this.state.selectedTicketData);
    } catch (error) {
      this.setState({ errorMsg: "Error retreiving data" });
    }
  };

  loadTicketData = async () => {
    try {
      const response = await TicketClient.getDataList({
        _branch: this.state._branch,
      });

      this.setState({
        ticketDatas: response.data.success ? response.data.result : [],
        selectedTicketData: {
          id:
            response.data.result.length > 0 ? response.data.result[0]._id : "",
          startDate: moment(Date.now()).format("YYYY-MM-DD"),
        },
      });
    } catch (error) {
      this.setState({ errorMsg: "Error retreiving data" });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (e.target.name === "_branch") {
        this.loadTicketData();
      }
    });
  };

  onChangeCheckBox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  onChangeRecommenderId = (e) => {
    this.setState({
      recommender_id: e.target.value,
    });
  }

  // setStartDate = async(e) => {
  //     console.log(e)
  //     this.setState({startDate: e})
  // }

  // setEndDate = async(e) => {
  //     console.log(e)
  //     this.setState({endDate: e})
  // }

  setStartDate = async (e) => {
    const date = moment(e.target.value).format("YYYY-MM-DD");
    this.setState({ startDate: date });
  };

  setEndDate = async (e) => {
    const date = moment(e.target.value).format("YYYY-MM-DD");
    this.setState({ endDate: date });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    if (this.state.duplicate_check === false) {
      alert("등록 전 휴대전화 번호 중복 확인이 필요합니다.");
      return;
    }

    if (this.state.duplicate === true) {
      alert("사용 할 수 없는 휴대전화 번호입니다.");
      return;
    }

    if (
      this.state.special_management == true &&
      this.state.special_management_comment == ""
    ) {
      alert("특별 관리 대상 지정시 메모는 필수 입력되어야 합니다.");
      return;
    }

    var funnels = [];
    Object.keys(constants.funnel).forEach((key) => {
      var doc = document.getElementById(key);
      console.log(doc);
      if (doc == null) return;

      if (doc.checked) {
        funnels.push(key);
      }
    });

    if (funnels.length == 0) {
      alert("등록 경로는 최소 한 개 선택되어야 합니다.");
      return;
    }

    var accessibility = "";
    switch (this.state.accessibility_type) {
      case constants.accessibility_type.none.id:
        alert("접근성 선택은 필수입니다.");
        return;
      case constants.accessibility_type.home.id:
        accessibility = constants.accessibility_type.home.desc;
        break;
      case constants.accessibility_type.work.id:
        accessibility = constants.accessibility_type.work.desc;
        break;
      case constants.accessibility_type.etc.id:
        accessibility = this.state.accessibility;
        break;
    }

    if (accessibility === "") {
      alert("접근성 기타 선택시 값을 입력 해 주세요.");
      return;
    }

    const sign = {
      password: this.state.password,
      password2: this.state.password2,
      name: this.state.name,
      phone: this.state.phone,
      gender: this.state.gender,
      recommender: this.state.recommender_id,
      birth_year: this.state.birth_year,
      birth_month: this.state.birth_month,
      birth_day: this.state.birth_day,
      about_user: this.state.about_user,
      about_lesson: this.state.about_lesson,
      _branch: this.state._branch,
      funnel: funnels,
      accessibility: accessibility,
      special_management: this.state.special_management,
      special_management_comment: this.state.special_management_comment,
    };

    try {
      const res = await UserClient.create(sign);
      if (res.data.success) {
        if (this.state.selectedTicketData.id !== "") {
          const ticketRes = await TicketClient.create({
            _user: res.data.result._id,
            _ticketData: this.state.selectedTicketData.id,
            startDate: this.state.selectedTicketData.startDate,
            discount_type: Number(this.state.discount_type),
            discount_value:
              this.state.discount_type !== 0
                ? Number(this.state.discount_value)
                : 0,
            comment: this.state.ticket_comment,
            coupon: '',
            skipPoint: this.state.skipPoint,
          });

          if (!ticketRes.data.success) {
            alert("회원 등록 성공했으나 수강권 등록에 실패하였습니다.");
          }
        }
        alert(res.data.message);

        window.location.href = "/user/userlist";
      } else {
        alert(res.data.message);
      }
    } catch (ex) {
      console.log(ex);
      alert(ex.message + "실패");
    }
  };

  setTicketStartDate = async (e) => {
    const date = moment(e.target.value).format("YYYY-MM-DD");
    var ticketData = this.state.selectedTicketData;
    ticketData.startDate = date;
    this.setState({
      selectedTicketData: ticketData
    });
  };

  setTicketData = async (e) => {
    var ticketData = this.state.selectedTicketData;
    ticketData.id = e.target.value;
    this.setState({
      selectedTicketData: ticketData
    });
    console.log(ticketData);
  };

  setDiscountType = async (e) => {
    e.preventDefault();

    this.state.discount_type = e.target.value;
  };

  getExpectedPoint() {
    if(this.state.skipPoint) return 0;
    var ticketData = this.state.ticketDatas.find(ticketData => ticketData._id == this.state.selectedTicketData.id);
    // console.log(ticketData);
    return ticketData ? (ticketData.price * 0.03).toLocaleString("en-US") : "0";
  }

  onClickDuplicationCheck = async (e) => {
    e.preventDefault();

    try {
      const res = await UserClient.DuplicationCheck({
        phone: this.state.phone,
      });
      if (res.data.success === false) {
        window.alert(res.data.message);
        return;
      }

      this.setState({
        duplicate_check: true,
        duplicate: res.data.result.duplicate,
      });

      if (res.data.result.duplicate) {
        console.log(res.data.result.user);
        const name = res.data.result.user ? res.data.result.user.name : "";
        const branchName = res.data.result.user
          ? res.data.result.user._branch.name
          : "";
        const ticket = res.data.result.ticket
          ? `${moment(res.data.result.ticket.startDate).format(
              "YY-MM-DD"
            )} ~ ${moment(res.data.result.ticket.endDate).format("YY-MM-DD")}`
          : "";
        const lessonCount = res.data.result.ticket
          ? res.data.result.ticket.lesson
          : 0;
        var label = `이름 : ${name}<br/>
                            지점 : ${branchName}<br/>
                            수강권 : ${ticket}<br/>
                            잔여 수강 횟수 : ${lessonCount}`;
        if (res.data.result.user.special_management) {
          label += `<br />특별관리대상 : ${res.data.result.user.special_management_comment}`;
        }
        if (res.data.result.user.black_user) {
          label += `<br />블랙리스트 : ${res.data.result.user.black_user_comment}`;
        }
        Swal.fire({
          title: "휴대전화 번호 중복",
          html: label,
          icon: "warning",
        });
      }
    } catch (error) {
      window.alert(error);
    }
  };

  onClickRecommenderCheck = async (e) => {
    e.preventDefault();

    const user_state = [ "승인" ];
    var query = {
      user_state: user_state,
      ticket_state: 1,
      page: 0,
      countPerPage: -1,
      sortType: 1,
      sortOrder: 1,
      _branch: this.state._branch,
      name: this.state.recommender,
      searchType: 'name',
    };

    console.log(query);
    try {
      const response = await UserClient.getList(query);

      if (response.data.success == false) {
        alert(response.data.message);
        return;
      }
      // console.log(response.data);
      this.setState({
        recommender_search_list: response.data.result.list,
      });
    } catch (err) {
      alert(err.message);
    }
  };

  renderTicket() {
    return (
      <>
        <div className="divider"></div>
        <h5 className="card-title">수강권</h5>

        <form>
          <div className="row g-3 align-center">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" htmlFor="site-off">
                  수강권 추가
                </label>
                <span className="form-note">수강권 선택하세요</span>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col">
                  <div className="form-wrap">
                    <input
                        type="date"
                        className="form-control"
                        name="ticketStartDate"
                        onChange={this.setTicketStartDate}
                        defaultValue={this.state.selectedTicketData.startDate}
                        required="required"
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-wrap">
                    <select
                        className="form-control"
                        name="selectedTicketData"
                        onChange={this.setTicketData}
                        // value={this.state._branch ? this.state._branch.name : null}
                        defaultValue={this.state.selectedTicketData.id}
                    >
                      {this.state.ticketDatas.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.name}
                          </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3 align-center">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" htmlFor="site-off">
                  할인 정보
                </label>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col">
                  <div className="form-wrap">
                    <input
                        type="number"
                        className="form-control"
                        name="discount_value"
                        defaultValue={0}
                        required="required"
                        onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-wrap">
                    <select
                        className="form-control"
                        name="discount_type"
                        onChange={this.onChange}
                        // value={this.state._branch ? this.state._branch.name : null}
                        defaultValue={this.state.discount_type}
                        disabled={true}
                    >
                      {this.state.discountTypeList.map((item, index) => (
                          <option key={index} value={index}>
                            {item.desc}
                          </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3 align-start">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" htmlFor="site-off">
                  메모
                </label>
              </div>
            </div>
            <div className="col-lg-7">
              <textarea
                  type="text"
                  className="form-control"
                  id="ticket_comment"
                  name="ticket_comment"
                  onChange={this.onChange}
                  value={this.state.ticket_comment}
              />
            </div>
          </div>
          <div className="row g-3 align-center">
            <div className="col-lg-5">
              <div className="form-group">
                <label className="form-label" htmlFor="site-off">
                  포인트 적립
                </label>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="form-group">
                <div className="form-control-wrap">
                  <label>{this.getExpectedPoint()} 원</label>
                  <label>
                    <input
                        className="display-inline"
                        type="checkbox"
                        id="skipPoint"
                        name="skipPoint"
                        value={this.state.skipPoint}
                        checked={this.state.skipPoint}
                        onChange={this.onChangeCheckBox}
                        style={{marginLeft: "8px"}}
                    />
                    <span style={{marginLeft: "4px"}}>적립 안함</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }

  render_duplicate_message() {
    if (this.state.duplicate)
      return (
          <span className="form-note" style={{color: "red"}}>
          {" "}
            사용 할 수 없는 휴대전화 번호입니다.{" "}
        </span>
      );
    else
      return (
          <span className="form-note" style={{color: "blue"}}>
          {" "}
            사용 가능 한 번호입니다.{" "}
        </span>
      );
  }

  render_recommender_list() {
    return (

        <div className="card-inner p-0">
          <div className="table-responsive">
            <table className="table">
              <thead className="thead-light">
              <tr>
                <th></th>
                <th>회원명</th>
                <th>아이디</th>
                <th>휴대전화</th>
                <th>생년월일</th>
                <th>성별</th>
                <th>수강내역</th>
                <th>수강기간</th>
              </tr>
              </thead>
              <tbody>{this.state.recommender_search_list.map((user, index) => (
                  <tr key={index}>
                    <td scope="row">
                      <input
                          type="radio"
                          value={user._id}
                          onChange={this.onChangeRecommenderId}
                          checked={user._id == this.state.recommender_id}
                      />
                    </td>
                    <td>{user.name ? user.name : null}</td>
                    <td>{user.id ? user.id : null}</td>
                      <td>{user.phone ? user.phone : null}</td>
                      <td>
                        {user.birth_year ? user.birth_year : null}.
                        {user.birth_month ? user.birth_month : null}.
                        {user.birth_day ? user.birth_day : null}
                      </td>
                      <td>{user.gender ? user.gender : null}</td>
                      <td>
                        {user.ticket.length > 0 ? user.ticket[0].name : null}
                        {user.ticket.length > 1 ? "(+)" : ""}
                      </td>
                      <td>
                        {user.ticket.length > 0
                            ? moment(user.ticket[0].startDate).format("YYYY.MM.DD")
                            : null}
                        ~
                        {user.ticket.length > 0
                            ? moment(user.ticket[0].endDate).format("YYYY.MM.DD")
                            : null}
                      </td>
                    </tr>
                ))}</tbody>
              </table>
            </div>
          </div>
    );
  }

  render() {
    if (
        this.context.role !== "일반관리자" &&
        this.context.role !== "최고관리자" &&
        this.context.role !== "지점관리자" &&
        this.context.role !== "매니저"
    ) {
      return (
          <div>
            <Menubar/>
            <div className="nk-content">
              <div className="container-fluid">접근 권한이 없습니다.</div>
            </div>
          </div>
      );
    }

    let yearArr = [];

    const thisyear = moment().format("yyyy");

    for (var i = 0; i < 82; i++) {
      yearArr.push(thisyear - i);
    }
    const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const day = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ];

    return (
        <>
          <Menubar/>
          <Content>
            <ContentHeader
                prevlink="/user/userlist"
                prevTitle="목록보기"
                title="회원 등록하기"
                // subTitle={`${this.state.name} 회원님의 정보`}
            />
            <div className="nk-block">
              <div className="card card-bordered">
                <div className="card-inner">
                  <form onSubmit={this.onSubmit} className="gy-3">
                    <h5 className="card-title">계정</h5>
                    <div className="row g-3 align-center">
                      <div className="col-lg-5">
                        <div className="form-group">
                          <label className="form-label">이름</label>
                          <span className="form-note">회원 이름</span>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                onChange={this.onChange}
                                defaultValue={this.state.name}
                                required="required"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 align-center">
                      <div className="col-lg-5">
                        <div className="form-group">
                          <label className="form-label" htmlFor="site-off">
                            휴대전화
                          </label>
                          <span className="form-note">- 없이 입력</span>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="row">
                          <div className="col">
                            <div className="form-control-wrap">
                              <input
                                  type="text"
                                  className="form-control"
                                  name="phone"
                                  onChange={this.onChange}
                                  defaultValue={this.state.phone}
                                  required="required"
                              />
                            </div>
                        </div>
                        <button
                            className="btn btn-primary mr-1"
                            onClick={this.onClickDuplicationCheck}
                        >
                          중복확인
                        </button>
                      </div>
                      {this.state.duplicate_check
                          ? this.render_duplicate_message()
                          : null}
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" htmlFor="site-off">
                          생년월일
                        </label>
                        <span className="form-note">년 / 월 / 일</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="row">
                        <div className="col">
                          <div className="form-wrap">
                            <div className="form-text-hint mr-3">
                              <span className="overline-title">년</span>
                            </div>
                            <select
                                className="form-control"
                                name="birth_year"
                                onChange={this.onChange}
                                defaultValue={this.state.birth_year}
                                required="required"
                            >
                              <option value="" disabled>
                                년
                              </option>
                              {yearArr.map((year, index) => (
                                  <option
                                      key={index}
                                      value={year}
                                      name="birth_year"
                                      onChange={this.onChange}
                                  >
                                    {year}
                                  </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-wrap">
                            <div className="form-text-hint mr-3">
                              <span className="overline-title">월</span>
                            </div>
                            <select
                                className="form-control"
                                name="birth_month"
                                onChange={this.onChange}
                                defaultValue={this.state.birth_month}
                                required="required"
                            >
                              <option value="" disabled>
                                월
                              </option>
                              {month.map((month, index) => (
                                  <option
                                      key={index}
                                      value={month}
                                      name="birth_month"
                                      onChange={this.onChange}
                                  >
                                    {month}
                                  </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-wrap">
                            <div className="form-text-hint mr-3">
                              <span className="overline-title">일</span>
                            </div>
                            <select
                                className="form-control"
                                name="birth_day"
                                onChange={this.onChange}
                                defaultValue={this.state.birth_day}
                                required="required"
                            >
                              <option value="" disabled>
                                일
                              </option>
                              {day.map((day, index) => (
                                  <option
                                      key={index}
                                      value={day}
                                      name="birth_day"
                                      onChange={this.onChange}
                                  >
                                    {day}
                                  </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" htmlFor="site-off">
                          성별
                        </label>
                        <span className="form-note">남/여</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <select
                              className="form-control"
                              name="gender"
                              onChange={this.onChange}
                              defaultValue={this.state.gender}
                              required="required"
                          >
                            <option value="" disabled>
                              선택
                            </option>
                            <option value="여성" onChange={this.onChange}>
                              여성
                            </option>
                            <option value="남성" onChange={this.onChange}>
                              남성
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" htmlFor="site-off">
                          추천인
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="row">
                        <div className="col">
                          <div className="form-control-wrap">
                            <input
                                type="text"
                                className="form-control"
                                name="recommender"
                                onChange={this.onChange}
                                defaultValue={this.state.recommender}
                            />
                          </div>
                        </div>
                        <button
                            className="btn btn-primary mr-1"
                            onClick={this.onClickRecommenderCheck}
                        >
                          확인
                        </button>
                      </div>
                      <div>
                        {this.render_recommender_list()}
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                  <h5 className="card-title">수강정보</h5>
                  {this.context.role === "최고관리자" ||
                  this.context.role === "일반관리자" ? (
                      <div className="row g-3 align-center">
                        <div className="col-lg-5">
                          <div className="form-group">
                            <label className="form-label" htmlFor="site-off">
                              지점
                            </label>
                            <span className="form-note">
                            등록 지점을 선택하세요
                          </span>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <select
                                  className="form-control"
                                  name="_branch"
                                  onChange={this.onChange}
                                  defaultValue={this.state._branch}
                                  required="required"
                              >
                                <option value="" disabled>
                                  선택
                                </option>
                                {/* <option value={this.state._branch ? this.state._branch : null} disabled>{this.state._branch ? this.state._branch.name : null}</option> */}
                                {this.state.branchArr.map((item, index) => (
                                    <option
                                        key={index}
                                        value={item._id}
                                        selected={
                                          this.state._branch == item._id
                                              ? true
                                              : false
                                        }
                                    >
                                      {item.name}
                                    </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                  ) : null}
                  <div className="row g-3 align-start">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" htmlFor="site-off">
                          수강생 참고사항
                        </label>
                        <span className="form-note">직업</span>
                        <span className="form-note">주소</span>
                        <span className="form-note">성격, 커뮤니티 관심도</span>
                        <span className="form-note">참고사항</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <textarea
                          type="text"
                          className="form-control"
                          id="about_user"
                          name="about_user"
                          onChange={this.onChange}
                          defaultValue={this.state.about_user}
                      />
                    </div>
                  </div>
                  <div className="row g-3 align-start">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" htmlFor="site-off">
                          레슨시 참고사항
                        </label>
                        <span className="form-note">좋아하는 곡, 장르</span>
                        <span className="form-note">원하는 곡</span>
                        <span className="form-note">최종목표</span>
                        <span className="form-note">마지막으로 배운 시기</span>
                        <span className="form-note">
                          악보를 읽을 수 있다/없다
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <textarea
                          type="text"
                          className="form-control"
                          id="about_lesson"
                          name="about_lesson"
                          onChange={this.onChange}
                          defaultValue={this.state.about_lesson}
                      />
                    </div>
                  </div>
                  <div className="row g-3 align-start">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" htmlFor="site-off">
                          등록 경로
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      {Object.keys(constants.funnel).map((key) => (
                          <label>
                            <input
                                className="myCheckbox"
                                type="checkbox"
                                id={key}
                            />
                            <span className="form-label" for="site-off">
                            {" "}
                              &nbsp;{constants.funnel[key]}&nbsp;&nbsp;&nbsp;{" "}
                          </span>
                          </label>
                      ))}
                    </div>
                  </div>
                  <div className="row g-3 align-start">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" htmlFor="site-off">
                          접근성
                        </label>
                        <span className="form-note">
                          기타 선택 시 값 입력 필수
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <label style={{"margin-right": "10px"}}>
                        <input
                            type="radio"
                            // value={item._id}
                            checked={
                                this.state.accessibility_type ===
                                constants.accessibility_type.home.id
                            }
                            onChange={() => {
                              this.setState({
                                accessibility_type:
                                constants.accessibility_type.home.id,
                              });
                            }}
                            style={{"margin-right": "5px"}}
                        />

                        <span>집 근처</span>
                      </label>
                      <label style={{"margin-right": "10px"}}>
                        <input
                            type="radio"
                            // value={item._id}
                            checked={
                                this.state.accessibility_type ===
                                constants.accessibility_type.work.id
                            }
                            onChange={() => {
                              this.setState({
                                accessibility_type:
                                constants.accessibility_type.work.id,
                              });
                            }}
                            style={{"margin-right": "5px"}}
                        />

                        <span>회사 근처</span>
                      </label>
                      <label style={{"margin-right": "10px"}}>
                        <input
                            type="radio"
                            // value={item._id}
                            checked={
                                this.state.accessibility_type ===
                                constants.accessibility_type.etc.id
                            }
                            onChange={() => {
                              this.setState({
                                accessibility_type:
                                constants.accessibility_type.etc.id,
                              });
                            }}
                            style={{"margin-right": "5px"}}
                        />

                        <input
                            type="text"
                            name="accessibility"
                            onChange={this.onChange}
                            value={this.state.accessibility}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-off">
                          특별 관리 대상
                        </label>
                        <span className="form-note">체크시 메모 필수</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <textarea
                              type="text"
                              className="form-control"
                              id="special_management_comment"
                              name="special_management_comment"
                              onChange={this.onChange}
                              value={this.state.special_management_comment}
                          />
                          <input
                              className="display-inline"
                              type="checkbox"
                              name="special_management"
                              value={this.state.special_management}
                              checked={this.state.special_management}
                              onChange={this.onChangeCheckBox}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.renderTicket()}
                  <div className="row g-3">
                    <div className="col-lg-7 offset-lg-5">
                      <div className="form-group mt-2">
                        {this.context.role !== "강사" ? (
                            <button
                                type="submit"
                                className="btn btn-lg btn-primary mr-1"
                            >
                              저장
                            </button>
                        ) : null}
                        <Link
                            to={
                              this.context.role !== "강사"
                                  ? "/user/userlist"
                                  : "/book/bookmanagement"
                            }
                        >
                          <a
                              href={
                                this.context.role !== "강사"
                                    ? "/user/userlist"
                                    : "/book/bookmanagement"
                              }
                              className="btn btn-lg btn-secondary"
                          >
                            취소
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Content>
      </>
    );
  }
}

export default withRouter(UserRegister);
