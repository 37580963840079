import AxiosClient from "./AxiosClient";

const ReceiptClient = {

    refresh: async (id, data) =>{
        return await AxiosClient.post(`receipt/refresh/${id}`, data);
    },

}

export default ReceiptClient;