import AxiosClient from "./AxiosClient";

const BookClient = {
    getBooks: async (data) => {
        return await AxiosClient.get('book/list', data);
    },

    getListAll: async (data) => {
        return await AxiosClient.get('book/listall', data);
    },

    getMonth: async (data) =>{
        return await AxiosClient.get(`book/month${data}`);
    },

    getTeacherBookList: async(data) => {
        return await AxiosClient.get('book/teacher', data);
    },

    getItems: async(data) => {
        return await AxiosClient.get('book/item', data);
    },

    getTeacherLessonCount: async(data) => {
        return await AxiosClient.get('book/teacherLessonCount', data);
    },

    getTeacherLessonList: async(data) => {
        return await AxiosClient.get(`book/teacherlessonlist${data}`);
    },
    
    getTakeLessons: async(data) => {
        return await AxiosClient.get('book/takelessons', data);
    },

    createBookAdmin: async(data) => {
        return await AxiosClient.post('book/admincreate', data);
    },

    delete: async(data) => {
        return await AxiosClient.del('book/delete', data);
    },

    update: async(bookId, data) => {
        return await AxiosClient.patch(`book/update/${bookId}`, data);
    },

    updateTime: async(data) => {
        return await AxiosClient.patch('book/updateTime', data);
    },

    updateLessonNote: async(data) => {
        return await AxiosClient.patch('book/updateLessonNote', data);
    },

    lessonTransfer: async(data) => {
        return await AxiosClient.patch('book/lessontransfer', data);
    },
}

export default BookClient;