import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import AdminClient from "../utils/Http/AdminClient"

class PasswordReset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // id: '',
            // password: '',
            password1: "",
            password2: "",
        };
    }

    componentDidMount = async () => {
        try {
            const response = await AdminClient.getAdmin(this.props.match.params.id);
            this.setState({
                id: response.data.result.id,
                // password: response.data.result.password,
            });
        } catch (err) {
            console.log(err);
        }
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        const reset = {
            id: this.state.id,
            // password: this.state.password,
            password1: this.state.password1,
            password2: this.state.password2,
        };

        try{
            const res = await AdminClient.reset(this.props.match.params.id, reset);

            if (res.data.success === true) {
                alert(res.data.message);
                this.props.history.push(`/`);
            } else {
                alert(res.data.message);
            }
        }
        catch(err){
            console.log(err);
            alert(err.message + "실패");
        }
    };

    render() {
        return (
            <div class="nk-content ">
                <div class="nk-block nk-block-middle nk-auth-body  wide-xs">
                    <div class="card card-bordered">
                        <div class="card-inner card-inner-lg">
                            <div class="nk-block-head">
                                <div class="nk-block-head-content">
                                    <h5 class="nk-block-title">
                                        비밀번호 재설정
                                    </h5>
                                    <div class="nk-block-des">
                                        <p>
                                            암호를 잊어버린 경우 암호를 재설정
                                            메일을 보내드립니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={this.onSubmit}>
                                {/* <input type="text" class="form-control form-control-lg" name="id" value={this.state.id} required="required" onChange={this.onChange}/> */}
                                {/* <input type="text" class="form-control form-control-lg" name="password" placeholder="현재 비밀번호를 입력하세요." value={this.state.password} required="required" onChange={this.onChange}/> */}
                                <div class="form-group">
                                    <div class="form-label-group">
                                        <label
                                            class="form-label"
                                            for="default-01"
                                        >
                                            New Password
                                        </label>
                                    </div>
                                    <input
                                        type="password"
                                        class="form-control form-control-lg"
                                        name="password1"
                                        placeholder="새 비밀번호를 입력하세요."
                                        required="required"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div class="form-group">
                                    <div class="form-label-group">
                                        <label
                                            class="form-label"
                                            for="default-01"
                                        >
                                            New Password Check
                                        </label>
                                    </div>
                                    <input
                                        type="password"
                                        class="form-control form-control-lg"
                                        name="password2"
                                        placeholder="새 비밀번호를 확인합니다."
                                        required="required"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div class="form-group">
                                    <button
                                        type="submit"
                                        class="btn btn-lg bigbtn btn-block"
                                    >
                                        비밀번호 바꾸기
                                    </button>
                                </div>
                            </form>
                            <div class="form-note-s2 text-center pt-4">
                                <Link to="/login">
                                    <a href="/login" className="alink">
                                        로그인으로 돌아가기
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PasswordReset);
