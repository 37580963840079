import React, { Component } from "react";
import { Link } from "react-router-dom";

export class ContentHeader extends Component {
    render() {
        return (
            <div class="nk-block-head nk-block-head-lg wide-sm">
                <div class="nk-block-head-content">
                    {this.props.prevlink ? (
                        <div class="nk-block-head-sub">
                            <Link to={this.props.prevlink}>
                                <em class="icon ni ni-arrow-left"></em>
                                <span>{this.props.prevTitle}</span>
                            </Link>
                        </div>
                    ) : null}
                    <h3 class="nk-block-title page-title">
                        {this.props.title}
                    </h3>
                    <div class="nk-block-des">
                        <p class="lead">{this.props.subTitle}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContentHeader;
