import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Content from "../components/Content";
import Menubar from "../components/Menubar";
import ScheduleClient from "../utils/Http/ScheduleClient";

var constants = require("../common/Constants");

class TeacherSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectTime: [],
      teacherSchedule: [],
      dayOfWeek: [
        "일요일",
        "월요일",
        "화요일",
        "수요일",
        "목요일",
        "금요일",
        "토요일",
      ],
      allowHalfTimes: [false, false, false, false, false, false, false],
    };
  }

  selectTime = (dow, time) => {
    console.log(dow, time);
  };

  onClick = (e) => {
    console.log(e.target.dataset.checked);
    console.log(e.target.dataset);
    if (e.target.dataset.checked === "true") {
      this.setState((state) => ({
        teacherSchedule: state.teacherSchedule.filter(
          (item) =>
            item.dow !== e.target.dataset.dow * 1 ||
            item.time !== e.target.dataset.time
        ),
      }));
    } else {
      this.setState((state) => ({
        teacherSchedule: [
          ...state.teacherSchedule,
          {
            dow: e.target.dataset.dow * 1,
            time: e.target.dataset.time,
          },
        ],
      }));
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      const resultRes = await ScheduleClient.update(
        this.props.match.params.id,
        {
          unable_schedule: this.state.teacherSchedule,
          allowHalfTimes: this.state.allowHalfTimes,
        }
      );

      if (resultRes.data.success) {
        alert("업데이트를 완료하였습니다.");
        window.location.reload();
      } else {
        alert("스케쥴 업데이트를 실패하였습니다.");
      }
    } catch (error) {
      alert("업데이트를 실패하였습니다.");
    }
  };

  allRowChange = (e) => {
    console.log(e.target.checked);
    console.log(e.target.dataset.dow);
    const dow = e.target.dataset.dow;
    if (e.target.checked) {
      let ts = this.state.teacherSchedule.filter(
        (item) => item.dow !== e.target.dataset.dow * 1
      );
      console.log(ts);
      let times = [];
      if (this.state.allowHalfTimes[dow]) times = constants.halfTimes;
      else times = constants.times;

      let dowTs = times.map((item) => ({
        dow: e.target.dataset.dow * 1,
        time: item,
      }));
      console.log(dowTs);
      const resultTs = [...ts, ...dowTs];
      console.log(resultTs);
      this.setState({
        teacherSchedule: resultTs,
      });
    } else {
      this.setState((state) => ({
        teacherSchedule: state.teacherSchedule.filter(
          (item) => item.dow !== e.target.dataset.dow * 1
        ),
      }));
    }
  };

  onChangeHalfTime(e, index) {
    this.state.allowHalfTimes[index] = e.target.checked;
    this.forceUpdate();
  }

  componentDidMount = async () => {
    try {
      const dataResult = await ScheduleClient.getUnableSchedule(
        this.props.match.params.id
      );
      // console.log(dataResult.data.result);
      if (dataResult.data.success) {
        this.setState({
          teacherSchedule: dataResult.data.result.unable_schedule,
          allowHalfTimes: dataResult.data.result.allowHalfTimes
            ? dataResult.data.result.allowHalfTimes
            : this.state.allowHalfTimes,
        });
      } else {
        alert("조회된 데이터가 없습니다.");
      }
    } catch (error) {}
  };

  render() {
    return (
      <>
        <Menubar />
        <Content>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">강사 스케쥴 관리</h3>
                {/* <div className="nk-block-des text-soft">
                                <p>{this.state.users.length}건의 데이터 조회</p>
                            </div> */}
              </div>
            </div>
          </div>

          <div className="nk-block">
            <div class="btn-group mb-2" aria-label="Basic example">
              <Link
                to={`/teacher/teacherprofile/${this.props.match.params.id}`}
              >
                <a
                  href={`/teacher/teacherprofile/${this.props.match.params.id}`}
                  className="btn btn-outline-primary"
                >
                  <p className="linkbar">프로필</p>
                </a>
              </Link>
              <Link
                to={`/teacher/teacherschedule/${this.props.match.params.id}`}
              >
                <a
                  href={`/teacher/teacherschedule/${this.props.match.params.id}`}
                  className="btn btn-outline-primary"
                >
                  <p className="nolinkbar">스케쥴 관리</p>
                </a>
              </Link>
              <Link
                to={`/teacher/teacherbookstatus/${this.props.match.params.id}`}
              >
                <a
                  href={`/teacher/teacherbookstatus/${this.props.match.params.id}`}
                  className="btn btn-outline-primary"
                >
                  <p className="nolinkbar">예약 현황</p>
                </a>
              </Link>
              <Link
                to={`/admin/teacherlessonnote/${this.props.match.params.id}`}
              >
                <a
                  href={`/admin/teacherlessonnote/${this.props.match.params.id}`}
                  className="btn btn-outline-primary"
                >
                  <p className="nolinkbar">레슨 노트</p>
                </a>
              </Link>

              <Link
                to={`/teacher/teacherlessontransfer/${this.props.match.params.id}`}
              >
                <a
                  href={`/teacher/teacherlessontransfer/${this.props.match.params.id}`}
                  className="btn btn-outline-primary"
                >
                  <p className="nolinkbar">레슨 이전</p>
                </a>
              </Link>
            </div>

            <div className="card card-bordered card-stretch">
              <div class="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>요일</th>
                      <th>레슨가능시간</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.dayOfWeek.map((item, index) => {
                      let times = [];
                      if (this.state.allowHalfTimes[index])
                        times = constants.halfTimes;
                      else times = constants.times;
                      return (
                        <tr key={index}>
                          <th>{item}</th>
                          <td>
                            {times.map((timeItem, timeIndex) => {
                              let checkStyle = "table_col btn btn-info mr-1";
                              let checked = false;
                              this.state.teacherSchedule.forEach(
                                (ts, tsIndex) => {
                                  if (
                                    ts.dow === index &&
                                    ts.time === timeItem
                                  ) {
                                    checkStyle =
                                      "table_col btn btn-secondary mr-1";
                                    checked = true;
                                  }
                                }
                              );
                              // console.log(item, timeItem, checked);

                              return (
                                <button
                                  key={timeIndex}
                                  className={checkStyle}
                                  data-checked={checked}
                                  data-dow={index}
                                  data-time={timeItem}
                                  onClick={this.onClick}
                                >
                                  {timeItem}
                                </button>
                              );
                            })}
                            <div className="mt-2">
                              <input
                                type="checkbox"
                                onChange={this.allRowChange}
                                data-dow={index}
                              />
                              전체선택
                              <input
                                type="checkbox"
                                className="ml-2"
                                checked={this.state.allowHalfTimes[index]}
                                onChange={(e) =>
                                  this.onChangeHalfTime(e, index)
                                }
                                data-dow={index}
                              />
                              30분 레슨
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="card-inner text-center">
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={this.onSubmit}
                >
                  저장
                </button>
              </div>
            </div>
          </div>
        </Content>
      </>
    );
  }
}

export default withRouter(TeacherSchedule);
