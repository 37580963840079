import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import Content from "../components/Content";
import BranchClient from "../utils/Http/BranchClient";
import SuggestionClient from "../utils/Http/SuggestionClient";
import moment from "moment";
import "moment-timezone";
moment.tz.setDefault("Asia/Seoul");

class BranchList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      content: "",
    };
  }
  componentDidMount = async () => {};

  onClickSubmit = async (e) => {
    try {
      const content = document.getElementById("content").value;
      if (!content) {
        alert("내용을 입력해주세요.");
        return;
      }

      var response = await SuggestionClient.create(this.context._id, content);
      if (response.data.success == false) {
        alert(response.data.message);
        return;
      }

      alert("작성 완료되었습니다.");
      window.location.reload();
    } catch (err) {
      alert(err);
    }
  };

  render() {
    return (
      <>
        <Menubar />
        <Content>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">건의하기</h3>
              </div>
            </div>
          </div>

          <div className="nk-block">
            <div className="card card-bordered">
              <div className="card-inner">
                <div className="row g-3 align-start">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="site-off">
                        내용
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <textarea
                      type="text"
                      className="form-control"
                      id="content"
                      name="content"
                      rows="10"
                    />
                  </div>
                </div>
                <div className="row g-3">
                  <div className="col-lg-7 offset-lg-5">
                    <div className="form-group mt-2">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary mr-1"
                        onClick={this.onClickSubmit}
                      >
                        작성완료
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </>
    );
  }
}

export default withRouter(BranchList);
