import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import PropTypes from "prop-types";
import queryString from "query-string";
import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Content from "../components/Content";
import BookClient from "../utils/Http/BookClient";
import { CaretUp, CaretDown } from "react-bootstrap-icons";
import Constants from "../common/Constants";

export class LessonNote extends Component {
  static propTypes = {};

  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      books: [],
      start_at: "",
      end_at: "",
      extend_comments: {},
      extend_teacherComments: {},
    };
  }

  componentDidMount = async () => {
    if (
      this.props._teacher &&
      this.props.start_at !== "" &&
      this.props.end_at !== ""
    ) {
      await this.getBooks(
        this.props._teacher,
        this.props.start_at,
        this.props.end_at
      );
    } else if (this.props._user) {
      await this.getUserLessons(
        this.props._user,
        this.props.start_at,
        this.props.end_at
      );
    }
  };

  componentDidUpdate = async () => {
    if (
      this.props._teacher &&
      this.props.start_at != this.state.start_at &&
      this.props.end_at != this.state.end_at
    ) {
      await this.getBooks(
        this.props._teacher,
        this.props.start_at,
        this.props.end_at
      );
    }
  };

  getUserLessons = async (_user, offset, limit) => {
    const books = await BookClient.getTakeLessons({ _user: _user });
    if (books.data.success) {
      this.setState({
        books: books.data.result,
      });
    } else {
      alert(books.data.message);
    }
  };

  getBooks = async (_teacher, start_at, end_at) => {
    const books = await BookClient.getTeacherBookList({
      _teacher: _teacher,
      start_at: start_at,
      end_at: end_at,
    });
    // console.log(books);
    const books_sort = books.data.result.sort((a, b) => {
      if (a.lesson_at == b.lesson_at) {
        if (a.lesson_time > b.lesson_time) {
          return 1;
        } else return -1;
      }

      if (a.lesson_at > b.lesson_at) {
        return 1;
      } else return -1;
    });

    if (books.data.success) {
      this.setState({
        books: books_sort,
        start_at: start_at,
        end_at: end_at,
      });
    } else {
      alert(books.data.message);
    }
  };

  onClickCommentCaret = (book_id) => {
    if (this.state.extend_comments[book_id] === undefined) {
      this.state.extend_comments[book_id] = false;
    }

    this.state.extend_comments[book_id] = !this.state.extend_comments[book_id];
    this.setState({
      extend_comments: this.state.extend_comments,
    });
  };

  onClickTeacherCommentCaret = (book_id) => {
    if (this.state.extend_teacherComments[book_id] === undefined) {
      this.state.extend_teacherComments[book_id] = false;
    }

    this.state.extend_teacherComments[book_id] =
      !this.state.extend_teacherComments[book_id];
    this.setState({
      extend_teacherComments: this.state.extend_teacherComments,
    });
  };

  onClickUpdate = async (id) => {
    try {
      const comment = document.getElementById("comment_" + id);
      const teacherComment = document.getElementById("teacherComment_" + id);
      if (!comment || !teacherComment) {
        alert("레슨 정보를 찾을 수 없습니다");
      }

      if (comment.value === "" && teacherComment.value === "") {
        alert("연습해보기 / 강사메모란에 내용을 입력해주세요.");
        return;
      }

      const res = await BookClient.updateLessonNote({
        _book: id,
        comment: comment.value,
        teacherComment: teacherComment.value,
      });
      if (res.data.success) {
        alert("업데이트 성공");
      } else {
        alert(res.data.message);
      }
    } catch (err) {
      alert(err);
    }
  };

  render_book(book) {
    if (book.status !== "apply") return null;
    return (
      <div className="nk-block">
        <div className="card card-bordered">
          <div class="container">
            <div className="row g-1">
              <div className="col">
                <h5 style={{ display: "inline" }} className="card-title inline">
                  {" "}
                  {book.lesson_at
                    ? moment(book.lesson_at).format("YYYY-MM-DD")
                    : null}{" "}
                  {book.lesson_time ? book.lesson_time : null}{" "}
                  {Constants.dayOfWeek[moment(book.lesson_at).day()]}
                </h5>
                <h5
                  style={{ display: "inline" }}
                  onClick={(e) =>
                    (window.location.href = `user/userdetail/${book.user._id}?render_state=3`)
                  }
                >
                  {book.user ? book.user.name : null}{" "}
                </h5>
                {this.props._user ? (
                  <h5 style={{ display: "inline" }}>
                    {book._teacher ? book._teacher.name : book.teacher_name}{" "}
                  </h5>
                ) : null}
              </div>
              <div>
                {this.context.role === "강사" &&
                this.context._id === this.props._teacher ? (
                  <button
                    onClick={(e) => this.onClickUpdate(book._id)}
                    className="btn btn-primary mr-1 button-height"
                  >
                    저장
                  </button>
                ) : null}
              </div>
            </div>
            <div className="row g-1">
              <div className="col-3">
                <div className="form-group">
                  <label className="form-label">연습해보기</label>
                </div>
              </div>
              <div className="col">
                <textarea
                  className="lessonnote"
                  id={"comment_" + book._id}
                  key={book._id}
                  defaultValue={book.comment}
                  placeholder="수강생전달내용"
                  rows={this.state.extend_comments[book._id] === true ? 5 : 1}
                />
              </div>
              <div
                className="col-1"
                onClick={() => this.onClickCommentCaret(book._id)}
              >
                {this.state.extend_comments[book._id] === true ? (
                  <CaretUp className="extend-icon" />
                ) : (
                  <CaretDown className="extend-icon" />
                )}
              </div>
            </div>
            <div className="row g-1">
              <div className="col-3">
                <div className="form-group">
                  <label className="form-label">강사메모란</label>
                </div>
              </div>
              <div className="col">
                <textarea
                  className="lessonnote"
                  id={"teacherComment_" + book._id}
                  key={book._id}
                  defaultValue={book.teacherComment}
                  placeholder="비공개내용"
                  rows={
                    this.state.extend_teacherComments[book._id] === true ? 5 : 1
                  }
                />
              </div>
              <div
                className="col-1"
                onClick={() => this.onClickTeacherCommentCaret(book._id)}
              >
                {this.state.extend_teacherComments[book._id] === true ? (
                  <CaretUp className="extend-icon" />
                ) : (
                  <CaretDown className="extend-icon" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { books } = this.state;
    // console.log(this.state.teacher);
    return <>{books.map((book, index) => this.render_book(book))}</>;
  }
}

export default LessonNote;
