import AxiosClient from "./AxiosClient";

const SubjectClient = {
  create: async (data) => {
    return await AxiosClient.post(`subject/`, data);
  },
  getList: async (data) => {
    return await AxiosClient.get(`subject/list`, data);
  },
  update: async (id, data) => {
    return await AxiosClient.patch(`subject/update/${id}`, data);
  },
  delete: async (id) => {
    return await AxiosClient.patch(`subject/delete/${id}`, null);
  },
};

export default SubjectClient;
