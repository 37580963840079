import React, { Component } from "react";
// import '../styles/Calendar.scss';
import moment from "moment";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../components/AuthenticatedComponent";
import BookClient from "../utils/Http/BookClient";

class Calendar extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            startDate: "",
            endDate: "",
            books: [],
            today: "",
            viewMonth: "",
        };
    }

    generate(date, data) {
        const today = moment(date);
        const startWeek = today.clone().startOf("month").week();
        const endWeek =
            today.clone().endOf("month").week() === 1
                ? 53
                : today.clone().endOf("month").week();
        let calendar = [];
        for (let week = startWeek; week <= endWeek; week++) {
            calendar.push(
                <tbody key={week}>
                    <tr className="calendar-row">
                        {Array(7)
                            .fill(0)
                            .map((n, i) => {
                                let current = today
                                    .clone()
                                    .week(week)
                                    .startOf("week")
                                    .add(n + i, "day");
                                // let isSelected = today.format('YYYYMMDD') === current.format('YYYYMMDD') ? 'selected' : '';
                                let isOffset =
                                    current.clone().format("MM") ===
                                    today.format("MM")
                                        ? false
                                        : true;
                                let isCurrent =
                                    current.clone().format("YYYY-MM-DD") ===
                                    today.clone().format("YYYY-MM-DD")
                                        ? true
                                        : false;
                                const fullDate = current
                                    .clone()
                                    .format("YYYY-MM-DD");
                                const nextDate = current.clone().add(1, "date");
                                let countOk = 0;
                                let countCancel = 0;
                                data.forEach((item) => {
                                    if (
                                        fullDate ===
                                        moment(item.lesson_at).format(
                                            "YYYY-MM-DD"
                                        )
                                    ) {
                                        // console.log(
                                        //     moment(item.lesson_at).format(
                                        //         "YYYY-MM-DD"
                                        //     )
                                        // );

                                        if (item.status === "apply") {
                                            ++countOk;
                                        } else {
                                            ++countCancel;
                                        }
                                    }
                                });
                                // console.log(countOk, countCancel);

                                let queryString = `booklist?gte=${fullDate}&lte=${nextDate}`;

                                if (this.context.branch) {
                                    queryString += `&_branch=${this.context.branch._id}`;
                                }

                                return (
                                    <td
                                        className={`box ${
                                            isOffset ? "calendar-offset" : ""
                                        } ${
                                            isCurrent ? "calendar-current" : ""
                                        }`}
                                        key={i}
                                        onClick={() =>
                                            this.props.history.push(
                                                `${queryString}`
                                            )
                                        }
                                    >
                                        <span
                                            className={`calendar-text ${
                                                isOffset ? "offset" : ""
                                            }`}
                                        >
                                            {current.clone().format("D")}
                                        </span>
                                        <div className="mb-1">
                                            <span className="badge badge-md badge-dim badge-primary w-100">
                                                {" "}
                                                예약:{" "}
                                                <span className="bookcount">
                                                    {countOk}
                                                </span>{" "}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="badge badge-md badge-dim badge-danger w-100">
                                                {" "}
                                                취소:{" "}
                                                <span className="bookcount">
                                                    {countCancel}
                                                </span>{" "}
                                            </span>
                                        </div>
                                    </td>
                                );
                            })}
                    </tr>
                </tbody>
            );
        }
        return calendar;
    }

    getMonthDuration = (month) => {
        var start = moment(month)
            .startOf("month")
            .startOf("week")
            .format("YYYY-MM-DD");
        var end = moment(month)
            .endOf("month")
            .endOf("week")
            .format("YYYY-MM-DD");
        return {
            start,
            end,
        };
    };

    getBooks = async (start, end) => {
        let queryString = `?gte=${start}&lte=${end}`;

        if (this.context.branch) {
            queryString += `&_branch=${this.context.branch._id}`;
        }

        const getItems = await BookClient.getMonth(queryString);
        if (getItems.data.success) {
            return getItems.data.result;
        } else {
            alert("데이터 조회 실패");
            return false;
        }
    };

    componentDidMount = async () => {
        const duration = this.getMonthDuration(moment().format("YYYY-MM-DD"));
        const getItems = await this.getBooks(duration.start, duration.end);
        // console.log(getItems);
        if (!getItems) {
            alert("데이터 조회 실패");
        } else {
            this.setState({
                startDate: duration.start,
                endDate: duration.end,
                books: getItems,
                today: moment().format("YYYY-MM-DD"),
                viewMonth: moment().format("YYYY-MM-DD"),
            });
        }
    };

    onChangePreMonth = async (date) => {
        const preMonth = moment(date).subtract(1, "month");
        const firstDate = preMonth.startOf("Month").format("YYYY-MM-DD");
        const duration = this.getMonthDuration(firstDate);
        const getItems = await this.getBooks(duration.start, duration.end);
        this.setState({
            startDate: duration.start,
            endDate: duration.end,
            books: getItems,
            viewMonth: firstDate,
        });
    };

    onChangeNextMonth = async (date) => {
        const nextMonth = moment(date).add(1, "month");
        const firstDate = nextMonth.startOf("Month").format("YYYY-MM-DD");
        const duration = this.getMonthDuration(firstDate);
        const getItems = await this.getBooks(duration.start, duration.end);

        this.setState({
            startDate: duration.start,
            endDate: duration.end,
            books: getItems,
            viewMonth: firstDate,
        });
    };

    render() {
        return (
            <div
                id="calendar"
                className="nk-calendar fc fc-media-screen fc-direction-ltr fc-theme-bootstrap"
            >
                {/* <div className="fc-header-toolbar fc-toolbar fc-toolbar-ltr"> */}
                <div className="text-center">
                    <div className="fc-toolbar-chunk mb-3">
                        <div className="btn-group">
                            <button
                                className="fc-prev-button btn"
                                onClick={() =>
                                    this.onChangePreMonth(this.state.viewMonth)
                                }
                            >
                                <span className="fa fa-chevron-left"></span>
                            </button>
                            <h2 className="caletitle">
                                {moment(this.state.viewMonth).format("YYYY-MM")}
                            </h2>
                            {/* <h2 className="fc-toolbar-title">{moment(this.state.viewMonth).format('YYYY-MM')}</h2> */}
                            <button
                                className="fc-next-button btn"
                                onClick={() =>
                                    this.onChangeNextMonth(this.state.viewMonth)
                                }
                            >
                                <span className="fa fa-chevron-right"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table className="table">
                        <thead>
                            <tr className="calendar-row">
                                <th scope="row" className="fc-day-sun">
                                    <div className="fc-scrollgrid-sync-inner">
                                        <p className="fc-col-header-cell-cushion ">
                                            Sun
                                        </p>
                                    </div>
                                </th>
                                <th scope="row" className="fc-day-mon">
                                    <div className="fc-scrollgrid-sync-inner">
                                        <p className="fc-col-header-cell-cushion ">
                                            Mon
                                        </p>
                                    </div>
                                </th>
                                <th scope="row" className="fc-day-tue">
                                    <div className="fc-scrollgrid-sync-inner">
                                        <p className="fc-col-header-cell-cushion ">
                                            Tue
                                        </p>
                                    </div>
                                </th>
                                <th scope="row" className="fc-day-wed">
                                    <div className="fc-scrollgrid-sync-inner">
                                        <p className="fc-col-header-cell-cushion ">
                                            Wed
                                        </p>
                                    </div>
                                </th>
                                <th scope="row" className="fc-day-thu">
                                    <div className="fc-scrollgrid-sync-inner">
                                        <p className="fc-col-header-cell-cushion ">
                                            Thu
                                        </p>
                                    </div>
                                </th>
                                <th scope="row" className="fc-day-fri">
                                    <div className="fc-scrollgrid-sync-inner">
                                        <p className="fc-col-header-cell-cushion ">
                                            Fri
                                        </p>
                                    </div>
                                </th>
                                <th scope="row" className="fc-day-sat">
                                    <div className="fc-scrollgrid-sync-inner">
                                        <p className="fc-col-header-cell-cushion ">
                                            Sat
                                        </p>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        {this.generate(this.state.viewMonth, this.state.books)}
                    </table>
                </div>
            </div>
        );
    }
}

export default withRouter(Calendar);
