import AxiosClient from "./AxiosClient";

const ScheduleClient = {
  get: async (id, query) => {
    return await AxiosClient.get(`schedule/list/${id}`, query);
  },

  getUnableSchedule: async (id) => {
    return await AxiosClient.get(`schedule/list/${id}/unable`);
  },

  getSchedule: async (id, data) => {
    return await AxiosClient.get(`schedule/list/${id}/all`, data);
  },

  getScheduleAll: async (data) => {
    return await AxiosClient.get(`schedule/branch`, data);
  },

  getList: async (data) => {
    return await AxiosClient.get("schedule/list", data);
  },

  update: async (id, data) => {
    return await AxiosClient.patch(`schedule/update/${id}`, data);
  },

  open: async (id, data) => {
    return await AxiosClient.post(`schedule/open/${id}`, data);
  },

  close: async (id, data) => {
    return await AxiosClient.post(`schedule/close/${id}`, data);
  },

  createTemp: async (data) => {
    return await AxiosClient.post("schedule/temp", data);
  },

  deleteTemp: async (data) => {
    return await AxiosClient.patch("schedule/temp/delete", data);
  },
  makehalf: async (id, data) => {
    return await AxiosClient.post(`schedule/makehalf/${id}`, data);
  },
  destoryhalf: async (id, data) => {
    return await AxiosClient.post(`schedule/destoryhalf/${id}`, data);
  },
};

export default ScheduleClient;
