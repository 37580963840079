import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";

import Dashboard from "../pages/Dashboard";
import AdminList from "../pages/AdminList";
import AdminModify from "../pages/AdminModify";
import AdminRegister from "../pages/AdminRegister";
import BookManagement from "../pages/BookManagement";
import BookRegister from "../pages/BookRegister";
import BookStatus from "../pages/BookStatus";
import BranchList from "../pages/BranchList";
import BranchModify from "../pages/BranchModify";
import BranchRegister from "../pages/BranchRegister";
import LessonDetail from "../pages/LessonDetail";
import TeacherBookStatus from "../pages/TeacherBookStatus";
import AdminTeacherLessonNote from "../pages/AdminTeacherLessonNote";
import TeacherList from "../pages/TeacherList";
import TeacherProfile from "../pages/TeacherProfile";
import TeacherRegister from "../pages/TeacherRegister";
import TeacherSchedule from "../pages/TeacherSchedule";
import TeacherLessonList from "../pages/TeacherLessonList";
import TeacherLessonNote from "../pages/TeacherLessonNote";
import TeacherLessonTransfer from "../pages/TeacherLessonTransfer";
import UserDetail from "../pages/UserDetail";
import UserList from "../pages/UserList";
import UserRegister from "../pages/UserRegister";
import UserDelete from "../pages/UserDelete";
import SubjectList from "../pages/SubjectList";
import SubjectInfo from "../pages/SubjectInfo";
import SubjectRegister from "../pages/SubjectRegister";
import Suggestion from "../pages/Suggestion";
import SuggestionList from "../pages/SuggestionList";

import Logout from "../pages/Logout";

import AuthenticatedComponent from "../components/AuthenticatedComponent";
import BookList from "../pages/BookList";

class Normal extends Component {
  componentDidMount() {}

  render() {
    const token = localStorage.getItem("token_mlb");
    let redirect = true;
    if (token) {
      const decoded = jwt_decode(token);

      if (
        decoded.role === "일반관리자" ||
        decoded.role === "최고관리자" ||
        decoded.role === "지점관리자" ||
        decoded.role === "매니저"
      ) {
        redirect = false;
      }
    }

    return (
      <Switch>
        <AuthenticatedComponent>
          <Route path="/dashboard" exact={true}>
            {redirect ? <Redirect to="/book/bookmanagement" /> : <Dashboard />}
          </Route>
          <Route path="/admin/adminlist" exact={true} component={AdminList} />
          <Route
            path="/admin/adminmodify/:id"
            exact={true}
            component={AdminModify}
          />
          <Route
            path="/admin/adminregister"
            exact={true}
            component={AdminRegister}
          />

          <Route
            path="/book/bookmanagement"
            exact={true}
            component={BookManagement}
          />
          <Route
            path="/book/bookregister"
            exact={true}
            component={BookRegister}
          />
          <Route path="/book/bookstatus" exact={true} component={BookStatus} />
          <Route path="/book/booklist" exact={true} component={BookList} />

          <Route
            path="/branch/branchlist"
            exact={true}
            component={BranchList}
          />
          <Route
            path="/branch/branchmodify/:id"
            exact={true}
            component={BranchModify}
          />
          <Route
            path="/branch/branchregister"
            exact={true}
            component={BranchRegister}
          />

          <Route
            path="/branch/suggestionlist"
            exact={true}
            component={SuggestionList}
          />

          <Route
            path="/branch/suggestion"
            exact={true}
            component={Suggestion}
          />

          <Route path="/lessondetail" exact={true} component={LessonDetail} />

          <Route
            path="/teacher/teacherbookstatus/:id"
            exact={true}
            component={TeacherBookStatus}
          />
          <Route
            path="/admin/teacherlessonnote/:id"
            exact={true}
            component={AdminTeacherLessonNote}
          />
          <Route
            path="/teacher/teacherlist"
            exact={true}
            component={TeacherList}
          />
          <Route
            path="/teacher/teacherprofile/:id"
            exact={true}
            component={TeacherProfile}
          />
          <Route
            path="/teacher/teacherlessontransfer/:id"
            exact={true}
            component={TeacherLessonTransfer}
          />
          <Route
            path="/teacher/teacherregister"
            exact={true}
            component={TeacherRegister}
          />
          <Route
            path="/teacher/teacherschedule/:id"
            exact={true}
            component={TeacherSchedule}
          />
          <Route path="/teacher/lessonlist" component={TeacherLessonList} />
          <Route path="/teacher/lessonnote" component={TeacherLessonNote} />

          <Route
            path="/user/userdetail/:id"
            exact={true}
            component={UserDetail}
          />
          <Route path="/user/userlist" exact={true} component={UserList} />
          <Route
            path="/user/userregister"
            exact={true}
            component={UserRegister}
          />
          <Route path="/user/userdelete" exact={true} component={UserDelete} />
          <Route
            path="/subject/subjectlist"
            exact={true}
            component={SubjectList}
          />
          <Route
            path="/subject/subjectinfo"
            exact={true}
            component={SubjectInfo}
          />
          <Route
            path="/subject/subjectregister"
            exact={true}
            component={SubjectRegister}
          />

          <Route path="/logout" exact={true} component={Logout} />
        </AuthenticatedComponent>
      </Switch>
    );
  }
}
export default Normal;
