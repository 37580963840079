import AxiosClient from "./AxiosClient";

const DashboardClient = {
    get: async (data) => {
        return await AxiosClient.get('dashboard/user', data);
    },

    getDailyStatistics: async (data) => {
        return await AxiosClient.get('dashboard/statistics/daily', data);
    },

    getMonthlyStatistics: async (data) => {
        return await AxiosClient.get('dashboard/statistics/monthly', data);
    },
}

export default DashboardClient;