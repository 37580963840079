import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { AuthContext } from "./AuthenticatedComponent";
import moment from "moment";
import AdminClient from '../utils/Http/AdminClient';
import BranchClient from '../utils/Http/BranchClient';
import PointClient from '../utils/Http/PointClient';
import ReactPaginate from "react-paginate";

const RenderState = {
    None: 0,
    Point: 1,
    Detail: 2,
    History: 3,
}
class PointList extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        // var now = moment();
        // var date = now.add(1, 'day').format("YYYY-MM-DD");

        // const query = queryString.parse(this.props.location.search);

        this.state = {
            _user: props._user,
            renderState: RenderState.Point,
            points: [],
            histories: [],
            page: 0,
            countPerPage: 10,
        };
    }

    componentDidMount () {
        this.load();
    };

    load = async () => {
        try {

            const response = await PointClient.get(this.state._user);

            if(response.data.success === false)
            {
                alert(response.data.message);
                return;
            }

            // console.log(response.data.list);

            this.setState({
                points: response.data.list ? response.data.list : []
            }, () => this.loadHistory() );
        } catch (err) {
            console.log(err);
        }
    }

    loadHistory = async () => {
        try {

            const response = await PointClient.history(this.state._user, this.state.page, this.state.countPerPage);

            if(response.data.success === false)
            {
                alert(response.data.message);
                return;
            }

            // console.log(response.data.result.list);

            this.setState({
                histories: response.data.result.list ? response.data.result.list : [],
                pageCount:
                    Number.parseInt(
                        (response.data.result.count - 1) / this.state.countPerPage
                    ) + 1,
                totalCount: response.data.result.count,
            });
        } catch (err) {
            console.log(err);
        }
    }



    onClickPointUpdate = async (e, _id) => {
        e.preventDefault();
        try {
            const amount = parseInt(
                document.getElementById(`amount_${_id}`).value
            );
            const expired_at = moment(
                document.getElementById(`expired_at_${_id}`).value
            ).format("YYYY-MM-DD");

            const res = await PointClient.update(_id, amount, expired_at);

            if (!res.data.success) {
                alert(res.data.message);
                return;
            }

            window.location.reload();
        } catch (error) {
            alert(error);
        }
    };

    onClickPointDelete = async (e, _id) => {
        e.preventDefault();
        if (window.confirm("정보를 삭제하시겠습니까?") == false) return;
        try {
            const res = await PointClient.delete(_id);

            if (!res.data.success) {
                alert(res.data.message);
                return;
            }

            window.location.reload();
        } catch (error) {
            alert(error);
        }
    };

    onClickPage = (e) => {
        const selectedPage = e.selected;

        this.setState(
            {
                page: selectedPage,
            },
            () => {
                this.loadHistory();
            }
        );
    };

    setRenderState = (e, renderState) => {
        e.preventDefault();

        this.setState({
            renderState: renderState,
        });
    }

    render_table = () => {
        switch(this.state.renderState)
        {
            case RenderState.Point:
                return this.render_point()
            case RenderState.Detail:
                return this.render_detail()
            case RenderState.History:
                return this.render_history()
            default:
                return (<></>);
        }

    }

    render_point = () => {

        const list = {};

        this.state.points.forEach((point) => {
            if(!list[point._branch.name]) {
                list[point._branch.name] = 0;
            }

            list[point._branch.name] += point.amount;
        })

        // console.log(list);

        return (
            <div className="table-responsive">
                <table className="table">
                    <thead className="thead-light">
                    <tr>
                        <th>지점</th>
                        <th>금액</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(list).map((key, index) => {
                        return (
                            <tr>
                                <td>{key}</td>
                                <td>{list[key]}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }


    render_detail = () => {
        return (
            <div className="table-responsive">
                <table className="table">
                    <thead className="thead-light">
                    <tr>
                        <th>지점</th>
                        <th>금액</th>
                        <th>만료일</th>
                        <th>생성일</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.points.map((item, index) => {
                        return (
                            <tr>
                                <td>
                                    {item._branch.name}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        id={`amount_${item._id}`}
                                        defaultValue={item.amount}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="date"
                                        id={`expired_at_${item._id}`}
                                        defaultValue={moment(item.expired_at).format(
                                            "YYYY-MM-DD"
                                        )}
                                    />
                                </td>
                                <td>
                                    {moment(item.create_at).format('YYYY-MM-DD')}
                                </td>
                                <td>
                                    <button
                                        className="btn btn-primary"
                                        data-key={index}
                                        onClick={(e) =>
                                            this.onClickPointUpdate(e, item._id)
                                        }
                                    >
                                        수정
                                    </button>
                                    <button
                                        className="btn btn-dim btn-outline-secondary"
                                        data-key={index}
                                        onClick={(e) =>
                                            this.onClickPointDelete(e, item._id)
                                        }
                                    >
                                        삭제
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }

    render_history = () => {
        return (
            <div>
                <div className="table-responsive">
                    <table className="table">
                        <thead className="thead-light">
                        <tr>
                            <th>지점</th>
                            <th>금액</th>
                            <th>생성일시</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.histories.map((item, index) => {
                            return (
                                <tr>
                                    <td>{item._branch.name}</td>
                                    <td>{item.amount}</td>
                                    <td>{moment(item.create_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.onClickPage}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    className="text-center"
                    forcePage={this.state.page}
                />
            </div>
        );
    }


    render() {
        return (
            <>
                <div>
                    <h5 className="card-title" style={{display: "inline"}}>
                        적립금 현황
                    </h5>
                </div>

                <div className="btn-group mb-2" aria-label="Basic example">
                    <button
                        onClick={(e) => this.setRenderState(e, RenderState.Point)}
                        className="btn btn-outline-primary"
                    >
                        정보
                    </button>
                    <button
                        onClick={(e) => this.setRenderState(e, RenderState.Detail)}
                        className="btn btn-outline-primary"
                    >
                        상세보기
                    </button>
                    <button
                        onClick={(e) => this.setRenderState(e, RenderState.History)}
                        className="btn btn-outline-primary"
                    >
                        히스토리
                    </button>
                </div>

                {this.render_table()}
            </>
        )
            ;
    }
}

export default withRouter(PointList);