import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "./AuthenticatedComponent";
import moment from "moment";
import NoticePopupClient from '../utils/Http/NoticePopupClient';
import { serverUrl } from '../config/api';

class BranchNoticePopup extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        const formatted_now = moment().format('YYYY-MM-DD');
        console.log('asdf');
        this.state = {
            title: '',
            start_at: '',
            end_at: '',
            image_url: '',
            assign_all: false,
        };
    }

    componentDidMount () {
        this.loadData();
    };

    loadData = async (e) => {
        try
        {
            const response = await NoticePopupClient.Get(this.props.notice_id);

            if(!response.data.success)
            {
                alert(response.data.message);
                return;
            }

            const notice = response.data.result;
            this.setState({
                title: notice.title,
                start_at: moment(notice.start_at).format('YYYY-MM-DD'),
                end_at: moment(notice.end_at).format('YYYY-MM-DD'),
                image_url: notice.image_url,
                image: null,
                preview_image: null,
                assign_all: notice._branch === undefined ? true : false
            });
            console.log({
                title: notice.title,
                start_at: moment(notice.start_at).format('YYYY-MM-DD'),
                end_at: moment(notice.end_at).format('YYYY-MM-DD'),
                image_url: notice.image_url,
                assign_all: notice._branch === undefined ? true : false
            });
        }
        catch(err)
        {
            alert(err.message);
        }
    };

    onChange = (e) => {
        console.log(e.target.name, e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    };

    encodeFileToBase64 = (fileBlob) => {
        
      };

    onSelectedImage = (e) => {
        if(0 == e.target.files.length )
            return;
        console.log(e.target.files[0]);
        // this.setState({
        //     image: ,
        // });

        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        return new Promise((resolve) => {
          reader.onload = () => {
            this.setState({
                preview_image: reader.result,
                image: e.target.files[0],
            });
            resolve();
          };
        });
    }

    onSubmit = async (e) => {
        e.preventDefault();

        try
        {
            var formData = new FormData();
            formData.append('image', this.state.image);
            formData.append('title', this.state.title);
            formData.append('start_at', this.state.start_at);
            formData.append('end_at', this.state.end_at);

            console.log(this.state.start_at);
            console.log(this.state.end_at);

            if(this.state.assign_all)
            {
                if(this.context.role !== '최고관리자' && this.context.role !== '일반관리자')
                {
                    alert('전 지점 등록은 최고관리자/일반관리자 계정에서만 가능합니다.');
                    return;
                }
            }
            else
            {
                formData.append('_branch', this.props.branch);
            }

            const response = await NoticePopupClient.Update(this.props.notice_id, formData);
            if(response.data.success) {
                alert(response.data.message);
                this.props.OnClickCancel();
            }
            else {
                alert(response.data.message);
            }
        }
        catch(ex)
        {
            console.log(ex);
            alert(ex.message + "실패");
        }
    };

    renderCheckBoxAllBranch() {
        if(this.context.role === '최고관리자' || this.context.role === '일반관리자') {
            return (
                <div className="row g-3 align-start">
                    <div className="col-lg-5">
                        <div className="form-group">
                            <label className="form-label">
                                전 지점 적용
                            </label>
                            <span className="form-note">
                                선택시 전체 지점에 적용
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="form-group">
                            <div className="form-control-wrap">
                                <label className="box-radio-input">
                                    <input
                                        type="checkbox"
                                        name="assign_all"
                                        value={this.state.assign_all}
                                        checked={this.state.assign_all}
                                        onChange={(e) => { this.setState({[e.target.name] : e.target.checked }); }}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    render() {
        console.log(JSON.parse(this.state.assign_all));
        return (
            <div className="nk-block">
                <div className="card card-bordered">
                    <div className="card-inner">
                        <form onSubmit={this.onSubmit} className="gy-3">
                            <div className="row g-3 align-center">
                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label className="form-label">
                                            제목
                                        </label>
                                        <span className="form-note">
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="form-group">
                                        <div className="form-control-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                onChange={this.onChange}
                                                defaultValue={
                                                    this.state.title
                                                }
                                                required="required"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 align-center">
                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label className="form-label" for="site-off">
                                            기간
                                        </label>
                                        <span className="form-note">
                                            시작일, 종료일을 입력하세요
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col duration">
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="start_at"
                                                onChange={this.onChange}
                                                defaultValue={this.state.start_at}
                                                required="required"
                                            />
                                        </div>
                                        <div className="col">
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="end_at"
                                                onChange={this.onChange}
                                                defaultValue={this.state.end_at}
                                                required="required"
                                                // disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 align-start">
                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            htmlFor="site-off"
                                        >
                                            이미지
                                        </label>
                                        <span className="form-note">
                                            이미지 업로드
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div>
                                        <input
                                            type="file"
                                            id="image"
                                            name="image"
                                            accept='image/*'
                                            onChange={this.onSelectedImage}
                                        />
                                    </div>
                                    <div>
                                        {
                                            <img src={this.state.image ? this.state.preview_image : `${serverUrl}${this.state.image_url}`} width="300" height="300" style={{marginTop: "10px"}}/>
                                        }
                                    </div>
                                </div>
                            </div>
                            { this.renderCheckBoxAllBranch() }
                            <div className="row g-3">
                                <div className="col-lg-7 offset-lg-5">
                                    <div className="form-group mt-2">

                                        <button
                                            type="submit"
                                            className="btn btn-lg btn-primary mr-1"
                                        >
                                            저장
                                        </button>
                                        <button
                                            onClick={() => this.props.OnClickCancel()}
                                            className="btn btn-lg btn-secondary"
                                        >
                                            취소
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(BranchNoticePopup);