import React from "react";
import { withRouter, Link } from "react-router-dom";
import Calendar from "react-calendar";
import moment from "moment";
import "moment-timezone";
import Menubar from "../components/Menubar";
import { AuthContext } from "../components/AuthenticatedComponent";
import Content from "../components/Content";
import BookClient from "../utils/Http/BookClient";
import BranchClient from "../utils/Http/BranchClient";
import ScheduleClient from "../utils/Http/ScheduleClient";
import AdminClient from "../utils/Http/AdminClient";
import UserClient from "../utils/Http/UserClient";
import HolidayClient from "../utils/Http/HolidayClient";
import Constants from "../common/Constants";

const scheduleState = {
  Open: 1,
  Close: 2,
  Booked: 3,
};

moment.tz.setDefault("Asia/Seoul");
class BookRegister extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    // this.handleDateClick = this.onChange1.bind(this); const token =
    // localStorage.getItem('token_mlb') const decoded = jwt_decode(token)
    // console.log(decoded.name+'sdf132123123')

    this.state = {
      _branch: "",
      branchArr: [],
      _subject: "",
      _teacher: "",
      teacherArr: [],
      lesson_time: "",
      lesson_at: new Date(),
      // _user: decoded._id, _user: '',
      errorMsg: "",
      dayOfWeek: "",
      unable_schedule: [],
      schedule: [],
      id: "",
      repeat: false,
      // email: ""
      holidays: [],
      availableTeacherList: [],
    };
  }

  setDayOfWeek(date) {
    const dow = moment(date).day();
    let day = "";
    if (dow === 0) day = "sunday";
    if (dow === 1) day = "monday";
    if (dow === 2) day = "tuesday";
    if (dow === 3) day = "wednesday";
    if (dow === 4) day = "thursday";
    if (dow === 5) day = "friday";
    if (dow === 6) day = "saturday";

    return day;
  }

  isOpenedSchedule = (data, dow, date, time) => {
    if (this.isHoliday(moment(this.state.lesson_at))) {
      // console.log(params + true);
      return true;
    }

    if (!this.state.schedule[this.state._teacher]) return true;
    // console.log(this.state.unable_schedule);
    // console.log(params + " " +this.state._teacher + " %o", this.state.unable_schedule.filter((item) => item.teacher === this.state._teacher && item.time === params));
    console.log(this.state.schedule[this.state._teacher], time);
    return this.state.schedule[this.state._teacher][time] != scheduleState.Open;
  };

  // isOpenedDate = (teacher) => {
  //   const teacher_schedule = this.state.schedule[teacher._id];
  //
  //   if (!teacher_schedule) {
  //     return false;
  //   }
  //
  //   return Object.values(teacher_schedule).some(
  //     (item) => item != scheduleState.Close
  //   );
  // };

  isOpenedDate = (teacher, schedule, dow, date) => {
    const formatted_date = moment(date).format("YYYY-MM-DD");

    if (
        formatted_date <
        moment(teacher.contract_period.start).format("YYYY-MM-DD") ||
        moment(teacher.contract_period.end).format("YYYY-MM-DD") < formatted_date
    )
      return false;

    return Object.values(schedule).some(
        (item) =>
            item.openOnTime == Constants.scheduleState.Open ||
            item.openOnTime == Constants.scheduleState.Booked ||
            item.openHalf == Constants.scheduleState.Open ||
            item.openHalf == Constants.scheduleState.Booked
    );
  };


  getAvailableTeacherList = (teacherArr, schedules, dow, date) => {
    const formatted_date = moment(date).format("YYYY-MM-DD");

    // console.log(teacherArr);
    let availableTeacherList = teacherArr.filter((teacher) => {
      var schedule = schedules[teacher._id];

      if (!schedule) return false;
      // console.log(schedule);
      if (!this.isOpenedDate(teacher, schedule, dow, date)) return false;

      return true;
    });

    return availableTeacherList;
  };

  setTeacherSchedule = async (_teacher, dow, date) => {
    const res = await ScheduleClient.get(_teacher);
    if (res.data.success) {
      if (res.data.result) {
        const result = [];
        for (var i = 0; i < this.state.times.length; ++i) {
          if (
            !this.isOpenedSchedule(
              res.data.result,
              dow,
              date,
              this.state.times[i]
            )
          ) {
            result.push({
              teacher: _teacher,
              time: this.state.times[i],
              type: "closed",
            });
          }
        }
        return result;
      } else {
        return [];
      }
    } else {
      alert(res.data.message);
      return [];
    }
  };

  async fetchData(lesson_at, _branch, teacherList) {
    const lessonAt = moment(lesson_at).format("YYYY-MM-DD");
    const teacherL = await BookClient.getBooks({
      _branch: _branch,
      lesson_at: lessonAt,
      status: "apply",
    });

    const holidayRes = await HolidayClient.getList({
      _branch: _branch,
    });

    // console.log(_branch);
    const teacherLL = teacherL.data.result;

    var bookList = [];
    teacherLL.forEach((item) => {
      if (!bookList[item._teacher._id]) {
        bookList[item._teacher._id] = [];
      }
      bookList[item._teacher._id].push({
        teacher: item._teacher._id,
        time: item.lesson_time,
        type: "booked",
      });
    });

    const dow = this.setDayOfWeek(lesson_at);
    const dowNum = moment(lesson_at).day();
    let schedule = [];
    let lessTime = true;
    // console.log(teacherList);

    const scheduleRes = await ScheduleClient.getScheduleAll({
      _branch: _branch,
      lessonDate: lessonAt,
    });

    var schedules = {};

    if (scheduleRes.data.success) {
      scheduleRes.data.result.schedules.forEach((schedule) => {
        // console.log(schedule);
        schedules[schedule._teacher] = {};

        Constants.times.forEach((time) => {
          var initValue = {
            allowHalfTime: false,
            openOnTime: Constants.scheduleState.Open,
            openHalf: Constants.scheduleState.Close,
          };

          // console.log(dow)
          if (schedule.allowHalfTimes[dowNum]) {
            initValue.allowHalfTime = true;
            initValue.openHalf = Constants.scheduleState.Open;
          }
          schedules[schedule._teacher][time] = initValue;
          // console.log(schedules[schedule._teacher][time]);
        });

        schedule.unable_schedule.forEach((unable) => {
          var splits = unable.time.split(":");
          if (dowNum == unable.dow) {
            // console.log(unable, unable.time);
            const time = `${splits[0]}:00`;
            if (splits[1] == "00") {
              schedules[schedule._teacher][time].openOnTime =
                  Constants.scheduleState.Close;
            } else {
              schedules[schedule._teacher][time].openHalf =
                  Constants.scheduleState.Close;
            }
          }
        });
      });

      scheduleRes.data.result.temporarySchedules.forEach(
          (temporarySchedule) => {
            const splits = temporarySchedule.lessonTime.split(":");
            const hour = splits[0];
            const minutes = splits[1];
            const schedule = schedules[temporarySchedule._teacher][`${hour}:00`];

            if (minutes == "00") {
              schedule.openOnTime =
                  temporarySchedule.status == 1
                      ? Constants.scheduleState.Open
                      : Constants.scheduleState.Close;
            } else {
              schedule.openHalf =
                  temporarySchedule.status == 1
                      ? Constants.scheduleState.Open
                      : Constants.scheduleState.Close;
            }
          }
      );

      scheduleRes.data.result.temporaryScheduleHalfTimes.forEach(
          (temporaryScheduleHalfTime) => {
            const splits = temporaryScheduleHalfTime.lessonTime.split(":");
            const hour = splits[0];
            const schedule =
                schedules[temporaryScheduleHalfTime._teacher][`${hour}:00`];
            schedule.allowHalfTime =
                temporaryScheduleHalfTime.type == 1 ? true : false;
          }
      );
      // console.log(schedules);
      // console.log(teacherLL);
      teacherLL.forEach((book) => {
        // if (book.status == "cancel") return;
        const schedule = schedules[book._teacher._id];
        // console.log(book, schedule);
        if (!schedule) return;

        // console.log(book);

        var splits = book.lesson_time.split(":");
        if (splits[1] == "00") {
          schedule[book.lesson_time].openOnTime =
              Constants.scheduleState.Booked;
          if (book.lesson_type !== Constants.lessonType.Thirty) {
            schedule[book.lesson_time].openHalf =
                Constants.scheduleState.Booked;
          }
        } else {
          schedule[`${splits[0]}:00`].openHalf = Constants.scheduleState.Booked;
          if (book.lesson_type !== Constants.lessonType.Thirty) {
            schedule[`${this.getNextHourString(splits[0])}:00`].openOnTime =
                Constants.scheduleState.Booked;
          }
        }
      });
    } else {
      alert("스케쥴 정보를 가져올 수 없습니다");
      return;
    }

    if (this.state._branch != "" && this.state._teacher != "") {
      const lessonTimes = document.getElementsByName("lesson_time");

      lessonTimes.forEach((item, index) => {
        if (schedule.some((element) => element.time === item.value)) {
          item.checked = false;
          lessTime = false;
        } else {
          item.disabled = false;
        }
      });
    }

    const availableTeacherList = this.getAvailableTeacherList(
        teacherList,
        schedules,
        dow,
        lesson_at
    );

    this.setState((state) => ({
      lesson_at,
      dayOfWeek: dow,
      dowNum: dowNum,
      // unable_schedule: aleady ? schedule.concat(aleady) : schedule,
      schedule: schedules,
      lesson_time: lessTime ? state.lesson_time : "",
      holidays: holidayRes.data.success ? holidayRes.data.result : [],
      availableTeacherList: availableTeacherList
    }));
  }

  getNextHourString(hour) {
    return ("00" + (Number(hour) + 1).toString()).slice(-2);
  }

  componentDidMount = async () => {
    try {
      const dow = await this.setDayOfWeek(Date.now());
      const branchList = await BranchClient.getList();
      if (
        this.context.role === "최고관리자" ||
        this.context.role === "일반관리자"
      ) {
        this.setState({
          dayOfWeek: dow,
          dowNum: moment(Date.now()).day(),
          branchArr: branchList.data.success ? branchList.data.result : [],
        });
      } else {
        const teacherRes = await AdminClient.getList({
          _branch: this.context.branch._id,
          _subject: "all",
          role: "강사",
        });

        await this.fetchData(
          this.state.lesson_at,
          this.context.branch._id,
          teacherRes.data.result
        );

        this.setState({
          dayOfWeek: dow,
          dowNum: moment(Date.now()).day(),
          branchArr: branchList.data.success ? branchList.data.result : [],
          _branch: this.context.branch._id,
          teacherArr: teacherRes.data.result,
        });
      }
    } catch (error) {
      this.setState({ errorMsg: "Error retreiving data" });
    }
  };

  onChange = async (e) => {
    if (e.target.name === "_teacher") {
      this.setState((state) => {
        return {
          [e.target.name]: e.target.value,
          lesson_time: "",
        };
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onBranchChange = async (e) => {
    const teacherRes = await AdminClient.getList({
      _branch: e.target.value,
      _subject: "all",
      role: "강사",
    });

    if (teacherRes.data.success == false) {
      this.setState({
        _branch: e.target.value,
      });
    }

    await this.fetchData(
      this.state.lesson_at,
      e.target.value,
      teacherRes.data.result
    );

    this.setState({
      _branch: e.target.value,
      teacherArr: teacherRes.data.result,
    });
  };

  handleDateClick = async (lesson_at) => {
    try {
      if (this.state._branch == "" || this.state.teacherArr.length == 0) {
        this.setState({
          lesson_at: lesson_at,
        });
        return;
      }
      await this.fetchData(
        lesson_at,
        this.state._branch,
        this.state.teacherArr
      );
    } catch (error) {
      console.log(error);
      alert("데이터 조회 실패");
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const lesson_at = moment(this.state.lesson_at).format("YYYY-MM-DD");

    const teacher = this.state.teacherArr.find(
        (item) => item._id == this.state._teacher
    );

    const userRes = await UserClient.getById(this.state.id);
    if(!userRes.data.success) {
      alert('회원 정보를 찾을 수 없습니다.');
      return;
    }

    console.log(userRes.data.result);

    const tickets = userRes.data.result.tickets;
    if(tickets.length == 0) {
      alert('수강권 정보를 찾을 수 없습니다.');
      return;
    }

    const ticket = tickets[0];

    let lessonType = Constants.lessonType.Fifty;
    const lessonPlan = ticket.lessonPlans.find(plan => plan._id === String(teacher._subject._id));
    if (lessonPlan) {
      lessonType = lessonPlan.value;
    }

    const splits = this.state.lesson_time.split(":");
    const hour = splits[0];
    const minutes = splits[1];

    const teacher_schedule = this.state.schedule[this.state._teacher];
    const schedule = teacher_schedule[`${hour}:00`];

    // console.log(teacher_schedule);

    if (lessonType == Constants.lessonType.Fifty) {
      if (minutes == "00") {
        if (
            schedule.allowHalfTime &&
            schedule.openHalf != Constants.scheduleState.Open
        ) {
          if (
              window.confirm(
                  "※주의※ 해당 시간에 예약시 30분 수업으로 진행됩니다. 예약하시겠습니까?"
              ) === false
          ) {
            return;
          }
        }
      } else {
        const nextSchedule =
            teacher_schedule[`${this.getNextHourString(hour)}:00`];
        if (
            !nextSchedule.allowHalfTime ||
            nextSchedule.openOnTime != Constants.scheduleState.Open
        ) {
          if (
              window.confirm(
                  "※주의※ 해당 시간에 예약시 30분 수업으로 진행됩니다. 예약하시겠습니까?"
              ) === false
          ) {
            return;
          }
        }
      }
    } else {
      if(!schedule.allowHalfTime) {
        const confirm = window.confirm("※주의※ 해당 시간에 예약시 60분 수업으로 진행되며 레슨 횟수가 2회 소모됩니다. 예약하시겠습니까?");
        if (confirm === false) {
          return;
        }
      }
    }

    const book = {
      _branch: this.state._branch,
      _subject: teacher._subject._id,
      _teacher: this.state._teacher,
      lesson_time: this.state.lesson_time,
      lesson_at: lesson_at,
      id: this.state.id,
      repeat: this.state.repeat,
      // email: this.state.email
    };

    try {
      const res = await BookClient.createBookAdmin(book);

      if (res.data.success === true) {
        let message = "";
        for (var i = 0; i < res.data.result.success.length; ++i) {
          message += "\n";
          message += res.data.result.success[i];
        }
        for (var i = 0; i < res.data.result.failed.length; ++i) {
          message += "\n";
          message += res.data.result.failed[i];
        }
        alert(message);

        // this.props.history.push('/book/bookmanagement');
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (ex) {
      console.log(ex);
      alert(ex.message + "실패");
    }
  };

  setDisableRadio = (time) => {
    if (this.isHoliday(moment(this.state.lesson_at))) {
      // console.log(params + true);
      return true;
    }

    const splits = time.split(":");

    const schedule = this.state.schedule[this.state._teacher];
    // console.log(this.state.schedule, this.state._teacher);

    // console.log(time, schedule);
    if (!schedule) return true;
    // console.log(this.state._teacher, schedule);
    if (splits[1] == "00") {
      // console.log(time, schedule[`${splits[0]}:00`].openOnTime);
      return (
          schedule[`${splits[0]}:00`].openOnTime != Constants.scheduleState.Open
      );
    } else {
      // console.log(time, schedule[`${splits[0]}:00`].openHalf);
      return (
          schedule[`${splits[0]}:00`].openHalf != Constants.scheduleState.Open
      );
    }
  };

  isHoliday(date) {
    var year = date.year();
    var month = date.month() + 1;
    var day = date.date();
    var holiday = this.state.holidays.find(function (item) {
      return item.year === year && item.month === month && item.day === day;
    });
    return holiday !== undefined;
  }

  radioChange = (e) => {
    this.setState({ lesson_time: e.target.value });
  };

  onRepeatChange = (e) => {
    try {
      this.setState({
        repeat: !this.state.repeat,
      });
    } catch (error) {
      alert(error);
    }
  };

  // setDisableRadio = (params) => {   return
  // this.state.unable_schedule.some((item) => item === params); };

  _renderButton(time, index) {
    const disabled = this.setDisableRadio(time);
    return (
        <label key={index} className="box-radio-input">
          <input
              type="radio"
              name="lesson_time"
              value={time}
              disabled={disabled}
              checked={time === this.state.lesson_time && !disabled}
              onChange={this.radioChange}
          />
          <span className={disabled ? "bg-secondary" : ""}>{time}</span>
        </label>
    );
  }

  renderButton(time, index) {
    const splits = time.split(":");
    const hour = splits[0];
    const minutes = splits[1];

    const schedule = this.state.schedule[this.state._teacher];

    return (
        <>
          {this._renderButton(time, index)}
          {schedule && schedule[time].allowHalfTime
              ? this._renderButton(`${hour}:30`)
              : null}
        </>
    );
  }

  render() {
    if (
      this.context.role !== "일반관리자" &&
      this.context.role !== "최고관리자" &&
      this.context.role !== "지점관리자" &&
      this.context.role !== "매니저"
    ) {
      return (
        <div>
          <Menubar />
          <div className="nk-content">
            <div className="container-fluid">접근 권한이 없습니다.</div>
          </div>
        </div>
      );
    }

    const teacherList = this.state.availableTeacherList;
    const isHoliday = this.isHoliday(moment(this.state.lesson_at));
    return (
      <>
        <Menubar />
        <Content>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">예약등록</h3>
                <div className="nk-block-des text-soft">
                  <p>관리자 예약등록</p>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            <div className="card card-bordered">
              <div className="card-inner">
                <div className="card-head">
                  <h5 className="card-title">예약등록</h5>
                </div>
                <form onSubmit={this.onSubmit}>
                  <div className="row g-3 align-start">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" htmlFor="site-name">
                          날짜선택
                        </label>
                        <span className="form-note">예약날짜를 선택하세요</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <section className="mw-500 ma-10 mb-30">
                        <div id="calendiv" className="w-100 mt-30"></div>
                        <Calendar
                          onChange={this.handleDateClick}
                          value={this.state.lesson_at}
                          name="lesson_at"
                          minDate={new Date()}
                        />
                      </section>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label">회원아이디</label>
                        <span className="form-note">회원아이디</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            name="id"
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row g-3 align-center">
										<div className="col-lg-5">
											<div className="form-group">
												<label className="form-label">회원 이메일</label>
												<span className="form-note">사용자 확인을 위한 이메일</span>
											</div>
										</div>
										<div className="col-lg-7">
											<div className="form-group">
												<div className="form-control-wrap">
													<input
													type="text"
													data-msg="Required"
													className="form-control required"
													name="email"
													onChange={this.onChange}
													/>
												</div>
											</div>
										</div>
									</div> */}
                  {this.context.role === "최고관리자" ||
                  this.context.role === "일반관리자" ? (
                    <div className="row g-3 align-center">
                      <div className="col-lg-5">
                        <div className="form-group">
                          <label className="form-label">지점</label>
                          <span className="form-note">지점 선택</span>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="form-group">
                          <div className="form-control-wrap">
                            {/* disabled={this.context.role !== '최고관리자'} key={this.state._branch} */}
                            <select
                              className="form-control"
                              name="_branch"
                              id="_branch"
                              onChange={this.onBranchChange}
                              defaultValue={this.state._branch}
                            >
                              <option value="" disabled="disabled">
                                선택
                              </option>
                              {this.state.branchArr.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label">강사</label>
                        <span className="form-note">강사 선택</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          {teacherList.length > 0 ? (
                            teacherList.map((item, index) => {
                              return (
                                <label key={index} className="box-radio-input">
                                  <input
                                    type="radio"
                                    name="_teacher"
                                    value={item._id}
                                    checked={item._id === this.state._teacher}
                                    onChange={this.onChange}
                                  />

                                  <span
                                    className={
                                      item._id === this.state._teacher
                                        ? "bg-secondary"
                                        : ""
                                    }
                                  >
                                    {item.name}
                                  </span>
                                </label>
                              );
                            })
                          ) : (
                            <label>수강 신청 가능 한 강사가 없습니다.</label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-start">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label">시간</label>
                        <span className="form-note">시간 선택</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          {Constants.times.map((item, index) => {
                            return this.renderButton(item, index);
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-start">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label">반복</label>
                        <span className="form-note">
                          선택시 남은 횟수만큼 반복 적용
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <label className="box-radio-input">
                            <input
                              type="checkbox"
                              name="repeat"
                              value={this.state.repeat}
                              checked={this.state.repeat}
                              onChange={this.onRepeatChange}
                            />
                            {/* <span className={this.setDisableRadio(item) ? 'bg-secondary' : ''}>{item}</span> */}
                          </label>
                        </div>
                      </div>

                      <div className="col-md-12 mt-0 text-center">
                        <div className="form-group">
                          <hr className="hrbook" />
                          <p>
                            예약일시{" "}
                            {moment(this.state.lesson_at).format(
                              "YYYY년 MM월 DD일"
                            )}{" "}
                            {this.state.lesson_time}시
                          </p>
                          <p className="bookcheck">
                            예약 취소는 레슨일 기준 전날 20시까지 가능하며, 당일
                            예약 및 취소는 불가합니다.
                          </p>
                          {isHoliday ? (
                            <p className="bookcheck">휴일은 예약 불가합니다.</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-lg-7 offset-lg-5">
                      <div className="form-group mt-2">
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary mr-1"
                          disabled={isHoliday}
                        >
                          예약하기
                        </button>
                        <Link to="/dashboard">
                          <a
                            href="/dashboard"
                            className="btn btn-lg btn-secondary"
                          >
                            취소
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Content>
      </>
    );
  }
}
export default withRouter(BookRegister);
