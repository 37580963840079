import React, { Component } from "react";
import { Link, Route, withRouter } from "react-router-dom";
import Menubar from "../components/Menubar";
import moment from "moment";
import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../components/AuthenticatedComponent";
import Validation from "../utils/Validations";
import Content from "../components/Content";
import ContentHeader from "../components/ContentHeader";
import Ticket from "../components/Ticket";
import UserClient from "../utils/Http/UserClient";
import CouponClient from "../utils/Http/CouponClient";
import BranchClient from "../utils/Http/BranchClient";
import BookClient from "../utils/Http/BookClient";
import TicketClient from "../utils/Http/TicketClient";
import PointClient from "../utils/Http/PointClient";
import queryString from "query-string";
import LessonNote from "../components/LessonNote";
import Swal from "sweetalert2";
import UserHistory from "../components/UserHistory";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import PointList from "../components/PointList";

var constants = require("../common/Constants");
moment.tz.setDefault("Asia/Seoul");

const RenderState = {
  Info: 1,
  Book: 2,
  LessonNote: 3,
  Coupon: 4,
  Point: 5,
};

class UserDetail extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    const discountTypeList = [
      // { id: 0, desc: "없음" },
      // { id: 1, desc: "%" },
      { id: 2, desc: "원" },
    ];

    this.state = {
      render_state: RenderState.Info,
      id: "",
      password: "",
      password1: "",
      password2: "",
      name: "",
      email: "",
      phone: "",
      gender: "",
      birth_year: "",
      birth_month: "",
      birth_day: "",
      startDate: "",
      endDate: "",
      lesson_number: "",
      lesson_number_all: "",
      payment: "",
      holding_date: "",
      about_user: "",
      about_lesson: "",
      user_state: "",
      period: 0,
      _branch: "",
      branchArr: [],
      update_at: Date.now(),
      funnel: "",
      books: [],
      selectedTicketData: {
        id: "",
        startDate: "",
      },
      discountTypeList: discountTypeList,
      discount_type: 2,
      discount_value: 0,
      ticket_comment: "",
      phone_update: "",
      duplicate_check: false,
      duplicate: false,
      showHistory: false,
      special_management: false,
      special_management_comment: "",
      black_user: false,
      black_user_comment: "",
      user_before: undefined,
      user_after: undefined,
      assignee: "",
      accessibility_type: constants.accessibility_type.home.id,
      accessibility: "",
      hideCanceledBook: false,
      hideTakenLessons: false,
      coupons: [],
      coupon: "",
      coupon_name: "",
      coupon_price: 10000,
      coupon_started_at: moment().format("YYYY-MM-DD"),
      coupon_ended_at: moment().format("YYYY-MM-DD"),
      coupons_all: [],
      point: 0,
      point_expired_at: moment().add(6, "months").format("YYYY-MM-DD"),
      point_branch: "",
      points: [],
      point_details: [],
      skipPoint: true,
    };
  }

  componentDidMount = async () => {
    try {
      const query = queryString.parse(this.props.location.search);
      var query_user = null;
      if (query.user_state) {
        query_user = {
          user_state: query.user_state,
          ticket_state: query.ticket_state,
          page: query.page,
          countPerPage: query.countPerPage,
          sortType: query.sortType,
          sortOrder: query.sortOrder,
        };

        if (query._branch) query_user._branch = query._branch;
      }
      const res = await UserClient.get(this.props.match.params.id, query_user);
      const branchList = await BranchClient.getList();
      const bookList = await BookClient.getListAll({
        _user: this.props.match.params.id,
      });

      var query_ticketData = {};
      if (res.data.success) {
        query_ticketData._branch = res.data.result._branch._id;
      }
      const ticketDataList = await TicketClient.getDataList(query_ticketData);

      this.state.selectedTicketData.startDate = moment(Date.now()).format(
        "YYYY-MM-DD"
      );

      var render_state = RenderState.Info;
      if (query.render_state) {
        render_state = Number(query.render_state);
      }

      const ticketList = await TicketClient.AdminList({
        _user: this.props.match.params.id,
      });

      var accessibility_type = constants.accessibility_type.home.id;
      var accessibility = "";
      switch (res.data.result.accessibility) {
        case constants.accessibility_type.home.desc:
          accessibility_type = constants.accessibility_type.home.id;
          accessibility = "";
          break;
        case constants.accessibility_type.work.desc:
          accessibility_type = constants.accessibility_type.work.id;
          accessibility = "";
          break;
        default:
          accessibility_type = constants.accessibility_type.etc.id;
          accessibility = res.data.result.accessibility;
          break;
      }
      // console.log(accessibility_type);

      if (ticketDataList.data.result.length > 0) {
        this.state.selectedTicketData.id = ticketDataList.data.result[0]._id;
      }

      const couponList = await CouponClient.listAll(this.props.match.params.id);
      const _branch = res.data.result._branch
        ? res.data.result._branch._id
        : null;
      console.log(res.data.result);
      this.setState({
        render_state: render_state,
        id: res.data.result.id,
        name: res.data.result.name,
        email: res.data.result.email,
        phone: res.data.result.phone,
        phone_update: res.data.result.phone,
        gender: res.data.result.gender,
        birth_year: res.data.result.birth_year,
        birth_month: res.data.result.birth_month,
        birth_day: res.data.result.birth_day,
        startDate: moment(res.data.result.startDate).format("YYYY-MM-DD"),
        endDate: moment(res.data.result.endDate).format("YYYY-MM-DD"),
        lesson_course: res.data.result.lesson_course,
        lesson_number: res.data.result.lesson_number,
        lesson_number_all: res.data.result.lesson_number_all,
        payment: res.data.result.payment,
        about_user: res.data.result.about_user,
        about_lesson: res.data.result.about_lesson,
        period: res.data.result.period,
        user_state: res.data.result.user_state,
        user_before: res.data.result.before,
        user_after: res.data.result.after,
        // delete_at: res.data.result.delete_at ? res.data.result.delete_at : null,
        _branch: _branch,
        branchArr: branchList.data.success ? branchList.data.result : [],
        books: bookList.data.result,
        book: "",
        tickets: ticketList.data.result,
        ticketDatas: ticketDataList.data.success
          ? ticketDataList.data.result
          : [],
        funnel: res.data.result.funnel,
        special_management: res.data.result.special_management,
        special_management_comment: res.data.result.special_management_comment,
        black_user: res.data.result.black_user,
        black_user_comment: res.data.result.black_user_comment,
        assignee: res.data.result.assignee,
        accessibility_type: accessibility_type,
        accessibility: accessibility,
        coupons: res.data.result.coupons ? res.data.result.coupons : [],
        coupon_name: "",
        coupon_price: 10000,
        coupons_all: couponList.data.success ? couponList.data.list : [],
        points: res.data.result.points ? res.data.result.points : [],
        point_branch:
          this.context.role === "최고관리자" ||
          this.context.role === "일반관리자"
            ? _branch
            : this.context.branch._id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  onChange = (e) => {
    // console.log(e.target.name, e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeCheckbox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  onClickCreateCoupon = async (e) => {
    e.preventDefault();

    var response = await CouponClient.create(
      this.props.match.params.id,
      this.state.coupon_name,
      this.state.coupon_price,
      this.state.coupon_started_at,
      this.state.coupon_ended_at
    );

    if (response.data.success == false) {
      alert(response.data.message);
      return;
    }

    alert("쿠폰이 발급되었습니다.");
    window.location.reload();
  };

  onClickAddPoint = async (e) => {
    e.preventDefault();

    var response = await PointClient.add(
      this.props.match.params.id,
      this.context.role === "최고관리자" || this.context.role === "일반관리자"
        ? this.state.point_branch
        : this.context.branch._id,
      this.state.point,
      this.state.point_expired_at
    );

    if (response.data.success == false) {
      alert(response.data.message);
      return;
    }

    alert("추가 되었습니다.");
    window.location.reload();
  };

  onClickSubPoint = async (e) => {
    e.preventDefault();
  };

  onClickCouponUpdate = async (e, _id) => {
    e.preventDefault();
    try {
      const name = document.getElementById(`coupon_name_${_id}`).value;
      const price = parseInt(
        document.getElementById(`coupon_price_${_id}`).value
      );
      const started_at = moment(
        document.getElementById(`coupon_started_at_${_id}`).value
      ).format("YYYY-MM-DD");
      const ended_at = moment(
        document.getElementById(`coupon_ended_at_${_id}`).value
      ).format("YYYY-MM-DD");

      const res = await CouponClient.update(
        _id,
        name,
        price,
        started_at,
        ended_at
      );

      if (!res.data.success) {
        alert(res.data.message);
        return;
      }

      window.location.reload();
    } catch (error) {
      alert(error);
    }
  };

  onClickCouponDelete = async (e, _id) => {
    e.preventDefault();
    if (window.confirm("쿠폰을 삭제하시겠습니까?") == false) return;
    try {
      const res = await CouponClient.delete(_id);

      if (!res.data.success) {
        alert(res.data.message);
        return;
      }

      window.location.reload();
    } catch (error) {
      alert(error);
    }
  };

  setStartDate = async (e) => {
    const date = moment(e.target.value).format("YYYY-MM-DD");
    this.setState({ startDate: date });
  };

  setEndDate = async (e) => {
    const date = moment(e.target.value).format("YYYY-MM-DD");
    this.setState({ endDate: date });
  };

  onChangeDate = (e) => {
    console.log(e.target.name, e.target.value);
    this.setState({
      [e.target.name]: moment(e.target.value).format("YYYY-MM-DD"),
    });
  };

  handleRemove = async (e) => {
    // console.log(e.target.dataset.book)
    try {
      if (window.confirm("취소하시겠습니까?") === true) {
        const id = e.target.dataset.book;
        // console.log(this.state.id+'sdfsdfsdf')
        const book = this.state.books.filter((x) => x._id == id)[0];
        //alert('handleRemove ' + moment(Date.now()).format('YYYY-MM-DD') + ' ' + moment(book.lesson_at).format('YYYY-MM-DD'));
        let status = "cancel";

        const res = await BookClient.update(id, {
          admin: true,
          status: status,
        });

        if (res.data.success) {
          window.location.reload();
        } else {
          alert(res.data.message);
        }
      } else {
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  handelCancelOnDay = async (e) => {
    try {
      if (window.confirm("당일 취소하시겠습니까?") === true) {
        const id = e.target.dataset.book;
        // console.log(this.state.id+'sdfsdfsdf')
        const book = this.state.books.filter((x) => x._id == id)[0];
        //alert('handleRemove ' + moment(Date.now()).format('YYYY-MM-DD') + ' ' + moment(book.lesson_at).format('YYYY-MM-DD'));
        if (
          moment().format("YYYY-MM-DD") !=
          moment(book.lesson_at).format("YYYY-MM-DD")
        ) {
          alert("날짜가 달라 당일 취소 할 수 없습니다.");
          return;
        }
        let status = "cancelOnTheDay";

        const res = await BookClient.update(id, {
          admin: true,
          status: status,
        });

        window.location.reload();
      } else {
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();

    if (this.state.phone_update != this.state.phone) {
      if (this.state.duplicate_check === false) {
        alert("휴대전화 번호 중복 확인이 필요합니다.");
        return;
      }

      if (this.state.duplicate === true) {
        alert("사용 할 수 없는 휴대전화 번호입니다.");
        return;
      }
    }

    if (
      this.state.special_management == true &&
      this.state.special_management_comment == ""
    ) {
      alert("특별 관리 대상 지정시 메모는 필수 입력되어야 합니다.");
      return;
    }

    if (this.state.black_user == true && this.state.black_user_comment == "") {
      alert("블랙리스트 지정시 메모는 필수 입력되어야 합니다.");
      return;
    }

    const idValidation = await Validation.id(this.state.id);

    if (!idValidation.success) {
      alert(idValidation.message);
    } else {
      var funnels = [];
      Object.keys(constants.funnel).forEach((key) => {
        var doc = document.getElementById(key);
        console.log(doc);
        if (doc == null) return;

        if (doc.checked) {
          funnels.push(key);
        }
      });

      if (funnels.length == 0) {
        alert("등록 경로는 최소 한 개 선택되어야 합니다.");
        return;
      }

      var accessibility = "";
      switch (this.state.accessibility_type) {
        case constants.accessibility_type.home.id:
          accessibility = constants.accessibility_type.home.desc;
          break;
        case constants.accessibility_type.work.id:
          accessibility = constants.accessibility_type.work.desc;
          break;
        case constants.accessibility_type.etc.id:
          accessibility = this.state.accessibility;
          break;
      }

      if (accessibility === "") {
        alert("접근성 기타 선택시 값을 입력 해 주세요.");
        return;
      }

      const sign = {
        id: this.state.id,
        password1: this.state.password1,
        password2: this.state.password2,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone_update,
        gender: this.state.gender,
        birth_year: this.state.birth_year,
        birth_month: this.state.birth_month,
        birth_day: this.state.birth_day,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        lesson_course: this.state.lesson_course,
        lesson_number: this.state.lesson_number,
        lesson_number_all: this.state.lesson_number_all,
        payment: this.state.payment,
        about_user: this.state.about_user,
        about_lesson: this.state.about_lesson,
        user_state: this.state.user_state,
        delete_at: this.state.delete_at,
        _branch: this.state._branch,
        update_at: this.state.update_at,
        period: this.state.period,
        funnel: funnels,
        accessibility: accessibility,
        special_management: this.state.special_management,
        special_management_comment: this.state.special_management_comment,
        black_user: this.state.black_user,
        black_user_comment: this.state.black_user_comment,
      };

      try {
        const res = await UserClient.updateAdmin(
          this.props.match.params.id,
          sign
        );

        if (res.data.success === true) {
          alert(res.data.message);
          // this.props.history.push('/user/userlist');
          window.location.reload();
        } else {
          alert(res.data.message);
        }
      } catch (err) {
        console.log(err);
        alert(err.message + "실패");
      }
    }
  };

  onCreateTicket = async (e) => {
    e.preventDefault();

    try {
      const res = await TicketClient.create({
        _user: this.props.match.params.id,
        _ticketData: this.state.selectedTicketData.id,
        startDate: this.state.selectedTicketData.startDate,
        discount_type: Number(this.state.discount_type),
        discount_value:
          this.state.discount_type != 0 ? Number(this.state.discount_value) : 0,
        comment: this.state.ticket_comment,
        coupon: this.state.coupon,
        point: this.state.point,
        skipPoint: this.state.skipPoint,
      });

      if (res.data.success === true) {
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  onCloseHistory = (e) => {
    this.disableHistory();
  };

  enableHistory = (e) => {
    e.preventDefault();
    this.setState({
      showHistory: true,
    });
  };

  disableHistory() {
    this.setState({
      showHistory: false,
    });
  }

  setTicketStartDate = async (e) => {
    const date = moment(e.target.value).format("YYYY-MM-DD");
    var ticketData = this.state.selectedTicketData;
    ticketData.startDate = date;
    this.setState({
      selectedTicketData: ticketData,
    });
  };

  setTicketData = async (e) => {
    var ticketData = this.state.selectedTicketData;
    ticketData.id = e.target.value;
    this.setState({
      selectedTicketData: ticketData,
    });
    console.log(ticketData);
  };

  setDiscountType = async (e) => {
    e.preventDefault();

    this.state.discount_type = e.target.value;
    // alert(e.target.value);
  };

  setRenderState(render_state) {
    var query_string = this.getQueryString();

    window.history.pushState(
      null,
      null,
      `/user/userdetail/${this.props.match.params.id}${
        query_string === "" ? "?" : query_string + "&"
      }render_state=${render_state}`
    );
    this.setState({
      render_state: render_state,
    });
  }

  getQueryString() {
    const query = queryString.parse(this.props.location.search);
    var query_string = "?";
    if (query.user_state) query_string += `user_state=${query.user_state}&`;
    if (query.ticket_state)
      query_string += `ticket_state=${query.ticket_state}&`;
    if (query.page) query_string += `page=${query.page}&`;
    if (query.countPerPage)
      query_string += `countPerPage=${query.countPerPage}&`;
    if (query.sortType) query_string += `sortType=${query.sortType}&`;
    if (query.sortOrder) query_string += `sortOrder=${query.sortOrder}&`;
    if (query._branch) query_string += `_branch=${query._branch}&`;
    console.log(query_string);
    return query_string.slice(0, -1);
  }

  onClickUserBefore = (e) => {
    e.preventDefault();

    if (!this.state.user_before) return;

    window.location.href = `/user/userdetail/${
      this.state.user_before._id
    }${this.getQueryString()}`;
  };

  onClickUserAfter = (e) => {
    e.preventDefault();

    if (!this.state.user_after) return;

    window.location.href = `/user/userdetail/${
      this.state.user_after._id
    }${this.getQueryString()}`;
  };

  onClickDuplicationCheck = async (e) => {
    e.preventDefault();

    if (this.state.phone == this.state.phone_update) {
      alert("기존과 동일한 번호입니다.");
      return;
    }
    try {
      const res = await UserClient.DuplicationCheck({
        phone: this.state.phone_update,
      });
      if (res.data.success === false) {
        window.alert(res.data.message);
        return;
      }

      this.setState({
        duplicate_check: true,
        duplicate: res.data.result.duplicate,
      });

      if (res.data.result.duplicate) {
        const name = res.data.result.user ? res.data.result.user.name : "";
        const branchName = res.data.result.user
          ? res.data.result.user._branch.name
          : "";
        const ticket = res.data.result.ticket
          ? `${moment(res.data.result.ticket.startDate).format(
              "YY-MM-DD"
            )} ~ ${moment(res.data.result.ticket.endDate).format("YY-MM-DD")}`
          : "";
        const lessonCount = res.data.result.ticket
          ? res.data.result.ticket.lesson
          : 0;
        var label = `이름 : ${name}<br/>
                            지점 : ${branchName}<br/>
                            수강권 : ${ticket}<br/>
                            잔여 수강 횟수 : ${lessonCount}`;
        if (res.data.result.user.special_management) {
          label += `<br />특별관리대상 : ${res.data.result.user.special_management_comment}`;
        }
        if (res.data.result.user.black_user) {
          label += `<br />블랙리스트 : ${res.data.result.user.black_user_comment}`;
        }

        Swal.fire({
          title: "휴대전화 번호 중복",
          html: label,
          icon: "warning",
        });
      }
    } catch (error) {
      window.alert(error);
    }
  };

  getPoint() {
    // console.log(this.state.points);
    const point = this.state.points[this.state._branch];
    return point ? point.toLocaleString("en-US") : 0;
  }

  getExpectedPoint() {
    if (this.state.skipPoint) return 0;
    var ticketData = this.state.ticketDatas.find(
      (ticketData) => ticketData._id == this.state.selectedTicketData.id
    );
    return ticketData ? (ticketData.price * 0.03).toLocaleString("en-US") : "0";
  }

  getPeriod() {
    let period = this.state.period;

    // const now = moment().format('YYYY-MM-DD');
    // this.state.tickets.forEach((ticket) => {
    //     if(ticket.status == 3)
    //         return;
    //     // console.log(ticket);
    //     if(moment(ticket.endDate).format('YYYY-MM-DD') < now)
    //         return;

    //     if(now < moment(ticket.startDate).format('YYYY-MM-DD')) {
    //         // console.log(period, moment(ticket.endDate).diff(moment(ticket.startDate), 'days'));
    //         period -= moment(ticket.endDate).diff(moment(ticket.startDate), 'days');
    //     } else {
    //         // console.log(period, moment(ticket.endDate).diff(moment(), 'days'));
    //         period -= moment(ticket.endDate).diff(moment(), 'days');
    //         // console.log(moment(ticket.endDate).diff(moment(), 'days'));
    //     }
    //     // console.log(ticket);
    // });
    // console.log(period);

    return period;
  }

  render_duplicate_message() {
    if (this.state.duplicate)
      return (
        <span className="form-note" style={{ color: "red" }}>
          {" "}
          사용 할 수 없는 휴대전화 번호입니다.{" "}
        </span>
      );
    else
      return (
        <span className="form-note" style={{ color: "blue" }}>
          {" "}
          사용 가능 한 번호입니다.{" "}
        </span>
      );
  }

  renderBookStatus(book) {
    if (book.status === "apply") {
      if (this.context.role === "강사") return null;
      return (
        <td>
          <button
            className="btn btn-dim btn-outline-secondary"
            data-book={book._id}
            onClick={this.handleRemove}
          >
            예약취소
          </button>
          {moment().format("YYYY-MM-DD") ==
          moment(book.lesson_at).format("YYYY-MM-DD") ? (
            <button
              className="btn btn-dim btn-outline-secondary"
              data-book={book._id}
              onClick={this.handelCancelOnDay}
            >
              당일취소
            </button>
          ) : null}
        </td>
      );
    } else if (book.status === "cancelOnTheDay") {
      return (
        <td>
          <button
            className="btn btn-dim btn-outline-secondary"
            data-book={book._id}
            disabled
          >
            당일취소완료
          </button>
        </td>
      );
    } else {
      return (
        <td>
          <button
            className="btn btn-dim btn-outline-secondary"
            data-book={book._id}
            disabled
          >
            취소완료
          </button>
        </td>
      );
    }
  }

  render_info() {
    switch (this.state.render_state) {
      case RenderState.Info:
        return this.renderUser();
      case RenderState.Book:
        return this.renderBook();
      case RenderState.LessonNote:
        return this.renderLessonNote();
      case RenderState.Coupon:
        return this.renderCoupon();
      case RenderState.Point:
        return this.renderPoint();
    }
    return null;
  }

  renderUser() {
    let yearArr = [];

    const thisyear = moment().format("yyyy");

    for (var i = 0; i < 82; i++) {
      yearArr.push(thisyear - i);
    }
    const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const day = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ];

    return (
      <>
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-inner">
              {/* <div className="card-head"> */}
              <h5 className="card-title" style={{ display: "inline" }}>
                계정
              </h5>
              <label>
                &nbsp;&nbsp;{this.state.assignee ? this.state.assignee : ""}
              </label>
              {/* </div> */}
              <form onSubmit={this.onSubmit} className="gy-3">
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" for="site-name">
                        ID
                      </label>
                      <span className="form-note">
                        로그인 계정 정보 (수정불가)
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={this.state.id}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {this.context.role !== "강사" ? (
                  <>
                    <div className="row g-3 align-center">
                      <div className="col-lg-5">
                        <div className="form-group">
                          <label className="form-label">새 비밀번호</label>
                          <span className="form-note">
                            비밀번호 변경시 입력하세요
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <input
                              type="password"
                              className="form-control"
                              id="password1"
                              name="password1"
                              onChange={this.onChange}
                              defaultValue={this.state.password1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 align-center">
                      <div className="col-lg-5">
                        <div className="form-group">
                          <label className="form-label">새 비밀번호 확인</label>
                          <span className="form-note">
                            입력한 비밀번화를 한번더 입력하세요
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <input
                              type="password"
                              className="form-control"
                              id="password2"
                              name="password2"
                              onChange={this.onChange}
                              defaultValue={this.state.password2}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 align-center">
                      <div className="col-lg-5">
                        <div className="form-group">
                          <label className="form-label" for="site-name">
                            수강 기간
                          </label>
                          <span className="form-note">일 수</span>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              className="form-control"
                              id="period"
                              name="period"
                              onChange={this.onChange}
                              defaultValue={this.getPeriod()}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 align-center">
                      <div className="col-lg-5">
                        <div className="form-group">
                          <label className="form-label">회원상태</label>
                          <span className="form-note">
                            미승인, 승인 또는 탈퇴
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <select
                          className="form-control"
                          name="user_state"
                          onChange={this.onChange}
                          value={this.state.user_state}
                        >
                          <option value="" disabled>
                            {" "}
                            선택{" "}
                          </option>
                          <option
                            value="미승인"
                            name="user_state"
                            onChange={this.onChange}
                          >
                            미승인
                          </option>
                          <option
                            value="승인"
                            name="user_state"
                            onChange={this.onChange}
                          >
                            승인
                          </option>
                          <option
                            value="탈퇴"
                            name="user_state"
                            onChange={this.onChange}
                          >
                            탈퇴
                          </option>
                        </select>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="divider"></div>
                <div className="row g-3 align-center">
                  <div className="col-lg-1">
                    <h5 className="card-title" style={{ display: "inline" }}>
                      프로필
                    </h5>
                  </div>
                  <div className="col-lg-2">
                    <button
                      className="btn btn-primary mr-1"
                      onClick={this.enableHistory}
                    >
                      수정 내역 보기
                    </button>
                  </div>
                </div>
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label">이름</label>
                      <span className="form-note">회원 이름</span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          onChange={this.onChange}
                          defaultValue={this.state.name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {this.context.role !== "강사" ? (
                  <>
                    <div className="row g-3 align-center">
                      <div className="col-lg-5">
                        <div className="form-group">
                          <label className="form-label" for="site-off">
                            휴대전화
                          </label>
                          <span className="form-note">- 없이 입력</span>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="row">
                          <div className="col">
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="phone_update"
                                onChange={this.onChange}
                                defaultValue={this.state.phone}
                                required="required"
                              />
                            </div>
                          </div>
                          <button
                            className="btn btn-primary mr-1"
                            onClick={this.onClickDuplicationCheck}
                          >
                            중복확인
                          </button>
                        </div>
                        {this.state.duplicate_check
                          ? this.render_duplicate_message()
                          : null}
                      </div>
                    </div>
                    <div className="row g-3 align-center">
                      <div className="col-lg-5">
                        <div className="form-group">
                          <label className="form-label" for="site-off">
                            생년월일
                          </label>
                          <span className="form-note">년 / 월 / 일</span>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="row">
                          <div className="col">
                            <div className="form-wrap">
                              <div className="form-text-hint mr-3">
                                <span className="overline-title">년</span>
                              </div>
                              <select
                                className="form-control"
                                name="birth_year"
                                onChange={this.onChange}
                                value={this.state.birth_year}
                                required="required"
                              >
                                <option value="" disabled>
                                  년
                                </option>
                                {yearArr.map((year, index) => (
                                  <option
                                    key={index}
                                    value={year}
                                    name="birth_year"
                                    onChange={this.onChange}
                                  >
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-wrap">
                              <div className="form-text-hint mr-3">
                                <span className="overline-title">월</span>
                              </div>
                              <select
                                className="form-control"
                                name="birth_month"
                                onChange={this.onChange}
                                value={this.state.birth_month}
                                required="required"
                              >
                                <option value="" disabled>
                                  월
                                </option>
                                {month.map((month, index) => (
                                  <option
                                    key={index}
                                    value={month}
                                    name="birth_month"
                                    onChange={this.onChange}
                                  >
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-wrap">
                              <div className="form-text-hint mr-3">
                                <span className="overline-title">일</span>
                              </div>
                              <select
                                className="form-control"
                                name="birth_day"
                                onChange={this.onChange}
                                value={this.state.birth_day}
                                required="required"
                              >
                                <option value="" disabled>
                                  일
                                </option>
                                {day.map((day, index) => (
                                  <option
                                    key={index}
                                    value={day}
                                    name="birth_day"
                                    onChange={this.onChange}
                                  >
                                    {day}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 align-center">
                      <div className="col-lg-5">
                        <div className="form-group">
                          <label className="form-label" for="site-off">
                            성별
                          </label>
                          <span className="form-note">남/여</span>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <select
                              className="form-control"
                              name="gender"
                              onChange={this.onChange}
                              value={this.state.gender}
                              required="required"
                            >
                              <option value="" disabled>
                                선택
                              </option>
                              <option value="여성" onChange={this.onChange}>
                                여성
                              </option>
                              <option value="남성" onChange={this.onChange}>
                                남성
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="divider"></div>
                <h5 className="card-title">수강정보</h5>

                {this.context.role !== "강사" && (
                  <>
                    <div className="row g-3 align-center">
                      <div className="col-lg-5">
                        <div className="form-group">
                          <label className="form-label" for="site-off">
                            지점
                          </label>
                          <span className="form-note">
                            등록 지점을 선택하세요
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <select
                              className="form-control"
                              name="_branch"
                              onChange={this.onChange}
                              // value={this.state._branch ? this.state._branch.name : null}
                              defaultValue={
                                this.state._branch ? this.state._branch : "없음"
                              }
                            >
                              {/* <option value='' disabled>선택</option> */}
                              <option disabled>
                                {this.state._branch ? "선택" : "없음"}
                              </option>
                              {/* <option value={this.state._branch ? this.state._branch.name : null} disabled>{this.state._branch ? this.state._branch.name : null}</option> */}
                              {this.state.branchArr.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {this.context.role !== "강사" && (
                  <div className="row g-3 align-start">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-off">
                          수강생 참고사항
                        </label>
                        <span className="form-note">직업</span>
                        <span className="form-note">주소</span>
                        <span className="form-note">성격, 커뮤니티 관심도</span>
                        <span className="form-note">참고사항</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <textarea
                        type="text"
                        className="form-control"
                        id="about_user"
                        name="about_user"
                        onChange={this.onChange}
                        value={this.state.about_user}
                      />
                    </div>
                  </div>
                )}
                <div className="row g-3 align-start">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" for="site-off">
                        레슨시 참고사항
                      </label>
                      <span className="form-note">좋아하는 곡, 장르</span>
                      <span className="form-note">원하는 곡</span>
                      <span className="form-note">최종목표</span>
                      <span className="form-note">마지막으로 배운 시기</span>
                      <span className="form-note">
                        악보를 읽을 수 있다/없다
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <textarea
                      type="text"
                      className="form-control"
                      id="about_lesson"
                      name="about_lesson"
                      onChange={this.onChange}
                      value={this.state.about_lesson}
                    />
                  </div>
                </div>
                <div className="row g-3 align-start">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" htmlFor="site-off">
                        등록 경로
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    {Object.keys(constants.funnel).map((key) => (
                      <label>
                        <input
                          className="myCheckbox"
                          type="checkbox"
                          id={key}
                          defaultChecked={this.state.funnel.some(
                            (x) => x === key
                          )}
                        />
                        <span>
                          {" "}
                          &nbsp;{constants.funnel[key]}&nbsp;&nbsp;&nbsp;{" "}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
                <div className="row g-3 align-start">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" htmlFor="site-off">
                        접근성
                      </label>
                      <span className="form-note">
                        기타 선택 시 값 입력 필수
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <label style={{ "margin-right": "10px" }}>
                      <input
                        type="radio"
                        // value={item._id}
                        checked={
                          this.state.accessibility_type ===
                          constants.accessibility_type.home.id
                        }
                        onChange={() => {
                          this.setState({
                            accessibility_type:
                              constants.accessibility_type.home.id,
                          });
                        }}
                        style={{ "margin-right": "5px" }}
                      />

                      <span>집 근처</span>
                    </label>
                    <label style={{ "margin-right": "10px" }}>
                      <input
                        type="radio"
                        // value={item._id}
                        checked={
                          this.state.accessibility_type ===
                          constants.accessibility_type.work.id
                        }
                        onChange={() => {
                          this.setState({
                            accessibility_type:
                              constants.accessibility_type.work.id,
                          });
                        }}
                        style={{ "margin-right": "5px" }}
                      />

                      <span>회사 근처</span>
                    </label>
                    <label style={{ "margin-right": "10px" }}>
                      <input
                        type="radio"
                        // value={item._id}
                        checked={
                          this.state.accessibility_type ===
                          constants.accessibility_type.etc.id
                        }
                        onChange={() => {
                          this.setState({
                            accessibility_type:
                              constants.accessibility_type.etc.id,
                          });
                        }}
                        style={{ "margin-right": "5px" }}
                      />

                      <input
                        type="text"
                        name="accessibility"
                        onChange={this.onChange}
                        value={this.state.accessibility}
                      />
                    </label>
                  </div>
                </div>

                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" for="site-off">
                        특별 관리 대상
                      </label>
                      <span className="form-note">체크시 메모 필수</span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <textarea
                          type="text"
                          className="form-control"
                          id="special_management_comment"
                          name="special_management_comment"
                          onChange={this.onChange}
                          value={this.state.special_management_comment}
                        />
                        <input
                          className="display-inline"
                          type="checkbox"
                          name="special_management"
                          value={this.state.special_management}
                          checked={this.state.special_management}
                          onChange={this.onChangeCheckbox}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" for="site-off">
                        블랙리스트
                      </label>
                      <span className="form-note">체크시 메모 필수</span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <textarea
                          type="text"
                          className="form-control"
                          name="black_user_comment"
                          onChange={this.onChange}
                          value={this.state.black_user_comment}
                        />
                        <input
                          className="display-inline"
                          type="checkbox"
                          name="black_user"
                          value={this.state.black_user}
                          checked={this.state.black_user}
                          onChange={this.onChangeCheckbox}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row g-3">
                  <div className="col-lg-7 offset-lg-5">
                    <div className="form-group mt-2">
                      {this.context.role !== "강사" ? (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary mr-1"
                        >
                          수정내용 저장
                        </button>
                      ) : null}
                      <Link
                        to={
                          this.context.role !== "강사"
                            ? "/user/userlist"
                            : "/book/bookmanagement"
                        }
                      >
                        <a
                          href={
                            this.context.role !== "강사"
                              ? "/user/userlist"
                              : "/book/bookmanagement"
                          }
                          className="btn btn-lg btn-secondary"
                        >
                          취소
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {this.context.role !== "강사" ? this.renderTicket() : null}
      </>
    );
  }

  renderTicket() {
    return (
      <div className="nk-block">
        <div className="card card-bordered">
          <div className="card-inner">
            <div className="card-head">
              <h5 className="card-title">수강권</h5>
            </div>

            <form>
              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label" htmlFor="site-off">
                      수강권 추가
                    </label>
                    <span className="form-note">수강권 선택하세요</span>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="row">
                    <div className="col">
                      <div className="form-wrap">
                        <input
                          type="date"
                          className="form-control"
                          name="ticketStartDate"
                          onChange={this.setTicketStartDate}
                          defaultValue={this.state.selectedTicketData.startDate}
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-wrap">
                        <select
                          className="form-control"
                          name="selectedTicketData"
                          onChange={this.setTicketData}
                          // value={this.state._branch ? this.state._branch.name : null}
                          defaultValue={this.state.selectedTicketData.id}
                        >
                          {this.state.ticketDatas.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label" htmlFor="site-off">
                      할인 정보
                    </label>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="row">
                    <div className="col">
                      <div className="form-wrap">
                        <input
                          type="number"
                          className="form-control"
                          name="discount_value"
                          defaultValue={0}
                          required="required"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-wrap">
                        <select
                          className="form-control"
                          name="discount_type"
                          onChange={this.onChange}
                          // value={this.state._branch ? this.state._branch.name : null}
                          defaultValue={this.state.discount_type}
                          disabled={true}
                        >
                          {this.state.discountTypeList.map((item, index) => (
                            <option key={index} value={index}>
                              {item.desc}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label" htmlFor="site-off">
                      쿠폰
                    </label>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="row">
                    <div className="col">
                      <div className="form-wrap">
                        <select
                          className="form-control"
                          name="coupon"
                          onChange={this.onChange}
                          defaultValue="0"
                        >
                          <option value="">쿠폰을 선택해주세요.</option>

                          {this.state.coupons.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name} | {item.price} 원 |{" "}
                              {`${moment(item.started_at).format(
                                "YYYY-MM-DD"
                              )} ~ ${moment(item.ended_at).format(
                                "YYYY-MM-DD"
                              )}`}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label" htmlFor="site-off">
                      적립금
                    </label>
                    <span className="form-note">
                      보유 적립금 ( {this.getPoint()}원 )
                    </span>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="row">
                    <div className="col">
                      <div className="form-wrap">
                        <input
                          type="number"
                          className="form-control"
                          name="point"
                          defaultValue={0}
                          required="required"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-3 align-start">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label" htmlFor="site-off">
                      메모
                    </label>
                  </div>
                </div>
                <div className="col-lg-7">
                  <textarea
                    type="text"
                    className="form-control"
                    id="ticket_comment"
                    name="ticket_comment"
                    onChange={this.onChange}
                    value={this.state.ticket_comment}
                  />
                </div>
              </div>
              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label" htmlFor="site-off">
                      포인트 적립
                    </label>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <label>{this.getExpectedPoint()} 원</label>
                      <label>
                        <input
                          className="display-inline"
                          type="checkbox"
                          id="skipPoint"
                          name="skipPoint"
                          value={this.state.skipPoint}
                          checked={this.state.skipPoint}
                          onChange={this.onChangeCheckbox}
                          style={{ marginLeft: "8px" }}
                        />
                        <span style={{ marginLeft: "4px" }}>적립 안함</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-lg-7 offset-lg-5">
                  <div className="form-group mt-2">
                    <button
                      onClick={this.onCreateTicket}
                      className="btn btn-lg btn-primary mr-1"
                    >
                      추가
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <Ticket
              user={this.props.match.params.id}
              branch={this.state._branch}
              tickets={this.state.tickets}
              coupons={this.state.coupons}
            />
          </div>
        </div>
      </div>
    );
  }

  renderBook() {
    var bookCount = 0;
    var books = this.state.books.filter((book, index) => {
      if (this.state.hideCanceledBook && book.status == "cancel") return false;
      if (this.state.hideTakenLessons === true) {
        if (
          moment(book.lesson_at).format("YYYY-MM-DD") <
            moment().format("YYYY-MM-DD") ||
          (moment(book.lesson_at).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD") &&
            book.lesson_time < moment().format("HH:mm"))
        )
          return false;
      }

      return true;
    });

    bookCount = books.length;

    return (
      <>
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {this.state.date} 예약 리스트
              </h3>
              <label>
                <input
                  className="myCheckbox"
                  type="checkbox"
                  name="hideCanceledBook"
                  defaultChecked={this.state.hideCanceledBook}
                  onChange={(e) => {
                    this.setState({ hideCanceledBook: e.target.checked });
                  }}
                />
                <span>&nbsp;취소내역 숨기기&nbsp;&nbsp;&nbsp;</span>
              </label>
              <label>
                <input
                  className="myCheckbox"
                  type="checkbox"
                  name="hideTakenLessons"
                  defaultChecked={this.state.hideTakenLessons}
                  onChange={(e) => {
                    this.setState({ hideTakenLessons: e.target.checked });
                  }}
                />
                <span>&nbsp;지난레슨 숨기기&nbsp;&nbsp;&nbsp;</span>
              </label>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="card card-bordered card-stretch">
            <div class="table-responsive">
              <table class="table">
                <thead className="thead-light">
                  <th></th>
                  <th>예약일</th>
                  <th>예약시간</th>
                  <th>지점</th>
                  <th>강사</th>
                  <th>과목</th>
                  <th>취소여부</th>
                </thead>
                <tbody>
                  {books.map((book, index) => {
                    var lesson_at = moment(book.lesson_at);
                    return (
                      <tr key={index}>
                        <td>{bookCount - index}</td>
                        <td>
                          {book.lesson_at
                            ? `${lesson_at.format("YYYY년 MM월 DD일")} ${
                                constants.dayOfWeek[lesson_at.day()]
                              }`
                            : null}
                        </td>
                        <td>{book.lesson_time ? book.lesson_time : null}</td>
                        <td>{book._branch ? book._branch.name : null}</td>
                        <td>
                          {book._teacher
                            ? book._teacher.name
                            : book.teacher_name}
                        </td>
                        <td>{book._subject ? book._subject.name : null}</td>

                        {this.renderBookStatus(book)}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderLessonNote() {
    return (
      <>
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">레슨 노트</h3>
            </div>
          </div>
        </div>
        <LessonNote
          _user={this.props.match.params.id}
          start_at={this.state.start_at}
          end_at={this.state.end_at}
        />
      </>
    );
  }

  renderCoupon() {
    return (
      <>
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-inner">
              {/* <div className="card-head"> */}
              <h5 className="card-title" style={{ display: "inline" }}>
                쿠폰 생성
              </h5>
              <label>
                &nbsp;&nbsp;{this.state.assignee ? this.state.assignee : ""}
              </label>
              {/* </div> */}
              <form onSubmit={this.onSubmit} className="gy-3">
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label">쿠폰명</label>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          id="coupon_name"
                          name="coupon_name"
                          onChange={this.onChange}
                          value={this.state.coupon_name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label">금액</label>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                          type="number"
                          className="form-control"
                          id="coupon_price"
                          name="coupon_price"
                          onChange={this.onChange}
                          value={this.state.coupon_price}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label">시작일</label>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                          type="date"
                          className="form-control"
                          id="coupon_started_at"
                          name="coupon_started_at"
                          onChange={this.onChangeDate}
                          value={this.state.coupon_started_at}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label">종료일</label>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <input
                          type="date"
                          className="form-control"
                          id="coupon_ended_at"
                          name="coupon_ended_at"
                          onChange={this.onChangeDate}
                          value={this.state.coupon_ended_at}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-3">
                  <div className="col-lg-7 offset-lg-5">
                    <div className="form-group mt-2">
                      <button
                        type="button"
                        className="btn btn-lg btn-primary mr-1"
                        onClick={this.onClickCreateCoupon}
                      >
                        생성
                      </button>
                    </div>
                  </div>
                </div>

                <div className="divider"></div>
                <h5 className="card-title" style={{ display: "inline" }}>
                  쿠폰 리스트
                </h5>
                <>
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th>쿠폰명</th>
                          <th>할인액</th>
                          <th>시작일</th>
                          <th>종료일</th>
                          <th>사용여부</th>
                          {/* <th>생성일</th>
                          <th>수정일</th> */}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.coupons_all.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  id={`coupon_name_${item._id}`}
                                  defaultValue={item.name}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={`coupon_price_${item._id}`}
                                  defaultValue={item.price}
                                />
                              </td>
                              <td>
                                <input
                                  type="date"
                                  id={`coupon_started_at_${item._id}`}
                                  defaultValue={moment(item.started_at).format(
                                    "YYYY-MM-DD"
                                  )}
                                />
                              </td>
                              <td>
                                <input
                                  type="date"
                                  id={`coupon_ended_at_${item._id}`}
                                  defaultValue={moment(item.ended_at).format(
                                    "YYYY-MM-DD"
                                  )}
                                />
                              </td>
                              <td>{item.used ? "Y" : "N"}</td>
                              {/* <td>
                                {moment(item.create_at).format("YYYY-MM-DD")}
                              </td>
                              <td>
                                {moment(item.update_at).format("YYYY-MM-DD")}
                              </td> */}
                              <td>
                                <button
                                  className="btn btn-primary"
                                  data-key={index}
                                  onClick={(e) =>
                                    this.onClickCouponUpdate(e, item._id)
                                  }
                                >
                                  수정
                                </button>
                                <button
                                  className="btn btn-dim btn-outline-secondary"
                                  data-key={index}
                                  onClick={(e) =>
                                    this.onClickCouponDelete(e, item._id)
                                  }
                                >
                                  삭제
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderPoint() {
    return (
      <>
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-inner">
              {/* <div className="card-head"> */}
              <h5 className="card-title" style={{ display: "inline" }}>
                적립금
              </h5>
              <label>
                &nbsp;&nbsp;{this.state.assignee ? this.state.assignee : ""}
              </label>

              {this.context.role === "최고관리자" ||
              this.context.role === "일반관리자" ? (
                <div className="row g-3 align-center">
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label className="form-label" htmlFor="site-off">
                        지점
                      </label>
                      <span className="form-note">지점을 선택하세요</span>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <select
                          className="form-control"
                          name="point_branch"
                          onChange={this.onChange}
                          // value={this.state._branch ? this.state._branch.name : null}
                          defaultValue={this.state.point_branch}
                        >
                          {/* <option value='' disabled>선택</option> */}
                          <option disabled>
                            {this.state._branch ? "선택" : "없음"}
                          </option>
                          {/* <option value={this.state._branch ? this.state._branch.name : null} disabled>{this.state._branch ? this.state._branch.name : null}</option> */}
                          {this.state.branchArr.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* </div> */}
              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label">금액</label>
                    <span className="form-note">
                      마이너스 입력시 차감됩니다.
                    </span>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        className="form-control"
                        id="point"
                        name="point"
                        onChange={this.onChange}
                        value={this.state.point}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 align-center">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label className="form-label">만료일</label>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control"
                        id="point_expired_at"
                        name="point_expired_at"
                        onChange={this.onChange}
                        value={this.state.point_expired_at}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-lg-7 offset-lg-5">
                  <div className="form-group mt-2">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary mr-1"
                      onClick={this.onClickAddPoint}
                    >
                      추가
                    </button>
                  </div>
                </div>
              </div>

              <div className="divider"></div>
              <PointList _user={this.props.match.params.id}></PointList>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    if (!this.state.id) {
      return null;
    }

    return (
      <>
        <Menubar />
        <Content>
          <ContentHeader
            prevlink={
              this.context.role === "강사"
                ? "/book/bookmanagement"
                : "/user/userlist"
            }
            prevTitle="목록보기"
            title="회원정보"
            subTitle={`${this.state.name} 회원님의 정보`}
          />
          <div class="btn-group mb-2" aria-label="Basic example">
            <button
              onClick={() => this.setRenderState(RenderState.Info)}
              className="btn btn-outline-primary"
            >
              회원정보
            </button>
            <button
              onClick={() => this.setRenderState(RenderState.Book)}
              className="btn btn-outline-primary"
            >
              레슨 예약 내역
            </button>
            <button
              onClick={() => this.setRenderState(RenderState.LessonNote)}
              className="btn btn-outline-primary"
            >
              레슨 노트
            </button>
            <button
              onClick={() => this.setRenderState(RenderState.Coupon)}
              className="btn btn-outline-primary"
            >
              쿠폰함
            </button>
            <button
              onClick={() => this.setRenderState(RenderState.Point)}
              className="btn btn-outline-primary"
            >
              적립금
            </button>
          </div>
          {this.render_info()}
          <UserHistory
            _user={this.props.match.params.id}
            isOpen={this.state.showHistory}
            onClose={this.onCloseHistory}
          />
          <div>
            {this.state.user_before ? (
              <div className="user-before" onClick={this.onClickUserBefore}>
                <ChevronLeft className="user-navigator-icon" />
              </div>
            ) : null}
            {this.state.user_after ? (
              <div className="user-after" onClick={this.onClickUserAfter}>
                <ChevronRight className="user-navigator-icon" />
              </div>
            ) : null}
          </div>
        </Content>
      </>
    );
  }
}

export default withRouter(UserDetail);
