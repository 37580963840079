import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Menubar from "../components/Menubar";
import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Content from "../components/Content";
import UserClient from "../utils/Http/UserClient";
import BookClient from "../utils/Http/BookClient";
import BranchClient from "../utils/Http/BranchClient";
import { ArrowUp, ArrowDown } from "react-bootstrap-icons";
import ExportUserList from "../components/ExportUserList";

moment.tz.setDefault("Asia/Seoul");

const pageCountList = [10, 20, 50, 100];
const hasTicketOption = {
  All: 0,
  Positive: 1,
  Negative: 2,
};
const sortType = {
  none: 0,
  name: 1,
  id: 2,
  birth: 3,
  last_ticket_create: 4,
  user_create: 5,
  period: 6,
};
const sortOrder = [0, -1, 1];

const RenderState = {
  User: 0,
  UnreservedUser: 1,
};
class UserList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.csvLink = React.createRef();

    this.state = {
      sortType: sortType.last_ticket_create,
      branch: null,
      branchList: [],
      users: [],
      errorMsg: "",
      offset: 0,
      orgtableData: [],
      perPage: pageCountList[1],
      currentPage: 0,
      searchType: "name",
      selectedRow: [],
      sortAsc: true,
      page: 0,
      countPerPage: pageCountList[1],
      pageCount: 0,
      totalCount: 0,
      user_state: "승인",
      hasTicket: hasTicketOption.Positive,
      renderState: RenderState.User,
      sortOrderIndex: 1,
    };
  }

  loadBranchList = async () => {
    try {
      const response = await BranchClient.getList();

      if (!response.data.success) {
        alert(response.data.message);
        return;
      }

      this.setState(
        {
          branchList: response.data.result,
        },
        () => {
          this.loadUserList();
        }
      );
    } catch (err) {
      alert(err.message);
    }
  };

  loadUserList = async () => {
    var query = {
      user_state: this.state.user_state,
      ticket_state: this.state.hasTicket,
      page: this.state.page,
      countPerPage: this.state.countPerPage,
      sortType: this.state.sortType,
      sortOrder: sortOrder[this.state.sortOrderIndex % 3],
    };

    if (this.state.branch) {
      query._branch = this.state.branch;
    }

    try {
      const response = await UserClient.getList(query);

      if (response.data.success == false) {
        alert(response.data.message);
        return;
      }
      // console.log(response.data);
      this.setState({
        pageCount:
          Number.parseInt(
            (response.data.result.count - 1) / this.state.countPerPage
          ) + 1,
        totalCount: response.data.result.count,
        users: response.data.result.list,
        renderState: RenderState.User,
      });
    } catch (err) {
      alert(err.message);
    }
  };

  loadUnreservedList = async (e) => {
    e.preventDefault();

    var query = {};
    if (this.state.branch) {
      query._branch = this.state.branch;
    }

    try {
      const response = await UserClient.getUnreservedList(query);
      if (!response.data.success) {
        alert(response.data.message);
        return;
      }

      this.setState({
        pageCount:
          Number.parseInt(
            (response.data.result.count - 1) / this.state.countPerPage
          ) + 1,
        totalCount: response.data.result.count,
        users: response.data.result,
        renderState: RenderState.UnreservedUser,
      });
      console.log(response.data.result);
    } catch (ex) {
      console.log(ex);
      this.setState({ errorMsg: "Error retreiving data" });
    }
  };

  sortByBirthday = () => {
    const data = this.state.orgtableData;
    data.sort((a, b) => {
      const aBday =
        parseInt(a.birth_year) * 10000 +
        parseInt(a.birth_month) * 100 +
        parseInt(a.birth_day);
      const bBday =
        parseInt(b.birth_year) * 10000 +
        parseInt(b.birth_month) * 100 +
        parseInt(b.birth_day);

      if (this.state.sortAsc) {
        if (aBday === bBday) return 0;
        else if (aBday > bBday) return 1;
        else return -1;
      } else {
        if (aBday === bBday) return 0;
        else if (aBday > bBday) return -1;
        else return 1;
      }
    });

    const slice = data.slice(0, this.state.perPage);
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      users: slice,
      offset: 0,
      sortAsc: !this.state.sortAsc,
    });
  };

  sortByLessenDuration = () => {
    const data = this.state.orgtableData;
    data.sort((a, b) => {
      const aTime = new Date(a.holdingEndDate).getTime();
      const bTime = new Date(b.holdingEndDate).getTime();

      if (this.state.sortAsc) {
        if (aTime === bTime) return 0;
        else if (aTime > bTime) return 1;
        else return -1;
      } else {
        if (aTime === bTime) return 0;
        else if (aTime > bTime) return -1;
        else return 1;
      }
    });

    const slice = data.slice(0, this.state.perPage);
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      users: slice,
      offset: 0,
      sortAsc: !this.state.sortAsc,
    });
  };

  sortByLeftLessonNumber = () => {
    const data = this.state.orgtableData;
    data.sort((a, b) => {
      if (this.state.sortAsc) {
        if (a.lesson_number === b.lesson_number) return 0;
        else if (a.lesson_number > b.lesson_number) return 1;
        else return -1;
      } else {
        if (a.lesson_number === b.lesson_number) return 0;
        else if (a.lesson_number > b.lesson_number) return -1;
        else return 1;
      }
    });

    const slice = data.slice(0, this.state.perPage);
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      users: slice,
      offset: 0,
      sortAsc: !this.state.sortAsc,
    });
  };

  filterByLessonNumber = (number) => {
    const data = this.state.orgtableData.filter((a) =>
      a.ticket.length > 0 ? a.ticket[0].lesson <= number : false
    );
    data.sort((a, b) => {
      if (a.ticket.lesson === b.ticket.lesson) return 0;
      else if (a.ticket.lesson > b.ticket.lesson) return 1;
      else return -1;
    });

    const slice = data.slice(0, this.state.perPage);
    this.setState({
      orgtableData: data,
      pageCount: Math.ceil(data.length / this.state.perPage),
      users: slice,
      offset: 0,
    });
  };

  filterByLeftDate = (date) => {
    const data = this.state.orgtableData.filter((a) =>
      a.ticket.length > 0
        ? moment(a.ticket[a.ticket.length - 1].endDate).diff(
            moment(moment().format("YYYY-MM-DD")),
            "days"
          ) <= date
        : false
    );
    data.sort((a, b) => {
      const aTime = new Date(a.ticket[a.ticket.length - 1].endDate).getTime();
      const bTime = new Date(b.ticket[b.ticket.length - 1].endDate).getTime();

      if (aTime === bTime) return 0;
      else if (aTime > bTime) return 1;
      else return -1;
    });

    const slice = data.slice(0, this.state.perPage);
    this.setState({
      orgtableData: data,
      pageCount: Math.ceil(data.length / this.state.perPage),
      users: slice,
      offset: 0,
    });
  };

  setSearchType = (e) => {
    this.setState({ searchType: e.target.value });
  };
  setSearchText = (e) => {
    this.setState({ searchText: e.target.value });
  };
  onSearch = async (e) => {
    if (
      this.state.searchType === "lesson" ||
      this.state.searchType === "date"
    ) {
      const number = parseInt(this.state.searchText);
      if (!this.state.searchText) {
        alert("값을 입력해주세요.");
        return;
      }
      if (isNaN(number)) {
        alert("숫자를 입력해주세요.");
        return;
      }
      this.state.searchType === "lesson"
        ? this.filterByLessonNumber(number)
        : this.filterByLeftDate(number);

      return;
    }

    var query = {
      user_state: ["탈퇴", "승인", "미승인"],
      ticket_state: this.state.hasTicket,
      [this.state.searchType]: this.state.searchText,
      searchType: this.state.searchType,
      page: 0,
      countPerPage: -1,
      sortByPeriod: 0,
    };

    if (this.state.branch) {
      query._branch = this.state.branch;
    }

    try {
      const searchRes = await UserClient.getList(query);
      if (searchRes.data.success) {
        this.setState({
          users: searchRes.data.result.list,
          pageCount: 0,
        });
      } else {
        alert("잘못된 검색이거나 검색결과가 없습니다.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  onDeleteRow = async () => {
    if (!window.confirm("정말로 삭제하시겠습니까?")) return;
    try {
      for (var i = 0; i < this.state.selectedRow.length; ++i) {
        const deleteUser = await UserClient.delete({
          _id: this.state.selectedRow[i],
        });
      }

      window.location.reload();
    } catch (error) {
      alert("삭제에 실패했습니다.");
    }
  };

  onChangeSelectRow = (e) => {
    if (e.target.checked) {
      this.setState((state) => ({
        selectedRow: [...state.selectedRow, e.target.name],
      }));
    } else {
      this.setState((state) => ({
        selectedRow: state.selectedRow.filter((item) => item !== e.target.name),
      }));
    }
  };

  onCheckAll = (e) => {
    const checkboxes = document.getElementsByClassName("myCheckbox");
    checkboxes.forEach((item) => {
      item.checked = e.target.checked;
    });

    if (e.target.checked) {
      this.setState((state) => ({
        selectedRow: state.users.map((item) => item._id),
      }));
    } else {
      this.setState({
        selectedRow: [],
      });
    }
  };

  onChangeUserState = async (e) => {
    const result = await UserClient.updateState(e.target.dataset.user, {
      user_state: e.target.value,
    });
    if (result.data.success) {
      window.location.reload();
    } else {
      alert(result.data.message);
    }
  };

  getPrice(ticket) {
    let price = ticket.price;
    switch (ticket.discount_type) {
      case 1:
        price *= (100 - ticket.discount_value) / 100;
        break;
      case 2:
        price -= ticket.discount_value;
        break;
    }
    price += ticket.lesson_buy_price ? ticket.lesson_buy_price : 0;
    return price;
  }

  getPeriod(user) {
    // console.log(user);
    // const now = moment().format('YYYY-MM-DD');
    // let period = user.period;
    // user.ticket.forEach((ticket) => {
    //     if(moment(ticket.endDate).format('YYYY-MM-DD') < now)
    //         return;

    //     if(now < ticket.startDate.format('YYYY-MM-DD')) {
    //         console.log(period, moment(ticket.endDate).diff(moment(ticket.startDate), 'days'));
    //         period -= moment(ticket.endDate).diff(moment(ticket.startDate), 'days');
    //     } else {
    //         console.log(period, moment(ticket.endDate).diff(moment(), 'days'));
    //         period -= moment(ticket.endDate).diff(moment(), 'days');
    //         // console.log(moment(ticket.endDate).diff(moment(), 'days'));
    //     }
    //     // console.log(ticket);
    // });
    // console.log(period);
    // return parseInt(user.total_period / 30);
    return parseInt(user.period / 30);
    // return user.total_period;
  }

  componentDidMount() {
    if (
      this.context.role !== "최고관리자" &&
      this.context.role !== "일반관리자"
    ) {
      this.setState(
        {
          branch: this.context.branch._id,
        },
        () => {
          this.loadUserList();
        }
      );
    } else {
      this.loadBranchList();
    }
    // this.onClickUser("승인");
  }

  onClickBranch = (e) => {
    this.setState(
      {
        branch: e.target.value ? e.target.value : null,
        page: 0,
      },
      () => {
        this.loadUserList();
      }
    );
  };

  onClickPage = (e) => {
    const selectedPage = e.selected;

    this.setState(
      {
        page: selectedPage,
      },
      () => {
        this.loadUserList();
      }
    );
  };

  onClickSort = (e, type) => {
    e.preventDefault();

    let index = (this.state.sortOrderIndex + 1) % 3;
    if (type !== this.state.sortType) {
      index = 1;
    }
    this.setState(
      {
        sortType: type,
        sortOrderIndex: index,
      },
      () => {
        this.loadUserList();
      }
    );
  };

  onChange = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
        page: 0,
      },
      () => {
        this.loadUserList();
      }
    );
  };

  renderBranchList() {
    if (
      this.context.role !== "최고관리자" &&
      this.context.role !== "일반관리자"
    )
      return null;
    return (
      <select
        className="form-control"
        style={{ marginRight: 7 }}
        name="branchList"
        onChange={this.onClickBranch}
        defaultValue={this.state.branch}
      >
        <option value="">전체</option>
        {this.state.branchList.map((item, index) => (
          <option key={index} value={item._id}>
            {item.name}
          </option>
        ))}
      </select>
    );
  }

  renderSearchOption() {
    return (
      <select
        className="form-control mr-1"
        id="selectdrop"
        onChange={this.setSearchType}
      >
        <option value="" disabled>
          검색옵션
        </option>
        <option value="name" name="">
          회원명
        </option>
        <option value="phone" name="">
          전화번호
        </option>
        <option value="lesson" name="">
          레슨횟수 남은일
        </option>
        <option value="date" name="">
          이용기간 남은일
        </option>
      </select>
    );
  }

  renderCountPerPage() {
    return (
      <select
        className="form-control mr-1"
        id="countPerPage"
        onChange={this.onChange}
      >
        <option value="" disabled>
          표시 회원 수
        </option>
        {pageCountList.map((item) => {
          return (
            <option value={item} selected={this.state.countPerPage === item}>
              {item} 명
            </option>
          );
        })}
      </select>
    );
  }

  renderHasTicket() {
    return (
      <select
        className="form-control mr-1"
        id="hasTicket"
        onChange={this.onChange}
      >
        <option value="" disabled>
          수강중 여부
        </option>
        <option value={hasTicketOption.All}>전체</option>
        <option value={hasTicketOption.Positive} selected>
          수강중
        </option>
        <option value={hasTicketOption.Negative}>미수강</option>
      </select>
    );
  }

  renderUserState() {
    return (
      <select
        className="form-control mr-1"
        id="user_state"
        onChange={this.onChange}
      >
        <option value="" disabled>
          회원상태
        </option>
        <option value="">전체회원</option>
        <option value="승인">승인회원</option>
        <option value="미승인">미승인회원</option>
        <option value="탈퇴">탈퇴회원</option>
      </select>
    );
  }

  renderSortOrder() {
    if (sortOrder[this.state.sortOrderIndex] == 1) {
      return <ArrowUp className="extend-icon" />;
    } else if (sortOrder[this.state.sortOrderIndex] == -1) {
      return <ArrowDown className="extend-icon" />;
    }
    return null;
  }

  getQueryString() {
    var query_string = `?user_state=${this.state.user_state}&ticket_state=${
      this.state.hasTicket
    }&page=${this.state.page}&countPerPage=${
      this.state.countPerPage
    }&sortType=${this.state.sortType}&sortOrder=${
      sortOrder[this.state.sortOrderIndex % 3]
    }`;
    if (this.state.branch) query_string += `&_branch=${this.state.branch}`;
    return query_string;
  }

  renderUserList() {
    return this.state.users.map((user, index) => (
      <tr key={index}>
        <th scope="row">
          <input
            className="myCheckbox"
            type="checkbox"
            name={user._id}
            onChange={this.onChangeSelectRow}
          />
        </th>
        <td>{index + 1}</td>
        <td>
          <Link to={"/user/userdetail/" + user._id + this.getQueryString()}>
            <a href={"/user/userdetail/" + user._id + this.getQueryString()}>
              {user.name ? user.name : null}
            </a>
          </Link>
        </td>
        <td>{user.id ? user.id : null}</td>
        <td>{user.phone ? user.phone : null}</td>
        <td>
          {user.birth_year ? user.birth_year : null}.
          {user.birth_month ? user.birth_month : null}.
          {user.birth_day ? user.birth_day : null}
        </td>
        <td>{user.gender ? user.gender : null}</td>
        <td>{user.branch ? user.branch.name : null}</td>
        <td>
          {user.ticket.length > 0 ? user.ticket[0].name : null}
          {user.ticket.length > 1 ? "(+)" : ""}
        </td>
        <td>
          {user.ticket.length > 0
            ? moment(user.ticket[0].startDate).format("YYYY.MM.DD")
            : null}
          ~
          {user.ticket.length > 0
            ? moment(user.ticket[0].endDate).format("YYYY.MM.DD")
            : null}
        </td>
        <td>
          {user.ticket.length > 0 ? user.ticket[0].lesson : null} /{" "}
          {user.ticket.length > 0
            ? user.ticket[0].lesson_all +
              user.ticket[0].lesson_buy +
              user.ticket[0].lesson_carry_over
            : null}
        </td>
        <td>{user.ticket.length > 0 ? this.getPrice(user.ticket[0]) : 0}</td>
        <td>{moment(user.last_ticket_register_at).format("YYYY.MM.DD")}</td>
        <td>
          {this.getPeriod(user)}
          {/* {user.period ? parseInt(user.period / 30) : 0} */}
        </td>
        <td>
          {moment(user.create_at).format("YYYY.MM.DD")
            ? moment(user.create_at).format("YYYY.MM.DD")
            : null}
        </td>
      </tr>
    ));
  }

  render() {
    const { users } = this.state;

    if (
      this.context.role !== "일반관리자" &&
      this.context.role !== "최고관리자" &&
      this.context.role !== "지점관리자" &&
      this.context.role !== "매니저"
    ) {
      return (
        <div>
          <Menubar />
          <div className="nk-content">
            <div className="container-fluid">접근 권한이 없습니다.</div>
          </div>
        </div>
      );
    }

    if (!users) {
      return null;
    }
    return (
      <>
        <Menubar />
        <Content>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">회원 리스트</h3>
                {/* <div className="nk-block-des text-soft">
                                    <p>{this.state.users.length}건의 데이터 조회</p>
                                </div> */}
              </div>
              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    <em className="icon ni ni-menu-alt-r"></em>
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      <li>
                        <ExportUserList
                          branch={this.state.branch}
                          user_state={this.state.user_state}
                          ticket_state={this.state.hasTicket}
                          page={this.state.page}
                          countPerPage={-1}
                          sortByPeriod={sortOrder[this.state.sortOrderIndex]}
                        />
                      </li>
                      <li className="nk-block-tools-opt">
                        <Link to="/user/userregister">
                          <a
                            href="/user/userregister"
                            className="btn btn-primary"
                          >
                            데이터 추가
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            <div className="card card-bordered card-stretch">
              <div className="card-inner-group">
                <div className="card-inner position-relative card-tools-toggle">
                  <div className="card-title-group d-md-flex d-block">
                    <div className="card-tools mb-1">
                      {this.renderBranchList()}
                    </div>
                  </div>
                </div>
                <div className="card-inner position-relative card-tools-toggle">
                  <div className="card-title-group d-md-flex d-block">
                    <div className="card-tools mb-1">
                      <div className="form-inline flex-nowrap gx-3">
                        <div className="form-wrap w-150px">
                          <button
                            type="button"
                            className="btn btn-outline-secondary "
                            onClick={this.onDeleteRow}
                          >
                            선택삭제
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="card-tools mr-n1 mb-1">
                      <ul className="btn-toolbar gx-1">
                        <li>
                          <a
                            href={null}
                            className="btn btn-icon search-toggle toggle-search"
                            data-target="search"
                          >
                            <em className="icon ni ni-search"></em>
                          </a>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <li>
                          <div className="d-flex">
                            {this.renderSearchOption()}
                            {this.renderHasTicket()}
                            {this.renderUserState()}
                            {this.renderCountPerPage()}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="card-search search-wrap" data-search="search">
                    <div className="card-body">
                      <div className="search-content">
                        <div className="row">
                          <button
                            // href={null}
                            className="search-back btn btn-icon toggle-search"
                            data-target="search"
                            onClick={() => {
                              this.loadUserList();
                            }}
                          >
                            <em className="icon ni ni-arrow-left"></em>
                          </button>
                          <input
                            type="text"
                            className="form-control border-transparent form-focus-none"
                            placeholder="검색"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") this.onSearch();
                            }}
                            onChange={this.setSearchText}
                          />
                        </div>
                        <button
                          className="search-submit btn btn-icon"
                          onClick={this.onSearch}
                        >
                          <em className="icon ni ni-search"></em>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 테이블시작 */}
                <div class="card-inner p-0">
                  <div class="table-responsive">
                    <table class="table">
                      <thead className="thead-light">
                        <tr>
                          <th>
                            <input type="checkbox" onChange={this.onCheckAll} />
                          </th>
                          <th></th>
                          <th
                            onClick={(e) => this.onClickSort(e, sortType.name)}
                          >
                            회원명{" "}
                            {this.state.sortType === sortType.name
                              ? this.renderSortOrder()
                              : null}
                          </th>
                          <th onClick={(e) => this.onClickSort(e, sortType.id)}>
                            아이디{" "}
                            {this.state.sortType === sortType.id
                              ? this.renderSortOrder()
                              : null}
                          </th>
                          <th>휴대전화</th>
                          <th
                            onClick={(e) => this.onClickSort(e, sortType.birth)}
                          >
                            생년월일{" "}
                            {this.state.sortType === sortType.birth
                              ? this.renderSortOrder()
                              : null}
                          </th>
                          <th>성별</th>
                          <th>지점</th>
                          <th>수강내역</th>
                          <th>수강기간</th>
                          <th>레슨횟수</th>
                          <th>결제금액</th>
                          <th
                            onClick={(e) =>
                              this.onClickSort(e, sortType.last_ticket_create)
                            }
                          >
                            마지막 등록일{" "}
                            {this.state.sortType === sortType.last_ticket_create
                              ? this.renderSortOrder()
                              : null}
                          </th>
                          <th
                            onClick={(e) =>
                              this.onClickSort(e, sortType.period)
                            }
                          >
                            누적기간{" "}
                            {this.state.sortType === sortType.period
                              ? this.renderSortOrder()
                              : null}
                          </th>
                          <th
                            onClick={(e) =>
                              this.onClickSort(e, sortType.user_create)
                            }
                          >
                            가입일{" "}
                            {this.state.sortType === sortType.user_create
                              ? this.renderSortOrder()
                              : null}
                          </th>
                        </tr>
                      </thead>
                      <tbody>{this.renderUserList()}</tbody>
                    </table>
                  </div>
                </div>
                <div className="card-inner">
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.onClickPage}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    className="text-center"
                    forcePage={this.state.page}
                  />
                </div>
              </div>
            </div>
          </div>
        </Content>
      </>
    );
  }
}

export default withRouter(UserList);
