import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import jwt_decode from "jwt-decode";
import Content from "../components/Content";
import ContentHeader from "../components/ContentHeader";
import BranchClient from "../utils/Http/BranchClient";
import TicketClient from "../utils/Http/TicketClient";

// import moment from 'moment';
// moment.tz.setDefault("Asia/Seoul");

class BranchRegister extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem("token_mlb");
        const decoded = jwt_decode(token);

        this.state = {
            _branch: '',
            branches: [],
            name: "",
            address: "",
            phone: "",
            operate_hour: "",
            kakaoid: "",
            _author: decoded._id,
        };
    }

    componentDidMount () {
        this.LoadBranches();
    };

    LoadBranches = async () => {
        try
        {
            const response = await BranchClient.getList();

            if(!response.data.success)
            {
                alert(response.data.message);
                return;
            }

            this.setState({
                branches: response.data.result,
                _branch: response.data.result && response.data.result.length > 0 ? response.data.result[0]._id : '',
            });
        }
        catch(error)
        {
            alert(error.message);
        }
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        const sign = {
            name: this.state.name,
            address: this.state.address,
            phone: this.state.phone,
            operate_hour: this.state.operate_hour,
            kakaoid: this.state.kakaoid,
            _author: this.state._author,
            branch_base: this.state._branch,
        };

        try
        {
            const res = await BranchClient.create(sign);
            if (res.data.success === true) {
                // this.props.history.push('/branch/branchlist');
                window.location.href = "/branch/branchlist";
            } else {
                alert(res.data.message);
            }
        }
        catch(ex)
        {
            console.log(ex);
            alert(ex.message + "실패");
        }
    };

    render() {
        return (
            <>
                <Menubar />
                <Content>
                    <ContentHeader
                        prevlink="/branch/branchlist"
                        prevTitle="목록보기"
                        title="지점 등록하기"
                    />
                    <div className="nk-block">
                        <div class="card card-bordered">
                            <div class="card-inner">
                                <div class="card-head">
                                    <h5 class="card-title">지점정보</h5>
                                </div>
                                <form
                                    onSubmit={this.onSubmit}
                                    className="text-left mt-4"
                                >
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    지점 정보
                                                </label>
                                                <span className="form-note">
                                                    선택된 지점의 정보를 가져와 설정합니다(수강권, 추가레슨, 휴일)
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <select
                                                        className="form-control"
                                                        id="_branch"
                                                        name="_branch"
                                                        defaultValue={this.state._branch}
                                                        onChange={this.onChange}
                                                    >
                                                        <option
                                                            value=""
                                                        >
                                                            선택
                                                        </option>
                                                        {
                                                            this.state.branches.map((item) => {
                                                                return (<option value={item._id} selected={this.state._branch == item._id ? true : false}>{item.name}</option>);
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    이름
                                                </label>
                                                <span className="form-note">
                                                    등록할 지점 이름
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        onChange={this.onChange}
                                                        required="required"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    주소
                                                </label>
                                                <span className="form-note">
                                                    등록할 지점 주소
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        onChange={this.onChange}
                                                        required="required"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    전화번호
                                                </label>
                                                <span className="form-note">
                                                    - 없이 입력
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="phone"
                                                        name="phone"
                                                        onChange={this.onChange}
                                                        required="required"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    운영시간
                                                </label>
                                                <span className="form-note">
                                                    ex) 00:00시 부터 ~ 00:00시
                                                    까지
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="operate_hour"
                                                        name="operate_hour"
                                                        onChange={this.onChange}
                                                        required="required"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    for="site-name"
                                                >
                                                    카카오톡 아이디
                                                </label>
                                                <span className="form-note">
                                                    카카오톡 상담 아이디
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="kakaoid"
                                                        name="kakaoid"
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3">
                                        <div className="col-lg-7 offset-lg-5">
                                            <div className="form-group mt-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-primary mr-1"
                                                >
                                                    확인
                                                </button>
                                                <Link to="/branch/branchlist">
                                                    <a
                                                        href="/branch/branchlist"
                                                        className="btn btn-lg btn-secondary"
                                                    >
                                                        취소
                                                    </a>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Content>
            </>
        );
    }
}

export default withRouter(BranchRegister);
