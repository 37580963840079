import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";

import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import moment from "moment";

import { CSVLink } from "react-csv";
import Content from "../components/Content";
import AdminClient from "../utils/Http/AdminClient"
import BranchClient from "../utils/Http/BranchClient"
import SubjectClient from "../utils/Http/SubjectClient"

class TeacherList extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props);

        this.state = {
            teachers: [],
            errorMsg: "",
            offset: 0,
            orgtableData: [],
            perPage: 10,
            currentPage: 0,
            searchType: "name",
            selectedRow: [],
            branches: [],
            subjects: [],
            selectedSubject: "",
            selectedBranch: context.branch ? context.branch._id : "",
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState(
            {
                currentPage: selectedPage,
                offset: offset,
            },
            () => {
                this.loadMoreData();
            }
        );
    };

    loadMoreData() {
        const data = this.state.orgtableData;

        const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
        );
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            teachers: slice,
        });
    }

    setSearchType = (e) => {
        this.setState({
            searchType: e.target.value,
        });
    };

    setSearchText = (e) => {
        this.setState({
            searchText: e.target.value,
        });
    };

    onSearch = async (e) => {
        console.log(e);
        try {
            let params = { role: "강사" };
            params[this.state.searchType] = this.state.searchText;
            if (this.state.selectedBranch) {
                params._branch = this.state.selectedBranch;
            }
            if (this.state.selectedSubject) {
                params._subject = this.state.selectedSubject;
            }

            console.log(params);

            const searchRes = await AdminClient.getAdminList(params);

            if (searchRes.data.success) {
                this.setState({
                    teachers: searchRes.data.result,
                });
            } else {
                alert("잘못된 검색이거나 검색결과가 없습니다.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    onDeleteRow = async () => {
        if (!window.confirm("정말로 삭제하시겠습니까?")) return;
        try {
            const deleteUser = await AdminClient.delete({
                _id: this.state.selectedRow,
            });

            if (deleteUser.data.success) {
                window.location.reload();
            } else {
                alert("삭제에 실패했습니다.");
            }
        } catch (error) {
            alert("삭제에 실패했습니다.");
        }
    };

    onChangeSelectRow = (e) => {
        if (e.target.checked) {
            this.setState((state) => ({
                selectedRow: [...state.selectedRow, e.target.name],
            }));
        } else {
            this.setState((state) => ({
                selectedRow: state.selectedRow.filter(
                    (item) => item !== e.target.name
                ),
            }));
        }
    };

    onCheckAll = (e) => {
        const checkboxes = document.getElementsByClassName("myCheckbox");
        checkboxes.forEach((item) => {
            item.checked = e.target.checked;
        });

        if (e.target.checked) {
            this.setState((state) => ({
                selectedRow: state.teachers.map((item) => item._id),
            }));
        } else {
            this.setState({
                selectedRow: [],
            });
        }
    };

    onDeleteRow = async () => {
        if (!window.confirm("정말로 삭제하시겠습니까?")) return;
        try {
            const deleteUser = await AdminClient.delete({
                _id: this.state.selectedRow,
            });

            if (deleteUser.data.success) {
                window.location.reload();
            } else {
                alert("삭제에 실패했습니다.");
            }
        } catch (error) {
            alert("삭제에 실패했습니다.");
        }
    };

    onChangeSubjectCategory = async (e) => {
        let teachers = [];

        let params = {
            role: "강사",
            _subject: e.target.value ? e.target.value : undefined,
        };
        if (this.state.selectedBranch) {
            params._branch = this.state.selectedBranch;
        }

        teachers = await AdminClient.getAdminList(params);

        if (teachers.data.result) {
            // document.getElementById('searchinput').value = ''
            var slice = teachers.data.result.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
            );
            this.setState({
                pageCount: Math.ceil(
                    teachers.data.result.length / this.state.perPage
                ),
                orgtableData: teachers.data.result,
                teachers: slice,
                // teachers: teachers.data.result,
                selectedSubject: e.target.value,
                searchText: "",
            });
        } else {
            alert("데이터 조회 실패");
        }
    };

    onChnageBranchCategory = async (e) => {
        let teachers = [];

        let params = {
            role: "강사",
            _branch: e.target.value ? e.target.value : undefined,
        };
        if (this.state.selectedSubject) {
            params._subject = this.state.selectedSubject;
        }

        teachers = await AdminClient.getAdminList(params);

        if (teachers.data.result) {
            // document.getElementById('searchinput').value = ''
            var slice = teachers.data.result.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
            );
            this.setState({
                pageCount: Math.ceil(
                    teachers.data.result.length / this.state.perPage
                ),
                orgtableData: teachers.data.result,
                teachers: slice,
                // teachers: teachers.data.result,
                selectedBranch: e.target.value,
                searchText: "",
            });
        } else {
            alert("데이터 조회 실패");
        }
    };

    componentDidMount = async () => {
        var query = {
            role: '강사'
        };
        if (this.context.role === "지점관리자" || this.context.role === "매니저") {
            query._branch = this.context.branch._id;
        }

        const teachers = await AdminClient.getAdminList(query);
        const branches = await BranchClient.getList();
        const subjects = await SubjectClient.getList();

        if (
            teachers.data.success &&
            branches.data.success &&
            subjects.data.success
        ) {
            var slice = teachers.data.result.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
            );
            this.setState({
                pageCount: Math.ceil(
                    teachers.data.result.length / this.state.perPage
                ),
                orgtableData: teachers.data.result,
                teachers: slice,
                branches: branches.data.result,
                subjects: subjects.data.result,
            });
        } else {
            alert("데이터 조회 실패");
        }
    };
    render() {
        if (
            this.context.role !== "일반관리자" &&
            this.context.role !== "최고관리자" &&
            this.context.role !== "지점관리자" &&
            this.context.role !== "매니저"
        ) {
            return (
                <div>
                    <Menubar />
                    <div className="nk-content">
                        <div className="container-fluid">
                            접근 권한이 없습니다.
                        </div>
                    </div>
                </div>
            );
        }

        const { teachers } = this.state;
        if (!teachers) {
            return null;
        }

        return (
            <>
                <Menubar />
                <Content>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">
                                    강사 리스트
                                </h3>
                            </div>
                            <div className="nk-block-head-content">
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                    >
                                        <em className="icon ni ni-menu-alt-r"></em>
                                    </a>
                                    <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                    >
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <CSVLink
                                                    data={this.state.teachers}
                                                    className="btn btn-white btn-outline-light"
                                                >
                                                    <em className="icon ni ni-download-cloud"></em>
                                                    Export
                                                </CSVLink>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                                <Link to="/teacher/teacherregister">
                                                    <a
                                                        href="/teacher/teacherregister"
                                                        className="btn btn-primary"
                                                    >
                                                        데이터 추가
                                                    </a>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <div className="card card-bordered card-stretch">
                            <div className="card-inner-group">
                                <div className="card-inner position-relative card-tools-toggle">
                                    <div className="card-title-group d-md-flex d-block">
                                        <div className="card-tools mb-1">
                                            <div className="form-inline flex-nowrap gx-3">
                                                <div className="form-wrap w-150px">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary "
                                                        onClick={
                                                            this.onDeleteRow
                                                        }
                                                    >
                                                        선택삭제
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-tools mr-n1 mb-1">
                                            <ul className="btn-toolbar gx-1">
                                                <li>
                                                    <a
                                                        href={null}
                                                        className="btn btn-icon search-toggle toggle-search"
                                                        data-target="search"
                                                    >
                                                        <em className="icon ni ni-search"></em>
                                                    </a>
                                                </li>
                                                <li className="btn-toolbar-sep"></li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="mr-1">
                                                            <select
                                                                className="form-control "
                                                                id="selectdrop"
                                                                onChange={
                                                                    this
                                                                        .setSearchType
                                                                }
                                                            >
                                                                <option
                                                                    value=""
                                                                    disabled
                                                                >
                                                                    선택
                                                                </option>
                                                                <option value="name">
                                                                    강사명
                                                                </option>
                                                                <option value="id">
                                                                    아이디
                                                                </option>
                                                                <option value="phone">
                                                                    연락처
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div className="mr-1">
                                                            <select
                                                                name="subjectCategory"
                                                                id="subjectCategory"
                                                                className="form-control"
                                                                defaultValue={
                                                                    this.state
                                                                        .selectedSubject
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChangeSubjectCategory
                                                                }
                                                            >
                                                                <option value="">
                                                                    과목전체
                                                                </option>
                                                                {this.state.subjects.map(
                                                                    (item) => (
                                                                        <option
                                                                            key={
                                                                                item._id
                                                                            }
                                                                            value={
                                                                                item._id
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                        {this.context.role !== "지점관리자" && this.context.role !== "매니저" ? (
                                                            <div>
                                                                <select
                                                                    className="form-control "
                                                                    name="branchCategory"
                                                                    id="branchCategory"
                                                                    defaultValue={
                                                                        this
                                                                            .state
                                                                            .selectedBranch
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChnageBranchCategory
                                                                    }
                                                                >
                                                                    <option value="">
                                                                        전체
                                                                    </option>
                                                                    {this.state.branches.map(
                                                                        (
                                                                            item
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    item._id
                                                                                }
                                                                                value={
                                                                                    item._id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </select>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div
                                        className="card-search search-wrap"
                                        data-search="search"
                                    >
                                        <div className="card-body">
                                            <div className="search-content">
                                                <div className="row">
                                                    <a
                                                        href={null}
                                                        className="search-back btn btn-icon toggle-search"
                                                        data-target="search"
                                                    >
                                                        <em className="icon ni ni-arrow-left"></em>
                                                    </a>
                                                    <input
                                                        type="text"
                                                        className="form-control border-transparent form-focus-none"
                                                        placeholder="검색"
                                                        onChange={
                                                            this.setSearchText
                                                        }
                                                    />
                                                </div>
                                                <button
                                                    className="search-submit btn btn-icon"
                                                    onClick={this.onSearch}
                                                >
                                                    <em className="icon ni ni-search"></em>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-inner p-0">
                                    <div class="table-responsive">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            onChange={
                                                                this.onCheckAll
                                                            }
                                                        />
                                                    </th>
                                                    <th></th>
                                                    <th>아이디</th>
                                                    <th>강사명</th>
                                                    <th>휴대전화</th>
                                                    <th>지점명</th>
                                                    <th>레슨종목</th>
                                                    <th>가입일</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {teachers.map(
                                                    (teacher, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">
                                                                <input
                                                                    className="myCheckbox"
                                                                    type="checkbox"
                                                                    name={
                                                                        teacher._id
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChangeSelectRow
                                                                    }
                                                                />
                                                            </th>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {teacher.id}
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    to={
                                                                        "/teacher/teacherprofile/" +
                                                                        teacher._id
                                                                    }
                                                                >
                                                                    <a
                                                                        href={
                                                                            "/teacher/teacherprofile/" +
                                                                            teacher._id
                                                                        }
                                                                    >
                                                                        {
                                                                            teacher.name
                                                                        }
                                                                    </a>
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                {teacher.phone}
                                                            </td>
                                                            <td>
                                                                {teacher._branch
                                                                    ? teacher
                                                                          ._branch
                                                                          .name
                                                                    : null}
                                                            </td>
                                                            <td>
                                                                {teacher._subject
                                                                    ? teacher
                                                                          ._subject
                                                                          .name
                                                                    : null}
                                                            </td>
                                                            <td>
                                                                {moment(
                                                                    teacher.create_at
                                                                ).format(
                                                                    "YYYY년 MM월 DD일"
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-inner">
                                    <ReactPaginate
                                        previousLabel={"Prev"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        previousClassName={"page-item"}
                                        nextClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextLinkClassName={"page-link"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </>
        );
    }
}

export default withRouter(TeacherList);
