import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Menubar from "../components/Menubar";
import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { CSVLink } from "react-csv";
import Content from "../components/Content";
import UserClient from "../utils/Http/UserClient";

class UserDelete extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            errorMsg: "",
            offset: 0,
            orgtableData: [],
            perPage: 10,
            currentPage: 0,
            searchType: "name",
            selectedRow: [],
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState(
            {
                currentPage: selectedPage,
                offset: offset,
            },
            () => {
                this.loadMoreData();
            }
        );
    };

    loadMoreData() {
        const data = this.state.orgtableData;

        const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
        );
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            users: slice,
        });
    }

    setSearchType = (e) => {
        this.setState({
            searchType: e.target.value,
        });
    };

    setSearchText = (e) => {
        this.setState({
            searchText: e.target.value,
        });
    };

    onSearch = async (e) => {
        console.log(e);
        try {
            const searchRes = await UserClient.getList({
                user_state: "탈퇴",
                [this.state.searchType]: this.state.searchText,
                searchType: this.state.searchType,
            });

            if (searchRes.data.success) {
                this.setState({
                    users: searchRes.data.result,
                });
            } else {
                alert("잘못된 검색이거나 검색결과가 없습니다.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    onDeleteRow = async () => {
        if (!window.confirm("정말로 삭제하시겠습니까?")) return;
        try {
            const deleteUser = await UserClient.delete({
                _id: this.state.selectedRow,
            });

            if (deleteUser.data.success) {
                window.location.reload();
            } else {
                alert("삭제에 실패했습니다.");
            }
        } catch (error) {
            alert("삭제에 실패했습니다.");
        }
    };

    onCheckAll = (e) => {
        console.log(e.target.checked);
        const checkboxes = document.getElementsByClassName("myCheckbox");
        console.log("checkboxes", checkboxes);
        // for(var checkbox in checkboxes) {
        //     checkbox.checked = e.target.checked;
        // }
        checkboxes.forEach((item) => {
            item.checked = e.target.checked;
        });

        if (e.target.checked) {
            this.setState((state) => ({
                selectedRow: state.users.map((item) => item._id),
            }));
        } else {
            this.setState({
                selectedRow: [],
            });
        }
    };

    onChangeSelectRow = (e) => {
        if (e.target.checked) {
            this.setState((state) => ({
                selectedRow: [...state.selectedRow, e.target.name],
            }));
        } else {
            this.setState((state) => ({
                selectedRow: state.selectedRow.filter(
                    (item) => item !== e.target.name
                ),
            }));
        }
    };

    componentDidMount = async () => {
        try{
            const res = await UserClient.getList({
                user_state: '탈퇴'
            });

            var data = res.data.result;
            console.log(data);
            var slice = data.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
            );

            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                orgtableData: res.data.result,
                users: slice,
            });
        }
        catch(err){
            console.log(err);
            this.setState({ errorMsg: "Error retreiving data" });
        }
    }
    render() {
        const { users } = this.state;

        if (!users) {
            return null;
        }
        return (
            <>
                <Menubar />
                <Content>
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">
                                    탈퇴 회원 리스트
                                </h3>
                            </div>
                            <div className="nk-block-head-content">
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                    >
                                        <em className="icon ni ni-menu-alt-r"></em>
                                    </a>
                                    <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                    >
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <CSVLink
                                                    data={this.state.users}
                                                    className="btn btn-white btn-outline-light"
                                                >
                                                    <em className="icon ni ni-download-cloud"></em>
                                                    Export
                                                </CSVLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <div className="card card-bordered card-stretch">
                            <div className="card-inner-group">
                                <div className="card-inner position-relative card-tools-toggle">
                                    <div className="card-title-group">
                                        <div className="card-tools">
                                            <div className="form-inline flex-nowrap gx-3">
                                                <div className="form-wrap w-150px">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary "
                                                        onClick={
                                                            this.onDeleteRow
                                                        }
                                                    >
                                                        선택삭제
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-tools mr-n1">
                                            <ul className="btn-toolbar gx-1">
                                                <li>
                                                    <a
                                                        href={null}
                                                        className="btn btn-icon search-toggle toggle-search"
                                                        data-target="search"
                                                    >
                                                        <em className="icon ni ni-search"></em>
                                                    </a>
                                                </li>
                                                <li className="btn-toolbar-sep"></li>
                                                <li>
                                                    <div className="d-flex">
                                                        <select
                                                            className="form-control mr-1"
                                                            id="selectdrop"
                                                            onChange={
                                                                this
                                                                    .setSearchType
                                                            }
                                                        >
                                                            <option
                                                                value=""
                                                                disabled
                                                            >
                                                                검색옵션
                                                            </option>
                                                            <option
                                                                value="name"
                                                                name=""
                                                            >
                                                                회원명
                                                            </option>
                                                            <option
                                                                value="phone"
                                                                name=""
                                                            >
                                                                전화번호
                                                            </option>
                                                        </select>
                                                        <Link to="/user/userlist">
                                                            <a
                                                                href="/user/userlist"
                                                                className="btn btn-dim btn-info btn-block mr-1"
                                                            >
                                                                전체회원
                                                            </a>
                                                        </Link>
                                                        <Link to="/user/userdelete">
                                                            <a
                                                                href="/user/userdelete"
                                                                className="btn btn-dim btn-outline-info btn-block"
                                                            >
                                                                탈퇴회원
                                                            </a>
                                                        </Link>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div
                                        className="card-search search-wrap"
                                        data-search="search"
                                    >
                                        <div className="card-body">
                                            <div className="search-content">
                                                <div className="row">
                                                    <a
                                                        href={null}
                                                        className="search-back btn btn-icon toggle-search"
                                                        data-target="search"
                                                    >
                                                        <em className="icon ni ni-arrow-left"></em>
                                                    </a>
                                                    <input
                                                        type="text"
                                                        className="form-control border-transparent form-focus-none"
                                                        placeholder="검색"
                                                        onChange={
                                                            this.setSearchText
                                                        }
                                                    />
                                                </div>
                                                <button
                                                    className="search-submit btn btn-icon"
                                                    onClick={this.onSearch}
                                                >
                                                    <em className="icon ni ni-search"></em>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* 테이블시작 */}
                                <div class="card-inner p-0">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            onChange={
                                                                this.onCheckAll
                                                            }
                                                        />
                                                    </th>
                                                    <th>_id</th>
                                                    <th>탈퇴일</th>
                                                    <th>가입일</th>
                                                    <th>이름</th>
                                                    <th>아이디</th>
                                                    <th>전화번호</th>
                                                    <th>수강내역</th>
                                                    <th>회원상태</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((user, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">
                                                            <input
                                                                className="myCheckbox"
                                                                type="checkbox"
                                                                name={user._id}
                                                                onChange={
                                                                    this
                                                                        .onChangeSelectRow
                                                                }
                                                            />
                                                        </th>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {user.delete_at
                                                                ? moment(
                                                                      user.delete_at
                                                                  ).format(
                                                                      "YYYY.MM.DD"
                                                                  )
                                                                : null}
                                                        </td>
                                                        <td>
                                                            {user.create_at
                                                                ? moment(
                                                                      user.create_at
                                                                  ).format(
                                                                      "YYYY.MM.DD"
                                                                  )
                                                                : null}
                                                        </td>
                                                        <td>{user.name}</td>
                                                        <td>{user.id}</td>
                                                        <td>{user.phone}</td>
                                                        <td>
                                                            {user.lesson_course}
                                                        </td>
                                                        <td>
                                                            {user.user_state}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-inner">
                                    <ReactPaginate
                                        previousLabel={"Prev"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        previousClassName={"page-item"}
                                        nextClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextLinkClassName={"page-link"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </>
        );
    }
}

export default withRouter(UserDelete);
