import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Menubar from "../components/Menubar";
import { AuthContext } from "../components/AuthenticatedComponent";
import Validation from "../utils/Validations";
import Content from "../components/Content";
import AdminClient from "../utils/Http/AdminClient";
import BranchClient from "../utils/Http/BranchClient";
import SubjectClient from "../utils/Http/SubjectClient";

import moment from "moment";
// moment.tz.setDefault("Asia/Seoul");

class TeacherRegister extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      id: "",
      password: "",
      password2: "",
      phone: "",
      _branch: "",
      branchArr: [],
      _subject: "",
      subjectArr: [],
      role: "강사",
      lesson_price: 0,
      cancel_price: 0,
      contract_period_start: moment().format("YYYY-MM-DD"),
      contract_period_end: moment().format("YYYY-MM-DD"),
    };
  }

  componentDidMount = async () => {
    try {
      const branchList = await BranchClient.getList();
      const subjectList = await SubjectClient.getList();

      this.setState({
        branchArr:
          this.context.role === "지점관리자"
            ? [
                {
                  _id: this.context.branch._id,
                  name: this.context.branch.name,
                },
              ]
            : branchList.data.success
            ? branchList.data.result
            : [],
        subjectArr: subjectList.data.success ? subjectList.data.result : [],
      });
    } catch (error) {
      console.log(error);
      this.setState({ errorMsg: "Error retreiving data" });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const idValidation = await Validation.id(this.state.id);

    // console.log(this.state.contract_period_end , this.state.contract_period_start);
    const contract_period_start = moment(
      this.state.contract_period_start
    ).format("YYYY-MM-DD");
    const contract_period_end = moment(this.state.contract_period_end).format(
      "YYYY-MM-DD"
    );
    if (contract_period_end < contract_period_start) {
      alert("계약 종료일은 시작일 이후로 설정되어야합니다.");
      return;
    }

    if (!idValidation.success) {
      alert(idValidation.message);
    } else {
      const sign = {
        name: this.state.name,
        id: this.state.id,
        password: this.state.password.toLowerCase(),
        password2: this.state.password2.toLowerCase(),
        phone: this.state.phone,
        _branch: this.state._branch,
        _subject: this.state._subject,
        role: this.state.role,
        lesson_price: this.state.lesson_price,
        cancel_price: this.state.cancel_price,
        contract_period_start: contract_period_start,
        contract_period_end: contract_period_end,
      };

      try {
        const res = await AdminClient.create(sign);

        if (res.data.success === true) {
          alert(res.data.message);
          // this.props.history.push('/teacher/teacherlist');
          window.location.href = "/teacher/teacherlist";
        } else {
          alert(res.data.message);
        }
      } catch (err) {
        console.log(err);
        alert(err.message + "실패");
      }
    }
  };

  render() {
    if (
      this.context.role !== "최고관리자" &&
      this.context.role !== "일반관리자" &&
      this.context.role !== "지점관리자" &&
      this.context.role !== "매니저"
    ) {
      return (
        <div>
          <Menubar />
          <div className="nk-content">
            <div className="container-fluid">접근 권한이 없습니다.</div>
          </div>
        </div>
      );
    }
    // console.log(this.context.branch);
    return (
      <>
        <Menubar />
        <Content>
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">강사 등록</h3>
                {/* <div className="nk-block-des text-soft">
                                <p>{this.state.users.length}건의 데이터 조회</p>
                            </div> */}
              </div>
            </div>
          </div>
          <div className="nk-block">
            <div className="card card-bordered">
              <div className="card-inner">
                <div className="card-head">
                  <h5 className="card-title">계정</h5>
                </div>
                <form onSubmit={this.onSubmit} className="text-left mt-4">
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          이름
                        </label>
                        <span className="form-note">강사이름</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          아이디
                        </label>
                        <span className="form-note">로그인 아이디</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            id="id"
                            name="id"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          비밀번호
                        </label>
                        <span className="form-note">
                          비밀번호를 입력하세요.
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          비밀번호 확인
                        </label>
                        <span className="form-note">
                          비밀번호와 같은 비밀번호를 입력하세요
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            className="form-control"
                            id="password2"
                            name="password2"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          휴대전화
                        </label>
                        <span className="form-note">- 없이 입력</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          소속지점
                        </label>
                        <span className="form-note">지점 선택</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <select
                            className="form-control"
                            name="_branch"
                            id="form_message"
                            onChange={this.onChange}
                            defaultValue={this.state._branch}
                            required="required"
                          >
                            <option value="" disabled>
                              선택
                            </option>
                            {this.state.branchArr.map((item, index) => {
                              if (
                                (this.context.role == "지점관리자" ||
                                  this.context.role == "매니저") &&
                                this.context.branch._id != item._id
                              ) {
                                return null;
                              }
                              return (
                                <option key={index} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          과목
                        </label>
                        <span className="form-note">과목 선택</span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <select
                            className="custom-select"
                            name="_subject"
                            id="form_message"
                            onChange={this.onChange}
                            defaultValue={this.state._subject}
                            required="required"
                          >
                            <option value="" disabled>
                              선택
                            </option>
                            {this.state.subjectArr.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          레슨비
                        </label>
                        <span className="form-note">
                          레슨 1회당 비용을 입력하세요.
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            className="form-control"
                            id="lesson_price"
                            name="lesson_price"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          당일 취소
                        </label>
                        <span className="form-note">
                          당일 취소 1회당 비용을 입력하세요.
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            className="form-control"
                            id="cancel_price"
                            name="cancel_price"
                            onChange={this.onChange}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 align-center">
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label className="form-label" for="site-name">
                          계약 기간
                        </label>
                        <span className="form-note">
                          출강 시작 / 종료일을 입력하세요.
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="row">
                        <div className="col duration">
                          <input
                            type="date"
                            className="form-control"
                            name="contract_period_start"
                            onChange={this.onChange}
                            defaultValue={this.state.contract_period_start}
                            required="required"
                          />
                        </div>
                        <div className="col">
                          <input
                            type="date"
                            className="form-control"
                            name="contract_period_end"
                            onChange={this.onChange}
                            defaultValue={this.state.contract_period_end}
                            // disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-lg-7 offset-lg-5">
                      <div className="form-group mt-2">
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary mr-1"
                        >
                          저장
                        </button>
                        <Link to="/teacher/teacherlist">
                          <a
                            href="/teacher/teacherlist"
                            className="btn btn-lg btn-secondary"
                          >
                            취소
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Content>
      </>
    );
  }
}

export default withRouter(TeacherRegister);
