import AxiosClient from "./AxiosClient";

const TicketClient = {
  getList: async (data) => {
    return await AxiosClient.get('ticket/list', data);
  },

  AdminList: async (data) => {
    return await AxiosClient.get("ticket/adminList", data);
  },

  LessonPriceList: async (data) => {
    return await AxiosClient.get("ticket/lessonPrice/list", data);
  },

  deleteLessonPrice: async (data) => {
    return await AxiosClient.del("ticket/lessonPrice/delete", data);
  },

  getDataList: async (data) => {
    return await AxiosClient.get("ticket/dataList", data);
  },

  create: async (data) => {
    return await AxiosClient.post("ticket", data);
  },

  createHolding: async (data) => {
    return await AxiosClient.post("ticket/holding", data);
  },

  deleteHolding: async (data) => {
    return await AxiosClient.del("ticket/holding/delete", data);
  },

  createTicketData: async (data) => {
    return await AxiosClient.post("ticket/data", data);
  },

  createLessonPrice: async (data) => {
    return await AxiosClient.post("ticket/lessonPrice/create", data);
  },

  refund: async (data) => {
    return await AxiosClient.patch("ticket/refund", data);
  },

  delete: async (data) => {
    return await AxiosClient.patch("ticket/delete", data);
  },

  deleteData: async (data) => {
    return await AxiosClient.del("ticket/data/delete", data);
  },

  update: async (data) => {
    return await AxiosClient.patch("ticket/update", data);
  },

  updateData: async (id, data) => {
    return await AxiosClient.patch(`ticket/data/update/${id}`, data);
  },

  addDays: async (data) => {
    return await AxiosClient.patch("ticket/addDays", data);
  },

  addDaysCancel: async (id, data) => {
    return await AxiosClient.patch(`ticket/addDays/cancel/${id}`, data);
  },

  transferLessons: async (data) => {
    return await AxiosClient.patch("ticket/transferLessons", data);
  },

  lessonBuy: async (data) => {
    return await AxiosClient.patch("ticket/lesson/buy", data);
  },
};

export default TicketClient;
