import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { AuthContext } from "../components/AuthenticatedComponent";
import Menubar from "../components/Menubar";
import Content from "../components/Content";
import moment from "moment";
import ReactApexChart from "react-apexcharts"
import AdminClient from "../utils/Http/AdminClient"
import BranchClient from "../utils/Http/BranchClient"
import BookClient from "../utils/Http/BookClient"
import DashboardClient from "../utils/Http/DashboardClient"
import DashboardBranchInfo from "../components/DashboardBranchInfo";
import DashboardDailyStatistics from "../components/DashboardDailyStatistics";
import DashboardMonthlyStatistics from "../components/DashboardMonthlyStatistics";

var constants = require("../common/Constants");
// const Daily = constants.statistics_type.Daily;
// const Montly = constants.statistics_type.Montly;
var RenderState = {
    BranchInfo: 1,
    DailyStatistics: 2,
    MonthlyStatistics: 3,
}
class Dashboard extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            render_state: RenderState.BranchInfo,
            branchArr: [],
            teacherArr: [],
            dashboard_start: moment().subtract(8, 'days').format('YYYY-MM-DD'),
            dashboard_end: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            dashboard_branch: '',
            dashdata: null,
            userListType: 0,
            userListName: "",
            daily_statistics: {
                register_user_count_series: [],
                register_user_count_option: {},
                user_count_series: [],
                user_count_option: {},
                sales_tickets_series: [],
                sales_tickets_option: {},
                sales_price_series: [],
                sales_price_option: {},
                user_funnel_series: [],
                user_funnel_option: {},
            },
            monthly_statistics: {
                register_user_count_series: [],
                register_user_count_option: {},
                user_count_series: [],
                user_count_option: {},
                sales_price_series: [],
                sales_price_option: {},
            },
            // statistics_type: Daily,
            selected_year: moment().year(),
            books_tomorrow: [],
        };
    }

    componentDidMount = async () => {
        if(this.context.role === "최고관리자" || this.context.role === "일반관리자")
        {
            const branchList = await BranchClient.getList();
            this.setState({
                branchArr: branchList.data.success ? branchList.data.result : [],
            });
        }
    };

    onClickExpiredSoonList = async () => {
        try {
            this.setState({
                userListType: 1,
                userListName: "수강권 만료 10일 이내 회원 리스트",
            });
        } catch (error) {
            alert(error);
        }
    };

    onClickUnreservedList = async () => {
        try {
            this.setState({
                userListType: 2,
                userListName: "미예약 회원 리스트",
            });
        } catch (error) {
            alert(error);
        }
    };

    onClickTomorrowLessons = async () => {
        try {
            this.setState({
                userListType: 3,
                userListName: "내일 레슨 예약",
            });
        } catch (error) {
            alert(error);
        }
    };

    GetTeacherName(teacher_id)
    {
        const teacher = this.state.teacherArr.find((teacher) => teacher._id === teacher_id);
        return teacher ? teacher.name : "";
    }

    render_LinkUserDetail(id, name) {
        return (
            <td>
                <Link to={"/user/userdetail/" + id}>
                    <a href={"/user/userdetail/" + id}>{name}</a>
                </Link>
            </td>
        );
    }

    render_userList() {
        switch (this.state.userListType) {
            case 1:
                return this.render_userList_expiredSoon();
            case 2:
                return this.render_userList_unreserved();
            case 3:
                return this.render_userList_booksTomorrow();
            default:
                return null;
        }
    }

    render_userList_expiredSoon() {
        return (
            <div className="table-responsive">
                <table className="table fixed-height">
                    <thead className="thead-light">
                        <tr>
                            <th>회원명</th>
                            <th>종료일</th>
                            <th>레슨 횟수</th>
                            <th>마지막 레슨일</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.dashdata.expiredSoon.map((item, index) => {
                        return (
                            <tr key={index}>
                                {/* {console.item} */}
                                {this.render_LinkUserDetail(item._id, item.name)}
                                <td>{moment(item.ticket.endDate).format("YYYY-MM-DD")}</td>
                                <td>
                                    {item.ticket.lesson} / {item.ticket.lesson_all + item.ticket.lesson_buy + item.ticket.lesson_carry_over}
                                </td>
                                <td>{moment(item.book.lesson_at).format("YYYY-MM-DD")}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }

    render_userList_unreserved() {
        return (
            <div className="table-responsive">
                <table className="table fixed-height">
                    <thead className="thead-light">
                        <tr>
                            <th>회원명</th>
                            <th>종료일</th>
                            <th>레슨 횟수</th>
                            <th>마지막 레슨일</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.dashdata.unreservedUsers.map((item, index) => (
                        <tr key={index}>
                            {/* {console.item} */}
                            {this.render_LinkUserDetail(item.user._id, item.user.name)}
                            <td>{moment(item.endDate).format("YYYY-MM-DD")}</td>
                            <td>
                                {item.lesson} / {item.lesson_all + item.lesson_buy + item.lesson_carry_over}
                            </td>
                            <td>{moment(item.book.lesson_at).format("YYYY-MM-DD")}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }
    render_userList_booksTomorrow() {
        // console.log('render_userList_booksTomorrow');
        // console.log(this.state.books_tomorrow);
        const show_branch = (this.context.role === '최고관리자' || this.context.role === '일반관리자') && this.state.dashboard_branch === '';
        return (
            <div className="table-responsive">
                <table className="table fixed-height">
                    <thead className="thead-light">
                        <tr>
                            {show_branch ? <th>지점</th> : null}
                            <th>강사</th>
                            <th>회원명</th>
                            <th>시간</th>
                            <th>카톡 알림</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.books_tomorrow.map((item, index) => (
                        <tr key={index}>
                            {show_branch ? <td>{this.state.branchArr.find((branch) => branch._id === item._branch).name}</td> : null}
                            <td>{this.GetTeacherName(item._teacher)}</td>
                            {this.render_LinkUserDetail(item.user._id, item.user.name)}
                            <td>{item.lesson_time}</td>
                            <td>{item.notification_send === true ? '전송 완료' : '미전송'}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }

    onChange = (e) => {
		e.preventDefault();

		this.setState({
			[e.target.name]: e.target.value,
		});
	};

    onChangeBranch = async (e) => {
        this.onChange(e);

        const _branch = this.context.role === "최고관리자" || this.context.role === "일반관리자" ? e.target.value : this.context.branch._id;
        // await this.LoadDashboard(_branch);
    }

    onClickDashboardSearch = async (e) =>{
        const _branch = this.context.role === "최고관리자" || this.context.role === "일반관리자" ? this.state.dashboard_branch : this.context.branch._id;
        await this.DashboardSearch(_branch);
    }

    onChangeMonthlyStatistics = async (e) => {
        e.preventDefault();

        const _branch = this.context.role === "최고관리자" || this.context.role === "일반관리자" ? this.state.dashboard_branch : this.context.branch._id;
        await this.LoadMonthlyStatistics(_branch, e.target.value);
    }

    LoadMonthlyStatistics = async (_branch, year) => {
        var query = {
            _branch: _branch,
            year: year,
        }

        const response = await DashboardClient.getMonthlyStatistics(query);
        // console.log(response);
        if(response.data.success)
        {
            const statistics_data = response.data;
            const results = statistics_data.result;
            const months = Object.keys(results);

            var register_users = [];
            // var total_users = [];
            var total_price = [];
            var new_users = [];
            var old_users = [];
            var total_users = [];
            // var sales_ticket = {};
            // var funnels = {};
            var formatted_months = [];
            const yesterday = moment().subtract(1, 'days');
            for(var i = 1; i <= 12; ++i)
            {
                formatted_months.push(`${i} 월`);
                if(months.some((x) => x == i) == false)
                {
                    new_users.push(0);
                    old_users.push(0);
                    total_price.push(0);
                    register_users.push(0);
                }
                else
                {
                    var days = 0;
                    if(yesterday.year() === year && yesterday.month() + 1 == i)
                        days = yesterday.date();
                    else
                        days = moment(`${year}-${i}`).daysInMonth();
                    // console.log(days);
                    new_users.push((results[i].new_user / days).toFixed(2));
                    old_users.push((results[i].old_user / days).toFixed(2));
                    total_users.push((results[i].new_user + results[i].old_user) / days);
                    register_users.push(results[i].register_user);
                    // total_users.push(results[date].total_user);
                    total_price.push(results[i].total_price);
                    // Object.keys(results[date].tickets).forEach((key) => {
                    //     if(Object.keys(sales_ticket).some(x => x == key) == false)
                    //         sales_ticket[key] = results[date].tickets[key];
                    //     else
                    //         sales_ticket[key] += results[date].tickets[key];
                    // });
                }
            };

            const register_user_count_series = [
                { name: '신규 회원', data: register_users }
            ];
            const register_user_count_option = {
                chart: { type: 'bar', height: 350, },
                plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' }, },
                dataLabels: { enabled: false },
                stroke: { show: true, width: 2, colors: ['transparent'] },
                labels: Object.keys(register_users),
                xaxis: { categories: formatted_months },
                yaxis: {
                    labels:{ formatter: function (val) { return val + " 명" } },
                },
                fill: { opacity: 1 },
                tooltip: { y: { formatter: function (val) { return val + " 명" } } },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: { height: 500 },
                        legend: { position: 'bottom' },
                        plotOptions: { bar: { horizontal: true }},
                        xaxis: { labels: {formatter: function (val) { return val + " 명"; } } },
                        yaxis: {
                            labels:{ formatter: function (val) { return val; } },
                        },
                    },
                }]
            };

            const user_count_series = [ { name: '신규', data: new_users }, { name: '기존', data: old_users } ];
            const user_count_option = {
                chart: { type: 'bar', height: 350, stacked: true },
                plotOptions: { bar: { horizontal: false } },
                dataLabels: { enabled: false },
                stroke: { width: 1, colors: ['transparent'] },
                xaxis: { categories: formatted_months },
                yaxis: {
                    labels:{ formatter: function (val) { return val + " 명" } },
                },
                fill: { opacity: 1 },
                tooltip: { shared: true, intersect:false, y: { formatter: function (val) { return val + " 명" } } },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                  },
                  responsive: [{
                      breakpoint: 480,
                      options: {
                          chart: { height: 500 },
                          legend: { position: 'bottom' },
                          plotOptions: { bar: { horizontal: true }},
                          xaxis: { labels: {formatter: function (val) { return val + " 명"; } } },
                          yaxis: {
                              labels:{ formatter: function (val) { return val; } },
                          },
                      }
                  }]
            };
            // console.log(total_price);
            // console.log(months);
            const sales_price_series = [ { name: 'Price', data: total_price } ];
            const sales_price_option = {
                chart: { type: 'bar', height: 350, },
                plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' }, },
                dataLabels: { enabled: false },
                stroke: { show: true, width: 2, colors: ['transparent'] },
                xaxis: { categories: formatted_months },
                yaxis: {
                    labels:{ formatter: function (val) { return val.toLocaleString("en-US") + " 원" } },
                },
                fill: { opacity: 1 },
                tooltip: { y: { formatter: function (val) { return val.toLocaleString("en-US") + " 원" } } },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: { height: 500 },
                        legend: { position: 'bottom' },
                        plotOptions: { bar: { horizontal: true }},
                        xaxis: { labels: {formatter: function (val) { return val / 10000 + "만원"; } } },
                        yaxis: {
                            labels:{ formatter: function (val) { return val; } },
                        },
                    }
                }]
            };

            this.setState({
                monthly_statistics: {
                    register_user_count_option: register_user_count_option,
                    register_user_count_series: register_user_count_series,
                    user_count_series: user_count_series,
                    user_count_option: user_count_option,
                    sales_price_option: sales_price_option,
                    sales_price_series: sales_price_series,
                },
                selected_year: year
            });
        }
        else
        {
            alert(response.data.message);
        }
    }

    DashboardSearch = async (_branch) =>{
        try
        {
            var query = {
                _branch: _branch,
                startDate: this.state.dashboard_start,
                endDate: this.state.dashboard_end,
            }

            const dashboarStatisticsdRes = await DashboardClient.getDailyStatistics(query);
            // console.log(dashboarStatisticsdRes.data);
            if(dashboarStatisticsdRes.data.success)
            {
                const statistics_data = dashboarStatisticsdRes.data;
                const results = statistics_data.result;
                const dates = Object.keys(results);

                var register_users = [];
                var total_users = [];
                var new_users = [];
                var old_users = [];
                var total_price = [];
                var sales_ticket = {};
                var funnels = {};
                dates.forEach((date) => {
                    register_users.push(results[date].register_user);
                    total_users.push(results[date].total_user);
                    new_users.push(results[date].new_user);
                    old_users.push(results[date].old_user);
                    total_price.push(results[date].total_price);
                    Object.keys(results[date].tickets).forEach((key) => {
                        if(Object.keys(sales_ticket).some(x => x == key) == false)
                            sales_ticket[key] = results[date].tickets[key];
                        else
                            sales_ticket[key] += results[date].tickets[key];
                    });

                    Object.keys(results[date].funnels).forEach((key) => {
                        const key_name = constants.funnel[key];
                        if(Object.keys(funnels).some(x => x == key_name) == false)
                            funnels[key_name] = results[date].funnels[key];
                        else
                            funnels[key_name] += results[date].funnels[key];
                    });
                });

                var sales_array = [];
                Object.keys(sales_ticket).forEach((key) => {
                    sales_array.push({key: key, value: sales_ticket[key]});
                });

                const sales_keys = [];
                const sales_values = [];
                sales_array.sort((a, b) => {
                    if(a.value < b.value)
                        return 1;
                    return -1;
                }).forEach((sale) => {
                    if(sale.value > 0)
                    {
                        sales_keys.push(sale.key);
                        sales_values.push(sale.value);
                    }
                });

                // console.log(sales_keys);
                // console.log(sales_values);
                var funnels_array = [];
                Object.keys(funnels).forEach((key) => {
                    funnels_array.push({key: key, value: funnels[key]});
                });

                const funnel_keys = [];
                const funnel_values = [];
                funnels_array.sort((a, b) => {
                    if(a.value < b.value)
                        return 1;
                    return -1;
                }).forEach((funnel) => {
                    if(funnel.value > 0)
                    {
                        funnel_keys.push(funnel.key);
                        funnel_values.push(funnel.value);
                    }
                });


                const user_count_series = [ { name: '신규', data: new_users }, { name: '기존', data: old_users }, {name: '전체', data: total_users} ];
                const user_count_option = {
                    chart: { type: 'bar', height: 350 },
                    plotOptions: { bar: { horizontal: false, endingShape: 'rounded' }, },
                    dataLabels: { enabled: false },
                    stroke: { show: true, width: 2, colors: ['transparent'] },
                    xaxis: { categories: dates },
                    yaxis: { title: { text: '명' } },
                    fill: { opacity: 1 },
                    tooltip: { y: { formatter: function (val) { return val + " 명" } } },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: { height: 500 },
                            legend: { position: 'bottom' },
                            plotOptions: { bar: { horizontal: true }},
                            xaxis: { labels: {formatter: function (val) { return val + " 명"; } } },
                            yaxis: {
                                labels:{ formatter: function (val) { return val; } },
                            },
                        }
                    }]
                };

                const sales_price_series = [ { name: 'Price', data: total_price } ];
                const sales_price_option = {
                    chart: { type: 'bar', height: 350, },
                    plotOptions: { bar: { horizontal: false, endingShape: 'rounded' }, },
                    dataLabels: { enabled: false },
                    stroke: { show: true, width: 2, colors: ['transparent'] },
                    xaxis: { categories: dates, },
                    yaxis: {
                        labels:{ formatter: function (val) { return val.toLocaleString("en-US") + " 원" } },
                        title: { text: '원' }
                    },
                    fill: { opacity: 1 },
                    tooltip: { y: { formatter: function (val) { return val.toLocaleString("en-US") + " 원" } } },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: { height: 500 },
                            legend: { position: 'bottom' },
                            plotOptions: { bar: { horizontal: true }},
                            xaxis: { labels: {formatter: function (val) { return Number.isInteger(val) ? val / 10000 + "만원" : val; } } },
                            yaxis: {
                                labels:{ formatter: function (val) { return val; } },
                            },
                        }
                    }]
                };

                const sales_tickets_series = sales_values;
                const sales_tickets_option = {
                    chart: { width: 380, type: 'pie', },
                    labels: sales_keys,
                    fill: {
                        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#4CAF50', '#546E7A', '#D4526E', '#13D8AA', '#A5978B',
                                 '#4ECDC4', '#C7F464', '#81D4FA', '#FD6A6A', '#2B908F', '#F9A3A4', '#90EE7E', '#69D2E7', '#F86624', '#2E294E']
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: { width: 200 },
                            legend: { position: 'bottom' }
                        }
                    }]
                };
                // console.log(funnels);
                const user_funnel_series = funnel_values;
                const user_funnel_option = {
                    chart: { width: 380, height: 350, type: 'pie', },
                    labels: funnel_keys,
                    fill: {
                        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#4CAF50', '#546E7A', '#D4526E', '#13D8AA', '#A5978B',
                                 '#4ECDC4', '#C7F464', '#81D4FA', '#FD6A6A', '#2B908F', '#F9A3A4', '#90EE7E', '#69D2E7', '#F86624', '#2E294E']
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: { width: 200 },
                            legend: { position: 'bottom' }
                        }
                    }]
                };

                const register_user_count_series = [
                    { name: '전체 회원', type: 'column', data: total_users },
                    { name: '신규 회원', type: 'line', data: register_users }
                ];
                const register_user_count_option = {
                    chart: { height: 350, type: 'line', },
                    stroke: { width: [0, 4] },
                    dataLabels: { enabled: true, enabledOnSeries: [1] },
                    labels: Object.keys(register_users),
                    xaxis: { categories: dates },
                    yaxis: [
                        { title: { text: '전체 회원' } },
                        { opposite: true, title: { text: '신규 회원' } }
                    ]
                };

                this.setState({
                    daily_statistics: {
                        register_user_count_option: register_user_count_option,
                        register_user_count_series: register_user_count_series,
                        user_count_option: user_count_option,
                        user_count_series: user_count_series,
                        sales_price_option: sales_price_option,
                        sales_price_series: sales_price_series,
                        sales_tickets_option: sales_tickets_option,
                        sales_tickets_series: sales_tickets_series,
                        user_funnel_option: user_funnel_option,
                        user_funnel_series: user_funnel_series,
                    }
                });
            }
            else
            {
                alert(dashboarStatisticsdRes.data.message);
            }

            await this.LoadMonthlyStatistics(_branch, moment().year());
        }
        catch(ex)
        {
            alert(ex);
        }
    }

    GetBooks = async (_branch) => {
        console.log('getbooks');
        try
        {
            var tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
            const books_tomorrow = await BookClient.getItems({
                _branch: _branch,
                lesson_at: tomorrow,
            });

            if(books_tomorrow.data.success)
            {
                // console.log(books_tomorrow.data.result)
                const list = books_tomorrow.data.result.filter((item) => item.status === 'apply').sort((a, b) => {
                    if(a.lesson_time < b.lesson_time)
                        return -1;
                    else
                        return 1;
                });

                this.setState({
                    books_tomorrow: list,
                });
            }
            else
            {
                alert(books_tomorrow.data.message);
            }
        }
        catch(ex)
        {
            alert(ex);
        }
    }

    render_statistics_daily(){
        // console.log("render_statistics_daily");
        return (
        <>
            <div>
                <div className="col-lg-7">
                    <div className="row">
                        <div className="col duration">
                            <input
                                type="date"
                                className="form-control"
                                name="dashboard_start"
                                onChange={this.onChange}
                                defaultValue={moment(
                                    this.state.dashboard_start
                                ).format("YYYY-MM-DD")}
                                required="required"
                            />
                        </div>
                        <div className="col">
                            <input
                                type="date"
                                className="form-control"
                                name="dashboard_end"
                                onChange={this.onChange}
                                defaultValue={moment(this.state.dashboard_end).format(
                                    "YYYY-MM-DD"
                                )}
                                // disabled={true}
                            />
                        </div>
                        <div>
                            <button // className="btn btn-lg btn-primary mr-1"
                                className="btn btn-sm btn-primary"
                                onClick={
                                    this.onClickDashboardSearch
                                }
                            >
                                검색
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            <label className="form-label" for="site-name"> 신규 회원 수 </label>
                <div id="chart">
                    <ReactApexChart options={this.state.daily_statistics.register_user_count_option} series={this.state.daily_statistics.register_user_count_series} type="line" height={350}/>
                </div>
            </div>
            <div className="divider"></div>
            <div>
                <label className="form-label" for="site-name"> 회원 수 </label>
                <div id="columnchart">
                    <ReactApexChart options={this.state.daily_statistics.user_count_option} series={this.state.daily_statistics.user_count_series} type="bar" height={350}/>
                </div>
            </div>
            <div className="divider"></div>
            {this.context.role === "최고관리자" || this.context.role === "일반관리자" || this.context.role === "지점관리자" ? (
                <div>
                    <div>
                        <label className="form-label" for="site-name"> 매출 </label>
                        <div id="columnchart">
                            <ReactApexChart options={this.state.daily_statistics.sales_price_option} series={this.state.daily_statistics.sales_price_series} type="bar" height={350}/>
                        </div>
                    </div>
                    <div className="divider"></div>
                </div>
                ) : null
            }
            <div>
                <label className="form-label" for="site-name"> 수강권 구매 비율 </label>
                <div id="chart">
                    <ReactApexChart options={this.state.daily_statistics.sales_tickets_option} series={this.state.daily_statistics.sales_tickets_series} type="pie" height={350}/>
                </div>
            </div>
            <div className="divider"></div>
            <div>
                <label className="form-label" for="site-name"> 회원 유입 경로 </label>
                <div id="chart">
                    <ReactApexChart options={this.state.daily_statistics.user_funnel_option} series={this.state.daily_statistics.user_funnel_series} type="pie" height={350} />
                </div>
            </div>
        </>
        );
    }

    render_statistics_monthly(){

    }

    setRenderState(render_state) {
        this.setState({
            render_state: render_state
        })
    }

    render_component() {
        const renderSales = this.context.role === "최고관리자" || this.context.role === "일반관리자" || this.context.role === "지점관리자";
        const branch = this.context.role === "최고관리자" || this.context.role === "일반관리자" ? this.state.dashboard_branch : this.context.branch._id;
        switch(this.state.render_state) {
            case RenderState.BranchInfo:
                return (<DashboardBranchInfo branchList={this.state.branchArr} _branch={branch} renderSales={renderSales}/>);
            case RenderState.DailyStatistics:
                return (<DashboardDailyStatistics branchList={this.state.branchArr} _branch={branch} renderSales={renderSales}/>);
            case RenderState.MonthlyStatistics:
                return (<DashboardMonthlyStatistics branchList={this.state.branchArr} _branch={branch} renderSales={renderSales}/>);
        }
        return null;
    }

    render() {
        if (
            this.context.role !== "일반관리자" &&
            this.context.role !== "최고관리자" &&
            this.context.role !== "지점관리자" &&
            this.context.role !== "매니저"
        ) {
            return (
                <div>
                    <Menubar />
                    <div className="nk-content">
                        <div className="container-fluid">
                            접근 권한이 없습니다.
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <>
                <Menubar />
                <Content>
                    <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">대시보드</h3>
                    </div>

                    <div style={{marginTop: 30}}>
                        <div class="btn-group mb-2" aria-label="Basic example">
                            <button
                                onClick={() => this.setRenderState(RenderState.BranchInfo)}
                                className="btn btn-outline-primary"
                            >
                                현황판
                            </button>
                            <button
                                onClick={() => this.setRenderState(RenderState.DailyStatistics)}
                                className="btn btn-outline-primary"
                            >
                                일간 통계
                            </button>
                            <button
                                onClick={() => this.setRenderState(RenderState.MonthlyStatistics)}
                                className="btn btn-outline-primary"
                            >
                                월간 통계
                            </button>
                        </div>
                    </div>
                    {this.context.role === "최고관리자" || this.context.role === "일반관리자" ? (
                        <div>
                            <div className="row g-3 align-center">
                                <div className="col-lg-5">
                                    <div className="form-group">
                                        <label
                                            className="form-label"
                                            for="site-name"
                                        >
                                            지점
                                        </label>
                                        <span className="form-note">
                                            지점 선택
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="form-group">
                                        <div className="form-control-wrap">
                                            <select
                                                className="form-control"
                                                name="dashboard_branch"
                                                onChange={
                                                    this.onChangeBranch
                                                }
                                                required="required"
                                                //  value={this.state._branch ? this.state._branch.name : null}
                                                defaultValue={
                                                    this.state
                                                        .dashboard_branch
                                                        ? this.state
                                                                .dashboard_branch
                                                        : "없음"
                                                }
                                            >
                                                <option
                                                    value=""
                                                >
                                                    전체
                                                </option>
                                                {this.state.branchArr.map(
                                                    (
                                                        item,
                                                        index
                                                    ) => (
                                                        <option
                                                            key={
                                                                index
                                                            }
                                                            value={
                                                                item._id
                                                            }
                                                        >
                                                            {
                                                                item.name
                                                            }
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="divider"></div>
                        </div>
                    ) : null}
                    {this.render_component()}
                    {/* {this.render_statistics_monthly()} */}
                    {/* {this.render_statistics_daily()} */}
                </Content>
            </>
        );
    }
}

export default withRouter(Dashboard);
